// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function QrCodeCard(props) {
    const { data, layout, onUploadClick } = props;
    const { ClickCount, RedeemedCount, LongWebAddress, Name, ShortCodeMedias } = data
    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );

    return (
        <div className={cardClasses}>
            <div className="post-card__image">
                <a target="_blank" href={LongWebAddress}>
                    {/* <img src={postCardRecords?.images.length > 1 ? postCardRecords?.images[postCardRecords?.images.length - 1]?.url : postCardRecords?.images[0]?.url} alt={postCardRecords?.images?.name} /> */}
                    <img src={ShortCodeMedias?.length > 0 && ShortCodeMedias[0].ItemMedia?.CloudDriveIdentifier} />
                </a>
            </div>
            <div className="post-card__info">
                <div>
                    <div className="post-card__name">
                        <a target="_blank" href={LongWebAddress}>
                            {Name}
                        </a>
                    </div>

                    <div className="post-card__content">
                        {`Redeem Count : ${RedeemedCount}`}
                    </div>
                    <div className="post-card__content mt-1">
                        {`Click Count : ${ClickCount}`}
                    </div>

                    <div className="product-card__read-more">
                        <Button
                            type="button"
                            onClick={() => onUploadClick(data)}
                            className={'btn-lg fs-6 btn-primary border-0 product-card__addtocart w-100 btn btn-primary'}
                        >
                            Upload Media
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

QrCodeCard.propTypes = {
    post: PropTypes.object,
    layout: PropTypes.oneOf(['grid-nl', 'grid-lg', 'list-nl', 'list-sm']),
};

export default QrCodeCard;
