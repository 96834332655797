const SectionHeading = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF) => {
    let contentObject = JSON.parse(mainContent || "{}")

    let urlFormation1 = `https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/longborder/20240531071043023_LongBorder[1].png`

    documentMethods.on('pageAdded', function () {
        stylesheet?.value?.backgroundImage && documentMethods.image(stylesheet.value.backgroundImage.left, 0, 0, { height: documentMethods.page.height, width: documentMethods.page.width })
    })
    stylesheet?.value?.backgroundImage && documentMethods.image(stylesheet.value.backgroundImage.left, 0, 0, { height: documentMethods.page.height, width: documentMethods.page.width })

    stylesheet?.value?.screenTitle && documentMethods.font(stylesheet.value.screenTitle.font).fontSize(stylesheet.value.screenTitle.fontSize).fillColor(stylesheet.value.screenTitle.color).text(contentObject.title, { align: 'left' })

    stylesheet.title !== 'montkelian' && documentMethods.moveDown()

    stylesheet.title === 'montkelian' && documentMethods.image(await getBase64ImageFromURL(urlFormation1), 60, 85, { width: 500 }).moveDown()

    contentObject?.description && stylesheet?.value?.screenContentDescription &&
        documentMethods
            .text("", { continued: true })
            .text("", { continued: true })
            .font(stylesheet.value.screenContentDescription.font)
            .fontSize(stylesheet.value.screenContentDescription.fontSize)
            .text(contentObject?.description, { ...stylesheet.value.screenContentDescription })
}

export default SectionHeading