const NotesWithHeader = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF, calculateBlockHeight, screenCount, deckLength, deckScreenLength, screenType, imageHeight, blockHeight, index) => {
    let contentObject = JSON.parse(mainContent || "{}")

    const borderBottom = await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/ShortBorder/20240531063956992_ShortBorderCropped.png');
    const headerBgRed = await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/TableShortHeaderRed/20240531072108656_ShortHeader_RedCropped.png');

    if ((documentMethods.page.height - 120 - documentMethods.y) < imageHeight) {
        documentMethods.text("", documentMethods.x, documentMethods.y + (documentMethods.page.height - documentMethods.y), { continued: true });
    } else documentMethods.text("", documentMethods.x, documentMethods.y, { continued: true, columns: stylesheet?.title !== 'dungeon' ? 2 : 1 });

    // documentMethods.moveDown()
    documentMethods.text("", { continued: true, columns: stylesheet?.title !== 'dungeon' ? 2 : 1 })
    stylesheet.title === 'montkelian' && documentMethods.image(headerBgRed, documentMethods.x - 10, documentMethods.y + 15, {
        width: 240,
        continued: true
    })
    stylesheet?.value?.screenHeading && documentMethods
        .fillColor(stylesheet.title === 'montkelian' ? 'white' : 'black')
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .font(stylesheet.value.screenHeading.font)
        .fontSize(stylesheet.title === 'montkelian' ? 13 : 15)
        .text(contentObject?.title, stylesheet.title === 'montkelian' ? documentMethods.x + 50 : documentMethods.x, stylesheet.title === 'montkelian' ? index === 0 ? documentMethods.y + 20 : documentMethods.y + 3 : documentMethods.y, { continued: true })

    stylesheet.title === 'montkelian' ? documentMethods.text("", documentMethods.x - 50, documentMethods.y + 23, { continued: true }) : documentMethods.moveDown();

    documentMethods.text("", documentMethods.x, documentMethods.y, { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })

    stylesheet.title === 'montkelian' && documentMethods.rect(documentMethods.x - 10, documentMethods.y - 3, 240, imageHeight).fill('#f1ece3')
        .fill('black')
        .stroke()
    stylesheet?.value?.screenContentDescription && documentMethods.fontSize(13)
        .text("", { width: 150, continued: true })
        .font(stylesheet.value.screenContentDescription.font)
        .text("", documentMethods.x, documentMethods.y, { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
    // .text(formattedTemp[i]?.description, documentMethods.x, documentMethods.y, { width: 150, continued: true })
    stylesheet?.value?.screenHeading && stylesheet?.value?.screenContentDescription && convertHTMLToPDF(contentObject?.description, 'header');

    stylesheet.title === 'montkelian' && documentMethods
        .moveDown(2)
        .image(borderBottom, documentMethods.x - 11, index === 1 ? documentMethods.y : documentMethods.y - 10, { width: 242 }).text("", { continued: true })
}

export default NotesWithHeader