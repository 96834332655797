import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, InputLabel, FormControl, MenuItem, Select } from '@mui/material';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FormattedMessage } from 'react-intl';
import { Label, Spinner, InputGroupAddon } from 'reactstrap';
import { Col, Row, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import Compressor from 'compressorjs';
import classNames from 'classnames';
import _, { isEmpty } from 'lodash';
import * as Yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Creatable from "react-select/creatable";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import { withAsyncPaginate } from "react-select-async-paginate";
import { getAllBookContent } from './bookBuilderSlice';
import { PUBLICATION_TAG, USER_PUBLICATION_TAG } from 'common/tagFile';
import { useAddMutation, useLazyGetRecordsQuery, useUpdateMutation } from 'services/gamecodexApi';
import AsyncPaginates from 'global/SearchResult';
import CustomModal from 'common/CustomModal';
import CustomInput from 'global/CustomInput';
import { errorToast, successToast } from 'common/toast';
import styleSheetData from './styleSheetData';
import './bookBuilder.scss'

export const isSet = (value) => {
  return value !== undefined && value !== null;
};
const DynamicStepper = (props) => {
  const { user, isAuthenticated } = useAuth0()
  const currentUser = user
  const { classes, viewBook, downloadPdf, appData, createBook, styleSheet, bookImage, setBookImage, appNameField, cloneBtn, setAppNameField, setStyleSheet, setCreateBook, setAppData, setViewBook, showModal, selectedBook, setShowModal, setPreviewPanel, PUBLICATION_SECTION_TAG } = props;
  const [screenActiveStep, setScreenActiveStep] = useState(0);
  const [contentActiveStep, setContentActiveStep] = useState(0);
  const [template, setTemplate] = useState("");
  const [fileName, setFileName] = useState({});
  const [isOpenContentTypeModal, setIsOpenContentTypeModal] = useState({})
  const [confirmationModal, setConfirmationModal] = useState({})
  const [initialValue, setInitialValues] = useState()
  const [cloned, setCloned] = useState({})
  const [keyName, setKeyName] = useState("")
  const [selectedEmails, setSelectedEmails] = useState([]);
  const userId = localStorage.getItem("userId");
  const [currentOperationMode, setCurrentOperationMode] = useState({ status: null, currentOperationData: { title: "", primaryButton: { title: "", isLoading: false }, secondaryButton: { title: "", isLoading: false } } });
  const [invitePlayer] = useAddMutation()
  const dispatch = useDispatch()
  const sort = {
    sortDirection: 'asc',
    accessor: 'Id',
  };
  const crossSort = {
    sortDirection: 'asc',
    accessor: 'SortOrder',
  };
  const scrollingSurface = useRef(null)
  const [addMedia, { isLoading: isMediaAddLoading }] = useAddMutation();
  const [cloneApplication, { isLoading: isCloneApplicationLoading }] = useAddMutation();
  const [addApplication, { isLoading: isNewApplicationLoading }] = useAddMutation();
  const [updateApplication, { isLoading: updatingApplication }] = useUpdateMutation();
  const [addApplicationMedia] = useAddMutation();
  const [updateApplicationMedia] = useUpdateMutation();
  const [editor, setEditor] = useState(null);
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

  const editorConfiguration = {
    toolbar: {
      items: [
        'bold',
        'italic',
        // '|',
        // 'bulletedList',
        // 'numberedList',
      ]
    }
  };

  const [
    getPublication,
    {
      data: publicationData,
      isLoading: isPublicationDataLoading
    }
  ] = useLazyGetRecordsQuery();
  const [
    getPublicationSection,
    {
      data: publicationSection,
      isFetching: isPublicationSectionFetching
    }
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getPublication({
      columns: [],
      entity: "Publication",
      sort: sort,
      tag: PUBLICATION_TAG,
      globalFilter: [],
      expand: "UserPublications,PublicationType,MasterClone"
    })
  }, [])

  useEffect(() => {
    const initialVal = publicationSection?.value.map((app) => {
      let splitSectionName = app?.Section?.Name?.split(' - ')
      let screenName = splitSectionName?.[splitSectionName.length - 1]
      return {
        publicationId: app?.PublicationId,
        zoneId: app?.Section?.Id,
        screenName,
        sectionType: { label: `${app?.Section?.SectionTypeId} - ${app?.Section?.SectionType?.Name}`, value: app?.Section?.SectionType },
        sortOrder: app?.SortOrder,
        contents: app?.Section?.SectionContents.map((sectionContent) => {
          let splitContentName = sectionContent?.Content?.Name?.split(' - ')
          let contentName = splitContentName?.[splitContentName.length - 1]
          return {
            Id: sectionContent?.ContentId,
            sortOrder: sectionContent?.SortOrder,
            // componentId: sectionContent?.ComponentId,
            contentName,
            contentType: { label: `${sectionContent?.Content?.ContentTypeId} - ${sectionContent?.Content?.ContentType?.Name}`, value: sectionContent?.Content?.ContentType },
            ...((sectionContent?.Content?.ContentType?.Name === "Table" || sectionContent?.Content?.ContentType?.Name === "SmallTable") && { tableFormat: sectionContent?.Content?.PubRandomEncounterContents?.length ? { label: `${sectionContent?.Content?.PubRandomEncounterContents?.[0]?.RandomEncounterId} - ${sectionContent?.Content?.PubRandomEncounterContents?.[0].RandomEncounter?.Name}`, value: sectionContent?.Content?.PubRandomEncounterContents?.[0].RandomEncounter } : "", randomEncounterContentId: sectionContent?.Content?.PubRandomEncounterContents?.[0]?.Id }),
            innerContent: sectionContent?.Content?.ContentObject ? JSON.parse(sectionContent?.Content?.ContentObject) : {}
          }
        })
      }
    })

    setInitialValues(initialVal)
  }, [publicationSection])

  useEffect(() => {
    cloneBtn && setTemplate({ id: selectedBook?.Id })
  }, [selectedBook, cloneBtn])

  useEffect(() => {
    if (viewBook?.id) {
      getPublicationSection({
        columns: [],
        entity: "PublicationSection",
        sort: crossSort,
        tag: PUBLICATION_SECTION_TAG,
        columnFilter: { PublicationId: viewBook?.id },
        globalFilter: [],
        expand: "Publication,Section($expand=SectionType,SectionContents($expand=Content($expand=ContentType,PubRandomEncounterContents($expand=RandomEncounter));$filter=IsDeleted ne true;$orderby=SortOrder asc))"
      })

      const clonedPubSection = publicationData?.value?.reduce((acc, crr) => {
        if (crr.Id === viewBook?.product?.MasterCloneId) acc = crr
        return acc
      }, "")

      setCloned(clonedPubSection)

      const pubName = viewBook?.name?.replace(` - ${clonedPubSection?.Name}`, "")
      setAppData(viewBook)
      setStyleSheet(viewBook?.product?.Stylesheet)
      setAppNameField(pubName)
      setBookImage({ url: viewBook?.product?.PublicationMedias?.[0]?.Media?.Url || "https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/content/381/20240604103702720_pv2hb2gdo9e8tvmu5zmj.png", name: viewBook?.product?.PublicationMedias?.[0]?.Media?.Name, applicationId: viewBook?.id, applicationMediaId: viewBook?.product?.PublicationMedias?.[0]?.Id, mediaId: viewBook?.product?.PublicationMedias?.[0]?.MediaId })
    }
    const element = document.getElementById('book_creation');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [viewBook?.id])

  useEffect(() => {
    styleSheet && styleSheetData(styleSheet).then((resp) => {
      dispatch(getAllBookContent(resp))
    })
  }, [styleSheet])

  const changeStylesheet = (event) => {
    setStyleSheet(event.target.value);
    setPreviewPanel(<div className='block block-products-carousel block-products-carousel--loading'>
      <div className="block-products-carousel__preloader" style={{ marginTop: '50%' }}>
      </div>
    </div>)
  };

  const handleEditorChange = (editor, values, screenIndex, contentIndex, index, data, setFieldValue) => {
    const contentObjectData =
      values.screen && values.screen;
    contentObjectData[screenIndex].contents[contentIndex].innerContent.lists[index][data] = editor.getData()
    setFieldValue("screen", contentObjectData);
  };

  const clonedApplication = (appName, appId) => {
    cloneApplication({
      entity: `ClonePublication`,
      query: `prefix=${appName}&tableName=Publication&id=${appId.id}&stylesheet=${styleSheet}`,
      tag: [PUBLICATION_TAG, PUBLICATION_SECTION_TAG, USER_PUBLICATION_TAG]
    }).then((res) => {
      setViewBook({ id: res.data.id, btn: "Edit", name: res.data.name, product: { MasterCloneId: res.data.masterCloneId, Stylesheet: res.data.stylesheet } })
      getPublicationSection({
        columns: [],
        entity: "PublicationSection",
        sort: crossSort,
        tag: PUBLICATION_SECTION_TAG,
        columnFilter: { PublicationId: res.data.id },
        globalFilter: [],
        expand: "Publication,Section($expand=SectionContents($expand=Content($expand=ContentType,PubRandomEncounterContents($expand=RandomEncounter));$filter=IsDeleted ne true;$orderby=SortOrder asc))"
      })
    })
    setShowModal(false)
    setCreateBook(true)
    const element = document.getElementById('book_creation');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const submitCampaignForm = (values) => {
    setPreviewPanel(
      <div className='block block-products-carousel block-products-carousel--loading'>
        <div className="block-products-carousel__preloader" style={{ marginTop: '50%' }}>
        </div>
      </div>
    )
    let payload = []
    let prefix = appNameField
    values.screen.forEach((screen, screenIndex) => {
      screen.contents.forEach((mainContent, contentIndex) => {
        payload.push({
          publicationId: screen?.publicationId,
          prefix,
          sectionName: screen.screenName,
          sectionTypeId: screen.sectionType?.value?.Id,
          ...(screen?.zoneId && { sectionId: screen.zoneId }),
          PublicationSectionSortOrder: screenIndex + 1,
          SectionContentSortOrder: contentIndex + 1,
          content: {
            ...(mainContent?.Id && { Id: mainContent.Id, }),
            name: mainContent.contentName,
            contentTypeId: mainContent.contentType.value.Id,
            contentObject: JSON.stringify(mainContent.innerContent)
          },
          ...((mainContent.contentType.value.Name === 'Table' || mainContent.contentType.value.Name === 'SmallTable') && mainContent?.tableFormat && { randomEncounterId: ((mainContent.contentType.value.Name === 'Table' || mainContent.contentType.value.Name === 'SmallTable') && !mainContent.tableFormat?.value?.RandomEncounterId) ? mainContent.tableFormat?.value?.Id : mainContent.tableFormat?.value.RandomEncounterId }),
          ...((mainContent.contentType.value.Name === 'Table' || mainContent.contentType.value.Name === 'SmallTable') && mainContent.randomEncounterContentId && {
            randomEncounterContentId: mainContent.randomEncounterContentId
          })
        })
      })
    })

    const oldName = viewBook?.name?.replace(` - ${cloned?.Name}`, "")
    if (appNameField !== oldName || styleSheet !== viewBook.product.Stylesheet) {
      updateApplication({
        entity: 'Publication',
        data: {
          Id: viewBook?.id,
          Name: appNameField,
          Description: "",
          IsCloned: viewBook.product.IsCloned,
          MasterCloneId: viewBook.product.MasterCloneId,
          Stylesheet: styleSheet,
          PublicationTypeId: publicationSection.value[0]?.Publication?.PublicationTypeId
        },
        tag: [PUBLICATION_TAG, PUBLICATION_SECTION_TAG, USER_PUBLICATION_TAG]
      })
    }
    addApplication({
      entity: `PubContent/AddMultipleContent`,
      data: payload,
      tag: [PUBLICATION_SECTION_TAG, USER_PUBLICATION_TAG]
    }).then(() => {
      setAppData(viewBook)
    })
  }

  const onSubmitInvitation = (values) => {
    const selectedEmailList = [];

    selectedEmails.forEach((item) => selectedEmailList.push({ email: item.value, isNew: item.__isNew__ }))

    const invitedUsers = { userEmail: currentUser?.email, domain: process.env.REACT_APP_LOGIN_HOST, publicationId: viewBook?.id, emailLists: selectedEmailList }
    try {
      ((values.Component !== null && !isEmpty(values.Component)) || selectedEmails?.length) &&
        invitePlayer({
          entity: 'PublicationInvitation',
          data: invitedUsers,
        }).then(() => {
          setCurrentOperationMode({ status: null })
          successToast(<FormattedMessage id="successToast.PlayerList.InvitationSent" defaultMessage="Invitation sent successfully." />);
          setSelectedEmails([])
        })
    } catch (error) {
      errorToast(<FormattedMessage id="errorToast.EditAvatar.AddingError" defaultMessage="Error while adding avatar-attribute." />);
    }
  }

  const userInvitation = () => {
    let inviteData = { title: "Invite Users", button: { title: "Cancel", isLoading: false }, secondaryButton: { title: "Send invitation", isLoading: false } }
    setCurrentOperationMode({ status: 'Invite', currentOperationData: inviteData })
  }

  const blockClasses = classNames('block block-products-carousel', {
    'block-products-carousel--loading': true,
    'block-products-carousel--has-items': false,
  });

  const uploadImage = (setFieldValue, values, screenIndex = 0, contentIndex = 0, index = 0) => {
    new Compressor(values[`File${index}`] || values['File'], {
      quality: 0.8,
      success: (compressedResult) => {
        if (values[`File${index}`]) values.screen[screenIndex].contents[contentIndex].innerContent.images[index].url = ""
        var formData = new FormData();
        const newFile = new File([values[`File${index}`] || values['File']], compressedResult.name, {
          type: compressedResult.type,
        });
        if (values[`File${index}`] !== undefined || values['File'] !== undefined) {
          formData.append("file", newFile);
        }
        addMedia({
          entity: `PubMedia?pathFolderName=content`,
          data: formData,
          tag: PUBLICATION_TAG,
        }).then((response) => {
          if (!response.error) {
            if (values[`File${index}`]) {
              const contentObjectData = values.screen && values.screen;
              contentObjectData[screenIndex].contents[contentIndex].innerContent.images[index].name = response.data.Name;
              contentObjectData[screenIndex].contents[contentIndex].innerContent.images[index].url = response.data.Url;
              setFieldValue("screen", contentObjectData);
              successToast(<FormattedMessage id="successToast.Publisher.MediaUploaded" defaultMessage='Media uploaded successfully' />);
              setFieldValue(`File${index}`, "");
            } else setFieldValue({ ...bookImage, url: response.data.Url, name: response.data.Name, mediaId: response.data.Id })
          }
        });
      },
    });
  };

  const onModalHide = () => {
    if (isOpenContentTypeModal?.action === 'Add') {
      isOpenContentTypeModal?.remove(isOpenContentTypeModal?.entity === "SectionType" ? isOpenContentTypeModal?.screenIndex : isOpenContentTypeModal?.contentIndex)
      setIsOpenContentTypeModal({ ...isOpenContentTypeModal, isOpen: false })
    } else setIsOpenContentTypeModal({ ...isOpenContentTypeModal, isOpen: false })
  }

  const onModalSubmit = () => {
    const contentValue = isOpenContentTypeModal?.allValues?.screen;
    if (isOpenContentTypeModal?.entity === "PubContentType") {
      contentValue[isOpenContentTypeModal?.screenIndex].contents[isOpenContentTypeModal?.contentIndex]['contentName'] = isOpenContentTypeModal?.name
      contentValue[isOpenContentTypeModal?.screenIndex].contents[isOpenContentTypeModal?.contentIndex]['innerContent'] = JSON.parse(isOpenContentTypeModal?.mainContent?.contentType?.value.ContentObject)
      contentValue[isOpenContentTypeModal?.screenIndex].contents[isOpenContentTypeModal?.contentIndex]['contentType'] = isOpenContentTypeModal?.mainContent?.contentType
    } else {
      contentValue[isOpenContentTypeModal?.screenIndex]['screenName'] = isOpenContentTypeModal?.name
      contentValue[isOpenContentTypeModal?.screenIndex]['sectionType'] = isOpenContentTypeModal?.screenStep?.sectionType
    }
    isOpenContentTypeModal?.setFieldValue("screen", contentValue)
    setIsOpenContentTypeModal({ ...isOpenContentTypeModal, isOpen: false })
  }

  return (
    <React.Fragment>
      {
        isAuthenticated && <>
          <Formik
            initialValues={{
              applicationName: appNameField,
              bookImage: bookImage,
              screen: initialValue,
            }}
            onSubmit={submitCampaignForm}
            enableReinitialize
          >
            {(formik) => {
              const { values, errors, touched, handleBlur, setFieldValue, submitForm } = formik;
              return (
                <Form>
                  <div className={classes.body} ref={scrollingSurface}>
                    {(createBook || viewBook.btn === 'Edit') && <div style={{ padding: 24 }}>
                      <Row className='mb-5'>
                        <Col>
                          <h3 id='book_creation'>
                            <FormattedMessage id="applications.Custom.Button.Update" defaultMessage="Book Editor" />
                          </h3>
                        </Col>
                      </Row>
                      <div className='d-flex'>
                        <div className='d-flex flex-column me-3'>
                          <span className='mb-2' style={{ fontSize: '12px' }}>Cloned From :</span>
                          <span>{cloned?.Name}</span>
                        </div>
                        <FormControl sx={{ ml: 3, minWidth: 150 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            <FormattedMessage id="applications.BookBuilder.CreateBook.Input.Stylesheet" defaultMessage="Stylesheet" />
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            value={styleSheet ?? ""}
                            onChange={changeStylesheet}
                            label="Style Sheet"
                          >
                            <MenuItem value="dungeon">
                              <FormattedMessage id="applications.BookBuilder.CreateBook.Input.DungeonEpics" defaultMessage="Dungeon Epics" />
                            </MenuItem>
                            <MenuItem value="sci-fi">
                              <FormattedMessage id="applications.BookBuilder.CreateBook.Input.SciFiEpics" defaultMessage="Sci-Fi Epics" />
                            </MenuItem>
                            <MenuItem value="montkelian">
                              <FormattedMessage id="applications.BookBuilder.CreateBook.Input.Montkelian" defaultMessage="Montkelian" />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>}
                    {
                      (viewBook.btn === 'Edit' || createBook) &&
                      <>
                        <Row className='align-items-center' style={{ padding: "24px" }}>
                          <Col>
                            <CustomInput
                              label={"Book Name"}
                              name={"Book Name"}
                              placeholder={"Enter book name"}
                              fieldValue={appNameField}
                              handleChange={(e) => setAppNameField(e.target.value)}
                            />
                          </Col>
                          {/* <Col md={2} style={{ marginTop: "13px" }} className='d-flex justify-content-end'>
                            <Button
                              disabled={((!values.applicationName || !appData?.id) || isCloneApplicationLoading || updatingApplication) || (appNameField === viewBook?.name)}
                              style={{ padding: "12px 27px" }}
                              variant='contained'
                              component="label"
                              className={`pdf-create-btn  ${(isCloneApplicationLoading || updatingApplication || (appNameField === viewBook?.name)) && 'disable-upload-btn'}`}
                              onClick={() => clonedApplication(values.applicationName, appData)}
                            >
                              {((isCloneApplicationLoading || updatingApplication) && (
                                <Spinner size="sm" variant="light" style={{ marginRight: "5px" }} />
                              ))}
                              {viewBook?.id && viewBook.btn === "Edit" ? 'Update' : 'Create'}
                            </Button>
                          </Col> */}
                        </Row>
                        {<>
                          <div style={{
                            position: 'relative'
                          }}>
                            <FieldArray name={`screen`}>
                              {({ push, remove }) => (<>
                                <Stepper nonLinear activeStep={screenActiveStep} orientation="vertical" className='parent-stepper'>
                                  {values?.screen && values?.screen.map((screenStep, screenIndex) => {
                                    return (
                                      <Step key={`step_${screenIndex}_container`}>
                                        <StepButton icon={<InsertDriveFileIcon color={screenActiveStep === screenIndex ? `primary` : ''} />}
                                          style={{
                                            display: 'block',
                                          }}
                                          onClick={() => {
                                            setScreenActiveStep(screenActiveStep === screenIndex && isSet(values?.screen[screenIndex].value)
                                              ? values?.screen?.length
                                              : screenIndex)
                                              setContentActiveStep(0)
                                          }}
                                        >
                                          <div className='w-100 d-flex justify-content-between'>
                                            <Typography>{screenStep?.screenName}</Typography>
                                            <div>
                                              <EditIcon style={{ cursor: "pointer" }} onClick={() => {
                                                setIsOpenContentTypeModal({
                                                  isOpen: true,
                                                  allValues: values,
                                                  entity: 'SectionType',
                                                  name: screenStep?.screenName,
                                                  screenStep,
                                                  screenIndex,
                                                  action: 'Edit',
                                                  // contentIndex,
                                                  setFieldValue,
                                                })
                                              }} />
                                              <DeleteIcon style={{ cursor: "pointer" }} onClick={() => setConfirmationModal({
                                                isOpen: true,
                                                label: 'Section',
                                                index: screenIndex,
                                                remove
                                              })} />
                                            </div>
                                          </div>
                                        </StepButton>
                                        <StepContent>
                                          <FieldArray name={`screen[${screenIndex}].contents`}>
                                            {({ push, remove }) => (<>
                                              <Stepper className='px-0' nonLinear activeStep={contentActiveStep} orientation="vertical">
                                                {screenStep.contents.map((mainContent, contentIndex) => (
                                                  <Step key={`step_${contentIndex}_container`}>
                                                    <StepButton icon={<DescriptionIcon color={contentActiveStep === contentIndex ? `primary` : ''} />}
                                                      onClick={() => {
                                                        setContentActiveStep(contentActiveStep === contentIndex && isSet(values?.screen[screenIndex]?.contents[contentIndex].value)
                                                          ? values?.screen[screenIndex]?.contents?.length
                                                          : contentIndex)
                                                      }}
                                                      style={{
                                                        display: 'block',
                                                      }}
                                                    >
                                                      <div className='w-100 d-flex justify-content-between'>
                                                        <Typography>{mainContent?.contentName || ""}</Typography>
                                                        <div>
                                                          <EditIcon style={{ cursor: "pointer" }} onClick={() => {
                                                            setIsOpenContentTypeModal({
                                                              isOpen: true,
                                                              allValues: values,
                                                              entity: 'PubContentType',
                                                              name: mainContent?.contentName,
                                                              mainContent,
                                                              screenIndex,
                                                              contentIndex,
                                                              action: 'Edit',
                                                              setFieldValue,
                                                            })
                                                          }} />
                                                          <DeleteIcon style={{ cursor: "pointer" }} onClick={() => setConfirmationModal({
                                                            isOpen: true,
                                                            label: 'Content',
                                                            index: contentIndex,
                                                            remove
                                                          })} />
                                                        </div>
                                                      </div>
                                                    </StepButton>
                                                    <StepContent className={`step-content ${contentActiveStep === contentIndex ? 'step-content-width' : ''}`}>
                                                      <div key={`content_${contentIndex}`} className="d-flex flex-column">
                                                        {mainContent?.innerContent &&
                                                          <>
                                                            <Row>
                                                              {Object.keys(mainContent.innerContent).map((data, fieldIndex) => {
                                                                if (data !== "images" && data !== "ZoneId" && data !== "lists") {
                                                                  return (
                                                                    <Col
                                                                      md={data.toLocaleLowerCase() === "description" ? 12 : 6}
                                                                      key={fieldIndex}
                                                                      className='pb-4'
                                                                    >
                                                                      {data.toLocaleLowerCase() === "description" ?
                                                                        <CKEditor
                                                                          onReady={editor => {
                                                                            setEditor(editor)
                                                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                                              editor.ui.view.toolbar.element,
                                                                              editor.ui.getEditableElement()
                                                                            );
                                                                          }}
                                                                          onError={(error, { willEditorRestart }) => {
                                                                            if (willEditorRestart) {
                                                                              editor.ui.view.toolbar.element.remove();
                                                                            }
                                                                          }}
                                                                          editor={ClassicEditor}
                                                                          onChange={(e, editor) => {
                                                                            const contentObjectData = values.screen && values.screen;
                                                                            contentObjectData[screenIndex].contents[contentIndex].innerContent[data] = editor.getData();
                                                                            setFieldValue("screen", contentObjectData);
                                                                          }}
                                                                          data={mainContent.innerContent[data]}
                                                                          config={editorConfiguration}
                                                                        />
                                                                        : <CustomInput
                                                                          label={_.startCase(data)}
                                                                          name={data}
                                                                          placeholder={_.startCase(data)}
                                                                          type={
                                                                            data.toLocaleLowerCase() === "description" ?
                                                                              "textarea" : ""
                                                                          }
                                                                          fieldValue={mainContent.innerContent[data]}
                                                                          handleChange={(e) => {
                                                                            const contentObjectData = values.screen && values.screen;
                                                                            contentObjectData[screenIndex].contents[contentIndex].innerContent[data] = e.target.value;
                                                                            setFieldValue("screen", contentObjectData);
                                                                          }}
                                                                        />}
                                                                    </Col>
                                                                  );
                                                                }
                                                              })
                                                              }
                                                            </Row>
                                                            <Row>
                                                              <Col>
                                                                {mainContent?.innerContent?.images?.length > 0 &&
                                                                  (<Row style={{ border: "1px solid #ced4da", padding: "15px 5px", borderRadius: "4px", margin: "10px 0" }}>
                                                                    {mainContent?.innerContent?.images.map((item, index) =>
                                                                      Object.keys(item).map((data, imgIndex) => {
                                                                        if (data === "url") {
                                                                          return (
                                                                            <React.Fragment key={`Image${index}${imgIndex}`}>
                                                                              <Col md={12} className="mb-2">
                                                                                <Label>Image - {index + 1}</Label>
                                                                              </Col>
                                                                              <Col md={12} key={imgIndex}>
                                                                                <Row>
                                                                                  <Col className='d-flex justify-content-center'>
                                                                                    {
                                                                                      ((isMediaAddLoading && `File${index}` === keyName) ? (
                                                                                        <div className='form-loader'>
                                                                                          <div style={{ margin: "100px 0 0 0" }}>
                                                                                            <div className={blockClasses}>
                                                                                              <div className="block-products-carousel__preloader" >
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      ) : <img
                                                                                        src={
                                                                                          mainContent?.innerContent?.images &&
                                                                                          mainContent?.innerContent?.images[index][data]
                                                                                        }
                                                                                        alt=""
                                                                                        style={{ marginBottom: "10px", maxWidth: "238px", width: "100%" }}
                                                                                      />
                                                                                      )
                                                                                    }
                                                                                  </Col>
                                                                                </Row>
                                                                                <Row className='align-items-center my-3'>
                                                                                  <Col md={5}>
                                                                                    <AsyncPaginates
                                                                                      label="Choose from Existing"
                                                                                      values={mainContent?.innerContent?.images[index].name.length ? { label: <><img src={mainContent?.innerContent?.images[index].url} style={{ width: "50px" }} alt="" /> {mainContent?.innerContent?.images[index].name}</>, value: mainContent?.innerContent?.images[index] } : ""}
                                                                                      name="File"
                                                                                      entity="PubMedia?pathFolderName=content"
                                                                                      allValues={values}
                                                                                      screenIndex={screenIndex}
                                                                                      contentIndex={contentIndex}
                                                                                      index={index}
                                                                                      setFieldValue={setFieldValue}
                                                                                      fieldErrors={errors.File}
                                                                                      fieldTouched={touched.File}
                                                                                      handleBlur={handleBlur}
                                                                                    />
                                                                                  </Col>
                                                                                  <Col md={2} className='p-0'>
                                                                                    <h4 className='text-center m-0'>OR</h4>
                                                                                  </Col>
                                                                                  <Col md={5}>
                                                                                    <Row className='align-items-center'>
                                                                                      <Col md={7} className="d-flex align-items-center">
                                                                                        <CustomInput
                                                                                          label="File"
                                                                                          name={`File${index}`}
                                                                                          type="file"
                                                                                          handleChange={(e) => {
                                                                                            fileName[`File${index}`] = e.target.files[0].name
                                                                                            setFieldValue(
                                                                                              `File${index}`,
                                                                                              e.target.files[0]
                                                                                            );
                                                                                          }}
                                                                                          fieldErrors={errors.File}
                                                                                          fieldTouched={touched.File}
                                                                                          handleBlur={handleBlur}
                                                                                        />
                                                                                        <span style={{ marginLeft: "15px", width: "100px", wordBreak: "break-word" }}>{fileName[`File${index}`]}</span>
                                                                                      </Col>
                                                                                      <Col md={5}
                                                                                        style={{
                                                                                          display: "flex",
                                                                                          justifyContent: "flex-end",
                                                                                          alignItems: "center",
                                                                                        }}
                                                                                      >
                                                                                        <Button
                                                                                          disabled={
                                                                                            !(values[`File${index}`] !== undefined) ||
                                                                                            values[`File${index}`] === "" ||
                                                                                            isMediaAddLoading
                                                                                          }
                                                                                          className={`pdf-generate-btn ${(!(values[`File${index}`] !== undefined) ||
                                                                                            values[`File${index}`] === "" ||
                                                                                            isMediaAddLoading) && "disable-upload-btn"}`}
                                                                                          variant='contained'
                                                                                          component="label"
                                                                                          onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setKeyName(`File${index}`)
                                                                                            uploadImage(
                                                                                              setFieldValue,
                                                                                              values,
                                                                                              screenIndex,
                                                                                              contentIndex,
                                                                                              index,
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          {(isMediaAddLoading && `File${index}` === keyName) ? (
                                                                                            <Spinner size="sm" variant="light" style={{ marginRight: "5px" }} />
                                                                                          ) : <FormattedMessage id="applications.BookBuilder.CreateBook.Upload.Button" defaultMessage="Upload" />}
                                                                                        </Button>
                                                                                      </Col>
                                                                                    </Row>
                                                                                  </Col>
                                                                                </Row>
                                                                              </Col>
                                                                            </React.Fragment>
                                                                          );
                                                                        } else {
                                                                          return (
                                                                            <Col
                                                                              md={6}
                                                                              key={imgIndex}
                                                                            >
                                                                              <CustomInput
                                                                                label={data}
                                                                                name={data}
                                                                                fieldValue={
                                                                                  mainContent.innerContent.images[index][data]
                                                                                }
                                                                                handleBlur={handleBlur}
                                                                                handleChange={(e) => {
                                                                                  const contentObjectData =
                                                                                    values.screen && values.screen;
                                                                                  contentObjectData[screenIndex].contents[contentIndex].innerContent.images[index][data] = e.target.value;
                                                                                  setFieldValue("screen", contentObjectData);
                                                                                }}
                                                                              />
                                                                            </Col>
                                                                          );
                                                                        }
                                                                      })
                                                                    )
                                                                    }
                                                                  </Row>)
                                                                }
                                                                {mainContent?.innerContent?.lists?.length > 0 &&
                                                                  (<Row style={{ border: "1px solid #ced4da", padding: "15px 5px", borderRadius: "4px", margin: "10px 0" }}>
                                                                    <Col md={12}>
                                                                      {mainContent?.innerContent?.lists.map((item, index) =>
                                                                        Object.keys(item).map((data, listIndex) => {
                                                                          return (
                                                                            <React.Fragment key={`List${index}${listIndex}`}>
                                                                              {data !== 'listType' &&
                                                                                <Row>
                                                                                  {data === "name" &&
                                                                                    <Col md={12} className="mb-2">
                                                                                      <Label>List - {index + 1}</Label>
                                                                                    </Col>
                                                                                  }
                                                                                  <Col
                                                                                    // md={6}
                                                                                    key={listIndex}
                                                                                    style={{ paddingLeft: "25px" }}
                                                                                  >
                                                                                    {item["listType"] === "table" && data === "tableImage" ?
                                                                                      <>
                                                                                        <AsyncPaginates
                                                                                          values={item?.tableImage?.value ? item?.tableImage : ""}
                                                                                          label="Add Table"
                                                                                          entity="RandomEncounter"
                                                                                          name="Component"
                                                                                          allValues={values}
                                                                                          tableIndex={index}
                                                                                          screenIndex={screenIndex}
                                                                                          contentIndex={contentIndex}
                                                                                          setFieldValue={setFieldValue}
                                                                                        />
                                                                                        {item?.tableImage?.value && <img src={item?.tableImage?.value} width={"100%"} />}
                                                                                      </> : item["listType"] === "image" && data === "url" ?
                                                                                        <>
                                                                                          <Row>
                                                                                            <Col className='d-flex justify-content-center'>
                                                                                              {
                                                                                                ((isMediaAddLoading && `File${index}` === keyName) ? (
                                                                                                  <div className='form-loader'>
                                                                                                    <div style={{ margin: "100px 0 0 0" }}>
                                                                                                      <div className={blockClasses}>
                                                                                                        <div className="block-products-carousel__preloader">
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  </div>
                                                                                                ) : <img
                                                                                                  src={
                                                                                                    mainContent?.innerContent?.lists &&
                                                                                                    mainContent?.innerContent?.lists[index][data]
                                                                                                  }
                                                                                                  alt=""
                                                                                                  style={{ marginBottom: "10px", maxWidth: "238px", width: "100%" }}
                                                                                                />
                                                                                                )
                                                                                              }
                                                                                            </Col>
                                                                                          </Row>
                                                                                          <Row className='align-items-center my-3'>
                                                                                            <Col md={5}>
                                                                                              <AsyncPaginates
                                                                                                label="Choose from Existing"
                                                                                                values={mainContent?.innerContent?.lists[index].name.length ? { label: <><img src={mainContent?.innerContent?.lists[index].url} style={{ width: "50px" }} alt="" /> {mainContent?.innerContent?.lists[index].name}</>, value: mainContent?.innerContent?.lists[index] } : ""}
                                                                                                name="File"
                                                                                                entity="PubMedia?pathFolderName=content"
                                                                                                allValues={values}
                                                                                                screenIndex={screenIndex}
                                                                                                contentIndex={contentIndex}
                                                                                                index={index}
                                                                                                setFieldValue={setFieldValue}
                                                                                                fieldErrors={errors.File}
                                                                                                fieldTouched={touched.File}
                                                                                                handleBlur={handleBlur}
                                                                                              />
                                                                                            </Col>
                                                                                            <Col md={2} className='p-0'>
                                                                                              <h4 className='text-center m-0'>OR</h4>
                                                                                            </Col>
                                                                                            <Col md={5}>
                                                                                              <Row className='align-items-center'>
                                                                                                <Col md={7} className="d-flex align-items-center">
                                                                                                  <CustomInput
                                                                                                    label="File"
                                                                                                    name={`File${index}`}
                                                                                                    type="file"
                                                                                                    handleChange={(e) => {
                                                                                                      fileName[`File${index}`] = e.target.files[0].name
                                                                                                      setFieldValue(
                                                                                                        `File${index}`,
                                                                                                        e.target.files[0]
                                                                                                      );
                                                                                                    }}
                                                                                                    fieldErrors={errors.File}
                                                                                                    fieldTouched={touched.File}
                                                                                                    handleBlur={handleBlur}
                                                                                                  />
                                                                                                  <span style={{ marginLeft: "15px", width: "100px", wordBreak: "break-word" }}>{fileName[`File${index}`]}</span>
                                                                                                </Col>
                                                                                                <Col md={5}
                                                                                                  style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-end",
                                                                                                    alignItems: "center",
                                                                                                  }}
                                                                                                >
                                                                                                  <Button
                                                                                                    disabled={
                                                                                                      !(values[`File${index}`] !== undefined) ||
                                                                                                      values[`File${index}`] === "" ||
                                                                                                      isMediaAddLoading
                                                                                                    }
                                                                                                    className={`pdf-generate-btn ${(!(values[`File${index}`] !== undefined) ||
                                                                                                      values[`File${index}`] === "" ||
                                                                                                      isMediaAddLoading) && "disable-upload-btn"}`}
                                                                                                    variant='contained'
                                                                                                    component="label"
                                                                                                    onClick={(e) => {
                                                                                                      e.preventDefault();
                                                                                                      setKeyName(`File${index}`)
                                                                                                      uploadImage(
                                                                                                        setFieldValue,
                                                                                                        values,
                                                                                                        screenIndex,
                                                                                                        contentIndex,
                                                                                                        index,
                                                                                                      );
                                                                                                    }}
                                                                                                  >
                                                                                                    {(isMediaAddLoading && `File${index}` === keyName) ? (
                                                                                                      <Spinner size="sm" variant="light" style={{ marginRight: "5px" }} />
                                                                                                    ) : <FormattedMessage id="applications.BookBuilder.CreateBook.Upload.Button" defaultMessage="Upload" />}
                                                                                                  </Button>
                                                                                                </Col>
                                                                                              </Row>
                                                                                            </Col>
                                                                                          </Row>
                                                                                        </>
                                                                                        : data === "description" ?
                                                                                          <div className='mb-3'>
                                                                                            <CKEditor
                                                                                              onReady={editor => {
                                                                                                setEditor(editor)
                                                                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                                                                  editor.ui.view.toolbar.element,
                                                                                                  editor.ui.getEditableElement()
                                                                                                );
                                                                                              }}
                                                                                              onError={(error, { willEditorRestart }) => {
                                                                                                if (willEditorRestart) {
                                                                                                  editor.ui.view.toolbar.element.remove();
                                                                                                }
                                                                                              }}
                                                                                              editor={ClassicEditor}
                                                                                              onChange={(e, editor) => {
                                                                                                handleEditorChange(editor, values, screenIndex, contentIndex, index, data, setFieldValue)
                                                                                              }}
                                                                                              data={item["description"]}
                                                                                              config={editorConfiguration}
                                                                                            />
                                                                                          </div>
                                                                                          : <CustomInput
                                                                                            label={_.startCase(data)}
                                                                                            name={data}
                                                                                            fieldValue={
                                                                                              mainContent?.innerContent?.lists[index][data]
                                                                                            }
                                                                                            placeholder={_.startCase(data)}
                                                                                            type={data.toLocaleLowerCase() === "description" ?
                                                                                              "textarea" : ""}
                                                                                            handleBlur={handleBlur}
                                                                                            handleChange={(e) => {
                                                                                              const contentObjectData =
                                                                                                values.screen && values.screen
                                                                                              contentObjectData[screenIndex].contents[contentIndex].innerContent.lists[index][data] = e.target.value;
                                                                                              setFieldValue("screen", contentObjectData);
                                                                                            }}
                                                                                          />
                                                                                    }
                                                                                  </Col>
                                                                                </Row>
                                                                              }
                                                                            </React.Fragment>
                                                                          );
                                                                        }
                                                                        ))
                                                                      }
                                                                    </Col>
                                                                  </Row>)
                                                                }
                                                              </Col>
                                                            </Row>
                                                          </>
                                                        }
                                                        {(mainContent.contentType?.value?.Name.toLowerCase() === "table" || mainContent.contentType?.value?.Name.toLowerCase() === "smalltable") &&
                                                          <>
                                                            <AsyncPaginates
                                                              values={mainContent.tableFormat}
                                                              label="Add Table"
                                                              entity="RandomEncounter"
                                                              name="Component"
                                                              allValues={values}
                                                              screenIndex={screenIndex}
                                                              contentIndex={contentIndex}
                                                              setFieldValue={setFieldValue}
                                                            />
                                                            {mainContent?.tableFormat && mainContent?.tableFormat?.value?.RandomEncounterObject && <img src={JSON.parse(mainContent?.tableFormat?.value?.RandomEncounterObject)?.[0]?.tableImage} />}
                                                          </>
                                                        }
                                                      </div>
                                                    </StepContent>
                                                  </Step>
                                                ))}
                                                <Step>
                                                  <StepButton
                                                    icon={<AddCircleIcon />}
                                                    onClick={() => {
                                                      push({
                                                        ...(screenStep?.zoneId && { zoneId: screenStep.zoneId }),
                                                        "contentName": "New Content",
                                                        "contentType": {
                                                          "label": "",
                                                          "value": {
                                                            "Name": "",
                                                            "Description": "",
                                                            "ContentObject": "",
                                                            "IsDeleted": false
                                                          }
                                                        },
                                                        "innerContent": {
                                                          "title": "",
                                                          "description": "",
                                                          "lists": []
                                                        }
                                                      })
                                                      setIsOpenContentTypeModal({
                                                        isOpen: true,
                                                        allValues: values,
                                                        entity: 'PubContentType',
                                                        screenIndex,
                                                        action: 'Add',
                                                        contentIndex: values.screen[screenIndex].contents.length,
                                                        setFieldValue,
                                                        remove
                                                      })
                                                    }}
                                                  >
                                                    <Typography>Add Content</Typography>
                                                  </StepButton>
                                                </Step>
                                              </Stepper>
                                            </>
                                            )}
                                          </FieldArray>
                                        </StepContent>
                                      </Step>
                                    )
                                  })
                                  }
                                  <Step>
                                    <StepButton
                                      icon={<AddCircleIcon />}
                                      onClick={() => {
                                        push({
                                          publicationId: values.screen[0].publicationId,
                                          screenName: "New Section",
                                          contents: []
                                        })
                                        setIsOpenContentTypeModal({
                                          isOpen: true,
                                          allValues: values,
                                          entity: 'SectionType',
                                          screenIndex: values.screen.length,
                                          action: 'Add',
                                          setFieldValue,
                                          remove
                                        })
                                      }}
                                    >
                                      <Typography>Add Section</Typography>
                                    </StepButton>
                                  </Step>
                                </Stepper>
                              </>
                              )}
                            </FieldArray>
                          </div>
                        </>
                        }
                      </>
                    }
                    {
                      (createBook || viewBook?.btn === 'Edit') &&
                      <div>
                        <Button component="label" variant='contained' color="secondary" className={`m-3 pdf-save-btn ${isNewApplicationLoading && 'disable-upload-btn'}`} onClick={submitForm} disabled={isNewApplicationLoading}>
                          {isNewApplicationLoading && <Spinner animation="border" size="sm" variant="primary" style={{ marginRight: "5px" }} />}
                          {viewBook?.id && viewBook.btn === "Edit" ? <FormattedMessage id="applications.BookBuilder.CreateBook.Update.Button" defaultMessage="Update Book" /> :
                            <FormattedMessage id="applications.BookBuilder.CreateBook.Save.Button" defaultMessage="Save book" />}
                        </Button>
                        <Button component="label" variant='contained' color="success" className='m-3 pdf-download-btn' onClick={() => {
                          downloadPdf.download = appNameField
                          downloadPdf.click()
                        }}>
                          <FormattedMessage id="applications.BookBuilder.CreateBook.Download.Button" defaultMessage="Download Now" />
                        </Button>
                        <Button component="label" variant='contained' className='m-3 pdf-create-btn' onClick={userInvitation} style={{ padding: '6px 20px' }}>
                          <FormattedMessage id="applications.BookBuilder.CreateBook.Invite.Button" defaultMessage="Invite" />
                        </Button>
                      </div>
                    }
                  </div>
                  <div>
                    <Modal className='justify-content-center' style={{ position: "absolute" }} show={showModal} centered onHide={() => setShowModal(false)} size="lg">
                      <Modal.Header closeButton className='justify-content-between'>
                        <h4 className='m-0'>{`${cloneBtn ? 'Clone' : 'Add'} a Book`}</h4>
                      </Modal.Header>
                      <div style={{ padding: 24 }}>
                        <FormControl sx={{ minWidth: 250 }} required>
                          <InputLabel id="demo-simple-select-helper-label">{`${cloneBtn ? 'Book' : 'Template'}`}</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            value={template?.id ?? ""}
                            required
                            onChange={(e) => setTemplate({ id: e.target.value })}
                            label={cloneBtn ? "Book" : "Template"}
                          >
                            {publicationData?.value.map((app, index) => {
                              const pubName = app?.Name.replace(`- ${app?.MasterClone?.Name}`, "")
                              return app.PublicationType.Name && ((!cloneBtn && !app.IsCloned) || (cloneBtn && (userId && app.UserPublications.some((user) => user.UserId === Number(userId))))) &&
                                <MenuItem key={`application_${index}`} className='all-epics' value={app.Id}>{pubName}</MenuItem>
                            })
                            }
                          </Select>
                        </FormControl>
                        <FormControl sx={{ ml: 3, minWidth: 150 }} required>
                          <InputLabel id="demo-simple-select-helper-label">Stylesheet</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            value={styleSheet ?? ""}
                            onChange={changeStylesheet}
                            required
                            label="Style Sheet"
                          >
                            <MenuItem value="dungeon">Dungeon Epics</MenuItem>
                            <MenuItem value="sci-fi">Sci-Fi Epics</MenuItem>
                            <MenuItem value="montkelian">Montkelian</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <Row className='align-items-center' style={{ padding: "0px 24px 24px 24px" }}>
                        <Col md={10}>
                          <CustomInput
                            label="Book Name"
                            name="Book Name"
                            required
                            placeholder="Enter book name"
                            fieldValue={appNameField}
                            handleChange={(e) => setAppNameField(e.target.value)}
                          />
                        </Col>
                        <Col md={2} style={{ marginTop: "13px" }} className='d-flex justify-content-end'>
                          <Button
                            disabled={((!values.applicationName || !template?.id) || isCloneApplicationLoading)}
                            style={{ padding: "12px 27px" }}
                            variant='contained'
                            component="label"
                            className={`pdf-create-btn ${((!values.applicationName || !template?.id) || isCloneApplicationLoading) && 'disable-upload-btn'}`}
                            onClick={() => clonedApplication(values.applicationName, template)}
                          >
                            {(isCloneApplicationLoading && (
                              <Spinner size="sm" variant="light" style={{ marginRight: "5px" }} />
                            ))}
                            <FormattedMessage id="applications.BookBuilder.CreateBook.Create.Button" defaultMessage="Create" />
                          </Button>
                        </Col>
                      </Row>
                    </Modal>
                  </div>
                  <div>
                    <Modal className='justify-content-center' show={isOpenContentTypeModal.isOpen} centered onHide={onModalHide} size="md">
                      <Modal.Header closeButton className='justify-content-between'>
                        <h4 className='m-0'>{`${isOpenContentTypeModal?.entity === "SectionType" ? `${isOpenContentTypeModal?.action} Section` : `${isOpenContentTypeModal?.action} Content`}`}</h4>
                      </Modal.Header>
                      <div style={{ padding: 24 }}>
                        <CustomInput
                          label={isOpenContentTypeModal?.entity === "SectionType" ? 'Section Name' : 'Content Name'}
                          name={isOpenContentTypeModal?.entity === "SectionType" ? 'Section Name' : 'Content Name'}
                          placeholder={isOpenContentTypeModal?.entity === "SectionType" ? 'Section Name' : 'Content Name'}
                          fieldValue={isOpenContentTypeModal?.name}
                          handleChange={(e) => {
                            setIsOpenContentTypeModal({ ...isOpenContentTypeModal, allValues: values, name: e.target.value })
                          }}
                        />
                        <AsyncPaginates
                          values={isOpenContentTypeModal?.entity === "SectionType" ? isOpenContentTypeModal?.screenStep?.sectionType
                            : isOpenContentTypeModal?.mainContent?.contentType?.label ? isOpenContentTypeModal?.mainContent?.contentType : ''}
                          label={isOpenContentTypeModal?.entity === "SectionType" ? "Section Type" : "Content Type"}
                          entity={isOpenContentTypeModal?.entity}
                          name="Component"
                          allValues={values}
                          screenIndex={isOpenContentTypeModal?.screenIndex}
                          contentIndex={isOpenContentTypeModal?.contentIndex}
                          setFieldValue={isOpenContentTypeModal?.setFieldValue}
                          isOpenContentTypeModal={isOpenContentTypeModal}
                          setIsOpenContentTypeModal={setIsOpenContentTypeModal}
                        />
                        {isOpenContentTypeModal?.entity === "PubContentType" && isOpenContentTypeModal?.mainContent?.contentType?.value?.Description && <div className='content-type-description'>{`*Note: ${isOpenContentTypeModal?.mainContent?.contentType?.value?.Description}`}
                        </div>
                        }
                      </div>
                      <Modal.Footer>
                        <Button
                          color="success" className='pdf-download-btn'
                          style={{ padding: "10px 20px" }}
                          variant='contained'
                          component="label"
                          onClick={onModalSubmit}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Modal className='justify-content-center' show={confirmationModal.isOpen} centered onHide={() => setConfirmationModal({})} size="md">
                      <div style={{ textAlign: 'center', padding: 24 }}>
                        <h4>{`Are you sure you want to remove this ${confirmationModal?.label}?`}</h4>
                      </div>
                      <Modal.Footer>
                        <Button
                          className='confirmation-btn'
                          style={{ padding: "10px 20px", backgroundColor: 'green' }}
                          variant='contained'
                          component="label"
                          onClick={() => setConfirmationModal({
                            ...confirmationModal,
                            isOpen: false
                          })}
                        >
                          No
                        </Button>
                        <Button
                          className='ms-3 confirmation-btn'
                          style={{ padding: "10px 20px", backgroundColor: 'red' }}
                          variant='contained'
                          component="label"
                          onClick={() => {
                            confirmationModal?.remove(confirmationModal?.index)
                            setConfirmationModal({ ...confirmationModal, isOpen: false })
                          }}
                        >
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </Form>
              )
            }}
          </Formik>
          {currentOperationMode?.status === 'Invite' &&
            <CustomModal
              setCurrentOperationMode={setCurrentOperationMode}
              submitForm={onSubmitInvitation}
              selectedPlayer={selectedEmails}
              currentOperationMode={currentOperationMode}
              setSelectedPlayers={setSelectedEmails}
            >
              <div className='d-flex my-4'>
                <InputGroupAddon addonType="prepend" style={{ width: "25%" }} className="campaignLabel">
                  <span className="input-group-text">
                    Email
                  </span>
                </InputGroupAddon>
                <CreatableAsyncPaginate
                  className={`lazySelect w-75`}
                  value={selectedEmails}
                  placeholder="Enter email"
                  onChange={(e) => setSelectedEmails(e)}
                  isMulti
                />
              </div>
            </CustomModal>}
        </>
      }
    </React.Fragment>
  )
}

DynamicStepper.propTypes = {
  classes: PropTypes.object,
};

const styles = (theme) => ({
  addButton: {
    color: '#0088f2',
    transformOrigin: 'center',
    transform: 'scale(1.3)',
    '&.disabled': {
      color: 'rgba(0,0,0,0.38)',
    },
  },
  addContent: {
    color: '#2e7d32',
    transformOrigin: 'center',
    transform: 'scale(1.3)',
    '&.disabled': {
      color: 'rgba(0,0,0,0.38)',
    },
  },
  body: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(7),
    },
  },
});

export default withStyles(styles)(DynamicStepper);