import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'universal-cookie'
import { TagType } from '../common/tagFile'
import { isArray } from "lodash";

const getBaseQuery = (args, WebApi, extraOptions) => {
  const func = fetchBaseQuery({
    baseUrl:
      args.isWogdV2Api
        ? process.env.REACT_APP_API_V2
        : process.env.REACT_APP_API,
    prepareHeaders: (headers) => {
      const cookies = new Cookies()
      const token = cookies.get('accessToken')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  });

  return func(args, WebApi, extraOptions);
};

export const gamecodexApi = createApi({
  reducerPath: 'gamecodexApi',
  tagTypes: TagType,
  baseQuery: getBaseQuery,
  endpoints: (builder) => ({
    getRecordById: builder.query({
      query: ({ entity, id, expand = {} }) => {
        const queryString =
          Object.entries(expand).length > 0
            ? '$expand=' +
            Object.entries(expand)
              .map((o) => `${o[0]}($select=${o[1]})`)
              .join(',')
            : ''
        return {
          url: `${entity}/${id}?${queryString}`,
          method: 'GET',
          headers: {
            'content-type': `application/json;odata=verbose`,
          },
        }
      },
      providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    }),
    getRecordsById: builder.query({
      query: ({ entity, columnFilter }) => {
        return {
          url: entity,
          method: 'GET',
          params: columnFilter,
          headers: {
            'content-type': `application/json;odata=verbose`,
          },
        }
      },
      providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    }),
    getAllRecords: builder.query({
      query: ({ entity, isWogdV2Api, queryParams }) => {
        return {
          url: `${entity}`,
          method: 'GET',
          isWogdV2Api: isWogdV2Api || false,
          params: queryParams && { playerEmail: queryParams.playerEmail },
          headers: {
            'content-type': `application/json;odata=verbose`,
          },
        }
      },
      providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    }),
    getRecords: builder.query({
      query: ({
        entity,
        columns,
        page,
        pageSize,
        sort,
        queryParams,
        columnFilter,
        expandFilter = "",
        globalFilter,
        expand = "",
        noFilters,
        isWogdV2Api,
      }) => {

        var skip = (page - 1) * pageSize
        var top = pageSize
        var sortUrlString =
          sort && sort.sortDirection !== 'none'
            ? `${!noFilters ? `&$orderby=${sort.accessor} ${sort.sortDirection}` : ''}`
            : `${!noFilters ? '&$orderby=Id asc' : ''}`

        var filterForAllColumns = { ...columnFilter }

        if (globalFilter) {
          columns.forEach((column) => {
            if (
              !column.disableFilters &&
              !filterForAllColumns[column.accessor]
            ) {
              if (!(column.contentType === 'Numeric' && isNaN(globalFilter)))
                filterForAllColumns[column.accessor] = globalFilter
            }
          })
        }
        var filterUrlString = "";
        if (columns && columns.length > 0) {
          filterUrlString = Object.entries(filterForAllColumns)
            .map(([key, value]) =>
              columns.find((c) => c.accessor === key).contentType === 'Numeric'
                ? ` AND ${key} eq ${value}`
                : ` AND contains(${key},'${value}')`
            )
            .join(' Or ')
        }
        else if (columnFilter && columnFilter !== "") {
          filterUrlString = Object.entries(filterForAllColumns)
            .map(([key, value]) => {
              if (isArray(value)) {
                var urlString = ' AND (';
                value.map((item, index) => {
                  index === 0 ?
                    urlString += `${key} eq '${item}'`
                    : urlString += ` OR ${key} eq '${item}'`
                }
                );
                urlString += ")";
                return (urlString)
              }
              if (typeof (value) === 'string') {
                return (` AND contains(${key},'${value}')`)
              }
              return (` AND ${key} eq ${value}`)
            }
            )
        }
        if (expand !== "") {
          filterUrlString = typeof (filterUrlString) === "string" ? filterUrlString : filterUrlString.join(" ")
          filterUrlString += `${!noFilters ? '&' : ''}$expand=${expand}`
        }
        filterUrlString = `${!noFilters ? '&$filter=IsDeleted ne true' : ''}${expandFilter && expandFilter.length ? ` and ${expandFilter}` : ""}${filterUrlString ? `${typeof (filterUrlString) === "string" ? filterUrlString : filterUrlString.join(" ")}` : ''}`
        var newUrl = "";
        if (pageSize) {
          newUrl = `${entity}?$skip=${skip}&$top=${top}&$count=true${sortUrlString}${filterUrlString}`
        }
        else {
          newUrl = `${entity}?${!noFilters ? '$count=true' : ''}${sortUrlString}${filterUrlString}`
        }
        return {
          url: newUrl,
          method: 'GET',
          isWogdV2Api: isWogdV2Api || false,
          ...(queryParams && { params: queryParams }),
          headers: {
            'content-type': `application/json;odata=verbose`,
          },
        }
      },
      providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    }),
    add: builder.mutation({
      query: (params) => ({
        url: `${params.entity}`,
        ...(params.responseType === 'text' && { responseHandler: (response) => response.text() }),
        method: 'POST',
        isWogdV2Api: params?.isWogdV2Api || false,
        body: params.data,
        params: params.query
      }),
      invalidatesTags: (result, error, arg) => (arg.tag ? isArray(arg.tag) ? [...arg.tag] : [arg.tag] : []),
    }),
    update: builder.mutation({
      query: (params) => ({
        url: params?.data?.Id ? `${params.entity}/${params?.data?.Id}` : `${params.entity}`,
        method: 'PUT',
        body: params.data,
      }),
      invalidatesTags: (result, error, arg) => (arg.tag ? isArray(arg.tag) ? [...arg.tag] : [arg.tag] : []),
    }),
    delete: builder.mutation({
      query: (params) => ({
        url: `${params.entity}/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => (arg.tag ? isArray(arg.tag) ? [...arg.tag] : [arg.tag] : []),
    }),
  }),
})

export const {
  useLazyGetRecordsByIdQuery,
  useGetRecordByIdQuery,
  useLazyGetRecordByIdQuery,
  useGetAllRecordsQuery,
  useLazyGetAllRecordsQuery,
  useGetRecordsQuery,
  useLazyGetRecordsQuery,
  useAddMutation,
  useUpdateMutation,
  useDeleteMutation,
} = gamecodexApi
