import React from 'react'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Select from 'react-select';

const GenerateCode = () => {

    const userInfoSchema = Yup.object().shape({
        Discount: Yup.string().required("discount Amount is required"),
        Code: Yup.string().required("Country is required"),
        Category: Yup.string().required("Category is required"),
    });

    const initialValue = {
        Discount: "",
        Category: "",
        Code: "",
    }

    const submitForm = (values) => {
        console.log(values, "value");
    }
    const categories = [{ label: 'Monthly', value: 'Monthly' }, { label: 'Annualy', value: 'Annualy' }];

    return (
        <>
            <div className="mt-5">
                <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
                    <h1> Generate Promo Code</h1>
                    <Formik
                        initialValues={initialValue}
                        validationSchema={userInfoSchema}
                        onSubmit={submitForm}
                    >
                        {(formik) => {
                            const { errors, touched, values, handleChange, setFieldValue } = formik;
                            return (
                                <Form>
                                    <FormGroup>
                                        <Label for="Discount" className="userInfo-label">Discount Percentage</Label>
                                        <Input
                                            name="Discount"
                                            id="Discount"
                                            type="number"
                                            placeholder="Discount"
                                            className={`${errors.Discount && touched.Discount ? "is-invalid" : null} userInfo-input`}
                                            value={values.Discount}
                                            onChange={handleChange}
                                        />
                                        {errors.Discount && <FormFeedback className="promoerror">{errors.Discount}</FormFeedback>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="Category" className="userInfo-label">Discount For</Label>
                                        <Select
                                            className={`${errors.Category && touched.Category ? "is-invalid" : ''} userInfo-input`}
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="Category"
                                            id="Category"
                                            options={categories}
                                            onChange={(value) => { setFieldValue("Category", value.value) }}
                                        />
                                        {errors.Category && <FormFeedback className="promoerror">{errors.Category}</FormFeedback>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="Code" className="userInfo-label">Promo Code</Label>
                                        <Input
                                            name="Code"
                                            id="Code"
                                            type="text"
                                            placeholder="Code"
                                            className={`${errors.Code && touched.Code ? "is-invalid" : null}  userInfo-input`}
                                            value={values.Code}
                                            onChange={handleChange}
                                        />
                                        {errors.Code && <FormFeedback className="promoerror">{errors.Code}</FormFeedback>}
                                    </FormGroup>
                                    <Button
                                        type="submit"
                                        className="add-promo-code"
                                    >
                                        Save
                                    </Button>
                                </Form >
                            );
                        }
                        }
                    </Formik >
                </div>
            </div>
        </>
    )
}

export default GenerateCode