import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { getStates, getCities } from "./subscriptionSlice";
import { STATE, CITY } from 'common/tagFile';
import { useLazyGetRecordsQuery } from "services/gamecodexApi";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

const UserInfo = ({ title, countryData }) => {

    const stateData = useSelector((state) => state.subscriptions.states);
    const cityData = useSelector((state) => state.subscriptions.cities);
    const dispatch = useDispatch();
    const sort = {
        sortDirection: 'none',
        accessor: 'some_accessor',
    };
    const [countries, setCountries] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedState, setSelectedState] = useState(0);

    const [
        getStateData,
        {
            data: stateList,
            error: stateItemError,
            isLoading: stateItemLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (isEmpty(stateData)) {
            getStateData({
                columns: [],
                entity: "LocaleState",
                tag: STATE,
                page: 1,
                sort: sort,
                columnFilter: [],
                globalFilter: []
            })
        }
    }, [stateData]);

    useEffect(() => {
        if (!stateItemLoading && !stateItemError && stateList && stateList?.value?.length > 0) {
            dispatch(getStates(stateList.value));
        }
    }, [stateItemError, stateItemLoading, stateList]);

    const [
        getCityData,
        {
            data: cityList,
            error: cityItemError,
            isLoading: cityItemLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (isEmpty(cityData)) {
            getCityData({
                columns: [],
                entity: "City",
                tag: CITY,
                page: 1,
                sort: sort,
                columnFilter: [],
                globalFilter: []
            })
        }
    }, []);

    useEffect(() => {
        if (!cityItemLoading && !cityItemError && cityList && cityList?.value?.length > 0) {
            dispatch(getCities(cityList.value));
        }
    }, [cityItemError, cityItemLoading, cityList]);

    useEffect(() => {
        var countryOptions = countryData.map((country) => {
            return { label: country.Name, value: country.Id }
        });
        setCountries(countryOptions);
    }, [])

    useEffect(() => {
        if (selectedCountry === 0) {
            var stateOptions = stateData.map((state) => {
                return { label: state.Name, value: state.Id };
            });
        }
        else {
            var stateOptions = stateData.filter((state) => state.CountryId === selectedCountry).map((state) => {
                return { label: state.Name, value: state.Id };
            });
        }
        setState(stateOptions);
    }, [selectedCountry, stateData]);

    useEffect(() => {
        if (selectedState === 0) {
            var cityOptions = cityData.map((city) => {
                return { label: city.Name, value: city.Id };
            });
        }
        else {
            var cityOptions = cityData.filter((city) => city.StateId === selectedState).map((city) => {
                return { label: city.Name, value: city.Id };
            });
        }
        setCity(cityOptions);
    }, [selectedState, cityData]);

    const userInfoSchema = Yup.object().shape({
        Phone: Yup.string().required("Phone is required"),
        Country: Yup.string().required("Country is required"),
        State: Yup.mixed().required("State is required"),
        City: Yup.string().required("City is required"),
        Address: Yup.string().required("Address is required"),
    });

    const initialValue = {
        Address: "",
        City: "",
        Phone: "",
        Country: "",
        State: ""
    }

    const submitForm = (values) => {
        console.log(values, "value");
    }

    return (
        <>
            <div>
                <p className="text-center text-primary subscription-cart-titles mb-0">{title === "gift" ? 'Add Details' : 'Create Your account'}</p>
                <p className="text-primary text-center mt-0">{title === "gift" ? ' ' : 'Already have an acount? Log in here'}</p>
            </div>
            <div>
                <Formik
                    initialValues={initialValue}
                    validationSchema={userInfoSchema}
                    onSubmit={submitForm}
                >
                    {(formik) => {
                        const { errors, touched, values, handleChange, setFieldValue } = formik;
                        return (
                            <Form>
                                {
                                    title === "gift" ? <FormGroup>
                                        <Label for="mail" className="userInfo-label">E-mail</Label>
                                        <Input
                                            name="mail"
                                            id="mail"
                                            type="email"
                                            placeholder="mail"
                                            className={`${errors.mail && touched.mail ? "is-invalid" : null} userInfo-input`}
                                            value={values.mail}
                                            onChange={handleChange}
                                        />
                                        {errors.mail && <FormFeedback className="promoerror">{errors.mail}</FormFeedback>}
                                    </FormGroup> : <></>
                                }
                                <FormGroup>
                                    <Label for="Phone" className="userInfo-label">Phone</Label>
                                    <Input
                                        name="Phone"
                                        id="Phone"
                                        type="text"
                                        placeholder="Phone"
                                        className={`${errors.Phone && touched.Phone ? "is-invalid" : null} userInfo-input`}
                                        value={values.Phone}
                                        onChange={handleChange}
                                    />
                                    {errors.Phone && <FormFeedback className="promoerror">{errors.Phone}</FormFeedback>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Country" className="userInfo-label">Country</Label>
                                    <Select
                                        className={`${errors.Country && touched.Country ? "is-invalid" : ''} userInfo-input`}
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="Country"
                                        id="Country"
                                        options={countries}
                                        onChange={(value) => { setFieldValue("Country", value.value); setSelectedCountry(value.value); }}
                                    />
                                    {errors.Country && <FormFeedback className="promoerror">{errors.Country}</FormFeedback>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Address" className="userInfo-label">Address</Label>
                                    <Input
                                        name="Address"
                                        id="Address"
                                        type="text"
                                        placeholder="Address"
                                        className={`${errors.Address && touched.Address ? "is-invalid" : null}  userInfo-input`}
                                        value={values.Address}
                                        onChange={handleChange}
                                    />
                                    {errors.Address && <FormFeedback className="promoerror">{errors.Address}</FormFeedback>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="City" className="userInfo-label">City</Label>
                                    <Select
                                        className={`${errors.City && touched.City ? "is-invalid" : ''} userInfo-input`}
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="City"
                                        id="City"
                                        options={city}
                                        onChange={(value) => { setFieldValue("City", value.value) }}
                                    />
                                    {errors.City && <FormFeedback className="promoerror">{errors.City}</FormFeedback>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="State" className="userInfo-label">State</Label>
                                    <Select
                                        className={`${errors.State ? "is-invalid" : ''}  userInfo-input`}
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="State"
                                        id="State"
                                        onChange={(e)=> {setFieldValue("State", e.value); setSelectedState(e.value); }}
                                        options={state}
                                    />
                                    {errors.State && <FormFeedback className="promoerror">{errors.State}</FormFeedback>}
                                </FormGroup>
                                <Button
                                    type="submit"
                                    className="add-promo-code"
                                >
                                    Save
                                </Button>
                            </Form >
                        );
                    }
                    }
                </Formik >
            </div>
        </>
    );
}

export default UserInfo;