import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SVGtoPDF from 'svg-to-pdfkit'
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import pdfRenderer from './bookComponents';
import { DECK_CARD_TAG } from 'common/tagFile';
import './../../../register-files'
let pdfkit = require('pdfkit').default
let blobStream = require('blob-stream');
const GlobalPDFComponent = ({ viewResponse, contentDefinition, setContentDefinition, contentRef, setDownloadPdf, setPreviewPanel, deckContent, cards, PUBLICATION_SECTION_TAG }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [stylesheet, setStylesheet] = useState("");
    const styleRecord = useSelector((state) => state.bookBuilderContent.bookContent)
    const sort = {
        sortDirection: 'asc',
        accessor: 'Id',
    };
    const sortPubSection = {
        sortDirection: 'asc',
        accessor: 'SortOrder',
    };
    const [
        getContent,
        {
            data: PDFContent,
            isFetching: isFetchingPdfContent
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        if (viewResponse?.id && cards) {
            getContent({
                columns: [],
                entity: 'DeckCard',
                tag: DECK_CARD_TAG,
                sort: sort,
                columnFilter: { DeckId: viewResponse.id },
                expand: `Deck($expand=DeckMedias($expand=Media)),Card($expand=CardMedias($expand=Media($expand=MediaType)),CardType)`,
            })
        } else {
            viewResponse?.id && getContent({
                columns: [],
                entity: 'PublicationSection',
                page: 1,
                tag: PUBLICATION_SECTION_TAG,
                sort: sortPubSection,
                columnFilter: { PublicationId: viewResponse.id },
                expand: "Publication,Section($expand=SectionContents($expand=Content($expand=ContentType,PubRandomEncounterContents($expand=RandomEncounter));$filter=IsDeleted ne true;$orderby=SortOrder asc))",
            })
        }
    }, [viewResponse?.id])

    useEffect(() => {
        setStylesheet(styleRecord)
    }, [styleRecord])

    let outerRspHandler = []
    useEffect(() => {
        setPreviewPanel(<div className='previewPanel' id='iframeContainer'>
            <div className='block block-products-carousel block-products-carousel--loading'>
                <div className="block-products-carousel__preloader" style={{ marginTop: !cards ? '50%' : '0' }}>
                </div>
            </div>
        </div>)
        const rsp = async () => {
            let documentMethods = new pdfkit({ bufferPages: true });
            let stream = documentMethods.pipe(blobStream());
            documentMethods.page.margins.top = stylesheet?.title === 'montkelian' ? 60 : 40
            documentMethods.page.margins.left = stylesheet?.title === 'montkelian' ? 70 : 40
            documentMethods.page.margins.right = stylesheet?.title === 'montkelian' ? 50 : 40
            documentMethods.page.margins.bottom = 60
            documentMethods.addSVG = function (svg, x, y, options) {
                return SVGtoPDF(this, svg, x, y, options), this;
            };
            documentMethods.registerFont('times new roman normal', 'times new roman.ttf')
            documentMethods.registerFont('times new roman bold', 'times new roman bold.ttf')
            documentMethods.registerFont('times new roman italics', 'times new roman italic.ttf')
            documentMethods.registerFont('times new roman boldItalics', 'times new roman bold italic.ttf')
            documentMethods.registerFont('earthOrbiter normal', 'earthorbiter.ttf')
            documentMethods.registerFont('earthOrbiter bold', 'earthorbiterbold.ttf')
            documentMethods.registerFont('Colchester', 'Colchester.TTF')
            documentMethods.registerFont('Felix', 'Felix.TTF')
            documentMethods.registerFont('FuturaBold', 'FuturaBold.TTF')
            documentMethods.registerFont('FuturaLight', 'FuturaLight.TTF')
            documentMethods.registerFont('MrDarcyBold', 'Mr-Darcy-Bold.ttf')
            documentMethods.registerFont('MrDarcyRegular', 'Mr-Darcy-Regular.ttf')
            documentMethods.registerFont('MinionPro', 'MinionPro-Regular.otf')

            if (PDFContent?.value) {
                if (cards) {
                    let count = 1;
                    const deckLength = PDFContent?.value.length
                    for (let deck of PDFContent?.value) {
                        await pdfRenderer(deck.Card, contentDefinition, setContentDefinition, outerRspHandler, setIsLoading, contentRef, documentMethods, stylesheet, count, 0, deckLength, deckContent, "", cards).then((resp) => {
                        })
                        count++
                    }
                } else {
                    for (let i = 0; i < PDFContent?.value.length; i++) {
                        await pdfRenderer(PDFContent?.value[i]?.Section?.SectionContents, contentDefinition, setContentDefinition, outerRspHandler, setIsLoading, contentRef, documentMethods, stylesheet, (i + 1)).then((resp) => {
                        })
                        i !== (PDFContent?.value.length - 1) && documentMethods.addPage()
                    }
                }
            } else if (cards) {
                let count = 1;
                const deckLength = deckContent.reduce((acc, crr) => {
                    acc += crr.Card.Quantity
                    return acc
                }, 0)
                for (let deck of deckContent) {
                    for (let i = 0; i < deck.Card.Quantity; i++) {
                        await pdfRenderer(deck.Card, contentDefinition, setContentDefinition, outerRspHandler, setIsLoading, contentRef, documentMethods, stylesheet, count, 0, deckLength, deckContent, "", cards).then((resp) => {
                        })
                        count++
                    }
                }
            } else {
                let count = 1;
                let screenCount = 1;
                const deckLength = deckContent.length
                for (let screen of deckContent) {
                    const deckScreenLength = screen.Screen.ScreenZones.length
                    for (let screenContents of screen.Screen.ScreenZones) {
                        await pdfRenderer(screenContents, contentDefinition, setContentDefinition, outerRspHandler, setIsLoading, contentRef, documentMethods, stylesheet, count, screenCount, deckLength, deckScreenLength, screen.Screen.ScreenType.Name).then((resp) => {
                        })
                        screenCount++
                    }
                    screenCount = 1
                    count++
                }
            }
            let pages = documentMethods.bufferedPageRange();
            let oldBottomMargin = documentMethods.page.margins.bottom;
            if (PDFContent?.value) {
                for (let i = 0; i < pages.count; i++) {
                    if (i > 2) {
                        documentMethods.switchToPage(i);
                        documentMethods.page.margins.bottom = 0
                        if (stylesheet?.title !== 'montkelian')
                            documentMethods.fontSize(10).lineGap(2)
                                .text(
                                    `-------------------------------------------------------------------------- \n © WOGD PVT LTD ${i - 1} of ${pages.count - 2}`, 0, documentMethods.page.height - 50, {
                                    align: "center"
                                });
                        else {
                            const backImage = (i + 1) % 2 === 0 ? stylesheet?.value?.backgroundImage.evenImage : stylesheet?.value?.backgroundImage.oddImage
                            documentMethods.image(backImage, 0, 0, { height: documentMethods.page.height, width: documentMethods.page.width })
                            documentMethods.font(stylesheet?.value?.screenHeading?.font).fontSize(15).fillColor('white')
                                .text(`${i - 1}`, i % 2 === 0 ? 0 : 45, i % 2 === 0 ? documentMethods.page.height - 54 : documentMethods.page.height - 57, {
                                    align: i % 2 === 0 ? 'right' : 'left'
                                });
                        }
                    }
                }
            }
            documentMethods.page.margins.bottom = oldBottomMargin;
            documentMethods.end()
            if (PDFContent?.value && !isFetchingPdfContent) {
                stream.on('finish', function () {
                    setPreviewPanel(<iframe src={stream.toBlobURL('application/pdf')}></iframe>)
                    const blob = stream.toBlob('application/pdf')
                    const pdf = document.createElement('a');
                    pdf.href = window.URL.createObjectURL(blob);
                    pdf.style.position = 'fixed';
                    document.body.appendChild(pdf);
                    document.body.removeChild(pdf);
                    setDownloadPdf(pdf)
                });
            }
        }
        (PDFContent?.value || deckContent) && rsp()
    }, [PDFContent, isFetchingPdfContent, stylesheet, deckContent])
    return (
        <></>
    )
}

export default GlobalPDFComponent