export const AVATAR_TAG = "avatar";
export const ATTRIBUTE_TAG = "attribute";
export const AVATAR_ATTRIBUTE_TAG = "avatar_attribute";
export const CELL_TAG = "cell";
export const CELL_AROUND_TAG = "cellAround";
export const TILE_TAG = "tile";
export const MY_COLLECTION = "my_collection";
export const SET = "set";
export const PRODUCT_MODEL = "product_model";
export const MY_DICE = "my_dice";
export const USER_TAG = "user";
export const AVATAR_CELL = "avatar_cell"
export const PLAYER_DATA = "player_data"
export const PLAYER_AVATAR_DATA = "player_avatar_data"
export const GAME_ITEM = "game_item"
export const GAME_SKILL = "game_skill"
export const SYSTEM_ATTRIBUTE = "system_attribute"
export const AVATAR_ROLE = "avatar_role"
export const SKU_TAG = "sku"
export const ORDER_TAG = "order"
export const ORDER_ITEM = "order_item"
export const PAYMENT_DATA = "make_payment"
export const COUNTRY = "country"
export const STATE = "state"
export const CITY = "city"
export const ALIGNMENT = "alignment"
export const SCALE_TAG = "scale"
export const CAMPAIGN_TAG = "campaign"
export const GAME_SYSTEM_TAG = "game_system"
export const EPIC_TAG = "game_productLine"
export const AVATAR_CAMPAIGN_TAG = "avatar_campaign"
export const GET_ALL_PRODUCTLINE_MEDIA_TAG = "get_all_productLine_media"
export const GET_ALL_SYSTEM_MEDIA_TAG = "get_all_system_media"
export const GET_ALL_CAMPAIGN_LOGO_TAG = "get_all_campaign_logo"
export const DEFAULT_CAMPAIGN_LOGO_TAG = "default_campaign_logo"
export const CAMPAIGN_INVITATION_LIST = "campaign_invitation_list"
export const GAME_ROLE_TAG = "game_role_tag"
export const ZONE_CONTENT_TAG = "zone_content_tag"
export const PLAYER_CAMPAIGN_TAG = "player_campaign_tag "
export const PAGE_RECORDS_TAG = "page_records_tag "
export const BOOK_TAG = "book_tag"
export const PAGE_TAG = "page_tag"
export const STYLESHEET_TAG = "stylesheet_tag"
export const SITE_ROLE_TAG = "site_role_tag"
export const SHORT_CODE = "short_code"
export const UPLOAD_MEDIA = "upload_media"
export const APPLICATION_TAG = "application_tag"
export const APPLICATION_SCREEN_TAG = "application_screen_tag"
export const PUBLICATION_TAG = "publication_tag"
export const PUBLICATION_SECTION_TAG = "publication_screen_tag"
export const USER_PUBLICATION_TAG = "user_publication_tag"
export const CONTRACT_TAG = "contract_tag"
export const RANDOM_ENCOUNTER_TAG = "random_encounter_tag"
export const EVENT_TAG = "event_tag"
export const AVATAR_EVENT_TAG = "avatar_event_tag"
export const PLAYER_EVENT_TAG = "player_event_tag"
export const EVENT_PLAYER_TAG = "event_player_tag"
export const TOURNAMENT_BRACKET_TAG = "tournament_bracket_tag"
export const EVENT_INVITATION = "event_invitation"
export const CARD_TAG = "card_tag"
export const DECK_TAG = "deck_tag"
export const DECK_CARD_TAG = "deck_card_tag"



export const TagType = [
    AVATAR_TAG,
    ATTRIBUTE_TAG,
    AVATAR_ATTRIBUTE_TAG,
    CELL_TAG,
    CELL_AROUND_TAG,
    TILE_TAG,
    AVATAR_ATTRIBUTE_TAG,
    MY_COLLECTION,
    SET,
    PRODUCT_MODEL,
    MY_DICE,
    USER_TAG,
    AVATAR_CELL,
    PLAYER_DATA,
    PLAYER_AVATAR_DATA,
    GAME_ITEM,
    GAME_SKILL,
    SYSTEM_ATTRIBUTE,
    AVATAR_ROLE,
    SKU_TAG,
    ORDER_TAG,
    ORDER_ITEM,
    PAYMENT_DATA,
    COUNTRY,
    STATE,
    CITY,
    ALIGNMENT,
    SCALE_TAG,
    CAMPAIGN_TAG,
    GAME_SYSTEM_TAG,
    EPIC_TAG,
    AVATAR_CAMPAIGN_TAG,
    DEFAULT_CAMPAIGN_LOGO_TAG,
    GET_ALL_PRODUCTLINE_MEDIA_TAG,
    GET_ALL_CAMPAIGN_LOGO_TAG,
    GET_ALL_SYSTEM_MEDIA_TAG,
    CAMPAIGN_INVITATION_LIST,
    GAME_ROLE_TAG,
    ZONE_CONTENT_TAG,
    PLAYER_CAMPAIGN_TAG,
    PAGE_RECORDS_TAG,
    BOOK_TAG,
    PAGE_TAG,
    STYLESHEET_TAG,
    SITE_ROLE_TAG,
    SHORT_CODE,
    UPLOAD_MEDIA,
    APPLICATION_TAG,
    APPLICATION_SCREEN_TAG,
    RANDOM_ENCOUNTER_TAG,
    EVENT_TAG,
    AVATAR_EVENT_TAG,
    PLAYER_EVENT_TAG,
    EVENT_PLAYER_TAG,
    TOURNAMENT_BRACKET_TAG,
    EVENT_INVITATION,
    CARD_TAG,
    DECK_TAG,
    DECK_CARD_TAG,
    PUBLICATION_TAG,
    PUBLICATION_SECTION_TAG,
    USER_PUBLICATION_TAG,
    CONTRACT_TAG,
];