import { createSlice } from "@reduxjs/toolkit";

const setTypeSlice = createSlice({
  name: "setType",
  initialState: {
   setType: [],
  },
  reducers: {
    getAllSetType: (state,action) => {
      state.setType = action.payload
    }
  },
});

export default setTypeSlice.reducer;
export const { getAllSetType } = setTypeSlice.actions

