import React from 'react'
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useAddMutation } from 'services/gamecodexApi';
import { PAYMENT_DATA } from 'common/tagFile';
import { errorToast, successToast } from 'common/toast';

const PaymentForm = ({ toggle }) => {
  const [addPayment] = useAddMutation();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if (paymentMethod.id) {
      addPayment({ entity: 'Stripe/MakePayment', data: { amount: 100, currency: "usd", paymentMethodToken: `${paymentMethod.id}` }, tag: PAYMENT_DATA }).unwrap()
        .then((response) => {
          if (response?.charges?.data[0].status === "succeeded") {
            successToast(<FormattedMessage id="successToast.PaymentForm.PaymentSuccess" defaultMessage="Payment Success" />);
          } else {
            errorToast(<FormattedMessage id="errorToast.PaymentForm.PaymentFailed" defaultMessage="Payment Failed" />);
          }
        });
    }
    toggle()
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="payment-form d-flex flex-column">
        <CardElement />
        <Button color="primary" type="submit" className="mt-3" disabled={!stripe || !elements}>
          Pay
        </Button>
      </form>
    </>
  )
}

export default PaymentForm
