import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { Link } from '@material-ui/core';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomInput from 'global/CustomInput';
import Cookies from 'universal-cookie';
import { useAddMutation, useLazyGetAllRecordsQuery, useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { CONTRACT_TAG } from 'common/tagFile';
import SignatureModal from './SignatureModal';
import { errorToast, successToast } from 'common/toast';
import AddMessage from './AddMessage';
import AddClauseModal from './AddClauseModal';

const numbersToWord = (num) => {
    const words = ["Zero", "one", "two", "Three", "Four","Five","Six","Seven","Eight","Nine","Ten"]
    return words[num]
}

const getLogo = (medias) => {
    return medias?.filter(logo => logo?.MediaDTO?.MediaTypeDTO?.Name?.toLowerCase() === "logo")
}

const Contract = () => {
    const { contractId } = useParams()
    const cookies = new Cookies();
    const { loginWithRedirect, isAuthenticated, user } = useAuth0();
    const [counterPartyContract, setCounterPartyContract] = useState();
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState();
    const [currentUserSign, setCurrentUserSign] = useState({ signaturePad: "", savedSignature: "" });
    const [isSaveSign, setIsSaveSign] = useState(false);
    const [isRejectionModal, setIsRejectionModal] = useState(false);
    const sort = { sortDirection: 'desc', accessor: 'Id', };
    const [allCounterPartyMembers, setAllCounterPartyMembers] = useState()
    const [viewedCounterPartyIds, setViewedCounterPartyIds] = useState([])
    const [isOnlyView, setIsOnlyView] = useState(false);
    const [isModifyClauses, setIsModifyClauses] = useState(false);
    const [isAddClauseModal, setIsAddClauseModal] = useState(false)
    const [clauses, setClauses] = useState([]);
    const [newClauses,setNewClauses] = useState([])
    const [selectedContractId, setSelectedContractId] = useState(contractId)
    const [changeDesign, setChangeDesign] = useState(1)
    
    const [getAllCounterPartyContracts, { data: CounterPartyContracts, isLoading: isCounterPartyContractsLoading }] = useLazyGetRecordsQuery();
    const [ContractSignature, { data: contractSignature, isLoading: signatureLoading, isFetching: isFetchingSignature }] = useLazyGetRecordsQuery();
    const [counterPartyAction, {data: CounterPartyAction}] = useLazyGetAllRecordsQuery();
    const [getPDF, {isLoading : loadingPDF}] = useAddMutation();
    const [uploadSign, {isLoading : isUploadingSign}] = useAddMutation();
    const [counterPartyActionLog, {isLoading: isCreatingLog}] = useAddMutation();
    const [addClauseModifyRequest, {isLoading : addClauseModifyRequestLoading}] = useAddMutation();

    useEffect(() => {
        const Signatures = contractSignature?.value || []
        if (Signatures?.length) {
            const counterPartyMembers = contractSignature?.value?.map(member => {
                return {
                    Role: member?.RoleTypeDTO?.Name,
                    SortOrder: member?.RoleTypeDTO?.SortOrder,
                    Signature: member?.ContractSignatureDTO?.[0]?.Signature,
                    Name: member?.Name,
                    Description: member?.Description,
                    Address:   member?.AddressDTO ? `${member?.AddressDTO?.Line || ""} 
                                ${member?.AddressDTO?.City || ""} 
                                ${member?.AddressDTO?.State|| ""} 
                                ${member?.AddressDTO?.Country|| ""} -
                                ${member?.AddressDTO?.ZipCode || ""}` : "",
                    Email: member?.EntityDTO?.UserDTO?.[0]?.Email || member?.Email || " "
                }
            }).sort((member1, member2) => member1?.SortOrder - member2?.SortOrder)
            counterPartyMembers.forEach(members => {
                if (members?.Role === counterPartyContract?.CounterPartyDTO?.[0]?.RoleTypeDTO?.Name) {
                    setCurrentUserSign(prev => { return { ...prev, "savedSignature": members?.Signature } })
                }
            })
            setAllCounterPartyMembers(counterPartyMembers)
        }else{
            setAllCounterPartyMembers(false)
        }
    }, [contractSignature, counterPartyContract, isSaveSign])

    const downloadPDF = async () => {
        let input = document.getElementById("divToPrint")
        let inputToString=input.outerHTML ? input.outerHTML : new XMLSerializer().serializeToString(input);

        try {
            const res = await getPDF({
                entity: "PubContent/GeneratePDFBaseOnContent",
                data: { htmlContent: inputToString },
                responseType: 'text'
            });

            if (!res?.error){
                successToast("PDF generated...")
                triggerDownload(res.data)
            }else{
                errorToast("Generate pdf failed...");
            }
        } catch (error) {
            errorToast("Generate pdf failed...");
        }
    };

    const triggerDownload = (pdfData) => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${pdfData}`;
        link.download = counterPartyContract?.Name || "Contract.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const uploadSignHandler = () => {
        const signDetails = {
            counterPartyId: counterPartyContract?.CounterPartyDTO?.[0]?.Id,
            signature: currentUserSign.savedSignature,
            contractId: counterPartyContract?.Id
        }
        const actionLog = {
            counterPartyId: counterPartyContract?.CounterPartyDTO?.[0]?.Id,
            actionName: "sign"
        }
        uploadSign({
            entity: "Doc/ContractSignature",
            isWogdV2Api: true,
            data: signDetails
        }).then((resp) =>{
            if (!resp.error) {
                counterPartyActionLog({
                    entity: "Doc/CreateCounterPartyActionLog",
                    isWogdV2Api: true,
                    data: actionLog
                })
                successToast("Signature uploaded")
                setIsSaveSign(false)
            }
        })
    }

    useEffect(() => {
        if (selectedContractId && CounterPartyContracts?.value) {
            const selectedContract = CounterPartyContracts?.value?.find((contract) => contract.Id === +contractId)
            selectedContract && handelContractChange({label: selectedContract.Name, value: selectedContract})
            setSelectedContractId(false)
        }
    },[selectedContractId, CounterPartyContracts])

    const handelContractChange = (contract) => {
        setIsModifyClauses(false)
        setCounterPartyContract(() => contract?.value)
        setIsOnlyView(contract?.value?.CounterPartyDTO?.some(item => item?.EntityDTO?.Name === user?.name))
        const isContractViewed = contract?.value?.CounterPartyDTO?.[0]?.CounterPartyActionDTO?.filter(action => 
            action?.ActionDTO?.Name?.toLowerCase() === "view" && 
            moment(new Date()).format("YYYY-MM-DD") === moment(action?.ActionDate).format("YYYY-MM-DD")
        )
        const viewContract = {
            counterPartyId: contract?.value?.CounterPartyDTO?.[0]?.Id,
            actionName: "view"
        }
        if(!isContractViewed?.length && 
            !viewedCounterPartyIds.includes(viewContract.counterPartyId) &&  
                contract?.value?.CounterPartyDTO?.length && 
                    contract?.value?.CounterPartyDTO.some(item => item?.EntityDTO?.Name === user?.name)
            ) {
            counterPartyActionLog({
                entity: "Doc/CreateCounterPartyActionLog",
                isWogdV2Api: true,
                data: viewContract
            }).then(resp => {
                if(resp?.data){
                    setViewedCounterPartyIds([...viewedCounterPartyIds,viewContract.counterPartyId])
                }
            })
        }
    }

    const handelSubmitRequest = () => {
        const modifyClauses = [...clauses.map(obj => {
            return {"id": obj?.value?.Id, "name": obj?.value?.Name, "fullText": obj?.value?.FullText, "clauseTypeId": obj?.value?.ClauseTypeId}
        }), ...newClauses.map(obj => obj?.value)]
        const requestPayload = {
            counterPartyId: counterPartyContract?.CounterPartyDTO?.[0]?.Id,
            clauseObject: JSON.stringify(modifyClauses)
        }
        if (modifyClauses.length) {
            addClauseModifyRequest({
                entity: "Doc/ContractRequest",
                isWogdV2Api: true,
                data: requestPayload
            }).then((resp)=>{
                if (!resp?.error) {
                    setIsModifyClauses(false)
                    setNewClauses([])
                    setClauses([])
                    successToast("Request submitted successfully")
                }
            })
        }
    }

    useEffect(() => {
        if ((currentUserSign.savedSignature) && isSaveSign) {
            uploadSignHandler()
        }
    }, [isSaveSign])

    useEffect(() => {
        if (counterPartyContract) {
            const clearSign = { signaturePad: "", savedSignature: "" }
            setCurrentUserSign({ ...clearSign })

            const contractId = counterPartyContract?.Id
            if (contractId) {
                ContractSignature({
                    entity: `Doc/GetCounterPartiesByContractId/${contractId}`,
                    isWogdV2Api: true,
                })
                if((counterPartyContract?.CounterPartyDTO?.length && counterPartyContract?.CounterPartyDTO.some(item => item?.EntityDTO?.Name === user?.name))) {
                    counterPartyAction({
                        entity: `Doc/CheckCounterPartyAction?counterPartyId=${counterPartyContract?.CounterPartyDTO?.[0]?.Id}`,
                        isWogdV2Api: true,
                    })
                }
            }
            const layout = counterPartyContract?.ContractMediaDTO?.filter(media => media?.MediaDTO?.MediaTypeDTO?.Name === "PDF Layout")?.[0]?.MediaDTO?.Name
            setChangeDesign(layout?.includes("layout3") ? 3 : layout?.includes("layout2") ? 2 : 1)
        }
    }, [counterPartyContract, isSaveSign, isRejectionModal])

    useEffect(async () => {
        if (isAuthenticated && cookies.get('accessToken')) {
            getAllCounterPartyContracts({
                columns: [],
                entity: "Doc/GetCounterPartyContracts",
                isWogdV2Api: true,
                tag: CONTRACT_TAG,
                sort: sort,
                globalFilter: [],
            })
        } else {
            if(!isAuthenticated && !cookies.get('accessToken')) {
                await loginWithRedirect()
                localStorage.setItem("contract-path", window.location.pathname)
            }
        }
    }, [isAuthenticated, cookies.get('accessToken')])

    return (
        <>
            <Row lg={12} md={12} sm={12} className='mt-5 p-3 position-relative'>
                {isCounterPartyContractsLoading &&
                    <Spinner animation="border" size="lg" variant="primary" style={{ height: '3rem', width: '3rem', position: "absolute", left: "50%" }} />
                }
                {!isCounterPartyContractsLoading && <Col lg={6} md={6} sm={8}>
                    <h3>Contract</h3>
                    <div className='my-4'>
                        <FormGroup>
                            <Label for="CounterParty-Contract" className="userInfo-label" >
                                CounterParty Contract
                            </Label>
                            <Select
                                className={"userInfo-input"}
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                name="CounterPartyContract"
                                id="CounterPartyContract"
                                value={counterPartyContract ? { label: counterPartyContract?.Name, value: counterPartyContract } : ""}
                                options={CounterPartyContracts?.value?.map(CounterPartyContract => {
                                    return { label: CounterPartyContract?.Name, value: CounterPartyContract }
                                })}
                                onChange={(contract) => handelContractChange(contract) }
                            />
                        </FormGroup>
                    </div>
                    <div style={{display: "flex", gap: "10px", width: "70%", marginBottom: "5px"}}>
                        <Button 
                            disabled={(!counterPartyContract || currentUserSign.savedSignature || signatureLoading || isFetchingSignature || !CounterPartyAction || !isOnlyView || isCreatingLog || isUploadingSign)} 
                            onClick={() => setIsSignatureModalOpen(counterPartyContract?.CounterPartyDTO?.[0]?.RoleTypeDTO?.Name?.toLowerCase())} 
                            style={{ cursor: "pointer" }} > 
                            <span style={{display: "flex", alignItems: "center"}}>
                                {(isUploadingSign || isCreatingLog) && <Spinner animation="border" size="sm" variant="primary" style={{ height: '1rem', width: '1rem', marginRight: "5px" }} /> } 
                                Sign
                            </span>
                        </Button>
                        <Button 
                            disabled={(!counterPartyContract || currentUserSign.savedSignature || signatureLoading || isFetchingSignature || !CounterPartyAction || !isOnlyView || isCreatingLog || isUploadingSign)} 
                            onClick={() => setIsRejectionModal(true)}>
                                Reject
                        </Button>
                        <Button onClick={downloadPDF} disabled={loadingPDF || isFetchingSignature || !counterPartyContract}>
                            <span style={{display: "flex", alignItems: "center"}}>
                                {loadingPDF && <Spinner animation="border" size="sm" variant="primary" style={{ height: '1rem', width: '1rem', marginRight: "5px" }} />}
                                Download
                            </span>
                        </Button>
                        <Button 
                            title='Request to modify clauses.' 
                            onClick={() => setIsModifyClauses(!isModifyClauses)}
                            disabled={ 
                                !counterPartyContract || 
                                signatureLoading || 
                                isFetchingSignature || 
                                !isOnlyView}  
                        > Request </Button>
                    </div>
                    { counterPartyContract && isModifyClauses && <div style={{border : "1px solid #cccccc", padding : "1rem", borderRadius : "5px", margin : "1rem 0rem"}}>
                        <Col lg={12} md={12} sm={12}>
                            <FormGroup>
                                <Col lg={6} md={12} sm={12}>
                                    <Label for="CounterParty-Contract" className="userInfo-label" >
                                        Clauses
                                    </Label>
                                    <Select
                                        className={"userInfo-input"}
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="Clauses"
                                        id="Clauses"
                                        isMulti
                                        options={[...counterPartyContract?.ContractClauseDTO?.map(contractClause => {
                                            return { label: contractClause?.ClauseDTO?.Name, value: contractClause?.ClauseDTO }
                                        })]}
                                        onChange={(clause) => {
                                            const tempClause = clause?.map(newClause => {
                                                const modifiedClaue = clauses?.filter(oldClause => oldClause?.value?.Id === newClause?.value?.Id) 
                                                if (modifiedClaue.length) {
                                                    return modifiedClaue[0]
                                                } else {
                                                    return newClause
                                                }
                                            })
                                            setClauses([...tempClause])
                                        }}
                                    />
                                </Col>
                                {
                                    clauses.map((clause, index) =>  (
                                        <CustomInput
                                            key={index}
                                            label={clause.label}
                                            name={clause.label}
                                            type="textarea"
                                            placeholder="Enter clause description"
                                            fieldValue={clause.value.FullText}
                                            handleChange={(e) => {
                                                const updatedClause = {label : clause.label , value : {...clause.value, "FullText": e.target.value}}
                                                let finalClauses = [...clauses]
                                                finalClauses.splice(index , 1 ,updatedClause)
                                                setClauses(finalClauses)
                                            }}
                                        />
                                    ))
                                }
                                {
                                    newClauses.map((clause, index) =>  (
                                        <div style={{display:'flex', alignItems: "center"}} key={index}>
                                            <Col
                                                xs={10}
                                                sm={10}
                                                md={10}
                                            >
                                                <CustomInput
                                                    key={index}
                                                    label={clause.label}
                                                    name={clause.label}
                                                    type="textarea"
                                                    placeholder="Enter clause description"
                                                    fieldValue={clause.value.fullText}
                                                    handleChange={(e) => {
                                                        const updatedNewClause = {label : clause.label , value : {...clause.value, "fullText": e.target.value}}
                                                        let finalClauses = [...newClauses]
                                                        finalClauses.splice(index , 1 ,updatedNewClause)
                                                        setNewClauses([...finalClauses])
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                xs={2}
                                                sm={2}
                                                md={2}
                                                className="p-0 d-flex justify-content-center"
                                                style={{
                                                    display: !index ? "none" : "block",
                                                }}
                                            >
                                                <HighlightOffIcon
                                                    onClick={() => {
                                                        const tempClauses = [...newClauses]
                                                        tempClauses.splice(index, 1)
                                                        setNewClauses([...tempClauses])
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </Col>
                                        </div>
                                    ))
                                }
                                <div className="d-flex justify-content-end ">
                                    <Link
                                        onClick={() => setIsAddClauseModal(true)}
                                        className="mt-2"
                                        style={{ color: "#d76d67", fontWeight : "bold", cursor : "pointer" }}
                                        size="sm"
                                    >
                                        + Add new Clause
                                    </Link>
                                </div>
                                <div className="d-flex justify-content-end ">
                                    <Button
                                        disabled={![...clauses , ...newClauses]?.length}
                                        onClick={() => handelSubmitRequest()}
                                    >    
                                       {addClauseModifyRequestLoading && <Spinner animation="border" size="sm" variant="primary" style={{ height: '1rem', width: '1rem', marginRight: "5px" }} />} Submit request
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </div> 
                    }
                </Col>}
                {counterPartyContract && <Col lg={8} md={6} sm={12} style={{ border: "2px solid black", margin: "0", padding: "0", minWidth: "750px", width: "750px", alignItems:"right" }} >
                    <div id='divToPrint' style={{ minHeight: "1000px", width: "750px", padding: "2rem", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div>
                            {changeDesign === 1 &&
                                counterPartyContract?.ContractMediaDTO?.[0]?.MediaDTO?.URL && getLogo(counterPartyContract?.ContractMediaDTO)?.length ?
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img style={{ width: "50%" }} src={getLogo(counterPartyContract?.ContractMediaDTO)?.[0]?.MediaDTO?.URL} />
                                </div> : ""
                            }
                            {!(changeDesign === 1) &&
                                counterPartyContract?.ContractMediaDTO?.[0]?.MediaDTO?.URL && getLogo(counterPartyContract?.ContractMediaDTO)?.length ?
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <img style={{ width: "25%" }} src={getLogo(counterPartyContract?.ContractMediaDTO)?.[0]?.MediaDTO?.URL} />
                                    <h6 style={{ fontWeight: "400", color: '#707070' }}>World of Game Design</h6>
                                </div> : ""
                            }
                            {!(changeDesign === 3) && <div style={{ height: "100px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <h2 style={{ fontWeight: "400", color: '#a6a6a6', marginBottom: 0 }}>{counterPartyContract?.ContractTypeDTO?.Name || ""}</h2>
                                <h3 style={{ fontWeight: "400", color: '#a6a6a6' }}>{counterPartyContract?.Name}</h3>
                            </div>}
                            {changeDesign === 3 && 
                            <div>
                                <div style={{ height: "80px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <h2 style={{ fontWeight: "400", color: '#a6a6a6', marginBottom: 0 }}>{counterPartyContract?.ContractTypeDTO?.Name || ""}</h2>
                                </div>
                                <div>
                                    {allCounterPartyMembers?.length && <h6 style={{ fontWeight: "400", color: '#707070' }}>This agreement is between the following {numbersToWord(allCounterPartyMembers?.length)} parties</h6>}
                                </div>

                                {allCounterPartyMembers?.length && !(isFetchingSignature || signatureLoading || loadingPDF || !counterPartyContract) &&
                                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                                    {allCounterPartyMembers?.map((member, memberIndex) => {
                                        return <div key={memberIndex} style={{ display: "flex", flexDirection: "column", width: "40%", height: "auto", justifyContent: "space-between"  }}>
                                            <div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h4 style={{ paddingBottom: "0px" }}>{member?.Role || ""}</h4>
                                            </div>
                                            <h5 style={{marginBottom: "0px", fontWeight: "500"}}>{member?.Name || ""}</h5>
                                            <h6 style={{marginBottom: "0px", fontWeight: "300"}}>{member?.Address || "" }</h6>
                                            {member?.Email || ""}
                                            </div>
                                            <hr style={{ border: "1px solid black", width: "100%"}} />
                                        </div>
                                    })}
                                </div>}
                                {(isFetchingSignature || signatureLoading || !counterPartyContract) && 
                                    <div style={{width:  "100%", display:"flex", justifyContent: "center"}}>
                                        <Spinner animation="border" size="lg" variant="primary" style={{ height: '3rem', width: '3rem' }} />
                                    </div>
                                }
                                <div>
                                    {allCounterPartyMembers?.length && <h6 style={{ fontWeight: "400", color: '#707070' }}>The parties agree to the following:</h6>}
                                </div>
                            </div>
                            }
                            {
                                counterPartyContract?.ContractClauseDTO?.length ? 
                                [...counterPartyContract?.ContractClauseDTO]?.sort((a,b) => a.Sequence - b.Sequence)?.map((contractClause, index) => (
                                    <span key={index}>
                                        <b>{contractClause?.ClauseDTO?.ClauseTypeDTO?.Name}</b> : 
                                        <pre style={{whiteSpace: "break-spaces",  textAlign: "justify", fontFamily: "inherit"}}>{contractClause?.ClauseDTO?.FullText}</pre>
                                    </span>
                                )) : ""
                            }
                        </div>
                        {allCounterPartyMembers?.length && !(isFetchingSignature || signatureLoading || loadingPDF || !counterPartyContract) &&
                        <div style={{ paddingBottom: "1rem", paddingTop: "3rem", display: "inline-block", display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                            {allCounterPartyMembers?.map((member, memberIndex) => {
                                return <div key={memberIndex} style={{ display: "flex", flexDirection: "column", minWidth: "40%", marginBottom: "1rem" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h4 style={{ paddingBottom: member?.Signature ? "0px" : "80px" }}>{member?.Role || ""}</h4>
                                    </div>
                                    {signatureLoading && <Spinner animation="border" size="lg" variant="primary" style={{ height: '3rem', width: '3rem' }} />}
                                    {member.Signature && (
                                        <img
                                            style={{ height: "80px", width: "100%" }}
                                            alt="creator-signature"
                                            src={member?.Signature || ""}
                                        />
                                    )}
                                    <hr style={{ border: "1px solid black", width: "100%"}} />
                                    <h5 style={{marginBottom: "0px"}}>{member?.Name || ""}</h5>
                                    {member?.Description || ""}
                                </div>
                            })}
                        </div>}
                        {(isFetchingSignature || signatureLoading || loadingPDF || !counterPartyContract) && 
                            <div style={{width:  "100%", display:"flex", justifyContent: "center"}}>
                                <Spinner animation="border" size="lg" variant="primary" style={{ height: '3rem', width: '3rem' }} />
                            </div>
                        }
                    </div>
                </Col>}
            </Row>
            {isSignatureModalOpen && <SignatureModal
                isOpen={isSignatureModalOpen}
                setIsOpen={() => setIsSignatureModalOpen(false)}
                saveSign={() => setIsSaveSign(true)}
                currentUserSign={currentUserSign}
                setCurrentUserSign={setCurrentUserSign}
            />}
            {isRejectionModal && <AddMessage
                isOpen={isRejectionModal}
                setIsOpen={() => setIsRejectionModal(false)}
                counterPartyId= {counterPartyContract?.CounterPartyDTO?.[0]?.Id}
            />}
            {isAddClauseModal && <AddClauseModal 
                isOpen={isAddClauseModal}
                setIsOpen={() => setIsAddClauseModal(false)}
                setNewClauses={setNewClauses}
            />}
        </>
    )
}

export default Contract
