// react
import React, { useState } from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import BlockHeader from './BlockHeader';
import PostCard from './PostCard';
import StroykaSlick from './StroykaSlick';

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 7,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

const BlockPosts = (props) => {
    const [slickRef, setSlickRef] = useState(null);
    const { layout, posts } = props;
    const [blockCard] = posts
    const handleNextClick = () => {
        if (slickRef) {
            slickRef.slickNext();
        }
    };
    const handlePrevClick = () => {
        if (slickRef) {
            slickRef.slickPrev();
        }
    };

    const postsList = posts.map((post) => post?.Content?.ContentObject && <PostCard key={`postcard${post.Id}`} keyId={`postcard${post.Id}`} post={post} layout={layout} />);
    return (
        <div className={`block block-posts block-posts--layout--${layout}`} data-layout={layout}>
            <div className="container">
                <BlockHeader
                    arrows
                    title={blockCard?.Zone?.Name}
                    onNext={handleNextClick}
                    onPrev={handlePrevClick}
                />

                <div className="block-posts__slider">
                    <StroykaSlick
                        ref={setSlickRef}
                        {...slickSettings[layout]}
                    >
                        {postsList}
                    </StroykaSlick>
                </div>
            </div>
        </div>
    );
}

BlockPosts.propTypes = {
    layout: PropTypes.oneOf(['list-sm', 'grid-nl']),
    posts: PropTypes.array,
};

BlockPosts.defaultProps = {
    layout: 'list-sm',
    posts: [],
};
export default BlockPosts
