import subscriptionsData from '../database/subscription';

function getSubscriptions(shift, options) {
    const limit = options.limit || 8;
    let subscriptions = [...subscriptionsData.slice(shift), ...subscriptionsData.slice(0, shift)];

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(subscriptions.slice(0, limit));
        }, 500);
    });
}

export function getPopularSubscriptions(options = {}) {
    return getSubscriptions(3, options);
}