import { createSlice } from "@reduxjs/toolkit";

const colorSlice = createSlice({
  name: "color",
  initialState: {
   color: [],
  },
  reducers: {
    getAllColor: (state,action) => {
      state.color = action.payload
    }
  },
});

export default colorSlice.reducer;
export const { getAllColor } = colorSlice.actions

