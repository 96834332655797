import { createSlice } from "@reduxjs/toolkit";

const manufacturerSlice = createSlice({
  name: "manufacturerData",
  initialState: {
    manufacturerData: [],
  },
  reducers: {
    getAllManufacturer: (state,action) => {
      state.manufacturerData = action.payload;
    }
  },
});

export default manufacturerSlice.reducer;
export const { getAllManufacturer } = manufacturerSlice.actions

