// react
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import { Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// application
import AsyncAction from '../apps/home/components/AsyncAction';
import { cartAddItem } from 'store/cart';
import { compareAddItem } from 'store/compare';
import { quickviewOpen } from 'store/quickview';
import { url } from 'services/utils';
import { wishlistAddItem } from 'store/wishlist';
import Rating from './Rating';
import { Quickview16Svg, Wishlist16Svg } from 'svg';
import { Cart16Svg } from '../svg/index';

function AppCard(props) {
    const { isAuthenticated } = useAuth0()
    const {
        product,
        layout,
        title,
        myFavoriteCampaign,
        callBack = () => { },
        actionHandler,
        addToFavoriteLogo,
        quickView,
        addBook
    } = props;

    const containerClasses = classNames('no-hover product-card', {
        'd-flex justify-content-end': true,
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });
    const history = useHistory()
    let badges = [];
    let image;
    if (product.badges && product.badges.includes('sale')) {
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
    }
    if (product.badges && product.badges.includes('hot')) {
        badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
    }
    if (product.badges && product.badges.includes('new')) {
        badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
    }
    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;
    if (product.images && product.images.length > 0) {
        image = (
            <div className="product-card__image product-image">
                <div onClick={() => {
                    title === "Campaign" ?
                        callBack({
                            pathname: url.campaign(product),
                            state: {
                                campaignId: product.campaignId,
                                campaignSystemImage: product.campaignSystemImage,
                                campaignProductLineImage: product.campaignProductLineImage,
                                campaignName: product.name
                            }
                        }) :
                        callBack(product, "View")
                }} className="product-image__body">
                    {
                        product.images[0] === "ZoomIn24Svg" ? <Cart16Svg /> :
                            <img className={`product-image__img ${product.statusId === 4 && "opacity-50"}`} src={product.images} alt="" />
                    }
                </div>
            </div>
        );
    }

    const handleClick = (product) => {
        history.push(`${product.redirect}`, { CampaignId: product?.data?.CampaignId, ...(product?.avatarRecord) && { avatarRecord: product?.avatarRecord } });
    }

    return (
        <div className={containerClasses} onClick={() => {
            product.secondButtonValue === 'Invite' || product.secondButtonValue === 'JOIN' ? actionHandler(product) : product.secondButtonValue === 'Add Book' && addBook()
        }}>
            <div className='d-flex justify-content-between'>
                {
                    addToFavoriteLogo && isAuthenticated && <AsyncAction
                        render={({ loading, addToFavorite, removeFromFavorite }) => (
                            <Button
                                type="button"
                                onClick={() => { myFavoriteCampaign ? removeFromFavorite() : addToFavorite() }}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist')}
                            >
                                {
                                    loading ? <Spinner animation="border" variant="primary" size={"sm"} /> : <Wishlist16Svg fill={myFavoriteCampaign ? '#bead85' : ""} />
                                }
                            </Button>
                        )}
                        myFavoriteCampaign={myFavoriteCampaign}
                        campaignId={product?.campaignId}
                    />
                }
                {quickView && <AsyncAction
                    render={({ loading }) => (
                        product.Id ? <button
                            type="button"
                            onClick={() => {
                                title === "Campaign" ?
                                    callBack({
                                        pathname: url.campaign(product),
                                        state: {
                                            campaignId: product.campaignId,
                                            campaignSystemImage: product.campaignSystemImage,
                                            campaignProductLineImage: product.campaignProductLineImage,
                                            campaignName: product.name
                                        }
                                    }) :
                                    callBack(product, "View")
                            }}
                            className={classNames('product-card__quickview', {
                                'product-card__quickview--preload': loading,
                            })}
                        >
                            <Quickview16Svg />
                        </button> : ""
                    )}
                />}
            </div>
            {badges}
            {image}
            <div className="align-self-center text-center" style={{ padding: "0 20px", height: "50px" }}>
                <div className="product-card__name" title={product.name}>
                    <div onClick={() => {
                        title === "Campaign" ?
                            callBack({
                                pathname: url.campaign(product),
                                state: {
                                    campaignId: product.campaignId,
                                    campaignSystemImage: product.campaignSystemImage,
                                    campaignProductLineImage: product.campaignProductLineImage,
                                    campaignName: product.name
                                }
                            }) :
                            callBack(product, "View")
                    }} className="text-decoration-none product-card-title">
                        <span>{product.name && product.name.length > 35 ? product.name.slice(0, 35).concat("....") : product.name}</span>
                    </div>
                </div>
            </div>
            <div className="align-self-center" style={{ margin: '10px 0' }}>
                {product.rating ? <div className="product-card__rating">
                    <Rating value={product.rating} />
                    <div className=" product-card__rating-legend">12 Reviews</div>
                </div> : <div style={{ height: "20px" }}></div>
                }
            </div>
            {
                isAuthenticated && <div className="w-100" style={title === 'Campaign' ? { height: "50px" } : { height: "100px" }}>
                    <div className="product-card__buttons" style={{ position: "absolute", bottom: "0", width: "99.5%" }}>
                        <AsyncAction
                            render={({ run, loading }) => (
                                <React.Fragment>
                                    {
                                        <>
                                            {
                                                product.isRequirePlayButton && product?.data?.StatusId !== 4 &&
                                                <Button
                                                    type="button"
                                                    onClick={() => title === "Books" ? callBack(product, "View") : handleClick(product)}
                                                    className={classNames(`${title === "Campaign" ? 'btn-lg' : 'btn'} fs-6 ${product.isRequirePlayButton === "Play" || product.isRequirePlayButton === "Join" ? "btn-primary" : "PlayButton"} border-0 product-card__addtocart w-100`, {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <FormattedMessage id={`applications.Custom.Button.${product.isRequirePlayButton}`} defaultMessage={product.isRequirePlayButton} />
                                                </Button>
                                            }
                                            {
                                                product.buttonValue && <Button
                                                    type="button"
                                                    className={classNames(`btn fs-6 ${product.buttonValue ? "m-0 viewButton" : "bg-transparent border-0"} product-card__addtocart w-100`, {
                                                        'btn-loading': loading,
                                                    })}
                                                    onClick={() => callBack(product, "Edit")}
                                                >
                                                    <FormattedMessage id={`applications.Custom.Button.${product.buttonValue}`} defaultMessage={product.buttonValue} />
                                                </Button>
                                            }
                                            {
                                                product.secondButtonValue &&
                                                <button
                                                    type="button"
                                                    onClick={() => product.secondButtonValue === "Add Book" ? addBook() : actionHandler(product)}
                                                    className={classNames(`fs-6 ${product.secondButtonValue === "JOIN" && `btn btn-primary`} ${(product.secondButtonValue === "Invite" || product.secondButtonValue === "Add Book") && 'position-absolute bottom-0 '} ${product.secondButtonValue !== "Remove" ? `btn btn-success ` : `btn btn-danger `}product-card__addtocart w-100 m-0`, {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <FormattedMessage id={`applications.Custom.Button.${product.secondButtonValue}`} defaultMessage={product.secondButtonValue} />
                                                </button>
                                            }
                                        </>
                                    }
                                </React.Fragment>
                            )}
                        />
                    </div>
                </div>
            }

        </div >
    );
}

AppCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppCard);
