import { createSlice } from "@reduxjs/toolkit";

const productLineSlice = createSlice({
  name: "productLine",
  initialState: {
    productLine: [],
  },
  reducers: {
    getAllProductLine: (state,action) => {
        state.productLine = action.payload
    },
  },
});

export default productLineSlice.reducer;
export const { getAllProductLine} = productLineSlice.actions