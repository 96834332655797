import React, { useEffect, useState } from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import { getCoords } from "./calc";
import ScaleNavigator from "./ScaleNavigator";

const Piece = ({ cell, isCellAroundFetching, selected, toggleSidebar, toggleSelected, arrowClick, currentScaleId, isAvatarCellLoading }) => {
  const [image] = useImage(`https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/avatars/20240610114043141_Baris_lhbnlb.png`);
  const coord = getCoords(cell);
  const [xCenter, setXCenter] = useState(window.innerWidth / 2);
  const [yCenter, setYCenter] = useState(window.innerHeight / 2);
  useEffect(() => {
    const windowsize = () => {
      setXCenter(window.innerWidth / 2);
      setYCenter(window.innerHeight / 2);
    }
    window.addEventListener('resize', windowsize);
    return () => { window.removeEventListener('resize', windowsize); }
  }, [])
  return (
    <div>
      <Image
        image={image}
        x={coord.X + xCenter - 25}
        y={coord.Y + yCenter - 30}
        shadowBlur={selected ? 10 : 10}
        shadowColor={!selected ? "#000000" : "#ffffff"}
        width={50}
        height={50}
        selected={selected}
        onClick={e => {
          toggleSelected();
          arrowClick("clicked");
        }}
        onContextMenu={e => {
          e.evt.preventDefault();
        }}
        onTap={toggleSelected}
        onDblTap={toggleSidebar}
        onDblClick={toggleSidebar}
      />
      <ScaleNavigator
        coord={coord}
        player={1}
        id={54}
        selected={selected}
        toggleSelected={toggleSelected}
        toggleSidebar={toggleSidebar}
        arrowClick={arrowClick}
        isAvatarCellLoading={isAvatarCellLoading}
        isCellAroundFetching={isCellAroundFetching}
        currentScaleId={currentScaleId}
      />
    </div>
  );
};
export default Piece;
