import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Views } from 'react-big-calendar';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
// application
import shopApi from 'api/shop';
import { useSubscriptionTabs } from 'services/hooks';
// blocks
import BlockProductsCarousel from '../../../common/BlockProductsCarousel';
import { useAddMutation, useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { COUNTRY, ORDER_ITEM, ORDER_TAG, SKU_TAG } from 'common/tagFile';
import { getCountries, getOrder, getOrderItem, getSku } from './subscriptionSlice';
import { errorToast } from 'common/toast';

function SubscriptionList() {

  const { isAuthenticated } = useAuth0()
  const sort = {
    sortDirection: 'none',
    accessor: 'some_accessor',
  };
  const userId = parseInt(localStorage.getItem('userId'));
  const subscriptionList = useSelector((state) => state.subscriptions.sku);
  const currentOrder = useSelector((state) => state.subscriptions.order);
  const userInfo = useSelector((state) => state.users.userInfo);
  const orderItems = useSelector((state) => state.subscriptions.orderItem);
  const countryData = useSelector((state) => state.subscriptions.countries);;
  const dispatch = useDispatch();
  const history = useHistory();
  const [addOrderData] = useAddMutation();
  const [addOrderItemData] = useAddMutation();
  const [openSkipMonthsModal, setOpenSkipMonthsModal] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const allViews = Object.keys(Views).map(k => Views[k])
  const [
    getCountryData,
    {
      data: countryList,
      error: countryItemError,
      isLoading: countryItemLoading,
    }
  ] = useLazyGetRecordsQuery();
  /**
   * Featured products.
   */
  const featuredProducts = useSubscriptionTabs(
    useMemo(() => [
      { id: 1, name: <FormattedMessage id="subscription.Carousel.Type.All" defaultMessage='All' />, categorySlug: 'epics' },
      { id: 2, name: <FormattedMessage id="subscription.Carousel.Type.Dice" defaultMessage='Dice' />, categorySlug: 'apps' },
      { id: 3, name: <FormattedMessage id="subscription.Carousel.Type.RPGs" defaultMessage='RPGs' />, categorySlug: 'dice' },
      { id: 4, name: <FormattedMessage id="subscription.Carousel.Type.Zines" defaultMessage='Zines' />, categorySlug: 'epics' },
      { id: 5, name: <FormattedMessage id="subscription.Carousel.Type.Comics" defaultMessage='Comics' />, categorySlug: 'epics' },
      { id: 6, name: <FormattedMessage id="subscription.Carousel.Type.Mine" defaultMessage='Mine' />, categorySlug: undefined },
    ], []),
    (tab) => shopApi.getPopularSubscriptions({ limit: 12, category: tab.categorySlug }),
  );

  const handleRedirectAction = (products) => {
    switch (products.action) {
      case "AddToCart":
        return addToCart()
      case "subscriptionDetails":
        return history.push('/subscription-details')
      default:
        return null
    }
  }


  useEffect(() => {
    isAuthenticated && getCountryData({
      columns: [],
      entity: "Country",
      tag: COUNTRY,
      page: 1,
      sort: sort,
      columnFilter: [],
      globalFilter: []
    })
  }, [isAuthenticated]);

  useEffect(() => {
    if (!countryItemLoading && !countryItemError && countryList && countryList.value.length > 0) {
      dispatch(getCountries(countryList.value));
    }
  }, [countryItemError, countryItemLoading, countryList]);

  const events = [
    {
      'title': 'All Day Event very long title',
      'allDay': true,
      'start': new Date(2021, 12, 0),
      'end': new Date(2021, 12, 1)
    }
  ];

  const [
    getSubscriptionRecord,
    {
      data: subscriptionData,
      error,
      isLoading,
    }
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    if (isEmpty(subscriptionList)) {
      isAuthenticated && getSubscriptionRecord({
        columns: [],
        entity: "Sku",
        tag: SKU_TAG,
        page: 1,
        sort: sort,
        columnFilter: [],
        globalFilter: [],
        expand: "Product($expand=ProductType($filter=Name eq 'Subscription'))"
      })
    }
  }, [isAuthenticated, subscriptionList]);

  useEffect(() => {
    if (!isLoading && !error && subscriptionData && subscriptionData.value.length > 0) {
      dispatch(getSku(subscriptionData.value));
    }
  }, [error, isLoading, subscriptionData]);

  const [
    getOrderRecord,
    {
      data: existCart,
      error: orderError,
      isLoading: orderisLoading,
    }
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    isAuthenticated && getOrderRecord({
      columns: [],
      entity: "Order",
      tag: ORDER_TAG,
      page: 1,
      sort: sort,
      columnFilter: { userId: userId },
      globalFilter: [],
      expand: "OrderState($filter=Name eq 'Shopping Cart')"
    })
  }, [isAuthenticated]);

  useEffect(() => {
    if (!orderisLoading && !orderError && existCart && existCart.value.length > 0) {
      dispatch(getOrder(existCart.value));
    }
  }, [orderError, orderisLoading, existCart]);

  const [
    getOrderItems,
    {
      data: orderItemList,
      error: orderItemError,
      isLoading: orderItemLoading,
    }
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    if (currentOrder && currentOrder.length > 0) {
      getOrderItems({
        columns: [],
        entity: "OrderItem",
        tag: ORDER_ITEM,
        page: 1,
        sort: sort,
        columnFilter: { OrderId: currentOrder[0].Id },
        globalFilter: [],
        expand: "sku"
      })
    }
  }, [currentOrder]);

  useEffect(() => {
    if (!orderItemError && !orderItemLoading && orderItemList && orderItemList.value.length > 0) {
      dispatch(getOrderItem(orderItemList.value));
      localStorage.setItem("orderId", currentOrder[0].Id);
    }
  }, [orderItemLoading, orderItemError, orderItemList]);

  const addToCart = (newSubscription) => {
    if (currentOrder.length === 0) {
      try {
        addOrderData({
          entity: 'Order', data: {
            UserId: userId,
            OrderTypeId: 1,
            OrderStateId: 1,
            OrderStatusId: 1,
            Name: `${userInfo[0].Name}'s Order`
          },
          tag: ORDER_TAG
        }).then((response) => {
          try {
            addOrderItemData({
              entity: 'OrderItem', data: {
                OrderId: response.data.Id,
                SkuId: newSubscription.Id
              },
              tag: ORDER_ITEM
            });
          }
          catch (e) {
            errorToast(<FormattedMessage id="errorToast.Subscription.Error.AddingItem" defaultMessage="Error in adding Order Item" />);
          }
        })
      } catch (error) {
        errorToast(<FormattedMessage id="errorToast.Subscription.Error.AddingOrder" defaultMessage="Error in adding Order" />);
      }
    } else {
      try {
        addOrderItemData({
          entity: 'OrderItem', data: {
            OrderId: currentOrder[0].Id,
            SkuId: newSubscription.Id
          },
          tag: ORDER_ITEM
        });
      }
      catch (e) {
        errorToast(<FormattedMessage id="errorToast.Subscription.Error.AddingItem" defaultMessage="Error in adding Order Item" />);
      }
    }
  }

  return (
    <React.Fragment>
      {useMemo(() => (
        <BlockProductsCarousel
          title={<FormattedMessage id="subscription.Carousel.Title.Subscriptions" defaultMessage="Subscriptions" />}
          layout="grid-5"
          rows={1}
          products={featuredProducts.data}
          loading={featuredProducts.isLoading}
          groups={featuredProducts.tabs}
          onGroupClick={featuredProducts.handleTabChange}
          link={true}
          callBack={handleRedirectAction}
        />
      ), [featuredProducts])}
    </React.Fragment>
  );
}

export default SubscriptionList;