export const theme= {
    name: "Wogd",
    fullName: "Wogd - Apps",
    url: "https://wogd.com",
    author: {
        name: "WOGD Inc",
        profile_url: "https://wogd.com",
    },
    contacts: {
        address: "2951 Marina Bay Drive, Suite 130-208",
        email: "j@wogd.com",
        phone: "(346) 360-5146",
    },
};
export default theme;