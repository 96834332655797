import { FormattedMessage } from 'react-intl';

export default [
    {
        title: <FormattedMessage id="headerNavigation.Home" defaultMessage="Home" />,
        url: '/',
    },
    {
        title: <FormattedMessage id="headerNavigation.GetStarted" defaultMessage='Get Started' />,
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        links: [
                            {
                                title: <FormattedMessage id="headerNavigation.Tools.CreateCharacter" defaultMessage="Create Characters" />,
                                url: '',
                                links: [
                                    { title: <FormattedMessage id="headerNavigation.Tools.Character.Evolutionary" defaultMessage="Evolutionary" />, url: '' },
                                    { title: <FormattedMessage id="headerNavigation.Tools.Character.Mork-Borg" defaultMessage="Mork Borg" />, url: '' },
                                    { title: <FormattedMessage id="headerNavigation.Tools.Character.Fifth-Edition" defaultMessage="5th Edition" />, url: '' },
                                    { title: <FormattedMessage id="headerNavigation.Tools.Character.Mothership" defaultMessage="Mothership" />, url: '' },
                                    { title: <FormattedMessage id="headerNavigation.Tools.Character.SwordMaster" defaultMessage="SwordMaster" />, url: '' },
                                    { title: <FormattedMessage id="headerNavigation.Tools.Character.Hannibal" defaultMessage="Hannibal" />, url: '' },
                                ],
                            },
                            {
                                title: <FormattedMessage id="headerNavigation.Tools.Create-Campaigns" defaultMessage="Create Campaigns" />,
                                url: '',
                                links: [
                                    {
                                        title: <FormattedMessage id="headerNavigation.Tools.AdventureHex-Campaigns" defaultMessage="AdventureHex Campaigns" />,
                                        url: ''
                                    },
                                    {
                                        title: <FormattedMessage id="headerNavigation.Tools.Highlander-PvP" defaultMessage="Highlander PvP" />,
                                        url: ''
                                    },
                                    {
                                        title: <FormattedMessage id="headerNavigation.Tools.Writers.BookBuilder" defaultMessage="Book Builder" />,
                                        url: '/tools/writer/bookbuilder'
                                    },
                                    {
                                        title: <FormattedMessage id="headerNavigation.Tools.Table-Maker-Tool" defaultMessage="Table Maker Tool" />,
                                        url: '/tools/writer/tablemaker'
                                    },
                                ],
                            },
                            {
                                title: <FormattedMessage id="headerNavigation.Create-Contracts" defaultMessage="Contract" />,
                                url: '/contract',
                            },
                        ],
                    },
                    {
                        size: 6,
                        links: [
                            {
                                title: <FormattedMessage id="headerNavigation.Administration.Manage-Collections" defaultMessage="Manage Collections" />,
                                url: '',
                                links: [
                                    {
                                        title: <FormattedMessage id="headerNavigation.Administration.Dice-Collection" defaultMessage="Dice Collection" />,
                                        url: ''
                                    },
                                    {
                                        title: <FormattedMessage id="headerNavigation.Administration.RPG-Collection" defaultMessage="RPG Collection" />, //आरपीजी कलेक्शन
                                        url: ''
                                    },
                                    {
                                        title: <FormattedMessage id="headerNavigation.Administration.Portfolio-Tool" defaultMessage="Portfolio Tool" />,
                                        url: ''
                                    },
                                    {
                                        title: <FormattedMessage id="headerNavigation.Administration.Subscription.Subscription-List" defaultMessage="Your Subscriptions" />,
                                        url: '/subscription',
                                    }
                                ],
                            },
                            {
                                title: <FormattedMessage id="headerNavigation.Kickstarters" defaultMessage="View Kickstarters" />,
                                url: '',
                                links: [
                                    {
                                        title: <FormattedMessage id="headerNavigation.Kickstarters.Hannibal-RPG" defaultMessage="Hannibal RPG" />,
                                        url: ''
                                    },
                                    {
                                        title: <FormattedMessage id="headerNavigation.Kickstarters.Highlander-TCG" defaultMessage="Highlander TCG" />,
                                        url: ''
                                    },
                                ],
                            },
                            {
                                title: <FormattedMessage id="headerNavigation.Product" defaultMessage="Product" />,
                                url: '',
                                links: [
                                    {
                                        title: <FormattedMessage id="headerNavigation.Product.ProductCatalog" defaultMessage="Product Catalog" />,
                                        url: '/product/product-catalog'
                                    }
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
];
