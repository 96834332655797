import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Spinner } from "reactstrap";

function CustomModal(props) {
    const { setCurrentOperationMode, selectedPlayer, submitForm, currentOperationMode, dataContainer, setSelectedPlayers } = props;
    const handleSubmit = (mode, btn) => {
        let { status, currentOperationData } = currentOperationMode
        if (btn === "createButton") {
            setCurrentOperationMode({ status: "CreateAvatar", currentOperationData })
        } else if (selectedPlayer?.length) {
            currentOperationData[btn].isLoading = true
            setCurrentOperationMode({ status, currentOperationData })
            submitForm(mode)
        }
    }
    return (
        <Modal show={true} onHide={() => {
            setCurrentOperationMode({ status: null })
            setSelectedPlayers && setSelectedPlayers([])
        }} centered={true} style={{ justifyContent: "center" }} size={currentOperationMode?.status !== "Invite" ? "lg" : "md"}>
            <Modal.Header closeButton>
                <h4>{currentOperationMode?.currentOperationData?.title}</h4>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "40vh", ...(currentOperationMode?.status !== "Invite" && { overflow: "auto" }) }}>
                {
                    props.children
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    currentOperationMode?.currentOperationData?.button && <Button type="submit" variant="success" disabled={currentOperationMode?.currentOperationData?.secondaryButton?.isLoading} onClick={() => setCurrentOperationMode({ status: null })} >
                        {currentOperationMode?.currentOperationData?.button?.title}
                    </Button>
                }
                {
                    currentOperationMode?.currentOperationData?.primaryButton && <Button type="submit" variant="danger" disabled={!dataContainer?.length || currentOperationMode?.currentOperationData?.primaryButton?.isLoading || currentOperationMode?.currentOperationData?.secondaryButton?.isLoading || currentOperationMode?.currentOperationData?.createButton?.isLoading} onClick={() => handleSubmit(3, "primaryButton")} >
                        {currentOperationMode?.currentOperationData?.primaryButton?.isLoading && <Spinner animation="border" variant="primary" size="sm" />} {currentOperationMode?.currentOperationData?.primaryButton?.title}
                    </Button>
                }
                {
                    currentOperationMode?.currentOperationData?.secondaryButton && <Button type="submit" disabled={(dataContainer && !dataContainer?.length) || currentOperationMode?.currentOperationData?.secondaryButton?.isLoading || currentOperationMode?.currentOperationData?.createButton?.isLoading || !selectedPlayer?.length} onClick={() => handleSubmit(2, "secondaryButton")}>
                        {currentOperationMode?.currentOperationData?.secondaryButton?.isLoading && <Spinner animation="border" variant="primary" size="sm" />} {currentOperationMode?.currentOperationData?.secondaryButton?.title}
                    </Button>
                }
                {
                    currentOperationMode?.currentOperationData?.createButton && <Button disabled={currentOperationMode?.currentOperationData?.createButton?.isLoading || currentOperationMode?.currentOperationData?.primaryButton?.isLoading || currentOperationMode?.currentOperationData?.secondaryButton?.isLoading} onClick={() => handleSubmit(1, "createButton")}>
                        {currentOperationMode?.currentOperationData?.createButton?.isLoading && <Spinner animation="border" variant="primary" size="sm" />} {currentOperationMode?.currentOperationData?.createButton?.title}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default CustomModal;