import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

// import { Navbar } from './global/Navbar'
import { IntlProvider } from 'react-intl';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import languages from './languages';
import ScrollToTop from "./global/ScrollToTop";

import Layout from './global/Layout'

import Home from './apps/home'

function App({ locale }) {
  const { messages } = languages[locale]
  return (
    <IntlProvider locale={locale} messages={messages}>
      <Router basename={process.env.PUBLIC_URL}>
        <HelmetProvider>
          <Helmet htmlAttributes={{ lang: locale, dir: 'ltr' }} />

          <ScrollToTop />
          {/* <Navbar /> */}
          <Switch>
            <Route
              path="/"
              render={(props) => (
                <Layout {...props} homeComponent={Home} />
              )}
            />
            <Redirect to="/" />
          </Switch>
        </HelmetProvider>
      </Router>
    </IntlProvider>
  )
}

export default App
