import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import CustomInput from 'global/CustomInput';

const AddClauseModal = ({ isOpen, setIsOpen, setNewClauses }) => {

    const addClauseSchema = Yup.object().shape({
        Name: Yup.string("Enter label").required("Please enter clause name."),
        Description: Yup.string("Enter Message").required("Please enter clause description")
      })

      const submitForm = (value) => {
        setNewClauses(prev => [...prev, {label: value?.Name, value: {name: value?.Name, fullText: value?.Description}}])
        setIsOpen()
      } 

  return (
    <Modal isOpen={isOpen} toggle={setIsOpen} size="md">
        <ModalHeader className='justify-content-between' toggle={setIsOpen} >
            Enter clause details
        </ModalHeader>
        <ModalBody>
            <Formik
              initialValues={{
                Name: "", 
                Description: ""
              }}
              validationSchema={addClauseSchema}
              onSubmit={submitForm}
            >
              {(formik) => {
                const {
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                } = formik;
                
                return (
                  <Form >
                    <CustomInput
                        label="Clause Name"
                        name="Name"
                        type="text"
                        placeholder="Enter name"
                        fieldValue={values.Name}
                        fieldErrors={errors.Name}
                        fieldTouched={touched.Name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                    />
                    <CustomInput
                        label="Clause description"
                        name="Description"
                        type="textarea"
                        placeholder="Enter clause description"
                        fieldValue={values.Description}
                        fieldErrors={errors.Description}
                        fieldTouched={touched.Description}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                    />
                    <Button onClick={handleSubmit}> Submit </Button>
                  </Form>
                )
              }}
            </Formik>
        </ModalBody>
      </Modal>
  )
}

export default AddClauseModal
