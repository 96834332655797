import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import { categoriesListData, prepareCategory } from './categories';

const getId = makeIdGenerator();

const attributesDef = [
    {
        name: 'System',
        slug: 'system',
        values: [
            { name: 'Evolutionary', slug: 'evolutionary' },
            { name: '5e', slug: '5e' },
            { name: 'SWADE', slug: 'swade' },
            { name: 'Swords & Wizardry', slug: 'sw' },
            { name: 'Old School', slug: 'osr' },
        ],
    },
    {
        name: 'Level',
        slug: 'level',
        values: [
            { name: 'Any', slug: 'any' },
        ],
    },
    {
        name: 'Epics',
        slug: 'epics',
        values: [
            { name: 'Dungeon Epics', slug: 'dungeon-epics' },
            { name: 'Dwarven Epics', slug: 'dwarven-epics' },
            { name: 'World War Epics', slug: 'world-war-epics' },
            { name: 'Mayan Epics', slug: 'mayan-epics' },
            { name: 'Sci Fi Epics', slug: 'sci-fi-epics' },
            { name: 'Cthulhu Epics', slug: 'cthulhu-epics' },
        ],
    },
];

const productsDef = [
    {
        slug: 'adventurehex-app',
        name: 'AdventureHex App',
        price: 5,
        images: [
            'images/products/product-1.jpg',
            'images/products/product-1-1.jpg',
        ],
        badges: 'hot',
        rating: 5,
        reviews: 12,
        availability: 'in-stock',
        brand: 'wogd',
        categories: ['apps'],
        attributes: [
            { slug: 'system', values: 'evolutionary' },
            { slug: 'level', values: 'any', featured: true },
            { slug: 'epics', values: 'mayan-epics', featured: true },
        ],
    },
];

const productsData = productsDef.map((productDef) => {
    let badges = [];

    if (productDef.badges) {
        badges = typeof productDef.badges === 'string' ? [productDef.badges] : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || []).map((productAttributeDef) => {
        const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

        if (!attributeDef) {
            return null;
        }

        let valuesDef = [];

        if (typeof productAttributeDef.values === 'string') {
            valuesDef = [productAttributeDef.values];
        } else if (productAttributeDef.values) {
            valuesDef = productAttributeDef.values;
        }

        const values = valuesDef.map((valueSlug) => {
            const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

            if (!valueDef) {
                return null;
            }

            return {
                ...valueDef,
                customFields: {},
            };
        }).filter((x) => x !== null);

        if (!values.length) {
            return null;
        }

        return {
            name: attributeDef.name,
            slug: attributeDef.slug,
            featured: !!productAttributeDef.featured,
            values,
            customFields: {},
        };
    }).filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: '83690/32',
        slug: productDef.slug,
        price: productDef.price,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: badges.slice(),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: productDef.availability,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
    };
});

export default productsData;
