import { createSlice } from "@reduxjs/toolkit";

const playerSlice = createSlice({
  name: "player",
  initialState: {
    players: [],
    playerAvatar:[],
  },
  reducers: {
    getPlayer: (state,action) => {
        state.players = action.payload
    },
    getPlayerAvatar:(state,action)=>{
        state.playerAvatar=action.payload
    }
  },
});

export default playerSlice.reducer;
export const { getPlayer,getPlayerAvatar} = playerSlice.actions