const SmallTable = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF, calculateBlockHeight, screenCount, deckLength, deckScreenLength, screenType, heightOfString, blockHeight, index) => {
    let contentObject = JSON.parse(mainContent?.ContentObject || "{}")
    let imageData = JSON.parse(mainContent?.PubRandomEncounterContents?.[0]?.RandomEncounter?.RandomEncounterObject || "[]")

    documentMethods.text("", { columns: 2, continued: true })
    const tableHeader = 'https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/ShortHeaderYellow/20240531072459589_ShortHeader_YellowCropped.png'
    const urlFormation1 = `https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/CropBorder/20240531072352854_LongBorder-crop.png`

    // stylesheet?.value?.screenContentDescription && documentMethods.text("").fontSize(13).font(stylesheet.value.screenContentDescription.font)

    if ((documentMethods.page.height - 120 - documentMethods.y) < 200) {
        documentMethods.text("", documentMethods.x, documentMethods.y + (documentMethods.page.height - documentMethods.y), { continued: true, columns: stylesheet?.title !== 'dungeon' ? 2 : 1 });
    }

    stylesheet?.title === 'montkelian' && documentMethods.image(await getBase64ImageFromURL(tableHeader), { width: 230, continued: true })
    stylesheet?.value?.screenHeading && documentMethods.fillColor(stylesheet?.title === 'montkelian' ? 'white' : 'black')
        .fontSize(stylesheet?.title === 'montkelian' ? 13 : 18)
        .font(stylesheet.value.screenHeading.font)
        .text(contentObject?.title, stylesheet?.title === 'montkelian' ? documentMethods.x + 60 : documentMethods.x, stylesheet?.title === 'montkelian' ? (index === 0 || index === 1) ? documentMethods.y - 20 : documentMethods.y - 37 : documentMethods.y, { continued: true })

    stylesheet?.title !== 'montkelian' && documentMethods.moveDown()

    imageData[0]?.tableImage && documentMethods.image(await getBase64ImageFromURL(imageData[0]?.tableImage), stylesheet?.title === 'montkelian' ? documentMethods.x - 60 : documentMethods.x, stylesheet?.title === 'montkelian' ? documentMethods.y + 20 : documentMethods.y, { width: 230, continued: true })

    documentMethods.text("", documentMethods.x - 60, documentMethods.y + 132, { continued: true })

    stylesheet?.title === 'montkelian' && documentMethods.image(await getBase64ImageFromURL(urlFormation1), { continued: true, width: 230 })
}

export default SmallTable