// react
import React, { Component } from 'react';

// third-party
//import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
//import Indicator from '../Indicator';
import {
    Menu18x14Svg,
    // LogoSmallSvg,
    // Search20Svg,
    // Heart20Svg,
    // Cart20Svg,
} from 'svg';
import { mobileMenuOpen } from 'store/mobile-menu';
// import Search from '../Search';

class MobileHeader extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         searchOpen: false,
    //     };
    //     this.searchInput = React.createRef();
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     const { searchOpen } = this.state;

    //     if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
    //         this.searchInput.current.focus();
    //     }
    // }

    // handleOpenSearch = () => {
    //     this.setState(() => ({ searchOpen: true }));
    // };

    // handleCloseSearch = () => {
    //     this.setState(() => ({ searchOpen: false }));
    // };

    render() {
        const { openMobileMenu} = this.props;
        // const { openMobileMenu, wishlist, cart } = this.props;
        // const { searchOpen } = this.state;
        // const searchClasses = classNames('mobile-header__search', {
        //     'mobile-header__search--open': searchOpen,
        // });

        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                                <Menu18x14Svg />
                            </button>
                            <Link to="/" className="mobile-header__logo"><img src="https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/logo/wogd/20240610114308430_WOGD-Bright-30_zfuqy6.png" alt="World of Game Design Logo"/></Link>
                            {/* <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            /> */}
                            <div className="mobile-header__indicators">
                                {/* <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                /> */}
                                {/* <Indicator
                                    className="indicator--mobile d-sm-flex d-none"
                                    url="/shop/wishlist"
                                    value={wishlist.length}
                                    icon={<Heart20Svg />}
                                /> */}
                                {/* <Indicator
                                    className="indicator--mobile"
                                    url="/shop/cart"
                                    value={cart.quantity}
                                    icon={<Cart20Svg />}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileHeader);
