import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { UploadSvg } from "svg";

const CustomInput = ({
    label,
    placeholder,
    name,
    card,
    type,
    fieldErrors,
    fieldTouched,
    fieldValue,
    handleBlur,
    accept,
    handleChange,
    readonly = false,
    required = false,
}) => {
    return (
        <FormGroup className={type === "file" ? "m-0 d-flex" : ""}>
            <Label for={name ?? ""} className={type === "file" ? "m-0" : ""}>
                {type !== "file" && label}
                {type === "file" && <UploadSvg width={50} height={50} fill={`${card ? "#ffffff" : "#1976d2"}`} style={{ cursor: "pointer" }} />}
                {required && <font color="red"> *</font>}
            </Label>
            <Input
                name={name ?? ""}
                id={name ?? ""}
                type={type}
                accept={accept ?? ""}
                placeholder={placeholder || name}
                required={required}
                value={fieldValue ?? ""}
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly={readonly}
                style={{ height: type === "textarea" ? "100px" : "50px", borderRadius: "4px", ...(type === "file" && { display: "none" }) }}
            />
            {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
        </FormGroup>
    )
};

export default CustomInput;
