import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from 'api/client'

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await client.get('/fakeApi/users')
    return response.users
})
const usersSlice = createSlice({
    name: 'users',
    initialState: {
        userInfo: []
      },
    reducers: {
        addUser:(state,action)=>{
            state.userInfo=action.payload
          },
    },
    extraReducers: {
        [fetchUsers.fulfilled]: (state, action) => {
            return action.payload
        }
    }
})

export default usersSlice.reducer
export const { addUser } = usersSlice.actions