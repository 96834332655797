import React, { useEffect, useReducer, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { Spinner } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { InputLabel, FormControl, MenuItem, Select } from '@mui/material';
import html2canvas from "html2canvas";
import Table from "./Table";
import { randomColor, shortId } from "./utils";
import CustomInput from "global/CustomInput";
import { useAddMutation } from "services/gamecodexApi";
import { errorToast, successToast } from 'common/toast';
import { RANDOM_ENCOUNTER_TAG } from "common/tagFile";
import "./tableMaker.scss";

let contentObject = [];
function reducer(state, action) {

    let newData = state.data.map((item, index) => {
        let obj = { tableImage: "" }
        state.columns.forEach((data, i) => {
            if (data.label !== "+") obj[`${data.label}`] = item[`${data.id}`]
        });
        return obj;
    })
    contentObject = newData
    switch (action.type) {
        case "add_option_to_column":
            const optionIndex = state.columns.findIndex(
                (column) => column.id === action.columnId
            );
            return {
                ...state,
                skipReset: true,
                columns: [
                    ...state.columns.slice(0, optionIndex),
                    {
                        ...state.columns[optionIndex],
                        options: [
                            ...state.columns[optionIndex].options,
                            { label: action.option, backgroundColor: action.backgroundColor }
                        ]
                    },
                    ...state.columns.slice(optionIndex + 1, state.columns.length)
                ]
            };
        case "add_row":
            return {
                ...state,
                skipReset: true,
                data: action.maxValue ? [...state.data, {
                    "minimumValue": `${action.payload}`, "maximumValue": `${action.payload}`
                }] : [...state.data, {
                    "minimumValue": `${action.payload}`
                }]

            };
        case "update_column_type":
            const typeIndex = state.columns.findIndex(
                (column) => column.id === action.columnId
            );
            switch (action.dataType) {
                case "number":
                    if (state.columns[typeIndex].dataType === "number") {
                        return state;
                    } else {
                        return {
                            ...state,
                            columns: [
                                ...state.columns.slice(0, typeIndex),
                                { ...state.columns[typeIndex], dataType: action.dataType },
                                ...state.columns.slice(typeIndex + 1, state.columns.length)
                            ],
                            data: state.data.map((row) => ({
                                ...row,
                                [action.columnId]: isNaN(row[action.columnId])
                                    ? ""
                                    : Number.parseInt(row[action.columnId])
                            }))
                        };
                    }
                case "select":
                    if (state.columns[typeIndex].dataType === "select") {
                        return {
                            ...state,
                            columns: [
                                ...state.columns.slice(0, typeIndex),
                                { ...state.columns[typeIndex], dataType: action.dataType },
                                ...state.columns.slice(typeIndex + 1, state.columns.length)
                            ],
                            skipReset: true
                        };
                    } else {
                        let options = [];
                        state.data.forEach((row) => {
                            if (row[action.columnId]) {
                                options.push({
                                    label: row[action.columnId],
                                    backgroundColor: randomColor()
                                });
                            }
                        });
                        return {
                            ...state,
                            columns: [
                                ...state.columns.slice(0, typeIndex),
                                {
                                    ...state.columns[typeIndex],
                                    dataType: action.dataType,
                                    options: [...state.columns[typeIndex].options, ...options]
                                },
                                ...state.columns.slice(typeIndex + 1, state.columns.length)
                            ],
                            skipReset: true
                        };
                    }
                case "text":
                    if (state.columns[typeIndex].dataType === "text") {
                        return state;
                    } else if (state.columns[typeIndex].dataType === "select") {
                        return {
                            ...state,
                            skipReset: true,
                            columns: [
                                ...state.columns.slice(0, typeIndex),
                                { ...state.columns[typeIndex], dataType: action.dataType },
                                ...state.columns.slice(typeIndex + 1, state.columns.length)
                            ]
                        };
                    } else {
                        return {
                            ...state,
                            skipReset: true,
                            columns: [
                                ...state.columns.slice(0, typeIndex),
                                { ...state.columns[typeIndex], dataType: action.dataType },
                                ...state.columns.slice(typeIndex + 1, state.columns.length)
                            ],
                            data: state.data.map((row) => ({
                                ...row,
                                [action.columnId]: row[action.columnId] + ""
                            }))
                        };
                    }
                default:
                    return state;
            }
        case "update_column_header":
            const index = state.columns.findIndex(
                (column) => column.id === action.columnId
            );
            return {
                ...state,
                skipReset: true,
                columns: [
                    ...state.columns.slice(0, index),
                    { ...state.columns[index], label: action.label },
                    ...state.columns.slice(index + 1, state.columns.length)
                ]
            };
        case "update_cell":
            return {
                ...state,
                skipReset: true,
                data: state.data.map((row, index) => {
                    if (index === action.rowIndex) {
                        return {
                            ...state.data[action.rowIndex],
                            [action.columnId]: action.value
                        };
                    }
                    return row;
                })
            };
        case "add_column_to_left":
            const leftIndex = state.columns.findIndex(
                (column) => column.id === action.columnId
            );
            let leftId = shortId();
            return {
                ...state,
                skipReset: true,
                columns: [
                    ...state.columns.slice(0, leftIndex),
                    {
                        id: leftId,
                        label: "Column",
                        accessor: leftId,
                        dataType: "text",
                        created: action.focus && true,
                        options: []
                    },
                    ...state.columns.slice(leftIndex, state.columns.length)
                ]
            };
        case "add_column_to_right":
            const rightIndex = state.columns.findIndex(
                (column) => column.id === action.columnId
            );
            const rightId = shortId();
            return {
                ...state,
                skipReset: true,
                columns: [
                    ...state.columns.slice(0, rightIndex + 1),
                    {
                        id: rightId,
                        label: "Column",
                        accessor: rightId,
                        dataType: "text",
                        created: action.focus && true,
                        options: []
                    },
                    ...state.columns.slice(rightIndex + 1, state.columns.length)
                ]
            };
        case "delete_column":
            const deleteIndex = state.columns.findIndex(
                (column) => column.id === action.columnId
            );
            return {
                ...state,
                skipReset: true,
                columns: [
                    ...state.columns.slice(0, deleteIndex),
                    ...state.columns.slice(deleteIndex + 1, state.columns.length)
                ]
            };
        case "enable_reset":
            return {
                ...state,
                skipReset: false
            };
        case "reset":
            return {
                ...state,
                data: [],
                columns: [
                    {
                        id: "minimumValue",
                        label: "Minimum Value",
                        accessor: "minimumValue",
                        minWidth: 20,
                        dataType: "number",
                        options: [],
                    },
                    {
                        id: "maximumValue",
                        label: "Maximum Value",
                        accessor: "maximumValue",
                        minWidth: 20,
                        dataType: "number",
                        options: []
                    },
                    {
                        id: 999999,
                        width: 20,
                        label: "+",
                        disableResizing: true,
                        dataType: "null"
                    }
                ]
            };
        default:
            return state;
    }
}

const TableMaker = () => {

    const [formula, setFormula] = useState('');
    const [tableName, setTableName] = useState('');
    const [minimumValue, setMinimumValue] = useState(0);
    const [maximumValue, setMaximumValue] = useState(0);
    const [previewTable, setPreviewTable] = useState(false);
    const [tableStyle, setTableStyle] = useState('sci-fi');
    const [addTable, { isLoading: isTableLoading }] = useAddMutation();
    const [addMedia] = useAddMutation();
    const tableRef = useRef();
    const { isAuthenticated } = useAuth0();
    const userId = localStorage.getItem("userId");


    const [state, dispatch] = useReducer(reducer, {
        columns: [
            {
                id: "minimumValue",
                label: "Minimum Value",
                accessor: "minimumValue",
                minWidth: 20,
                dataType: "number",
                options: [],
            },
            {
                id: "maximumValue",
                label: "Maximum Value",
                accessor: "maximumValue",
                minWidth: 20,
                dataType: "number",
                options: []
            },
            {
                id: 999999,
                width: 20,
                label: "+",
                disableResizing: true,
                dataType: "null"
            }
        ], data: [], skipReset: false
    });

    useEffect(() => {
        dispatch({ type: "enable_reset" });
    }, [state.data, state.columns]);

    useEffect(() => {
        if (minimumValue > 0) {
            dispatch({ type: "add_row", payload: minimumValue })
        }
    }, [minimumValue]);

    const getBackgroundColor = (index) => {
        if (tableStyle === "dungeon") {
            if (index % 2 === 0) return '#e4e4e4';
            else return "#FFF"
        } else {
            if (index % 2 !== 0) return '#E3D5C7';
            else return "#F1ECE3"
        }
    }

    const calculateFormula = (value) => {

        const addReplace = /(\+d | \+ d)/ig;
        const minusReplace = /(\-d | \- d)/ig;
        const mulReplace = /(\\*d | \* d)/ig;
        const divReplace = /(\/d | \/ d)/ig;
        const startsWith = /^d/ig;

        value = value.replace(addReplace, '+1d');
        value = value.replace(minusReplace, '-1d');
        value = value.replace(mulReplace, '*1d');
        value = value.replace(divReplace, '/1d');
        value = value.replace(startsWith, '1d');
        try {
            const minRegex = /d\d+/ig;
            const minValue = Math.abs(eval(value.replace(minRegex, '')));
            const maxRegex = /d/ig;
            const maxValue = Math.abs(eval(value.replace(maxRegex, '*')));
            setMinimumValue(minValue);
            setMaximumValue(maxValue);
        } catch {
            errorToast("Please enter a valid formula")
        }
    }

    const saveTable = () => {
        const tableAreaClick = async () => {
            var formData = new FormData();
            const canvas = await html2canvas(tableRef.current, { quality: 1 });
            const dataURL = canvas.toDataURL()
            formData.append("photo", dataURLtoFile(dataURL, 'table.png'));
            await addMedia({
                entity: `SiteMedia?pathFolderName=content`,
                data: formData
            }).then((response) => {
                contentObject.forEach(item => item.tableImage = response.data.CloudinaryAddress)
                addTable({
                    entity: "RandomEncounter",
                    data: {
                        Name: tableName,
                        UserId: Number(userId),
                        RandomEncounterObject: JSON.stringify(contentObject),
                    },
                    tag: RANDOM_ENCOUNTER_TAG
                }).then((res) => {
                    setTableName("");
                    setFormula("");
                    setMaximumValue(0);
                    setMinimumValue(0);
                    setPreviewTable(false);
                    dispatch({ type: "reset" });
                    successToast("Table added successfully");
                })
            })
            function dataURLtoFile(dataURL, filename) {
                var arr = dataURL.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
            }
        };
        previewTable && tableAreaClick();
    }

    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    marginTop: "54px"
                }}
            >
                <div
                    style={{
                        height: 120,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}
                >
                    <h1 style={{ color: "#424242" }}>Table Maker Tool</h1>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <Row className="justify-content-center align-items-center" style={{ maxWidth: '1000px', width: '100%' }}>
                        <Col className="p-0">
                            <CustomInput
                                label={"Table Name"}
                                name={"Table Name"}
                                placeholder={"Enter table name"}
                                fieldValue={tableName}
                                handleChange={(e) => setTableName(e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <Row className="justify-content-center align-items-center" style={{ maxWidth: '1000px', width: '100%' }}>
                        <Col lg={10} className="ps-0">
                            <CustomInput
                                label={"Dice Formula"}
                                name={"Dice Formula"}
                                placeholder={"Enter formula"}
                                fieldValue={formula}
                                handleChange={(e) => {
                                    state.data = []
                                    setFormula(e.target.value)
                                }}
                            />
                        </Col>
                        <Col lg={2} className="pe-0 d-flex justify-content-end">
                            <Button
                                style={{ padding: "12px 27px", marginTop: "10px" }}
                                variant='contained'
                                component="label"
                                className={`pdf-create-btn`}
                                onClick={() => calculateFormula(formula)}
                            >
                                Generate
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ overflow: "auto", display: "flex" }}>
                    <div
                        style={{
                            flex: "1 1 auto",
                            border: "1px solid #E0E0E0",
                            maxWidth: 1000,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                    >
                        <Table
                            columns={state.columns}
                            data={state.data}
                            dispatch={dispatch}
                            skipReset={state.skipReset}
                            maximumValue={maximumValue}
                        />
                    </div>
                </div>
                {isAuthenticated && <div className="d-flex justify-content-center mt-3 mb-3">
                    <Row className="justify-content-end" style={{ maxWidth: '1000px', width: '100%' }}>
                        <Col lg={6} className="d-flex justify-content-end pe-0">
                            <Button
                                disabled={isTableLoading || !minimumValue || !maximumValue || !formula || !tableName}
                                component="label"
                                variant='contained'
                                color="success"
                                className='table-preview-btn me-3'
                                onClick={() => {
                                    setPreviewTable(true)
                                    const element = document.getElementById('preview-table');
                                    if (element) {
                                        element.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }
                                }
                            >
                                Preview
                            </Button>
                            <Button
                                disabled={isTableLoading || !minimumValue || !maximumValue || !formula || !tableName}
                                component="label"
                                variant='contained'
                                color="secondary"
                                className='table-save-btn'
                                onClick={saveTable}
                            >
                                {isTableLoading && <Spinner animation="border" size="sm" variant="primary" style={{ marginRight: "5px" }} />}
                                Save
                            </Button>
                        </Col>
                    </Row>
                </div>
                }
                <div id="preview-table">
                    {previewTable && <div className="d-flex justify-content-center mt-5 mb-5">
                        <div style={{ maxWidth: '1000px', width: '100%' }}>
                            <Row className="mb-3">
                                <Col>
                                    <FormControl sx={{ minWidth: 150 }}>
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Style
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            value={tableStyle ?? ""}
                                            onChange={(event) => {
                                                setTableStyle(event.target.value)
                                            }}
                                            label="Style"
                                        >
                                            <MenuItem value="sci-fi">
                                                Sci-Fi Epics
                                            </MenuItem>
                                            <MenuItem value="dungeon">
                                                Dungeon Epics
                                            </MenuItem>
                                            <MenuItem value="montkelian">
                                                Montkelian
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row style={{ padding: "0 10px" }}>
                                <Col className="p-0">
                                    <table className="dice-table" width="100%" align="center" ref={tableRef} border={0} style={{ ...(tableStyle === 'montkelian' && { borderSpacing: '1px', borderCollapse: "separate" }) }}>
                                        <thead style={{ backgroundColor: tableStyle === 'montkelian' ? '#AB9371' : '' }}>
                                            <tr>
                                                {
                                                    state.columns.map((column, index) => {
                                                        if (column.label !== "+") {
                                                            return <th key={`heading_${index}`} className={`text-center preview-table-heading ${tableStyle === 'montkelian' ? 'border-color' : ''}`}>{column.label}</th>
                                                        }
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.data.map((item, rowIndex) => {
                                                return <tr key={`row_${rowIndex}`} style={{ background: tableStyle !== "sci-fi" && getBackgroundColor(rowIndex) }}>
                                                    {Object.keys(item).map((key, index) => {
                                                        return <td key={`rowData_${index}`} className={`text-center preview-table-data ${tableStyle === 'montkelian' ? 'border-color' : ''}`}> {item[key]} </td>
                                                    })}
                                                </tr>
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    );
}

export default TableMaker;

