import React from "react";
import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'

const SkipMonthSelector = () => {
    const groups = [{ id: 1, title: 'group 1' }]

    const items = [
        {
            id: 1,
            group: 1,
            title: 'item 1',
            start_time: moment().add(5,'month'),
            end_time: moment().add(7, 'month')
        },
        {
            id: 2,
            group: 1,
            title: 'item 2',
            start_time: moment().add(12,'month'),
            end_time: moment().add(3, 'month')
        }
    ]
    return (
        <Timeline
            groups={groups}
            items={items}
            defaultTimeStart={moment().startOf('year')}
            defaultTimeEnd={moment().add('month')}
        />
    );
}

export default SkipMonthSelector;