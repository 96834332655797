import React from 'react'
import WOGDLogo from './../assets/logoWOGD-Bright-No-Shadow.png'
import 'animate.css';

const CustomLoader = () => {
    return (
        <div className='loader_container'>
            <div className="animate__animated animate__pulse animate__infinite	infinite" >
                <div className='loader_wrapper'>
                    <img src={WOGDLogo} alt="wogd_spinner" srcSet="" className='loader_logo' />
                </div>
            </div>
            <div className="col-12 w-100 d-flex justify-content-center mt-4" >
                <div className="snippet" data-title=".dot-flashing">
                    <div className="stage">
                        <div className="dot-flashing"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomLoader