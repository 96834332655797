import { createSlice } from "@reduxjs/toolkit";

const productModelSlice = createSlice({
  name: "productModel",
  initialState: {
   productModel: [],
  },
  reducers: {
    getAllProductModel: (state,action) => {
      state.productModel = action.payload
    }
  },
});

export default productModelSlice.reducer;
export const { getAllProductModel } = productModelSlice.actions

