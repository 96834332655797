
// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react'
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { Button } from "reactstrap";
import { FormattedMessage } from 'react-intl';
import IndicatorAccount from './IndicatorAccount';
import NavLinks from './NavLinks';
import { useAddMutation, useLazyGetAllRecordsQuery, useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { USER_TAG } from 'common/tagFile'
import { errorToast } from 'common/toast'
import { addUser } from "apps/forum/users/usersSlice";

export const NavPanel = (props) => {
    const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [skipUserInfo, setSkipUserInfo] = useState(true);
    const [isPublisher, setIsPublisher] = useState()
    const [addNewUser] = useAddMutation();
    const sort = {
        sortDirection: 'none',
        accessor: 'some_accessor',
    }
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoggedIn(isAuthenticated)
    }, [isAuthenticated])

    const [
        getUserRecord,
        {
            data: userData,
            error,
            isLoading,
        }
    ] = useLazyGetRecordsQuery();

    const [ getCounterPartyInvitations ] = useAddMutation();

    useEffect(() => {
        if (!skipUserInfo && isAuthenticated) {
            getUserRecord({
                columns: [],
                entity: `User/GetCurrentUser`,
                tag: USER_TAG,
                page: 1,
                sort: sort,
                columnFilter: [],
                globalFilter: [],
            }, {
                skip: skipUserInfo
            }).then((res) => {
                if(!res.error && res?.data?.email){
                    getCounterPartyInvitations({
                        entity: `Doc/CounterPartyInvitations?email=${res.data.email}`,
                        tag: USER_TAG,
                        isWogdV2Api: true,
                    })
                }
            })
        }
    }, [skipUserInfo, isAuthenticated])

    useEffect(() => {
        if (!isLoading && !error && userData) {
            dispatch(addUser(userData));
            setSkipUserInfo(true);
            userData && localStorage.setItem("userId", userData.id);
        }
        else {
            if (error) {
                errorToast(<FormattedMessage id="errorToast.Common.Something-Went-Wrong" defaultMessage="Something went wrong." />);
            }
        }
    }, [isLoading, error, userData]);

    useEffect(() => {
        if (!isLoading && !error && userData) {
            dispatch(addUser(userData));
        }
    }, [isLoading, error, userData]);

    useEffect(() => {
        if (isLoggedIn) {
            var accessToken = '';
            const getToken = async () => {
                accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH_AUDIENCE
                });
                const cookies = new Cookies();
                cookies.set('accessToken', accessToken);
                setSkipUserInfo(false);
            }
            getToken();
        }
    }, [isLoggedIn])

    useEffect(() => {
        if (isLoggedIn && userData) {
            const isUserExists = userData.email === user.email;
            if (!isUserExists) {
                var newUserData = {
                    Name: user.name,
                    Email: user.email,
                    SsoIdentifier: user.sub,
                    Description: user.name
                }
                try {
                    addNewUser({
                        entity: 'User/CreateUser',
                        data: { ...newUserData },
                        tag: USER_TAG
                    }).unwrap().then((res) => {
                        setSkipUserInfo(true);
                        if(!res.error && res?.email){
                            getCounterPartyInvitations({
                                entity: `Doc/CounterPartyInvitations?email=${res?.email}`,
                                tag: USER_TAG,
                                isWogdV2Api: true,
                            }).then(resp => {
                                if (!resp.error) {
                                    const cookies = new Cookies();
                                    if(!cookies.get('accessToken')) {
                                        history.push("/contract")
                                    }
                                }
                            })
                        }
                        }).catch(rejected => {
                            errorToast(<FormattedMessage id="errorToast.NavPannel.UserNotAdded" defaultMessage="User Not Added." />)
                        });
                } catch {
                    errorToast(<FormattedMessage id="errorToast.NavPannel.UserNotAdded" defaultMessage="User Not Added." />)
                }
            }
        }
    }, [userData, isLoading]);

    useEffect(() => {
        if (user && isAuthenticated) {
            const data = user['https://api.wogd.com/roles']
            if (data?.includes('Publisher')) {
                setIsPublisher(true)
            } else {
                setIsPublisher('')
            }
        } else {
            localStorage.removeItem('isPublisher')
            setIsPublisher('')
        }
    }, [user, userData])

    const { layout } = props;

    let logo = null;
    let departments = null;

    logo = (
        <div className="nav-panel__logo">
            <a href="https://wogd.com/" target="_blanck"><img src="https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/logo/wogd/20240610114308430_WOGD-Bright-30_zfuqy6.png" alt="World of Game Design Logo" /></a>
        </div>
    );

    return (
        <div className="nav-panel">
            <div className="nav-panel__container container">
                <div className="nav-panel__row">
                    {logo}
                    {departments}

                    <div className="nav-panel__nav-links nav-links">
                        <NavLinks isPublisher={isPublisher} />
                    </div>

                    {isLoggedIn ?
                        <>
                            <div className='i-acc d-flex' style={{ position: "absolute", right: '1%' }}>
                                <IndicatorAccount layout={layout} />
                            </div>
                        </>
                        :
                        <>
                            <Button className="button" style={{ position: "absolute", right: '1%' }} onClick={async() => {
                                await loginWithRedirect()
                                localStorage.setItem("path", window.location.pathname)
                            }}>
                                <FormattedMessage id="headerNavigation.Login" defaultMessage="Login" />
                            </Button>
                        </>
                    }

                    <div className='site_header_image'></div>
                </div>
            </div>
        </div>
    );
}

NavPanel.propTypes = {
    layout: PropTypes.oneOf(['default', 'compact']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
