// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function PostCard(props) {
    const { post, layout } = props;
    let postCardRecords = JSON.parse(post?.Content?.ContentObject)
    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    // const categories = post.ZoneContents.map((category, index) => {
    //     if (category.Content.ContentType.Name === 'Categories')
    //         return <Link key={`categoryLink${index}`} to="/">{category.Content.Name}</Link>
    //     else return null
    // }
    // );
    return (
        <div className={cardClasses}>
            <div className="post-card__image">
                <Link to="/blog/post-classic">
                    <img src={postCardRecords?.images.length > 1 ? postCardRecords?.images[postCardRecords?.images.length - 1]?.url : postCardRecords?.images[0]?.url} alt={postCardRecords?.images?.name} />
                </Link>
            </div>
            <div className="post-card__info">
                <div>
                    <div className="post-card__category">
                        {/* {categories} */}
                    </div>
                    <div className="post-card__name">
                        <Link to="/blog/post-classic">{postCardRecords?.title}</Link>
                    </div>

                    <div className="post-card__content">
                        {postCardRecords?.description}
                    </div>
                    <div className="post-card__date">
                        {postCardRecords?.blogdate}
                    </div>

                    <div className="post-card__read-more">
                        <Link to="/" className="btn btn-secondary btn-sm">Read More</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

PostCard.propTypes = {
    post: PropTypes.object,
    layout: PropTypes.oneOf(['grid-nl', 'grid-lg', 'list-nl', 'list-sm']),
};

export default PostCard;
