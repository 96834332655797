import React from "react";
import { useAddMutation } from 'services/gamecodexApi';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { Button, Row, Col, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import { PLAYER_DATA } from "common/tagFile";
import { successToast } from 'common/toast'

const PlayerForm = () => {
    const [addPlayer, { isLoading: isCreatingPlayer }] = useAddMutation();
    const userId = parseInt(localStorage.getItem("userId"));
    const playerSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required")
    });

    const initialValues = {
        Name: ""
    }

    const submitForm = (values) => {
        addPlayer({ entity: 'Player', data: { Name: values.Name, UserId: userId }, tag: PLAYER_DATA }).unwrap()
            .then(() => {
                successToast(<FormattedMessage id="successToast.PlayerForm.PlayerAdded" defaultMessage="Player added successfully." />);
            });
    }

    return (
        <div className="wrapper">
            <Formik
                initialValues={initialValues}
                validationSchema={playerSchema}
                onSubmit={submitForm}
            >
                {(formik) => {
                    const { errors, touched, values, handleBlur, handleChange } = formik;
                    return (
                        <Form>
                            <FormGroup className="">
                                <Row className="mt-2">
                                    <Col sm="12" md="2">
                                        <Label for="name" className="d-flex align-items-center justify-content-center mt-1">
                                            <FormattedMessage id="createCampaign.Campaign.InputName" defaultMessage="Name" /><span className="text-danger ms-1">*</span></Label>
                                    </Col>
                                    <Col md="10" sm="12">
                                        <Input
                                            name="Name"
                                            id="Name"
                                            type="text"
                                            placeholder="Name"
                                            className={`${errors.Name && touched.Name ? "is-invalid" : null}`}
                                            value={values.Name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.Name && <FormFeedback >{errors.Name}</FormFeedback>}
                                    </Col>
                                </Row>
                            </FormGroup>
                            <Row>
                                <Col md="12">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="float-end"
                                        disabled={isCreatingPlayer}
                                    >
                                        {isCreatingPlayer && <Spinner animation="border" variant="primary" size="sm" />} Create
                                    </Button>
                                </Col>
                            </Row>
                        </Form >
                    );
                }
                }
            </Formik >
        </div >
    );
}

export default PlayerForm;