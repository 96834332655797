import { createSlice } from "@reduxjs/toolkit";

const avatarSlice = createSlice({
  name: "avatar",
  initialState: {
    avatars: [],
    myAvatar: [],
    avatarCell: [],
    avatarRole: [],
  },
  reducers: {
    getAllAvatars: (state, action) => {
      state.avatars = action.payload
    },
    getMyAvatar: (state, action) => {
      state.myAvatar = action.payload
    },
    getMyAvatarCell: (state, action) => {
      state.avatarCell = action.payload
    },
    getAvatarRole: (state, action) => {
      state.avatarRole = action.payload
    }
  },
});

export default avatarSlice.reducer;
export const { getAllAvatars, getMyAvatar, getMyAvatarCell, getAvatarRole } = avatarSlice.actions