import React, { useState, useEffect } from "react";
import "./StartScreen.scss";
import logo from "./assets/AdventureHexOnlineLogo.png";
import motto from "./assets/ExploreColonizeCreate.png";
import { useDispatch, useSelector } from 'react-redux'

// third-party
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'universal-cookie';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { PLAYER_DATA, PLAYER_AVATAR_DATA, AVATAR_CAMPAIGN_TAG, AVATAR_CELL } from 'common/tagFile'
import { getPlayer, getPlayerAvatar } from "./player/playerSlice";
import PlayerForm from "./player/playerForm";
import AvatarForm from "./player/avatarForm";
import SelectAvatarScreen from "./admin/avatar/selectAvatarScreen";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import SelectPlayerScreen from "./admin/avatar/selectPlayerScreen";

const StartScreen = () => {
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [createPlayer, setCreatePlayer] = useState(false);
  const [createAvatar, setCreateAvatar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const myAvatars = useSelector((state) => state.player.playerAvatar);
  const playerInfo = useSelector((state) => state.player.players);
  const [sort] = useState({
    sortDirection: 'none',
    accessor: 'some_accessor',
  });
  const location = useLocation()
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const history = useHistory();
  const [unique, setUnique] = useState(true);
  const campaignId = location?.state?.CampaignId
  let user = JSON.parse(localStorage.getItem('playerRecord'));
  const [selectedPlayer, setSelectedPlayer] = useState(user ? user : {});
  useEffect(() => {
    if (isAuthenticated) {
      var accessToken = '';
      const getToken = async () => {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH_AUDIENCE
        });
        const cookies = new Cookies();
        cookies.set('accessToken', accessToken);
      }
      getToken();
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  const [
    getPlayerRecord,
    {
      data: playerData,
      error,
      isLoading,
    }
  ] = useLazyGetRecordsQuery();
  useEffect(() => {
    if (user && user != null && unique) {
      setSelectedPlayer(user);
      setUnique(false)

    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (!localStorage.getItem("userId") || !isAuthenticated) {
      loginWithRedirect();
      history.push('/')
    }
    if (user != null) {
      setSelectedPlayer(user)
    }
    isAuthenticated && getPlayerRecord({
      columns: [],
      entity: `Player`,
      tag: PLAYER_DATA,
      page: 1,
      sort: sort,
      columnFilter: { userId: parseInt(userId) },
      globalFilter: [],
    })
    // eslint-disable-next-line
  }, [modal, isAuthenticated])

  isLoading
    ? console.log('-=-Loading=-=')
    : error
      ? console.log('=-ERRR=-')
      : playerData && dispatch(getPlayer(playerData.value))

  const [
    getRecord,
    {
      data: avatarList,
      isLoading: playerLoading,
    }
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    if (!isEmpty(selectedPlayer) && campaignId) {
      getRecord({
        columns: [],
        entity: "PlayerAvatar",
        tag: PLAYER_AVATAR_DATA,
        page: 1,
        sort: sort,
        columnFilter: { PlayerId: user.Id },
        globalFilter: [],
        expand: `Avatar`
      })
    }
    // eslint-disable-next-line
  }, [selectedPlayer, campaignId, modal])

  const [
    getCurrentPlayerAvatar,
    {
      data: playerAvatar,
    }
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    getCurrentPlayerAvatar({
      columns: [],
      entity: "AvatarCampaign",
      tag: AVATAR_CAMPAIGN_TAG,
      page: 1,
      sort: sort,
      columnFilter: { campaignId: campaignId },
      globalFilter: [],
      expand: `Avatar`
    })
    // eslint-disable-next-line
  }, [avatarList])



  useEffect(() => {
    if (avatarList && avatarList?.value?.length && playerAvatar && playerAvatar?.value?.length) {
      var filteredAvatarRecords = []
      avatarList.value.forEach((val) => {
        playerAvatar.value.forEach((o) => {
          if (o.Avatar.Id === val.Avatar.Id) {
            filteredAvatarRecords.push(o)
          }
        })
      })
      dispatch(getPlayerAvatar(filteredAvatarRecords))
    }
    // eslint-disable-next-line
  }, [avatarList, playerAvatar])


  useEffect(() => {
    if (!isLoading && !error && playerData && playerData.value.length !== 0) {
      setCreatePlayer(false);
    }
    else {
      setCreatePlayer(true);
    }
    if (!isLoading && !error && playerData && playerData.value.length === 1) {
      localStorage.setItem('playerRecord', JSON.stringify(playerData.value[0]))
      setCreatePlayer(false);
    }
    if (myAvatars.length === 0 && playerData && playerData.value.length > 0) {
      setCreateAvatar(true);
    }
    else {
      setCreateAvatar(false);
    }
  }, [isLoading, error, playerData, myAvatars]);

  useEffect(() => {
    if (createPlayer && !isLoading && playerLoading === undefined) {
      setLoading(false);
    }
    else if (createAvatar && !isLoading && !playerLoading) {
      setLoading(false);
    }
    else if (!isLoading && !playerLoading) {
      setLoading(false);
    }
    else {
      setLoading(true);
    }
  }, [createAvatar, createPlayer, isLoading, playerLoading]);

  const onToggle = () => {
    setModal(!modal)
  }
  return (
    <div className="startScreenContent">
      <div className="background-part" >
        {isAuthenticated &&
          <div>
            <button className="play-button" onClick={() => onToggle()} disabled={loading ? true : false}>{loading ? 'Loading...' : createPlayer
              ? 'Create Player' : isEmpty(selectedPlayer)
                ? 'Select Player' : createAvatar
                  ? 'Create Avatar' : 'Play Now'}
            </button>
            <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
              <ModalHeader toggle={() => { setModal(false) }}>{createPlayer ? 'Add Player' : isEmpty(selectedPlayer) ? 'Select Player' : createAvatar ? 'Add Avatar' : 'Select Avatar'}</ModalHeader>
              <ModalBody>
                {createPlayer ? <PlayerForm setModal={setModal} />
                  : isEmpty(selectedPlayer) ? <SelectPlayerScreen setModal={setModal} modal={modal} setSelectedPlayer={setSelectedPlayer} />
                    : createAvatar ? <AvatarForm playerInfo={playerInfo} setModal={setModal} campaignId={campaignId} tags={[PLAYER_AVATAR_DATA, AVATAR_CELL]} />
                      : <SelectAvatarScreen setModal={setModal} modal={modal} playerInfo={selectedPlayer} campaignId={campaignId} />}
              </ModalBody>
            </Modal>
          </div>
        }
      </div>
      <img
        className="start-screen__logo"
        src={logo}
        alt="AdventureHex Online"
      />
      <img
        className="start-screen__motto"
        src={motto}
        alt="Explore Colonize Create"
      />
    </div>
  );
};
export default StartScreen;
