const styleSheetData = async (styleSheet) => {
    let styles = [
        {
            title: "sci-fi",
            value: {
                screenTitleBanner: {
                    fontSize: 30,
                    align: 'center',
                    font: "earthOrbiter bold"
                },
                screenTitle: {
                    fontSize: 40,
                    color: 'black',
                    align: 'center',
                    font: "earthOrbiter bold"
                },
                screenHeading: {
                    fontSize: 20,
                    align: 'left',
                    columns: 2,
                    continued: true,
                    font: 'earthOrbiter normal'
                },
                screenContentDescription: {
                    fontSize: 15,
                    columns: 2,
                    continued: true,
                    font: 'times new roman normal'
                },
                keyFeatures: {
                    fontSize: 16,
                    font: 'times new roman bold'
                },
                backgroundImage: {
                    left: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/media/content/20240610114117129_bwqfcxfa0rf8kx7fqzax.jpeg'),
                    right: "",
                    commonBg: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/media/content/20240610114117129_bwqfcxfa0rf8kx7fqzax.jpeg'),
                },
                svg: {
                    screenHeading: {
                        svg: `<svg viewBox="0 0 425 0" style="background-color:red" >
                        <style type="text/css">
                        .st0{fill:#FFFFFF;}
                        .st1{fill:none;stroke:#FFFFFF;stroke-width:1.2954;stroke-miterlimit:10;}
                        .st2{stroke:#FFFFFF;stroke-miterlimit:10;}
                        .st3{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
                        .st4{fill:none;stroke:#FFFFFF;stroke-width:11;stroke-miterlimit:10;stroke-dasharray:1,3;}
                        .st5{fill:none;stroke:#FFFFFF;stroke-width:11;stroke-miterlimit:10;}
                        .st6{fill:none;stroke:#FFFFFF;stroke-width:11;stroke-miterlimit:10;stroke-dasharray:1.0038,2.0077;}
                        .st7{fill:none;stroke:#FFFFFF;stroke-width:2.08;stroke-miterlimit:10;}
                        .st8{fill:none;stroke:#FFFFFF;stroke-width:0.4798;stroke-miterlimit:10;}
                        .st9{fill:none;stroke:#FFFFFF;stroke-width:1.4393;stroke-miterlimit:10;}
                        .st10{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
                        .st11{fill:none;stroke:#FFFFFF;stroke-width:1.3817;stroke-miterlimit:10;}
                        .st12{fill:none;stroke:#FFFFFF;stroke-width:0.6909;stroke-miterlimit:10;}
                        .st13{fill:none;stroke:#FFFFFF;stroke-width:4;stroke-miterlimit:10;}
                        .st14{fill:none;stroke:#FFFFFF;stroke-width:2.2616;stroke-miterlimit:10;}
                        .st15{fill:none;stroke:#FFFFFF;stroke-width:0.6875;stroke-miterlimit:10;}
                        .st16{fill:none;stroke:#FFFFFF;stroke-width:0.7549;stroke-miterlimit:10;}
                        .st17{fill:none;stroke:#FFFFFF;stroke-width:0.8213;stroke-miterlimit:10;}
                        .st18{fill:none;stroke:#FFFFFF;stroke-width:13;stroke-miterlimit:10;stroke-dasharray:1,3;}
                        .st19{fill:none;stroke:#FFFFFF;stroke-width:1.1121;stroke-miterlimit:10;}
                        .st20{fill:none;stroke:#FFFFFF;stroke-width:0.556;stroke-miterlimit:10;}
                        </style>
                        <g opacity="0.5">
                        <circle class="st0" cx="208.09" cy="78.57" r="1.31"/>
                        <g>
                            <circle class="st1" cx="288.56" cy="81.09" r="2.01"/>
                        </g>
                        <circle class="st2" cx="108.36" cy="81.15" r="1.31"/>
                        <line class="st3" x1="147.41" y1="81.09" x2="108.16" y2="81.09"/>
                        <line class="st3" x1="258.73" y1="81.09" x2="151.43" y2="81.09"/>
                        <line class="st3" x1="286.55" y1="81.09" x2="262.59" y2="81.09"/>
                        <g>
                            <circle class="st1" cx="149.42" cy="81.09" r="2.01"/>
                        </g>
                        <circle class="st3" cx="62.6" cy="67.32" r="41.3"/>
                        <circle class="st3" cx="62.6" cy="67.32" r="38.46"/>
                        <path class="st4" d="M99.43,51.72c-6.01-14.2-19.27-23.12-33.65-24.28"/>
                        <g>
                            <path class="st0" d="M62.6,21.02c25.53,0,46.29,20.77,46.29,46.29s-20.77,46.29-46.29,46.29S16.31,92.84,16.31,67.32
                                S37.07,21.02,62.6,21.02 M62.6,19.02c-26.67,0-48.29,21.62-48.29,48.29s21.62,48.29,48.29,48.29s48.29-21.62,48.29-48.29
                                S89.27,19.02,62.6,19.02L62.6,19.02z"/>
                        </g>
                        <g>
                            <path class="st0" d="M62.6,16.15c28.26,0,51.17,22.91,51.17,51.17s-22.91,51.17-51.17,51.17S11.43,95.58,11.43,67.32
                                S34.34,16.15,62.6,16.15 M62.6,13.15c-29.87,0-54.17,24.3-54.17,54.17s24.3,54.17,54.17,54.17s54.17-24.3,54.17-54.17
                                S92.47,13.15,62.6,13.15L62.6,13.15z"/>
                        </g>
                        <g>
                            <g>
                                <path class="st5" d="M49.26,7.51c-0.16,0.04-0.33,0.07-0.49,0.11"/>
                                <path class="st6" d="M46.82,8.11C30.28,12.5,16.49,23.65,8.57,38.42"/>
                                <path class="st5" d="M8.1,39.31c-0.08,0.15-0.15,0.3-0.23,0.45"/>
                            </g>
                        </g>
                        <circle class="st7" cx="96.1" cy="17.18" r="7.87"/>
                        <circle class="st8" cx="96.1" cy="17.18" r="4.3"/>
                        <circle class="st9" cx="96.1" cy="17.18" r="2.16"/>
                        <path class="st10" d="M131.74,80.79c1.11-6.27,1.56-12.77,1.26-19.4c-0.31-6.82-1.38-13.43-3.14-19.74"/>
                        <path class="st3" d="M132.43,97.72c6.39-19.05,7.2-40.04,1.41-60.32"/>
                        <path class="st3" d="M140.91,64.68c-0.05-14.09-2.76-28.41-8.41-42.25"/>
                        <circle class="st0" cx="153.38" cy="55.33" r="1.31"/>
                        <g>
                            <circle class="st1" cx="170.65" cy="55.33" r="2.01"/>
                        </g>
                        <path class="st10" d="M125.9,68.09c0-12.1-2.93-24.29-8.12-34.35"/>
                        <path class="st10" d="M111.89,110.43c7.3-10.02,12.12-21.97,13.56-34.93"/>
                        <g>
                            <circle class="st11" cx="110.34" cy="112.79" r="3.58"/>
                            <circle class="st12" cx="110.34" cy="112.79" r="1.56"/>
                        </g>
                        <g>
                            <circle class="st11" cx="116.3" cy="30.56" r="3.58"/>
                            <circle class="st12" cx="116.3" cy="30.56" r="1.56"/>
                        </g>
                        <g>
                            <circle class="st11" cx="125.9" cy="71.68" r="3.58"/>
                            <circle class="st12" cx="125.9" cy="71.68" r="1.56"/>
                        </g>
                        <circle class="st13" cx="62.6" cy="67.32" r="22.19"/>
                        <circle class="st14" cx="62.6" cy="67.32" r="12.54"/>
                        <path class="st15" d="M52.27,42.97c-7.32,3.11-12.95,9.42-15.13,17.18"/>
                        <path class="st16" d="M58.55,38.56c-12.79,1.78-22.91,11.9-24.71,24.69"/>
                        <path class="st17" d="M62.6,35.73c-17.45,0-31.59,14.14-31.59,31.59"/>
                        <polyline class="st3" points="151.43,79.97 154.55,78.57 207.2,78.57 	"/>
                        <line class="st3" x1="174.55" y1="83.61" x2="237.21" y2="83.61"/>
                        <polyline class="st3" points="194.55,86.13 217.21,86.13 220.41,83.61 	"/>
                        <circle class="st0" cx="174.02" cy="83.61" r="1.31"/>
                        <circle class="st0" cx="193.64" cy="86.13" r="1.31"/>
                        <circle class="st0" cx="237.92" cy="83.61" r="1.31"/>
                        <line class="st3" x1="244.84" y1="66.58" x2="110.73" y2="66.58"/>
                        <line class="st3" x1="259.21" y1="79.07" x2="248.32" y2="68.17"/>
                        <path class="st3" d="M262.83,80.62"/>
                        <path class="st3" d="M286.24,80.62"/>
                        <line class="st3" x1="342.26" y1="80.62" x2="290.89" y2="80.62"/>
                        <line class="st3" x1="200.89" y1="73.6" x2="290.54" y2="73.6"/>
                        <line class="st18" x1="216.48" y1="69.6" x2="242.13" y2="69.6"/>
                        <g>
                            <circle class="st19" cx="246.83" cy="97.41" r="2.88"/>
                            <circle class="st20" cx="246.83" cy="97.41" r="1.25"/>
                        </g>
                        <g>
                            <circle class="st19" cx="260.66" cy="97.41" r="2.88"/>
                            <circle class="st20" cx="260.66" cy="97.41" r="1.25"/>
                        </g>
                        <g>
                            <circle class="st19" cx="232.99" cy="97.41" r="2.88"/>
                            <circle class="st20" cx="232.99" cy="97.41" r="1.25"/>
                        </g>
                        <g>
                            <circle class="st19" cx="246.83" cy="84.23" r="2.88"/>
                            <circle class="st20" cx="246.83" cy="84.23" r="1.25"/>
                        </g>
                        <g>
                            <circle class="st20" cx="246.83" cy="66.58" r="1.99"/>
                            <circle class="st20" cx="246.83" cy="66.58" r="0.86"/>
                        </g>
                        <g>
                            <circle class="st20" cx="260.66" cy="80.62" r="1.99"/>
                            <circle class="st20" cx="260.66" cy="80.62" r="0.86"/>
                        </g>
                        <line class="st19" x1="235.88" y1="97.41" x2="243.94" y2="97.41"/>
                        <line class="st19" x1="234.86" y1="95.32" x2="244.36" y2="85.82"/>
                        <line class="st19" x1="249.71" y1="97.41" x2="257.77" y2="97.41"/>
                        <line class="st19" x1="249.71" y1="85.82" x2="259.21" y2="95.32"/>
                        <line class="st20" x1="260.66" y1="82.59" x2="260.66" y2="94.52"/>
                        <line class="st20" x1="246.88" y1="68.51" x2="246.83" y2="81.35"/>
                        <g>
                            <circle class="st12" cx="348.34" cy="87.7" r="2.47"/>
                            <circle class="st12" cx="348.34" cy="87.7" r="1.07"/>
                        </g>
                        <line class="st11" x1="334.66" y1="101.05" x2="346.46" y2="89.24"/>
                        <circle class="st7" cx="362.26" cy="70.79" r="7.87"/>
                        <circle class="st8" cx="362.26" cy="70.79" r="4.3"/>
                        <circle class="st9" cx="362.26" cy="70.79" r="2.16"/>
                        <line class="st11" x1="349.96" y1="85.71" x2="358.08" y2="77.59"/>
                        <polyline class="st3" points="260.66,85.48 331.52,85.48 336.03,80.97 	"/>
                        <polyline class="st3" points="136.68,69.6 208.12,69.6 211.61,66.58 	"/>
                        <polyline class="st3" points="145.54,72.02 186.84,72.02 190.33,69.6 	"/>
                        <circle class="st0" cx="331.23" cy="85.54" r="1.31"/>
                        <circle class="st0" cx="336.03" cy="80.62" r="1.31"/>
                        <circle class="st0" cx="208.31" cy="69.6" r="1.31"/>
                        <circle class="st0" cx="190.46" cy="69.6" r="1.31"/>
                        <circle class="st0" cx="211.61" cy="66.58" r="1.31"/>
                        <circle class="st0" cx="145.54" cy="72.02" r="1.31"/>
                        <circle class="st0" cx="136.68" cy="69.6" r="1.31"/>
                        <g>
                            <circle class="st1" cx="344.46" cy="80.62" r="2.01"/>
                        </g>
                        <polyline class="st7" points="341.18,94.52 307.54,94.52 295.35,106.71 114.43,106.71 	"/>
                        <g>
                            <circle class="st1" cx="333.26" cy="102.52" r="2.01"/>
                        </g>
                    </g>
                    </svg>`,
                        style: {
                            useCSS: true,
                            assumePt: true,
                            width: 800,
                            height: 400
                        },
                        x: -145,
                        y: -280
                    }
                }
            },
        },
        {
            title: "dungeon",
            value: {
                screenTitleBanner: {
                    fontSize: 30,
                    align: 'center',
                    font: "times new roman bold"
                },
                screenTitle: {
                    fontSize: 40,
                    color: 'black',
                    align: 'center',
                    font: "times new roman bold"
                },
                screenHeading: {
                    fontSize: 20,
                    // width: 512,
                    align: 'left',
                    columns: 1,
                    font: 'times new roman bold'
                },
                screenContentDescription: {
                    fontSize: 15,
                    continued: true,
                    font: 'times new roman normal'
                },
                keyFeatures: {
                    fontSize: 16,
                    font: 'times new roman bold'
                },
                backgroundImage: {
                    left: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/media/content/20240610114151721_dqnhhtfybgc8dpao33rp.png'),
                    right: "",
                    commonBg: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/media/content/20240610114151721_dqnhhtfybgc8dpao33rp.png')
                }
            },
        },
        {
            title: "montkelian",
            value: {
                screenTitleBanner: {
                    fontSize: 30,
                    align: 'center',
                    font: "MrDarcyBold"
                },
                screenTitle: {
                    fontSize: 30,
                    color: '#551D15',
                    align: 'center',
                    font: "MrDarcyBold"
                },
                screenHeading: {
                    fontSize: 20,
                    color: '#551D15',
                    align: 'left',
                    columns: 1,
                    font: 'MrDarcyBold'
                },
                screenContentDescription: {
                    fontSize: 15,
                    columns: 2,
                    // align: 'justify',
                    continued: true,
                    font: 'MinionPro'
                },
                keyFeatures: {
                    fontSize: 16,
                    font: 'MinionPro'
                },
                backgroundImage: {
                    left: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/MontkelianBackground/20240531060008674_montkelian-background.jpg'),
                    right: '',
                    commonBg: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/MontkelianBackground/20240531060008674_montkelian-background.jpg'),
                    oddImage: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/rightbgcrop/20240531065254209_right-bg-exact.png'),
                    evenImage: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/leftbgcrop/20240531065453242_left-bg-exact.png'),
                    bottomRightImage: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/lighterright/20240531065643465_output-onlinepngtools-right.png'),
                    bottomLeftImage: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/lighterleft/20240531070340237_output-onlinepngtools-left.png')
                },
                headerBorder: {
                    url: await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/HeaderBorder/20240531070744766_LongBorder.png'),
                }
            },
        },
    ]
    return styles.find((style) => style.title === styleSheet)
}

async function getBase64ImageFromURL(url) {
    const resp = await fetch(url, { cache: 'no-cache' })
    const blob = await resp.blob()

    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })
}

// function getBase64ImageFromURL(url, format) {
//     return new Promise((resolve, reject) => {
//         let img = new Image();
//         img.setAttribute("crossOrigin", "anonymous");
//         img.onload = () => {
//             let canvas = document.createElement("canvas");
//             canvas.width = img.width;
//             canvas.height = img.height;

//             let ctx = canvas.getContext("2d");
//             ctx.drawImage(img, 0, 0);

//             let dataURL = canvas.toDataURL(`image/${format}`);

//             resolve(dataURL);
//         };

//         img.onerror = error => {
//             reject(error);
//         };

//         img.src = url;
//     });
// }

export default styleSheetData