import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Input, InputGroup, InputGroupAddon, Spinner } from 'reactstrap';
import { Formik, Form } from "formik";
import classNames from 'classnames';
import "react-multi-toggle/style.css";
import MultiToggle from "react-multi-toggle";
import * as Yup from "yup";
import { Modal } from 'react-bootstrap';
import { Lightbox } from 'react-modal-image'
import ReactImageUploading from 'react-images-uploading';
import { FormattedMessage } from 'react-intl';
import { useAddMutation, useLazyGetRecordsQuery, useUpdateMutation } from 'services/gamecodexApi';
import { AVATAR_CAMPAIGN_TAG, CAMPAIGN_TAG, DEFAULT_CAMPAIGN_LOGO_TAG, GAME_ROLE_TAG, GET_ALL_CAMPAIGN_LOGO_TAG, PLAYER_AVATAR_DATA, SCALE_TAG, AVATAR_CELL, GET_ALL_SYSTEM_MEDIA_TAG, GET_ALL_PRODUCTLINE_MEDIA_TAG } from 'common/tagFile';
import { errorToast, successToast } from 'common/toast';
import { GAME_MASTER_ROLE } from 'common/roleFile';
import AvatarForm from 'apps/adventure-hex/player/avatarForm';
import './CreateCampaign.scss'


const CreateCampaign = ({ toggleCampaignSideBar, setPage, setIsCampaignCreating, campaignId, currentOperationMode = "Add", setCurrentOperationMode, campaignItem, currentCampaignLogo }) => {
    const [selected, setSelected] = useState(campaignItem?.ScaleId || campaignItem?.ScaleId === 0 ? campaignItem?.ScaleId : 1);
    const [selectedLogoType, setSelectedLogoType] = useState(currentCampaignLogo ? false : true);
    const [sort] = useState({
        sortDirection: 'desc',
        accessor: 'Id',
    })
    const [updateCampaign, { isLoading: isUpdating }] = useUpdateMutation()
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedSystem, setSelectedSystem] = useState(0);
    const [selectedLogo, setSelectedLogo] = useState(0);
    const [options, setOptions] = useState([]);
    const [optionalLogo] = useState([
        { displayName: <FormattedMessage id="createCampaign.Campaign.ChooseLogo" defaultMessage="Choose Logo" />, value: true, optionClass: "line-height" },
        { displayName: <FormattedMessage id="createCampaign.Campaign.UploadLogo" defaultMessage="Upload Logo" />, value: false, optionClass: "line-height" },
    ]);
    const [addRecords, { isLoading: isCampaignAdding }] = useAddMutation()
    let player = JSON.parse(localStorage.getItem('playerRecord'));
    const [gameEpics, setGameEpics] = useState([]);
    const [systemEpics, setSystemEpics] = useState([]);
    const [campaignLogo, setCampaignLogo] = useState([])
    const [isImagePreview, setIsImagePreview] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const [loading, setLoading] = useState(true)
    const [currentLogoFile, setCurrentLogoFile] = useState({});
    const [images, setImages] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState(0);
    const maxNumber = 69;
    const [currentOperation, setCurrentOperation] = useState(null);
    const [
        getScaleRecords,
        {
            data: scaleRecord
        }
    ] = useLazyGetRecordsQuery();

    const [
        getDefaultCampaignLogo,
        {
            data: defaultCampaignLogo,
        }
    ] = useLazyGetRecordsQuery();

    const [
        getGameRole,
        {
            data: gameRole,
        }
    ] = useLazyGetRecordsQuery();
    const [
        getAvatarRecords,
        {
            data: avatarRecords,
        }
    ] = useLazyGetRecordsQuery();
    const [
        getProductLineMedia,
        {
            data: productLineMediaItem,
        }
    ] = useLazyGetRecordsQuery();
    const [
        getSystemMedia,
        {
            data: systemMediaItem,
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        currentCampaignLogo?.CampaignLogoUrl && setImages([{ "data_url": currentCampaignLogo?.CampaignLogoUrl }])
    }, [currentCampaignLogo])

    useEffect(() => {
        getScaleRecords({
            columns: [],
            entity: "Scale",
            tag: SCALE_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
        })
        getDefaultCampaignLogo({
            columns: [],
            entity: "DefaultCampaignLogo",
            tag: DEFAULT_CAMPAIGN_LOGO_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
        })
        getGameRole({
            columns: [],
            entity: "GameRole",
            tag: GAME_ROLE_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
        })
        player && getAvatarRecords({
            columns: [],
            entity: "PlayerAvatar",
            tag: PLAYER_AVATAR_DATA,
            page: 1,
            sort: sort,
            columnFilter: { PlayerId: player.Id },
            globalFilter: [],
            expandFilter: "Avatar/IsDeleted ne true",
            expand: "Avatar"
        })
        getProductLineMedia({
            columns: [],
            entity: "GetAllProductLineMedia",
            tag: GET_ALL_PRODUCTLINE_MEDIA_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            expand: "GameMedia"
        })
        getSystemMedia({
            columns: [],
            entity: "GetAllSystemMedia",
            tag: GET_ALL_SYSTEM_MEDIA_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            expand: "GameMedia"
        })
        // eslint-disable-next-line
    }, [getScaleRecords, sort])

    useEffect(() => {
        defaultCampaignLogo && defaultCampaignLogo?.value?.length && setCampaignLogo(defaultCampaignLogo.value)
    }, [defaultCampaignLogo, currentCampaignLogo])

    const selectedImageDesign = (value, index) => {
        document.getElementById(value).style.backgroundColor = "rgba(43, 130, 230, .8)";
        if (selectedOption !== 0) {
            document.getElementById(selectedOption).style.backgroundColor = "transparent";
        }
        selectedOption === value ? setSelectedOption(0) : setSelectedOption(value)
    }

    const selectedSystemDesign = (value, index) => {
        document.getElementById(`system${value}`).style.backgroundColor = "rgba(43, 130, 230, .8)";
        if (selectedSystem !== 0) {
            document.getElementById(`system${selectedSystem}`).style.backgroundColor = "transparent";
        }
        selectedSystem === value ? setSelectedSystem(0) : setSelectedSystem(value)
    }

    const selectedLogoDesign = (value, index) => {
        setImages([])
        document.getElementById(`logo${value}`).classList.add("singlefolio");
        if (selectedLogo !== 0) {
            document.getElementById(`logo${selectedLogo}`).classList.remove("singlefolio");
        }
        selectedLogo === value ? setSelectedLogo(0) : setSelectedLogo(value)
    }

    useEffect(() => {
        let data = [];
        scaleRecord && scaleRecord.value.map((val) => {
            return data = [...data, {
                displayName: val.Name, value: val.Id, optionClass: "line-height"
            }]
        })
        setOptions(data)
    }, [scaleRecord])

    useEffect(() => {
        const productLine = [];
        const system = [];
        productLineMediaItem && productLineMediaItem.value && productLineMediaItem.value.length && productLineMediaItem.value.forEach((val) => {
            productLine.push({ img: val.GameMedia.Url, Id: val.Id })
        })
        systemMediaItem && systemMediaItem.value && systemMediaItem.value.length && systemMediaItem.value.forEach((val) => {
            system.push({ img: val.GameMedia.Url, Id: val.Id })
        })
        if (productLine.length > 0 && system.length > 0) {
            setLoading(false)
        } else {
            setLoading(true)
        }
        setGameEpics(productLine);
        setSystemEpics(system)
        // eslint-disable-next-line
    }, [productLineMediaItem, systemMediaItem])

    const initialCampaignValues = {
        campaignName: campaignItem?.Name ? campaignItem.Name : "",
        campaignScale: campaignItem?.ScaleId ? campaignItem?.ScaleId : "",
        campaignSpawnPointX: campaignItem?.X || campaignItem?.X === 0 ? campaignItem?.X : 0,
        campaignSpawnPointY: campaignItem?.Y || campaignItem?.Y === 0 ? campaignItem?.Y : 0,
        campaignSpawnPointZ: campaignItem?.Z || campaignItem?.Z === 0 ? campaignItem?.Z : 0,
        campaignSpawnPointT: campaignItem?.T || campaignItem?.T === 0 ? campaignItem?.T : 0,
        campaignEpic: campaignItem?.ProductLineId ? campaignItem?.ProductLineId : "",
        campaignSystem: campaignItem?.SystemId ? campaignItem?.SystemId : "",
        campaignImage: currentCampaignLogo?.CampaignLogoUrl ? currentCampaignLogo.CampaignLogoUrl : "",
        campaignDescription: campaignItem?.Description ? campaignItem.Description : "",
        campaignAvatar: ""
    }

    const campaignValidationSchema = Yup.object().shape({
        campaignName: Yup.string("Enter valid string.").required("Campaign name is required.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        campaignScale: Yup.mixed(),
        campaignSpawnPointX: Yup.number("Enter valid number."),
        campaignSpawnPointY: Yup.number("Enter valid number."),
        campaignSpawnPointZ: Yup.number("Enter valid number."),
        campaignSpawnPointT: Yup.number("Enter valid number."),
        campaignEpic: Yup.mixed().required("Please choose on of the above epic."),
        campaignSystem: Yup.mixed().required("Please choose one of the above system."),
        campaignDescription: Yup.string("Enter valid string."),
        campaignLogo: Yup.string().ensure().when('campaignImage', {
            is: campaignImage => !(campaignImage?.length || Yup.ref("campaignImage")?.length),
            then: Yup.string().required("Please choose or upload campaign logo.")
        }),
        campaignImage: Yup.string().ensure().when('campaignLogo', {
            is: campaignLogo => !(campaignLogo?.length || Yup.ref("campaignLogo")?.length),
            then: Yup.string().required("Please choose or upload campaign logo.")
        }),
        ...(currentOperationMode === "Add") && { campaignAvatar: Yup.number().required('Please select avatar.') },

    }, [["campaignImage", "campaignLogo"]]);


    const onChange = (imageList, setFieldValue) => {
        imageList.length && setFieldValue("campaignImage", imageList[0].data_url)
        imageList.length && setFieldValue("campaignLogo", "")
        setImages(imageList);
        imageList.length && setCurrentLogoFile([]);
        document.getElementsByClassName('singlefolio')?.[0] && document.getElementsByClassName('singlefolio')[0].classList.remove('singlefolio')
    };

    const submitCampaignForm = async (values) => {

        var fileData;
        var formData = new FormData();
        var appendingFormData = new FormData();
        var campaignLogoStatus = null;
        if (!values.campaignLogo) {
            campaignLogoStatus = 1;
        } else {
            campaignLogoStatus = 2;
        }
        if (!images.length) {
            const toDataURL = currentLogoFile => fetch(currentLogoFile, { cache: 'no-cache' })
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
                }))

            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
            }
            await toDataURL(currentLogoFile)
                .then(dataUrl => {
                    fileData = dataURLtoFile(dataUrl, "imageName.jpg");
                    formData.append('Photo', fileData)
                    appendingFormData.append('Photo', fileData)
                    formData.append("CampaignLogoUrl", fileData)
                })
        } else {
            await formData.append('photo', images[0].file)
            formData.append('Photo', images[0].file)
            formData.append("CampaignLogoUrl", images[0].data_url)
        }
        //converting payload in formData format

        formData.append('Name', values.campaignName)
        formData.append("SystemId", values.campaignSystem)
        formData.append("ProductLineId", values.campaignEpic)
        formData.append("PlayerId", player?.Id,)
        formData.append('ScaleId', values.campaignScale ? values.campaignScale : 3)
        formData.append("X", values.campaignSpawnPointX ? values.campaignSpawnPointX : 0)
        formData.append("Y", values.campaignSpawnPointY ? values.campaignSpawnPointY : 0)
        formData.append("Z", values.campaignSpawnPointZ ? values.campaignSpawnPointZ : 0)
        formData.append("T", values.campaignSpawnPointT ? values.campaignSpawnPointT : 0)
        formData.append("Description", values.campaignDescription)
        formData.append("IsPrivate", false)
        formData.append("StatusId", campaignLogoStatus)

        const campaignResponse = {
            SystemId: values.campaignSystem,
            ProductLineId: values.campaignEpic,
            Name: values.campaignName,
            Description: values.campaignDescription,
            PlayerId: player?.Id,
            ScaleId: values.campaignScale,
            X: values.campaignSpawnPointX,
            Y: values.campaignSpawnPointY,
            Z: values.campaignSpawnPointZ,
            T: values.campaignSpawnPointT,
            Id: campaignId
        }
        switch (currentOperationMode) {
            case 'Add':
                try {
                    addRecords({
                        entity: 'CreateCampaignWithLogo',
                        data: formData,
                        tag: GET_ALL_CAMPAIGN_LOGO_TAG
                    }).unwrap().then((res) => {
                        var gameMasterRole = gameRole.value.find((role) => {
                            if (role.Name.toLowerCase().trim().replace(/ /g, "") === GAME_MASTER_ROLE.toLowerCase().trim().replace(/ /g, "")) {
                                return role.Id
                            }
                        })
                        addRecords({
                            entity: 'AvatarCampaign',
                            data: { CampaignId: res.Id, AvatarId: values.campaignAvatar, StatusId: 2, AvatarCampaignTypeId: 1 },
                            tag: AVATAR_CAMPAIGN_TAG
                        }).unwrap().then((res) => { }).catch(rejected => errorToast(rejected));
                        addRecords({
                            entity: 'PlayerRole',
                            data: { CampaignId: res.Id, PlayerId: res.PlayerId, RoleId: gameMasterRole.Id },
                            tag: [PLAYER_AVATAR_DATA, AVATAR_CAMPAIGN_TAG, GET_ALL_CAMPAIGN_LOGO_TAG, GAME_ROLE_TAG]
                        }).unwrap().then((res) => {
                        }).catch(rejected => errorToast(rejected));
                        setIsCampaignCreating(true)
                        setPage(1)
                        successToast(<FormattedMessage id="successToast.CreateCampaign.CampaignCreated" defaultMessage="Campaign created successfully." />);
                        toggleCampaignSideBar();
                        currentOperationMode(null)
                    }).catch(rejected => errorToast(rejected));
                } catch {
                    errorToast(<FormattedMessage id="errorToast.Common.Something-Went-Wrong" defaultMessage="Something went wrong." />)
                }
                break;
            case 'Edit':
                try {
                    if (!images.length) {
                        await addRecords({
                            entity: `AddPhotoForCampaign?campaignId=${campaignId}`,
                            data: appendingFormData,
                        }).unwrap().then((res) => {
                        }).catch(rejected => errorToast(rejected));
                    } else if (currentCampaignLogo?.CampaignLogoUrl !== values.campaignImage) {
                        await updateCampaign({
                            entity: 'CampaignLogo',
                            data: {
                                Id: currentCampaignLogo.Id,
                                StatusId: 1,
                                CampaignId: campaignId,
                                CampaignLogoUrl: values.campaignImage ? values.campaignImage : currentLogoFile
                            },
                        }).unwrap().then((res) => {
                        }).catch(rejected => errorToast(rejected));
                    }
                    await updateCampaign({
                        entity: 'Campaign',
                        data: campaignResponse,
                        tag: [GET_ALL_CAMPAIGN_LOGO_TAG, CAMPAIGN_TAG],
                    }).unwrap().then((res) => {
                        successToast(<FormattedMessage id="successToast.CreateCampaign.CampaignCreated" defaultMessage="Campaign created successfully." />);
                        toggleCampaignSideBar();
                        setCurrentOperationMode(null)
                    }).catch(rejected => errorToast(rejected));
                } catch {
                    errorToast(<FormattedMessage id="errorToast.Common.Something-Went-Wrong" defaultMessage="Something went wrong." />)
                }
                break;
            default:
                break;
        }

    }
    const selectedAvatarDesign = (value, submitForm) => {
        if (selectedAvatar !== 0) {
            document.getElementById(selectedAvatar).style.opacity = "1";
        }
        document.getElementById(value?.Id).style.opacity = "0.5";
        setSelectedAvatar(value?.Avatar?.Id);
        setCurrentOperation(null)
        submitForm()
    }
    const submitCampaignHandler = (errors, submitForm) => {
        currentOperationMode !== "Edit" && Object.entries(errors).length === 1 && setCurrentOperation("SELECT_AVATAR");
        // currentOperationMode === "Edit" && submitForm();
    }
    const blockClasses = classNames('p-3', {
        'block-products-carousel--loading': loading,
        'block-products-carousel--has-items': gameEpics.length > 0 && systemEpics.length > 0,
    });

    return (
        <div className={blockClasses}>
            <Formik
                initialValues={initialCampaignValues}
                validationSchema={campaignValidationSchema}
                onSubmit={submitCampaignForm}
            >
                {(formik) => {
                    const { errors, touched, values, handleBlur, handleChange, setFieldValue, submitForm } = formik;
                    return (
                        <>
                            <Button onClick={toggleCampaignSideBar} color="primary" className='closeCampaign' >X</Button>
                            <Form>
                                <Row>
                                    <h3 className='d-flex justify-content-center align-items-center p-3'>
                                        <FormattedMessage id="home.Carousel.Title.Campaign" defaultMessage="Campaign" />
                                    </h3>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md="12">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend" style={{ width: "25%" }} className="campaignLabel">
                                                <span className="input-group-text">
                                                    <FormattedMessage id="createCampaign.Campaign.InputName" defaultMessage="Name" />
                                                </span>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder={"Your campaign name"}
                                                name="campaignName"
                                                id="campaignName"
                                                value={values.campaignName}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                        <div className='d-flex justify-content-center'>
                                            {(touched.campaignName && errors.campaignName) && <small className='text-danger'>{errors.campaignName}</small>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md="12">
                                        <InputGroup className=''>
                                            <InputGroupAddon addonType="prepend" className="campaignLabel" >
                                                <span className="input-group-text">
                                                    <FormattedMessage id="createCampaign.Campaign.Scale" defaultMessage="Scale" />
                                                </span>
                                            </InputGroupAddon>
                                            {
                                                options.length
                                                    ?
                                                    <MultiToggle
                                                        options={options}
                                                        selectedOption={selected}
                                                        onSelectOption={value => {
                                                            setSelected(value);
                                                            setFieldValue("campaignScale", value)
                                                        }}
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md="12">
                                        <InputGroup className=''>
                                            <InputGroupAddon addonType="prepend" className="campaignLabel" >
                                                <span className="input-group-text">
                                                    <FormattedMessage id="createCampaign.Campaign.SpawnPoint" defaultMessage="Spawn Point" />
                                                </span>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="prepend" className='customCoordinateLabel'>X</InputGroupAddon>
                                            <Input
                                                placeholder="X"
                                                name='campaignSpawnPointX'
                                                id='campaignSpawnPointX'
                                                value={values.campaignSpawnPointX}
                                                onBlur={handleBlur}
                                                type="number"
                                                onChange={handleChange}
                                            />
                                            <InputGroupAddon addonType="prepend" className='customCoordinateLabel'>Y</InputGroupAddon>
                                            <Input
                                                placeholder="Y"
                                                name='campaignSpawnPointY'
                                                id='campaignSpawnPointY'
                                                value={values.campaignSpawnPointY}
                                                onBlur={handleBlur}
                                                type="number"
                                                onChange={handleChange}
                                            />
                                            <InputGroupAddon addonType="prepend" className='customCoordinateLabel'>Z</InputGroupAddon>
                                            <Input
                                                placeholder="Z"
                                                name='campaignSpawnPointZ'
                                                id='campaignSpawnPointZ'
                                                value={values.campaignSpawnPointZ}
                                                onBlur={handleBlur}
                                                type="number"
                                                onChange={handleChange}
                                            />
                                            <InputGroupAddon addonType="prepend" className='customCoordinateLabel'>T</InputGroupAddon>
                                            <Input
                                                placeholder="T"
                                                name='campaignSpawnPointT'
                                                id='campaignSpawnPointT'
                                                value={values.campaignSpawnPointT}
                                                onBlur={handleBlur}
                                                type="number"
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md="12">
                                        <InputGroup className='justify-content-evenly'>
                                            <InputGroupAddon addonType="prepend" className="campaignLabel" >
                                                <span className="input-group-text">
                                                    <FormattedMessage id="createCampaign.Campaign.ChooseAnEpic" defaultMessage="Choose an epic" />
                                                </span>
                                            </InputGroupAddon>
                                            {
                                                loading && <div style={{ height: '150px' }}>
                                                    <div className="block-products-carousel__preloader" style={{ top: "40px" }} />
                                                </div>
                                            }
                                            {
                                                !loading &&
                                                gameEpics.map((epic, index) => {
                                                    return (<div style={{ maxWidth: "120px", width: "100%", marginTop: "20px" }} key={index}>
                                                        <img
                                                            onClick={() => {
                                                                selectedImageDesign(epic.Id, index);
                                                                setFieldValue("campaignEpic", values.campaignEpic === epic.Id ? "" : epic.Id)
                                                            }}
                                                            onLoad={() => {
                                                                campaignItem?.ProductLineId === epic.Id && selectedImageDesign(epic.Id, index);
                                                            }}
                                                            className={`img d-flex flex-wrap`}
                                                            src={epic.img}
                                                            width="100%"
                                                            alt={epic.img}
                                                            id={Number(epic.Id)}
                                                        />
                                                    </div>)
                                                })
                                            }
                                        </InputGroup>
                                        <div className='d-flex justify-content-center'>
                                            {(touched.campaignEpic && errors.campaignEpic) && <small className='text-danger'>{errors.campaignEpic}</small>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md="12">
                                        <InputGroup className='justify-content-evenly'>
                                            <InputGroupAddon addonType="prepend" className="campaignLabel" >
                                                <MultiToggle
                                                    options={optionalLogo}
                                                    selectedOption={selectedLogoType}
                                                    onSelectOption={value => {
                                                        setSelectedLogoType(value);
                                                    }}
                                                />
                                            </InputGroupAddon>
                                            <Row className=''>
                                                {
                                                    loading && <div style={{ height: '150px' }}>
                                                        <div className="block-products-carousel__preloader" style={{ top: "40px" }} />
                                                    </div>
                                                }
                                                {
                                                    selectedLogoType && campaignLogo.map((epic, index) => {
                                                        return (
                                                            <Col md="2" key={index}>
                                                                <div className='stylecontainerWrapper' key={index}>
                                                                    <div id={`logo${epic.Id}`} className="gx-4 mt-4" onClick={() => {
                                                                        selectedLogoDesign(epic.Id, index);
                                                                        setCurrentLogoFile(epic.Url)
                                                                        !images.length && setFieldValue("campaignLogo", values.campaignLogo === epic.Id ? "" : epic.Id)
                                                                    }} onLoad={() => {
                                                                        epic?.Url === currentCampaignLogo?.CampaignLogoUrl && selectedLogoDesign(epic.Id, index);
                                                                        setFieldValue("campaignLogo", "")
                                                                    }}>
                                                                        <img
                                                                            src={epic.Url}
                                                                            alt={epic.Url}
                                                                            width="100%"
                                                                            className='img'
                                                                            name="campaignLogo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='previewIconWrapper'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        onClick={() => {
                                                                            setIsImagePreview(true);
                                                                            setCurrentImage(epic?.Url)
                                                                            document.getElementById("mainSidebar").children[0].scrollTop = 0
                                                                        }}
                                                                        width="16" height="16" fill="currentColor" className="bi bi-eye-fill previewIcon" viewBox="0 0 16 16" cursor="pointer">
                                                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                    </svg>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </InputGroup>
                                        {
                                            isImagePreview &&
                                            <Lightbox
                                                medium={currentImage}
                                                onClose={() => setIsImagePreview(!isImagePreview)}
                                                imageBackgroundColor="transparent"
                                                hideDownload={true}
                                            />
                                        }
                                        {
                                            !selectedLogoType && <ReactImageUploading
                                                value={images}
                                                onChange={(file) => onChange(file, setFieldValue)}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps
                                                }) => (
                                                    <div className="upload__image-wrapper">
                                                        {
                                                            !images.length && <button
                                                                style={isDragging ? { color: "red", border: "1px solid gray" } : { border: "1px solid gray" }}
                                                                onClick={onImageUpload}
                                                                {...dragProps}
                                                            >
                                                                <FormattedMessage id="createCampaign.Campaign.ClickORDrop" defaultMessage="Click or Drop here" />
                                                            </button>
                                                        }
                                                        &nbsp;
                                                        {
                                                            loading && <div style={{ height: '150px' }}>
                                                                <div className="block-products-carousel__preloader" style={{ top: "40px" }} />
                                                            </div>
                                                        }
                                                        {
                                                            imageList.map((image, index) => (
                                                                <div key={index} className="image-item">
                                                                    <img src={image.data_url} alt="" name="campaignLogo" width="150px" />
                                                                    <div className="image-item__btn-wrapper">
                                                                        <button onClick={() => { onImageRemove(index); setImages([]); setFieldValue("campaignImage", "") }} className="btn-sm btn-close"></button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </ReactImageUploading>
                                        }

                                        <div className='d-flex justify-content-center'>
                                            {((errors.campaignImage && touched.campaignImage) || (errors.campaignLogo && touched.campaignLogo)) && <small className='text-danger'>{errors.campaignImage || errors.campaignLogo}</small>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md="12">
                                        <InputGroup className='justify-content-evenly'>
                                            <InputGroupAddon addonType="prepend" className="campaignLabel" >
                                                <span className="input-group-text">
                                                    <FormattedMessage id="createCampaign.Campaign.ChooseAnSystem" defaultMessage="Choose an system" />
                                                </span>
                                            </InputGroupAddon>
                                            {
                                                loading && <div style={{ height: '150px' }}>
                                                    <div className="block-products-carousel__preloader" style={{ top: "40px" }} />
                                                </div>
                                            }
                                            {
                                                !loading && systemEpics.map((epic, index) => {
                                                    return <div key={index} id={`system${epic.Id}`}
                                                        style={{ maxWidth: "120px", width: "100%", padding: "20px", marginTop: "20px" }}>
                                                        <img
                                                            className="img d-flex flex-wrap"
                                                            src={epic.img}
                                                            width="100%"
                                                            onClick={() => {
                                                                selectedSystemDesign(epic.Id, index);
                                                                setFieldValue("campaignSystem", values.campaignSystem === epic.Id ? "" : epic.Id)
                                                            }}
                                                            onLoad={() => {
                                                                campaignItem?.SystemId === epic.Id && selectedSystemDesign(epic.Id, index);
                                                            }}
                                                            alt={epic}
                                                        />
                                                    </div>
                                                })
                                            }
                                        </InputGroup>
                                        <div className='d-flex justify-content-center'>
                                            {(touched.campaignSystem && errors.campaignSystem) && <small className='text-danger'>{errors.campaignSystem}</small>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md="12">
                                        <InputGroup className=''>
                                            <InputGroupAddon addonType="prepend" className="campaignLabel" >
                                                <span className="input-group-text">
                                                    <FormattedMessage id="createCampaign.Campaign.Description" defaultMessage="Description" />
                                                </span>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Full description"
                                                name='campaignDescription'
                                                id='campaignDescription'
                                                value={values.campaignDescription}
                                                onBlur={handleBlur}
                                                onChange={handleChange}

                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Modal className='justify-content-center' centered={true} style={{ left: '25%' }} onHide={() => setCurrentOperation(null)} show={currentOperation && currentOperation.length > 0} size="md">
                                    <Modal.Header closeButton>
                                        <h4>Select Avatar</h4>
                                    </Modal.Header>
                                    {
                                        currentOperation === "SELECT_AVATAR"
                                            ?
                                            <>
                                                <Modal.Body style={{ maxHeight: "350px", overflowY: "auto" }}>
                                                    <Row id="images" className="d-flex flex-wrap justify-content-center">
                                                        {
                                                            (avatarRecords && avatarRecords?.value?.length ? avatarRecords?.value.map((value, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <Col md="3" className="d-flex flex-column" key={value.Id}>
                                                                            <img onClick={async () => {
                                                                                await setFieldValue('campaignAvatar', value.Avatar.Id)
                                                                                selectedAvatarDesign(value, submitForm);
                                                                            }} id={value.Id} className="hexDisplay" src={`https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/avatars/20240610114043141_Baris_lhbnlb.png`} alt={`avatar-img-${index}`} />
                                                                            <span className="text-center">{value.Avatar.Name}</span>
                                                                        </Col>
                                                                    </React.Fragment>
                                                                )
                                                            }) :
                                                                <small className='d-flex justify-content-center mt-3'>
                                                                    <b>No more avatar's to join!</b>
                                                                </small>
                                                            )

                                                        }
                                                    </Row>
                                                    <small className='text-danger d-flex justify-content-center mt-3'>
                                                        {errors.campaignAvatar && <small className='text-danger'>{avatarRecords && avatarRecords?.value?.length ? errors.campaignAvatar : ""}</small>}
                                                    </small>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button type="submit" variant="danger" onClick={() => setCurrentOperation("ADD_AVATAR")}>
                                                        Create
                                                    </Button>
                                                </Modal.Footer>
                                            </>
                                            :
                                            <AvatarForm playerInfo={player} setModal={setCurrentOperation} tags={[PLAYER_AVATAR_DATA, AVATAR_CELL]} />
                                    }
                                </Modal>
                                <Row className='d-flex justify-content-center align-items-center'>
                                    <Col md="10">
                                        <button
                                            onClick={() => submitCampaignHandler(errors, submitForm)}
                                            className="createCampaign"
                                            disabled={isCampaignAdding || isUpdating}
                                        >
                                            {
                                                (isCampaignAdding || isUpdating) &&
                                                <Spinner animation="border" variant="primary" className="campaignLoader" />
                                            }{" "}{currentOperationMode === "Add" ?
                                                <FormattedMessage id="createCampaign.Campaign.CreateCampaign" defaultMessage="Create Campaign" />
                                                :
                                                <FormattedMessage id="createCampaign.Campaign.UpdateCampaign" defaultMessage="Update Campaign" />
                                            }
                                        </button>
                                    </Col>
                                </Row>
                            </Form >
                        </>
                    );
                }
                }
            </Formik >
        </div >
    )
}

export default CreateCampaign