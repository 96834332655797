const SingleImage = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF) => {
    let contentObject = JSON.parse(mainContent || "{}")

    documentMethods.text("", { columns: 2, continued: true })

    const imagePath = contentObject?.images[0]?.url && await getBase64ImageFromURL(contentObject?.images[0]?.url)

    if ((documentMethods.page.height - 120 - documentMethods.y) < 200) {
        documentMethods.text("", documentMethods.x, documentMethods.y + (documentMethods.page.height - documentMethods.y), { continued: true });
    }
    documentMethods
        .text("", { continued: true })
        .text("", { continued: true })
        .image(imagePath, documentMethods.x, documentMethods.y, {
            width: 240,
            continued: true,
        })
        .text("", { continued: true }).moveDown()
}

export default SingleImage