// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockBanner({ blockBanner }) {
    const [bannerRecords] = blockBanner
    let contentRecords = JSON.parse(bannerRecords.Content.ContentObject)
    return (
        <div className="block block-banner">
            <div className="container">
                <Link to="/" className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{ backgroundImage: `url(${contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url})` }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: `url(${contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url})` }}
                    />
                    {
                        contentRecords?.title &&
                        <div className="block-banner__title">
                            {contentRecords?.title}
                        </div>
                    }
                    {
                        contentRecords?.description &&
                        <div className="block-banner__text">
                            {contentRecords?.description}
                        </div>
                    }
                    {
                        contentRecords?.buttontext &&
                        <div className="block-banner__button">
                            <span className="btn btn-sm btn-primary">{contentRecords?.buttontext}</span>
                        </div>
                    }
                </Link>
            </div>
        </div>
    );
}
