import BlockBanner from 'apps/home/components/BlockBanner';
import BlockPosts from 'apps/home/components/BlockPosts';
import BlockSlideshow from 'apps/home/components/BlockSlideshow';
// import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import CampaignList from 'apps/home/components/CampaignList';
import PlayerList from 'apps/home/components/PlayerList';
import StartScreen from 'apps/adventure-hex/StartScreen';
import EditCampaign from 'apps/home/components/EditCampaign';
import MapViewer from 'apps/adventure-hex/MapViewer';
import SubscriptionList from 'apps/adventure-hex/subscription/subscriptionList';
import MySubscriptions from 'apps/adventure-hex/subscription/mySubscriptions';
import MySubscriptionDetail from 'apps/adventure-hex/subscription/mySubscriptionDetail';
import CustomLoader from './CustomLoader';
import Publisher from 'apps/home/components/Publisher';

const GlobalRenderer = ({ block, userRoles }) => {

    const [currentContent, setCurrentContent] = useState([]);
    let props = {}
    const sort = {
        sortDirection: 'asc',
        accessor: 'Id',
    };
    const [
        getZoneContentRecords,
        {
            data: zoneContent,
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        block && getZoneContentRecords({
            columns: [],
            entity: "ZoneContent",
            page: 1,
            sort: sort,
            columnFilter: { ZoneId: block.Zone.Id },
            globalFilter: [],
            expand: "Content($expand=ContentMedias($expand=Media),ContentType),Component,Zone($expand=ZoneMedias($expand=Media))"
        })
        // eslint-disable-next-line
    }, [block])

    const Components = {
        FeaturedStory: BlockSlideshow,
        Campain: CampaignList,
        BlockBanner: BlockBanner,
        BlockPosts: BlockPosts,
        PlayerList: PlayerList,
        Adventurehex: StartScreen,
        EditCampaign: EditCampaign,
        MapViewer: MapViewer,
        SubscriptionList: SubscriptionList,
        MySubscriptions: MySubscriptions,
        mySubscriptionDetail: MySubscriptionDetail,
        Publisher: Publisher
    };
    useEffect(() => {
        if (zoneContent?.value?.length) {
            setCurrentContent(zoneContent.value.find((zone) => Components[zone?.Component?.Name]))
        }
    }, [zoneContent])
    if (zoneContent?.value?.length) {
        props = {
            BlockPosts: { layout: "grid-nl", posts: zoneContent.value },
            BlockBanner: { blockBanner: zoneContent.value },
            Publisher: { layout: "grid-nl" },
        }
    }
    if (currentContent && Object.entries(currentContent).length && typeof Components[currentContent.Component.Name] !== "undefined") {
        return React.createElement(Components[currentContent?.Component?.Name], {
            zoneId: block.Zone.Id,
            userRoles: userRoles,
            ...(props[currentContent?.Component?.Name]) && { ...props[currentContent?.Component?.Name] },
        });
    } else if ((currentContent) && (!zoneContent?.value || zoneContent?.value?.length)) {
        return <CustomLoader />
    } else {
        return null
    }
};

export default GlobalRenderer