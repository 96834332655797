import React from 'react'
import { Col, Row } from 'reactstrap'
import UserInfo from './userInfo';

import YourCart from './yourCart';

const SubscriptionCart = (props) => {
    const countryData=props.location.state && props.location.state.countryData ? props.location.state.countryData : [];
    return (
        <div className="your-cart">
            <Row className="p-3">
                <Col md="6">
                    <YourCart />
                </Col>
                <Col md="6">
                    <UserInfo countryData={countryData}/>
                </Col>
            </Row>
        </div>
    )
}

export default SubscriptionCart
