import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import EditHexData from "./editHexData";
import HexPalette from './hexPalette';
import { useUpdateMutation, useLazyGetRecordsQuery } from "services/gamecodexApi";
import { errorToast, successToast } from 'common/toast'
import { AVATAR_CELL, TILE_TAG } from "common/tagFile";
import "./editHex.scss";
import { hexAdded, addTiles } from "./adventureHexSlice";
import { GAME_MASTER_ROLE } from "common/roleFile";

const EditHexTabs = (props) => {
    const { toggleTerrainSidebar, setSelectedHex, selectedHex, setNewSelectedTile, isAvatarAdmin, userRoles } = props;
    let mapData = useSelector((state) => state.adventureHexs.adventureHex);
    const [updateCellData, { isLoading: isCellRecordUpdating }] = useUpdateMutation();
    const [editHexData, setEditHexData] = useState(true);
    const dispatch = useDispatch();
    const sort = {
        sortDirection: 'none',
        accessor: 'some_accessor',
    };
    const [isPlayerGameMaster, setIsPlayerGameMaster] = useState(false);
    const location = useLocation();
    const campaignId = Number(location?.state?.CampaignId);
    useEffect(() => {
        if (props.userRoles) {
            props.userRoles.forEach((val) => {
                if (val.Roles.Name.toLowerCase().trim().replace(" ", "") === GAME_MASTER_ROLE.toLowerCase().trim().replace(" ", "") && val.CampaignId === campaignId) {
                    setIsPlayerGameMaster(true)
                }
            })
        }
    }, [props.userRoles, campaignId])
    const toggle = (value) => {
        if (value === "changeHex") {
            setEditHexData(true);
        }
        else {
            setEditHexData(false);
        }
    }

    const [
        getAvatarCellRecord,
        {
            data: tileList,
            error,
            isLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getAvatarCellRecord({
            columns: [],
            entity: "Tile",
            tag: TILE_TAG,
            page: 1,
            pageSize: 500,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isLoading && !error && tileList) {
            dispatch(addTiles(tileList.value))
        }
        // eslint-disable-next-line
    }, [isLoading, error, tileList]);

    const submitForm = (values) => {
        const { Scale, Tile, TileId, ScaleId, ...rest } = values
        var newMapData = mapData.map((cell) => {
            if (cell.Id === values.Id) {
                cell = { ...rest, TileId: values.Tile.value.Id, Id: selectedHex.Id, ScaleId: values.Scale.value.Id, Scale: values.Scale.value, Tile: values.Tile.value }
            }
            return cell;
        });
        dispatch(hexAdded(newMapData));
        try {
            updateCellData({ entity: 'Cell', data: { ...rest, TileId: values.Tile.value.Id, Id: selectedHex.Id, Scale: values.Scale.value, ScaleId: values.Scale.value.Id, Tile: values.Tile.value }, tag: AVATAR_CELL }).unwrap().then(() => {
                setSelectedHex({ ...rest, TileId: values.Tile.value.Id, Id: selectedHex.Id, ScaleId: values.Scale.value.Id, Scale: values.Scale.value, Tile: values.Tile.value })
                toggleTerrainSidebar();
                successToast(<FormattedMessage id="successToast.EditHex.CellUpdate" defaultMessage="Cell updated successfully." />);
            }).catch(rejected => errorToast(rejected));
        } catch {
            errorToast(<FormattedMessage id="errorToast.Common.Something-Went-Wrong" defaultMessage="Something went wrong." />)
        }
    };
    return (
        <div className="editHexTabs">
            <div>
                <Button color="primary" className="editHexCloseBtn" onClick={() => { toggleTerrainSidebar() }}>X</Button>
            </div>
            <div>
                <button className="editHexButton" onClick={() => { toggle("hexData") }}>
                    edit data
                </button>
                {
                    <button className="editHexButton" onClick={() => { toggle("changeHex") }}>
                        edit hex
                    </button>
                }
            </div>
            <div className="mt-5">
                {editHexData ?
                    <HexPalette toggleTerrainSidebar={toggleTerrainSidebar} isReadOnly={isPlayerGameMaster} setSelectedHex={setSelectedHex} selectedHex={selectedHex} setNewSelectedTile={setNewSelectedTile} />
                    :
                    <EditHexData toggleTerrainSidebar={toggleTerrainSidebar} isReadOnly={isPlayerGameMaster} selectedHex={selectedHex} submitForm={submitForm} isAvatarAdmin={isAvatarAdmin} isCellRecordUpdating={isCellRecordUpdating} />
                }
            </div>
        </div>
    );
}

export default EditHexTabs;