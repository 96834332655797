import { createSlice } from "@reduxjs/toolkit";

const maphexCellSlice = createSlice({
  name: "maphexCell",
  initialState: {
   maphexCell: [],
  },
  reducers: {
    getAllMaphexCell: (state,action) => {
      state.maphexCell = action.payload
    }
  },
});

export default maphexCellSlice.reducer;
export const { getAllMaphexCell } = maphexCellSlice.actions

