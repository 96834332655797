export const tileTypeLabels = [
    { 
        name: 'biome',
        code: 'b',
        values: [
            {name: 'marine', code: 'ma'},
            {name: 'desert', code: 'de'},
            {name: 'volcanic', code: 'vo'},
            {name: 'verdant', code: 've'},
            {name: 'polar', code: 'po'},
            {name: 'space', code: 'sp'},
            {name: 'mountainous', code: 'mo'},
            {name: 'arid', code: 'ar'},
        ]
    },
    { 
        name: 'type',
        code: 't',
        values: [
            {name: 'dirt', code: 'di'},
            {name: 'stone', code: 'st'},
            {name: 'basalt', code: 'ba'},
            {name: 'void', code: 'vo'},
            {name: 'water', code: 'wa'},
            {name: 'sand', code: 'sa'},
        ]
    },
    { 
        name: 'color',
        code: 'c',
        values: [
            {name: 'grey', code: 'gy'},
            {name: 'red', code: 're'},
            {name: 'white', code: 'wh'},
            {name: 'green', code: 'gr'},
            {name: 'dark green', code: 'dg'},
            {name: 'brown', code: 'br'},
            {name: 'black', code: 'bk'},
            {name: 'yellow', code: 'ye'},
            {name: 'light grey', code: 'ly'},
        ]
    },
    { 
        name: 'material',
        code: 'm',
        values: [
            {name: 'ashes', code: 'as'},
            {name: 'fumarole', code: 'fu'},
            {name: 'lava', code: 'la'},
            {name: 'snow', code: 'sn'},
            {name: 'grass', code: 'gr'},
            {name: 'swamp', code: 'sw'},
            {name: '', code: 'tp'},
            {name: '', code: 'wo'},
            {name: 'scrub', code: 'sr'},
            {name: 'rocks', code: 'ro'},
        ]
    },
    { 
        name: 'landform',
        code: 'l',
        values: [
            {name: 'pine forest', code: 'pf'},
            {name: 'jungle', code: 'ju'},
            {name: 'basic forest', code: 'bf'},
            {name: 'mountain', code: 'mo'},
            {name: 'hill', code: 'hi'},
            {name: 'dunes', code: 'du'},
            {name: 'mesa', code: 'me'},
            {name: 'crater', code: 'cr'},
        ]
    },
    { 
        name: 'growth',
        code: 'g',
        values: [
            {name: 'pine forest', code: 'pf'},
            {name: 'palm trees', code: 'pt'},
            {name: 'scorched forest', code: 'sf'},
            {name: 'oak trees', code: 'oa'},
        ]
    },
    { 
        name: 'feature',
        code: 'f',
        values: [
            {name: 'pond', code: 'po'},
            {name: 'cave', code: 'ca'},
            {name: 'castle', code: 'cs'},
            {name: 'clearing', code: 'cl'},
            {name: 'forestry', code: 'fo'},
            {name: 'farm', code: 'fa'},
            {name: 'sandpit', code: 'sa'},
            {name: 'oasis', code: 'oa'},
            {name: 'temple', code: 'tp'},

        ]
    },
    { 
        name: 'size',
        code: 's',
        values: [
            {name: 'small', code: 'sm'},
            {name: 'medium', code: 'md'},
            {name: 'large', code: 'lg'},
        ]
    },
    { 
        name: 'variant',
        code: 'b',
        values: [
            {name: '0', code: '0'},
            {name: '1', code: '1'},
            {name: '2', code: '2'},
            {name: '3', code: '3'},
            {name: '4', code: '4'},
        ]
    },
];
