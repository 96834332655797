import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import { isEmpty } from "lodash";
import Select from 'react-select';
import { SCALE_TAG } from "common/tagFile";

const EditHexData = ({ selectedHex, submitForm, isCellRecordUpdating, isAvatarAdmin, isReadOnly }) => {
    const tileData = useSelector((state) => state.adventureHexs.tiles);
    const { Scale, Tile, ...rest } = selectedHex
    let [hexData, setHexData] = useState({});
    const sort = {
        sortDirection: 'none',
        accessor: 'some_accessor',
    }
    const [initialData, setInitialData] = useState({
        Scale: { label: Scale?.Name, value: Scale },
        Tile: { value: Tile, label: `${Tile.Id} - ${Tile.UrlFileName}` },
        ...rest
    });

    const hexSchema = Yup.object().shape({
        Terrain: Yup.string().required("Terrain is required"),
        ScaleId: Yup.string().required("Scale is required"),
        Exits: Yup.string().required("Exits is required"),
    });

    const [
        getScaleRecords,
        {
            data: scaleList,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getScaleRecords({
            columns: [],
            entity: "Scale",
            tag: SCALE_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: []
        })
    }, []);

    useEffect(() => {
        let cellScaleRecords = {};
        let cellTileRecords = {};
        if (scaleList && scaleList.value.length > 0) {
            cellScaleRecords = scaleList.value.map((value) => {
                return ({ value: value, label: value.Name });
            });
        }
        if (tileData && tileData.length > 0) {
            cellTileRecords = tileData.map((value) => {
                return ({ value: value, label: `${value.Id} - ${value.UrlFileName}` });
            });
        }
        if (isEmpty(!cellScaleRecords) && isEmpty(!cellTileRecords)) {
            setHexData({ tile: cellTileRecords, scale: cellScaleRecords })
        }
    }, [scaleList, tileData])
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: "url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png')",
            backgroundPosition: '-826px 2578px',
            height: '30px',
            border: 'none',
            color: 'white',
            width: '100%',

        }),
        option: (provided, state) => ({
            ...provided,
            background: "url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png')",
            backgroundPosition: "-790px 2815px !important",
            borderColor: "gray !important",
            color: "White !important",
        }),
        input: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png')",
            backgroundPosition: '-826px 2578px',
            height: '30px',
            border: 'none',
            color: 'white',
            width: '100%',
        }),

    }
    return (
        <div className="editAvatarData">
            <h2>{isAvatarAdmin ? 'edit hex' : 'Hex Data'}</h2>
            <div className="mt-5">
                {initialData &&
                    <Formik
                        initialValues={initialData}
                        validationSchema={hexSchema}
                        onSubmit={submitForm}
                    >
                        {(formik) => {
                            const { errors, touched, values, handleBlur, handleChange, setFieldValue } = formik;

                            return (
                                <Form>
                                    <FormGroup >
                                        <Row className="justify-content-between" style={{ padding: '0 30px' }}>
                                            <Col md="3">
                                                <Label for="name" className="">Name</Label>
                                            </Col>
                                            <Col md="9">
                                                <Input
                                                    name="Name"
                                                    id="Name"
                                                    type="text"
                                                    placeholder="Name"
                                                    className={`${errors.Name && touched.Name ? "is-invalid" : null} editHexInput`}
                                                    value={values.Name ? values.Name : ""}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    readOnly={!isReadOnly}
                                                />
                                                {errors.Name && <FormFeedback >{errors.Name}</FormFeedback>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="justify-content-between" style={{ padding: '0 30px' }}>
                                            <Col md="3">
                                                <Label for="description" className="">Description</Label>
                                            </Col>
                                            <Col md="9">
                                                <Input
                                                    name="Description"
                                                    id="Description"
                                                    type="text"
                                                    placeholder="Description"
                                                    className={`${errors.Description && touched.Description ? "is-invalid" : ''} editHexInput`}
                                                    value={values.Description ? values.Description : ''}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    readOnly={!isReadOnly}
                                                />
                                                {errors.Description && <FormFeedback >{errors.Description}</FormFeedback>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="justify-content-between" style={{ padding: '0 30px' }}>
                                            <Col md="3">
                                                <Label for="x" className="txtDiv">X: {values.X}</Label>
                                            </Col>
                                            <Col md="3">
                                                <Label for="x" className="txtDiv">Y: {values.Y}</Label>
                                            </Col>
                                            <Col md="3">
                                                <Label for="x" className="txtDiv">Z: {values.Z}</Label>
                                            </Col>
                                            <Col md="3">
                                                <Label for="x" className="txtDiv">T: {values.T}</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="justify-content-between" style={{ padding: '0 30px' }}>
                                            <Col md="3">
                                                <Label for="tileId" className="">Tile</Label>
                                            </Col>
                                            <Col md="9">
                                                <Select
                                                    className={`${errors.ScaleId && touched.ScaleId ? "is-invalid" : ''}`}
                                                    classNamePrefix="select"
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    name="Tile"
                                                    id="Tile"
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    value={values.Tile}
                                                    options={hexData.tile}
                                                    onChange={(value) => { setFieldValue("Tile", value) }}
                                                    styles={customStyles}
                                                    isDisabled={!isReadOnly}
                                                />
                                                {errors.Tile && <FormFeedback >{errors.Tile}</FormFeedback>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="">
                                        <Row className="justify-content-between" style={{ padding: '0 30px' }}>
                                            <Col md="3">
                                                <Label for="parent" className="">Scale</Label>
                                            </Col>
                                            <Col md="9">
                                                <Select
                                                    className={`${errors.ScaleId && touched.ScaleId ? "is-invalid" : ''}`}
                                                    classNamePrefix="select"
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    name="Scale"
                                                    id="Scale"
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    value={values.Scale}
                                                    options={hexData.scale}
                                                    onChange={(value) => { setFieldValue("Scale", value) }}
                                                    styles={customStyles}
                                                    isDisabled={!isReadOnly}
                                                />
                                                {errors.Scale && <FormFeedback >{errors.ScaleId}</FormFeedback>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="justify-content-between" style={{ padding: '0 30px' }}>
                                            <Col md="3">
                                                <Label for="exits" className="">Exits</Label>
                                            </Col>
                                            <Col md="9">
                                                <Input
                                                    name="Exits"
                                                    id="Exits"
                                                    type="number"
                                                    placeholder="Exits"
                                                    className={`${errors.Exits && touched.Exits ? "is-invalid" : ''} editHexInput`}
                                                    value={values.Exits}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    readOnly={!isReadOnly}
                                                />
                                                {errors.Exits && <FormFeedback >{errors.Exits}</FormFeedback>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="justify-content-between" style={{ padding: '0 30px' }}>
                                            <Col md="3">
                                                <Label for="exits" className="">ParentId</Label>
                                            </Col>
                                            <Col md="9">
                                                <Input
                                                    name="ParentId"
                                                    id="ParentId"
                                                    type="number"
                                                    placeholder="ParentId"
                                                    className={`${errors.ParentId && touched.ParentId ? "is-invalid" : ''} editHexInput`}
                                                    value={values.ParentId}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    readOnly={!isReadOnly}
                                                />
                                                {errors.ParentId && <FormFeedback >{errors.ParentId}</FormFeedback>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <Row className="justify-content-end pe-md-5">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="saveAvatarStyle"
                                            disabled={isCellRecordUpdating || !isReadOnly}
                                        >
                                            {(isCellRecordUpdating) && (
                                                <Spinner size="sm" variant="light" />
                                            )}{" "}
                                            Save
                                        </Button>
                                    </Row>
                                </Form >
                            );
                        }
                        }
                    </Formik >
                }
            </div>
        </div >
    );
}

export default EditHexData;