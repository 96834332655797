import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAddMutation, useLazyGetRecordsQuery } from 'services/gamecodexApi'
import { useHistory } from 'react-router-dom'
import StroykaSlick from './StroykaSlick'
import QrCodeCard from './QrCodeCard'
import BlockHeader from './BlockHeader'
import PropTypes from 'prop-types';
import { SHORT_CODE, UPLOAD_MEDIA } from 'common/tagFile'
import { Modal, Form, Button } from 'react-bootstrap'
import { errorToast, successToast } from "common/toast";
import { Spinner } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

const slickSettings = {
    'grid-nl': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 7,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

const Publisher = (props) => {

    const { user } = useAuth0();

    const [slickRef, setSlickRef] = useState(null)
    const [isVisible, setModalVisible] = useState(false)
    const [selectedFiles, setSelectedFile] = useState([])
    const [selectedShotCode, setSelectedShotCode] = useState()
    const [addMedia, { isLoading: isMediaUploading }] = useAddMutation()
    const history = useHistory()
    const { layout } = props
    const userId = localStorage.getItem("userId");
    const [
        getPublisherCodeRecord,
        {
            data: publisherItems,
            isLoading: isDataLoading,
            isError
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (user) {
            const data = user['https://api.wogd.com/roles']
            if (data.includes('Publisher')) {
            } else {
                history.push('/')
            }
        } else {
            history.push('/')
        }
    }, [])

    useEffect(() => {
        if (user) {
            getPublisherCodeRecord({
                columns: [],
                entity: "ShortCode",
                tag: SHORT_CODE,
                page: 1,
                sort: {
                    sortDirection: 'asc',
                    accessor: 'Id',
                },
                expand: `shortCodeTags($expand=tag),shortCodeMedias($expand=itemMedia)`,
                columnFilter: { userId: parseInt(userId) },
                expandFilter: "IsDeleted eq false",
            })
        }
    }, [])

    const handleNextClick = () => {
        if (slickRef) {
            slickRef.slickNext();
        }
    };
    const handlePrevClick = () => {
        if (slickRef) {
            slickRef.slickPrev();
        }
    };

    const handleModel = (data) => {
        setModalVisible(true)
        setSelectedShotCode(data)
    }

    const onSelectFile = (e) => {
        const fileType = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', "stl"]
        const MAX_FILE_SIZE = 1024 * 10
        if (e.target?.files[0]?.size / 1024 > MAX_FILE_SIZE) {
            alert("File size must be less than 10 MB")
        } else {
            let fileName = e.target.files[0].name;
            if (fileType.includes(e.target.files[0].type) || fileName.substr(fileName.lastIndexOf('.') + 1) === "stl") {
                setSelectedFile([...selectedFiles, ...e.target.files])
            } else {
                errorToast(<FormattedMessage id="errorToast.Publisher.UploadValidation" defaultMessage='Select JPG, PNG, PDF or STL files' />)
            }
        }
    }

    const removeFile = (index) => {
        selectedFiles.splice(index, 1)
        setSelectedFile([...selectedFiles])
    }

    const handleUpload = () => {
        if (selectedFiles.length > 0) {

            let formData = new FormData()
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("photo", selectedFiles[i]);
            }
            addMedia({
                entity: 'ShortCodeAssetMedia/AddAsset',
                data: formData,
                query: `shortCodeId=${selectedShotCode?.Id}`,
                tag: [UPLOAD_MEDIA]
            }).then(() => {
                successToast(<FormattedMessage id="successToast.Publisher.MediaUploaded" defaultMessage='Media uploaded successfully' />)
                setModalVisible(false)
                setSelectedFile([])
            }).catch((error) => {
                errorToast(<FormattedMessage id="errorToast.Common.Something-Went-Wrong" defaultMessage="Something went wrong." />)
            })
        } else {
            errorToast(<FormattedMessage id="errorToast.Publisher.SelectOneFile" defaultMessage="Select at least one file" />)
        }
    }
    const publisherData = publisherItems?.value?.filter((item) => item?.ShortCodeMedias?.length > 0)
    const dataItems = publisherData?.map(item => <QrCodeCard key={`postcard${item.Id}`} keyId={`postcard${item.Id}`} layout={layout} onUploadClick={handleModel} data={item} />)

    const FileItem = ({ item, index }) => {
        return (
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    {item?.name}
                </div>
                <div>
                    <button
                        type="button"
                        className="btn-sm btn-close"
                        onClick={() => removeFile(index)}
                    />
                </div>
            </div>
        )
    }
    return (
        <>
            <Modal
                show={isVisible}
                onHide={() => setModalVisible(false)}
                backdrop="static"
                keyboard={false}
                className="justify-content-center"
                centered={true}
            >
                <Modal.Header closeButton={!isMediaUploading}>
                    <Modal.Title>Upload Media</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-3 mt-3'>
                        {selectedFiles?.map((item, index) => <FileItem key={index} item={item} index={index} />)}
                    </div>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Control type="file" key={''} name="fileCollection" onChange={(e) => onSelectFile(e)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={isMediaUploading}
                        variant="secondary"
                        onClick={() => setModalVisible(false)}>
                        Close
                    </Button>
                    <Button
                        disabled={isMediaUploading}
                        variant="primary"
                        onClick={handleUpload}>
                        {(isMediaUploading) && (
                            <Spinner size="sm" variant="light" />
                        )}{" "}
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className={`block block-posts block-posts--layout--${layout}`} data-layout={layout}>
                <div className="container">
                    <BlockHeader
                        arrows
                        title={"QR Codes"}
                        onNext={handleNextClick}
                        onPrev={handlePrevClick}
                    />

                    <div className="block-posts__slider">
                        <StroykaSlick
                            ref={setSlickRef}
                            {...slickSettings[layout]}
                        >
                            {dataItems && dataItems.length > 0 ? dataItems : !isDataLoading && "No Records Found"}
                        </StroykaSlick>
                    </div>
                </div>
            </div>
        </>
    )
}

Publisher.propTypes = {
    layout: PropTypes.oneOf(['list-sm', 'grid-nl'])
};

Publisher.defaultProps = {
    layout: 'list-sm'
};

export default Publisher