const StatBlock = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF, calculateBlockHeight, screenCount, deckLength, deckScreenLength, screenType, heightOfString, blockHeight, index) => {
    let contentObject = JSON.parse(mainContent || "{}")

    documentMethods.text("", { columns: 2, continued: true })
    if ((documentMethods.page.height - 120 - documentMethods.y) < blockHeight) {
        documentMethods.text("", documentMethods.x, documentMethods.y + (documentMethods.page.height - documentMethods.y), { columns: 2, continued: true });
    }
    documentMethods.text("", { continued: true })
    documentMethods.text("", { continued: true })
    documentMethods.text("", { continued: true })
    documentMethods.text("", { continued: true })
    if (stylesheet.title !== 'dungeon') {
        documentMethods.rect(documentMethods.x - 10, (index === 0 || index === 1) ? documentMethods.y - 15 : documentMethods.y, stylesheet.title === 'sci-fi' ? 260 : 240, 30).lineWidth(0).fillAndStroke("#095559", "#859eac")
        documentMethods.rect(documentMethods.x - 10, (index === 0 || index === 1) ? documentMethods.y - 15 : documentMethods.y, stylesheet.title === 'sci-fi' ? 260 : 240, blockHeight).lineWidth(4).stroke("#859eac")
        documentMethods.rect(documentMethods.x - 10 + 2, (index === 0 || index === 1) ? documentMethods.y + 15 : documentMethods.y + 30, stylesheet.title === 'sci-fi' ? 260 : 237.1, blockHeight - 30).lineWidth(3).fill("#f8f5f1")
        documentMethods.rect(documentMethods.x - 10 + 0.5, (index === 0 || index === 1) ? documentMethods.y - 15 : documentMethods.y, stylesheet.title === 'sci-fi' ? 260 : 240, blockHeight + 1).lineWidth(1).stroke("#095559")
    }

    stylesheet?.value?.screenHeading && documentMethods.fillColor(stylesheet.title !== 'dungeon' ? 'white' : 'black')
        .fontSize(stylesheet.title !== 'dungeon' ? 15 : 20)
        .font(stylesheet.value.screenHeading.font)
        .text(contentObject?.title, documentMethods.x, stylesheet.title !== 'dungeon' ? documentMethods.y - 10 : documentMethods.y, { continued: true })
        .text('', { continued: true })
        .text('', { continued: true })
        .text('', { continued: true })
        .text('', { continued: true })

    stylesheet?.value?.screenContentDescription && documentMethods.fillColor('black')
        .font(stylesheet.value.screenContentDescription.font)
        .fontSize(stylesheet.title !== 'dungeon' ? 10 : 15)
        .text('', { continued: true })
        .text('', documentMethods.x, documentMethods.y + 20, { continued: true })

    contentObject?.description.split('<br><br><br>').forEach((htmlData, index, array) => {
        stylesheet?.value?.screenHeading && stylesheet?.value?.screenContentDescription && convertHTMLToPDF(htmlData, 'block', array?.length - 1 === index);
        stylesheet.title === 'dungeon' && documentMethods.moveDown()
    })
    // documentMethods.text("", { continued: true }).text("", { continued: true });
    documentMethods.moveDown()
}

export default StatBlock