
import { createSlice } from '@reduxjs/toolkit'

const dieTypeSlice = createSlice({
    name: "dieType",
    initialState: {
        dieType:[]
    },
    reducers: {
        getAllDieType: (state,action) => {
            state.dieType = action.payload
        }
    }
});

export default dieTypeSlice.reducer;
export const { getAllDieType } = dieTypeSlice.actions

