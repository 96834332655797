const SimpleHeader = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF) => {
    let contentObject = JSON.parse(mainContent || "{}")

    documentMethods.text("", { continued: true, columns: stylesheet?.title !== 'dungeon' ? 2 : 1 })
    stylesheet?.value?.screenHeading && stylesheet?.value?.screenContentDescription &&
        documentMethods
            .text("", { continued: true })
            .text("", { continued: true })
            .fontSize(20)
            .font(stylesheet.value.screenHeading.font)
    // contentObject?.title && documentMethods.moveDown()
    documentMethods.text("", { continued: true }).fillColor('black')
    stylesheet?.value?.screenHeading && contentObject?.title && documentMethods.text(contentObject?.title, { ...stylesheet.value.screenHeading, continued: true })
    stylesheet?.value?.screenContentDescription && documentMethods
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .fontSize(15)
        .moveDown()
        .font(stylesheet.value.screenContentDescription.font)
    // .text(formattedTemp[i]?.description, {
    // 	columns: 2,
    // 	continued: true,
    // })
    stylesheet?.value?.screenHeading && stylesheet?.value?.screenContentDescription && convertHTMLToPDF(contentObject?.description, 'header');
    documentMethods.text("", documentMethods.x, documentMethods.y + 15, { continued: true })
}

export default SimpleHeader