import { createSlice } from "@reduxjs/toolkit";

const playerRoleSlice = createSlice({
    name: "role",
    initialState: {
        role: []
    },
    reducers: {
        getRole: (state, action) => {
            state.role = action.payload
        },
    },
});

export default playerRoleSlice.reducer;
export const { getRole } = playerRoleSlice.actions