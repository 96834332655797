const PDFTable = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF, calculateBlockHeight, screenCount, deckLength, deckScreenLength, screenType, heightOfString, blockHeight, index) => {
    let contentObject = JSON.parse(mainContent?.ContentObject || "{}")
    let imageData = JSON.parse(mainContent?.PubRandomEncounterContents?.[0]?.RandomEncounter?.RandomEncounterObject || "[]")

    const [image] = imageData
    const tableHeader = 'https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/CropTableHeader/20240531072242351_LongHeader_Yellow-crop.png'
    const urlFormation1 = `https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/CropBorder/20240531072352854_LongBorder-crop.png`
    documentMethods.moveDown()
    if ((documentMethods.page.height - 120 - documentMethods.y) < 250) {
        documentMethods.text("", documentMethods.x, documentMethods.y + (documentMethods.page.height - documentMethods.y), { continued: true });
    }
    stylesheet?.title === 'montkelian' && documentMethods.image(await getBase64ImageFromURL(tableHeader), { width: 450 })

    stylesheet?.value?.screenHeading && documentMethods.fillColor(stylesheet?.title === 'montkelian' ? 'white' : 'black')
        .fontSize(stylesheet?.title === 'montkelian' ? 10 : 18)
        .font(stylesheet.value.screenHeading.font)
        .text(contentObject?.title, stylesheet?.title === 'montkelian' ? documentMethods.x + 70 : documentMethods.x, stylesheet?.title === 'montkelian' ? (index === 0 || index === 1) ? documentMethods.y - 15 : documentMethods.y - 30 : documentMethods.y)

    stylesheet?.title !== 'montkelian' && documentMethods.moveDown();
    (index === 0 || index === 1) && documentMethods.text("", documentMethods.x - 70, documentMethods.y)

    image?.tableImage && documentMethods.image(await getBase64ImageFromURL(image?.tableImage), stylesheet?.title === 'montkelian' ? documentMethods.x : documentMethods.x, stylesheet?.title === 'montkelian' ? documentMethods.y + 5 : documentMethods.y, { width: 450, continued: true });

    stylesheet?.title === 'montkelian' && documentMethods.image(await getBase64ImageFromURL(urlFormation1), documentMethods.x, documentMethods.y + 180, { continued: true, width: 450 });
    // documentMethods.text("", documentMethods.x, documentMethods.y + 140, { continued: true });
}

export default PDFTable