const Notes = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF, calculateBlockHeight, screenCount, deckLength, deckScreenLength, screenType, imageHeight) => {
    let contentObject = JSON.parse(mainContent || "{}")

    const imagePath = await getBase64ImageFromURL('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/Note/20240531071410177_MontkelianTemplateNoteNew.png');

    // stylesheet?.value?.screenContentDescription && documentMethods.text("", { continued: true }).fontSize(13).font(stylesheet.value.screenContentDescription.font)

    // const imageHeight = documentMethods.heightOfString(contentObject?.description, { columns: 2 });

    if ((documentMethods.page.height - 120 - documentMethods.y) < imageHeight) {
        documentMethods.text("", documentMethods.x, documentMethods.y + (documentMethods.page.height - documentMethods.y), { continued: true, columns: 2 });
    }
    documentMethods.text("", { continued: true, columns: stylesheet?.title !== 'dungeon' ? 2 : 1 })
    // stylesheet.title === 'sci-fi' && documentMethods.text("", documentMethods.x + 90, documentMethods.y - 50, { continued: true })
    // documentMethods.moveDown()
    stylesheet.title === 'montkelian' && documentMethods.text("", { continued: true }).text("", { continued: true })
        .image(imagePath, documentMethods.x - 10, documentMethods.y, {
            width: 255,
            height: (imageHeight) + 30,
            continued: true
        });
    stylesheet?.value?.screenContentDescription && documentMethods.fillColor('#095559')
        .fontSize(15)
        .font(stylesheet.value.screenContentDescription.font)
        .text(contentObject?.title, stylesheet.title === 'montkelian' ? documentMethods.x + 5 : documentMethods.x, stylesheet.title === 'montkelian' ? (documentMethods.y - imageHeight - 25) : documentMethods.y, { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .text("", { continued: true })
        .fillColor('black')
        .fontSize(13)
        .font(stylesheet.value.screenContentDescription.font)
    // .text(formattedTemp[i]?.description, stylesheet.title === 'montkelian' ? documentMethods.x : documentMethods.x, stylesheet.title === 'montkelian' ? (documentMethods.y) : documentMethods.y, {
    // 	continued: (contentObject?.lists?.length - 1) !== i,
    // });
    stylesheet?.value?.screenHeading && stylesheet?.value?.screenContentDescription && convertHTMLToPDF(contentObject?.description, 'header');
    // documentMethods.text("", { continued: true }).text("", { continued: true });
    // stylesheet.title === 'sci-fi' && documentMethods.text("", { continued: i !== 0 })
    documentMethods.text("", documentMethods.x, documentMethods.y + (imageHeight - 25), { continued: true })
}

export default Notes