import { createSlice } from "@reduxjs/toolkit";

const siteSlice = createSlice({
  name: "site",
  initialState: {
    role: []
  },
  reducers: {
    getSiteRole: (state, action) => {
      state.role = action.payload
    }
  },
});

export default siteSlice.reducer;
export const { getSiteRole } = siteSlice.actions