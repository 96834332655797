const TableOfContents = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF) => {
    let contentObject = JSON.parse(mainContent || "{}")

    stylesheet?.value?.backgroundImage?.commonBg && documentMethods.image(stylesheet?.value?.backgroundImage?.commonBg, 0, 0, { width: documentMethods.page.width, height: documentMethods.page.height })

    stylesheet?.value?.screenTitle && documentMethods.font(stylesheet?.value?.screenTitle?.font).fontSize(stylesheet?.value?.screenTitle?.fontSize).fillColor(stylesheet?.value?.screenTitle?.color).text(contentObject?.title, { align: 'center' }).moveDown()

    stylesheet?.value?.headerBorder?.url && documentMethods.image(stylesheet?.value?.headerBorder.url, 60, 83, { width: 500 })
    // stylesheet?.value?.headerBorder?.url && documentMethods.moveDown()
    // contentObject?.lists.forEach((list, index) => {
    // documentMethods.text("")
    // documentMethods.text("")
    documentMethods.text("").fillColor("black")
    // documentMethods.font(stylesheet.value.screenHeading.font).fontSize(stylesheet.value.screenContentDescription.fontSize).text(list?.[`title${index + 1}`], { ...stylesheet.value.screenHeading, columns: 2, continued: true, align: 'left' })
    stylesheet?.value?.screenContentDescription && documentMethods.fontSize(stylesheet.value.screenContentDescription.fontSize).text("", { ...stylesheet.value.screenHeading, columns: 2, continued: true, align: 'left' })

    stylesheet?.value?.screenHeading && stylesheet?.value?.screenContentDescription && convertHTMLToPDF(contentObject?.description, 'index');
    // documentMethods.text(" ............... ", { continued: true, align: 'center' })
    // documentMethods.text("2", { align: 'right' })
    // documentMethods.moveDown()
    // documentMethods.text("")
    // documentMethods.text("")
    documentMethods.text("")
    // })
    stylesheet?.title === 'montkelian' && stylesheet?.value?.backgroundImage?.bottomRightImage &&
        documentMethods.image(stylesheet?.value.backgroundImage.bottomRightImage, 270, 560, { width: 400, opacity: 0 })
            .text("", documentMethods.x, documentMethods.y)
}

export default TableOfContents