import { createSlice } from '@reduxjs/toolkit'

const materialSlice = createSlice({
    name: "material",
    initialState: {
        material:[]
    },
    reducers: {
        getAllMaterial: (state,action) => {
            state.material = action.payload
        }
    }
});

export default materialSlice.reducer;
export const { getAllMaterial } = materialSlice.actions