import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { InputLabel, FormControl, MenuItem, Select } from '@mui/material';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import { Button } from 'react-bootstrap';
import { Spinner } from 'reactstrap';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from '../bookScrapRats/pdfMakeFonts';
import 'pdfmake/build/vfs_fonts';
import { errorToast } from 'common/toast';

const ProductCatalog = () => {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    const [tableData, setTableData] = useState([])
    const [productList, setProductList] = useState([])
    const [allProductDetails, setAllProductDetails] = useState([])
    const [sortBy, setSortBy] = useState("name")
    const [sortDirection, setSortDirection] = useState('asc')
    const [loader, setLoader] = useState(false)
    const [previewPanel, setPreviewPanel] = useState('')
    const [productDetail, setProductDetail] = useState("")

    useEffect(async () => {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_PC_API}Shopify/GetSmartCollectionDetailFromShopify`
        })
        setProductList(response?.data?.items)
    }, [])

    useEffect(() => {
        getTableData(allProductDetails)
    }, [sortBy, sortDirection])

    const getUserProducts = async (e) => {
        setProductDetail(e.target.value)
        try {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_PC_API}Shopify/GetProductDetailFromShopifyCollection?collectionId=${e.target.value}`
            })
            if (!response?.data?.length) errorToast('Collection does not contains any products')
            setAllProductDetails(response?.data)
            getTableData(response?.data)
        } catch (error) {
            setTableData([])
            setLoader(false)
            errorToast(error.response.data.Message)
        }
    }

    async function getTableData(data) {
        let tableBody = []
        let count = 0
        let modifiedData = [];
        let sortedData = [];

        data.forEach((item) => {
            item.variants.forEach((variant) => modifiedData.push({ productTitle: item.title, ...variant }))
        })

        if (sortBy === 'name') {
            sortedData = modifiedData.sort((a, b) => {
                if (sortDirection === 'asc') return a.productTitle.localeCompare(b.productTitle)
                else return b.productTitle.localeCompare(a.productTitle)
            })
        } else if (sortBy === 'sku') {
            sortedData = modifiedData.sort((a, b) => {
                if (sortDirection === 'asc') return (a.sku && b.sku) && a?.sku.localeCompare(b?.sku)
                else return (a.sku && b.sku) && b?.sku.localeCompare(a?.sku)
            })
        } else {
            sortedData = modifiedData.sort((a, b) => {
                if (sortDirection === 'asc') return a.price - b.price
                else return b.price - a.price
            })
        }

        for (const item of sortedData) {
            count++
            let details = [];
            details.push(item.sku)
            details.push('')
            details.push(`${item?.productTitle} - ${item?.title}`)
            details.push(`$${item?.price}`)
            details.push('')
            tableBody.push(details)
        }
        setTableData(tableBody)
        setLoader(false)
    }

    const generatePDF = async (data) => {
        setPreviewPanel(
            <div className='block block-products-carousel block-products-carousel--loading'>
                <div className="block-products-carousel__preloader">
                </div>
            </div>
        )

        const header = function (currentPage, pageCount) {
            return {
                text: `World of Game Design`,
                alignment: 'center',
                fontSize: 20,
                bold: true,
                margin: [0, 20, 0, 30]
            };
        };

        const footer = function (currentPage, pageCount) {
            return {
                stack: [
                    {
                        canvas: [
                            {
                                type: 'line',
                                x1: 40,
                                y1: 10,
                                x2: 550,
                                y2: 10,
                                lineWidth: 1,
                                lineColor: '#000000'
                            }
                        ]
                    },
                    {
                        columns: [
                            { text: 'info@wogd.com', alignment: 'left', fontSize: 10, margin: [40, 10, 0, 10] },
                            { text: `Page ${currentPage} of ${pageCount}`, alignment: 'right', fontSize: 10, margin: [0, 10, 40, 10] }
                        ]
                    }
                ],
                margin: [0, 0, 0, 20]
            };
        };

        const documentDefinition = {
            header,
            footer,
            content: [
                // {
                //     image:,
                //     width: 595, // page width
                //     height: 842 // page height
                // },
                // { text: '', pageBreak: 'after' },
                { text: '\nDate :________________\t\t\t\t\t\t\tPurchase Order Number :________________________________', style: 'boldText' },
                { text: 'Company :___________________________________', style: 'boldText' },
                { text: 'Address :_____________________________________________________________________________________', style: 'boldText' },
                { text: 'Phone :_____________________\tFax :_______________________ \tContact :________________________', style: 'boldText' },
                { text: 'Date Wanted :___________________________\tShipping Instructions :_____________________________', style: 'boldText' },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        dontBreakRows: true,
                        widths: ['auto', 50, 'auto', 'auto', 50],
                        body: [
                            [{ text: 'SKU Codes', style: 'tableHeader', alignment: 'center' }, { text: 'Qty', style: 'tableHeader', alignment: 'center' }, { text: 'Item Name and Description', style: 'tableHeader', alignment: 'center' }, { text: 'MSRP Price', style: 'tableHeader', alignment: 'center' }, { text: 'Total', style: 'tableHeader', alignment: 'center' }],
                            ...data
                        ],
                        headerStyles: { fillColor: '#CCCCCC', bold: true, alignment: 'center' }

                    }
                },
                { text: '\n\nF.O.B :___________ Ship By :__________ Terms :_______________ Delivery Location :________________', style: 'boldText' },
                { text: 'Contact :_____________________ Tax Code :___________ Delivery Date :___________________________', style: 'boldText' },
                { text: 'Company Ref # :_____________________ Buyer :_____________________ Date :______________________', style: 'boldText' },
            ],
            pageMargins: [40, 60, 40, 60],
            styles: {
                boldText: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black',
                    fillColor: '#CCCCCC',
                }
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getDataUrl((dataUrl) => {
            setPreviewPanel(<iframe src={dataUrl}></iframe>)
        });
    }

    return (
        <div className="pageWrapper">
            <div className='ps-3 dataEntryPanel d-flex justify-content-center'>
                <div className='w-75 mt-4'>
                    <h2>Product Catalog</h2>
                    <div className='d-flex flex-column align-items-center mt-4'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">
                                Collections
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                value={productDetail || ""}
                                onChange={(e) => {
                                    setLoader(true)
                                    setTableData([])
                                    getUserProducts(e)
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    PaperProps: {
                                        style: {
                                            maxHeight: '400px',
                                        },
                                    },
                                }}
                                label={'Collections'}
                            >
                                {productList?.length ? productList.map((product, index) =>
                                    product?.title && <MenuItem key={`product_${index}`} className='all-epics' value={product.id}>
                                        {product?.image?.src ? <img src={product?.image?.src} width={'50px'} style={{ marginRight: '10px', aspectRatio: '1.1', objectFit: 'contain' }} /> : <HideImageOutlinedIcon style={{ fontSize: '50px', marginRight: '10px' }} />} -   {product.title}
                                    </MenuItem>) : <MenuItem className='all-epics' disabled>Loading...</MenuItem>
                                }
                            </Select>
                        </FormControl>
                        <div className='d-flex justify-content-between w-100 mt-3'>
                            <FormControl fullWidth className='me-2'>
                                <InputLabel id="demo-simple-select-helper-label">
                                    Sort By
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    value={sortBy || ""}
                                    onChange={(e) => setSortBy(e.target.value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        PaperProps: {
                                            style: {
                                                maxHeight: '400px',
                                            },
                                        },
                                    }}
                                    label={'Sort By'}
                                >
                                    <MenuItem className='all-epics' value={'name'}>Item Name</MenuItem>
                                    <MenuItem className='all-epics' value={'sku'}>SKU Codes</MenuItem>
                                    <MenuItem className='all-epics' value={'msrp'}>MSRP Price</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth className='ms-2'>
                                <InputLabel id="demo-simple-select-helper-label">Order</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    value={sortDirection || ""}
                                    onChange={(e) => setSortDirection(e.target.value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        PaperProps: {
                                            style: {
                                                maxHeight: '400px',
                                            },
                                        },
                                    }}
                                    label={'  Order'}
                                >
                                    <MenuItem className='all-epics' value={'asc'}>Ascending</MenuItem>
                                    <MenuItem className='all-epics' value={'desc'}>Descending</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Button className='mt-3' disabled={!tableData.length} onClick={() => generatePDF(tableData)}>{loader && <Spinner size="sm" variant="light" style={{ marginRight: "5px" }} />} Preview</Button>
                    </div>
                </div>
            </div>
            <div className='pdf-block'>
                <div className='previewPanel' id='iframeContainer'>
                    {previewPanel}
                </div>
            </div>
        </div>
    )
}

export default ProductCatalog