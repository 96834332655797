import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, Card, CardBody, CardTitle, CardText, CardSubtitle, Row, CardGroup } from 'reactstrap';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { FormattedMessage } from 'react-intl';
import moment from 'moment'
import SkipMonthSelector from "./skipMonthSelector";
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

const MySubscriptionDetail = () => {
    const allViews = Object.keys(Views).map(k => Views[k])
    const [openSkipMonthsModal, setOpenSkipMonthsModal] = useState(false);
    const [openRenewSubscriptionModal, setOpenRenewSubscriptionModal] = useState(false);
    const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState(false);
    const [openDuplicateSubscriptionModal, setOpenDuplicateSubscriptionModal] = useState(false);

    const events = [
        {
            'title': 'All Day Event very long title',
            'allDay': true,
            'start': new Date(2021, 12, 0),
            'end': new Date(2021, 12, 1)
        }
    ];
    return (
        <div className='container'>
            <div className="d-flex flex-column" style={{ marginTop: '90px' }}>
                <h2><FormattedMessage id="subscription.Manage.ManageTitle" defaultMessage="Your Dice Fanatics Subscription" /></h2>
                <div className='mt-5'>
                    <div>
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">
                                    <FormattedMessage id="subscription.Manage.Subscription-Details" defaultMessage="Subscription Details" />
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 text-muted"
                                    tag="h6"
                                >
                                    <FormattedMessage id="subscription.Manage.Name" defaultMessage="Name" />
                                </CardSubtitle>
                                <CardText>
                                    <FormattedMessage id="subscription.Manage.Description" defaultMessage="Some quick example text to build on the card title and make up the bulk of the card's content." />
                                </CardText>
                                <CardSubtitle
                                    className="mb-2 text-muted"
                                    tag="h6"
                                >
                                    <FormattedMessage id="subscription.Manage.Price" defaultMessage="Price" />
                                </CardSubtitle>
                                <CardText>
                                    $29.00
                                </CardText>
                            </CardBody>
                        </Card>
                        <Row className='mt-5'>
                            <CardGroup>
                                <Card>
                                    <CardBody>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            <FormattedMessage id="subscription.Manage.Next-Delivery" defaultMessage="Next Delivery" />
                                        </CardSubtitle>
                                        <CardText>
                                            <FormattedMessage id="subscription.Manage.Next-Delivery.Date" defaultMessage="May 15, 2022" />
                                        </CardText>
                                        <button size='lg' className={'btn btn-primary'} onClick={() => setOpenSkipMonthsModal(true)}>
                                            <FormattedMessage id="subscription.Manage.Pause-Subscription" defaultMessage="Pause Subscription" />
                                        </button>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            <FormattedMessage id="subscription.Manage.Renewal-Date" defaultMessage="Renewal Date" />
                                        </CardSubtitle>
                                        <CardText>
                                            <FormattedMessage id="subscription.Manage.Cancel-Subscription.Date" defaultMessage="July 15, 2022" />
                                        </CardText>
                                        <button size='lg' className={'btn btn-primary'}>
                                            <FormattedMessage id="subscription.Manage.Cancel-Subscription" defaultMessage="Cancel Subscription" />
                                        </button>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            <FormattedMessage id="subscription.Manage.Duplicate-Subscription" defaultMessage="Duplicate Subscription" />
                                        </CardSubtitle>
                                        <CardText>
                                            <FormattedMessage id="subscription.Manage.Duplicate-Subscription.Description" defaultMessage="Buy another one" />
                                        </CardText>
                                        <button size='lg' className={'btn btn-primary'}>
                                            <FormattedMessage id="subscription.Manage.Duplicate-Subscription.Button" defaultMessage="Is it a gift?" />
                                        </button>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            <FormattedMessage id="subscription.Manage.Renew-Subscription" defaultMessage="Renew Subscription" />
                                        </CardSubtitle>
                                        <CardText>
                                            <FormattedMessage id="subscription.Manage.Renew-Subscription.Description" defaultMessage="Renew your subscription" />
                                        </CardText>
                                        <button size='lg' className={'btn btn-primary'}>
                                            <FormattedMessage id="subscription.Manage.Renew-Subscription.Button" defaultMessage="Purchase" />
                                        </button>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Row>
                    </div>
                </div>
                <div className='mt-5 w-75 m-auto'>
                    <Calendar
                        events={events}
                        vidiv={allViews}
                        step={60}
                        showMultiDayTimes
                        defaultDate={new Date()}
                        localizer={localizer}
                    />
                </div>
            </div>
            <Modal isOpen={openSkipMonthsModal} toggle={() => setOpenSkipMonthsModal(!openSkipMonthsModal)} size='lg'>
                <ModalHeader toggle={() => setOpenSkipMonthsModal(!openSkipMonthsModal)} color="primary">Choose months</ModalHeader>
                <ModalBody>
                    <SkipMonthSelector />
                </ModalBody>
            </Modal>
            <Modal isOpen={openRenewSubscriptionModal} toggle={() => setOpenRenewSubscriptionModal(false)} size='sm'>
                <ModalHeader toggle={() => setOpenRenewSubscriptionModal(false)} color="primary">Renew Subsciption</ModalHeader>
                <ModalBody className='align-self-center'>
                    <button size='lg' className={'btn btn-primary'}>Purchase</button>
                </ModalBody>
            </Modal>
            <Modal isOpen={openCancelSubscriptionModal} toggle={() => setOpenCancelSubscriptionModal(false)} size='sm'>
                <ModalHeader toggle={() => setOpenCancelSubscriptionModal(false)} color="primary">Renew Subsciption</ModalHeader>
                <ModalBody className='align-self-center'>
                    <button size='lg' className={'btn btn-primary'}>Purchase</button>
                </ModalBody>
            </Modal>
            <Modal isOpen={openDuplicateSubscriptionModal} toggle={() => setOpenDuplicateSubscriptionModal(false)} size='sm'>
                <ModalHeader toggle={() => setOpenDuplicateSubscriptionModal(false)} color="primary">Renew Subsciption</ModalHeader>
                <ModalBody className='align-self-center'>
                    <button size='lg' className={'btn btn-primary'}>Is this a gift?</button>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default MySubscriptionDetail