import { createSlice } from "@reduxjs/toolkit";

const dieSlice = createSlice({
  name: "die",
  initialState: {
    die: [],
    status: 'idle',
  },
  reducers: {
    getAllDie: (state, action) => {
      state.status = 'succeeded'
      state.die = action.payload
    }
  },
});

export default dieSlice.reducer;
export const { getAllDie } = dieSlice.actions

