const brandsData = [
    { name: 'World of Game Design', slug: 'wogd', image: 'assets/images/logos/logo-1.png' },
    { name: 'Chessex', slug: 'chessex', image: 'assets/images/logos/logo-2.png' },
    { name: 'Koplow', slug: 'koplow', image: 'assets/images/logos/logo-3.png' },
    { name: 'Dice Fanatics', slug: 'dice-fanatics', image: 'assets/images/logos/logo-4.png' },
    { name: 'Kraken', slug: 'kraken', image: 'assets/images/logos/logo-5.png' },
    { name: 'Die Hard Dice', slug: 'die-hard-dice', image: 'assets/images/logos/logo-6.png' },
    { name: 'HD Dice', slug: 'hd-dice', image: 'assets/images/logos/logo-7.png' },
];

export default brandsData;
