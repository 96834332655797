// react
import React, { useEffect, useState } from 'react';

// third-party
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'universal-cookie';
import { Button } from "reactstrap";
import { FormattedMessage } from 'react-intl';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../svg';




export default function IndicatorAccount({ layout }) {

    const { logout, isAuthenticated, user, loginWithRedirect } = useAuth0();

    const logoutUser = () => {
        logout();
        localStorage.removeItem("userId");
        localStorage.removeItem("hexList");
        localStorage.removeItem("playerRecord");
        const cookies = new Cookies();
        cookies.remove('accessToken');
        window.location.href = process.env.REACT_APP_LOGIN_HOST;

    }
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        setIsLoggedIn(isAuthenticated)
    }, [isAuthenticated])

    switch (layout) {
        case "adventureHex":
            document.getElementsByClassName('nav-panel')[0].style.backgroundImage = "url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113919088_Mayan-Dividers.png')"
            document.getElementsByTagName('body')[0].style.overflow = "hidden"
            break;
        default:
            document.getElementsByClassName('nav-panel')[0].style.backgroundImage = "";
            document.getElementsByTagName('body')[0].style.overflow = "auto"
    }
    const dropdown = (
        <div className="account-menu">
            <form className="account-menu__form">
                {isLoggedIn ?
                    <>
                        <div className="account-menu__form-title">
                            <FormattedMessage id="headerNavigation.Login.Message" defaultMessage="You are logged in as :" />
                        </div>
                        <div className="form-group user_profile">
                            <img src={user.picture} className="user_image" alt={user.name} />
                            <h5 className='m-0'>{user.name}</h5>
                        </div>
                    </>
                    :
                    <></>
                }
            </form>
            <div className="account-menu__divider" />
            <div className="account-menu__divider" />
            <ul className="account-menu__links d-flex justify-content-center">
                <div className="account-menu__form-button">
                    {isLoggedIn ?
                        <Button className="button" onClick={logoutUser}>
                            <FormattedMessage id="headerNavigation.logout" defaultMessage="Logout" />
                        </Button>
                        :
                        <Button className="button" onClick={loginWithRedirect}>
                            <FormattedMessage id="headerNavigation.Login" defaultMessage="Login" />
                        </Button>
                    }
                </div>
            </ul>
        </div>
    );

    return (

        layout !== "adventureHex" ?
            <>
                <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />
            </>
            :
            <>
                <div className="d-flex">
                    <div className="login_details_style">
                        <h4>{user.name}</h4>
                    </div>
                    <div className="login_image_style" onClick={logoutUser}>
                    </div>
                </div>
            </>
    );
}
