import { makeIdGenerator } from '../utils';
import CthulhuEpic from "../../assets/homeLogoCthulhuEpics_210.png";
import DungeonEpic from "../../assets/homeLogoDungeonEpics_210.png";
import WOGDEpic from "../../assets/logoWOGD-Bright-No-Shadow.png";
import DiceFanatics from "../../assets/DiceFanatics.png";

const getId = makeIdGenerator();

const productsDef = [
    {
        slug: 'subscription1',
        name: 'Cthulhu Epics',
        price: 5,
        buttonValue: "Manage My Subscription",
        images: [
            CthulhuEpic
        ],
        action: 'subscriptionDetails'
    },
    {
        slug: 'subscription1',
        name: 'Dungeon Epics',
        url: '/adventurehex',
        price: 5,
        buttonValue: "Subscribe",
        images: [
            DungeonEpic
        ],
        action: "AdToCart"
    },
    {
        slug: 'subscription1',
        name: 'World of Game Design Patreon',
        url: '/adventurehex',
        price: 5,
        buttonValue: "Manage My Subscription",
        images: [
            WOGDEpic
        ],
        action: "subscriptionDetails"
    },
    {
        slug: 'subscription1',
        name: 'Dice Fanatics',
        url: '/adventurehex',
        price: 5,
        buttonValue: "Subscribe",
        images: [
            DiceFanatics
        ],
        action: "AdToCart"

    },
    {
        slug: 'subscription1',
        name: 'New Sub',
        url: '/adventurehex',
        price: 5,
        buttonValue: "Subscribe",
        images: [
            CthulhuEpic
        ],
        action: "AddToCart"

    },
    {
        slug: 'subscription1',
        name: 'New Sub',
        url: '/adventurehex',
        price: 5,
        buttonValue: "Subscribe",
        images: [
            CthulhuEpic
        ],
        action: "AddToCart"
    },
];

const subscriptionsData = productsDef.map((productDef) => {
    return {
        id: getId(),
        name: productDef.name,
        buttonValue: productDef.buttonValue,
        sku: '83690/32',
        slug: productDef.slug,
        price: productDef.price,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        action: productDef.action
    };
});

export default subscriptionsData;
