import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { gamecodexApi } from '../services/gamecodexApi'
import postsReducer from '../apps/forum/posts/postsSlice'
import usersReducer from '../apps/forum/users/usersSlice'

import productLineReducer from '../apps/collectors/admin/productLine/productLineSlice'
import productModelReducer from '../apps/collectors/admin/productModel/productModelSlice'
import manufacturerReducer from '../apps/collectors/admin/manufacturer/manufacturerSlice'
import setReducer from '../apps/collectors/admin/set/setSlice'
import setTypeReducer from '../apps/collectors/admin/setType/setTypeSlice'
import colorReducer from '../apps/collectors/admin/color/colorSlice'
import materialReducer from '../apps/collectors/admin/material/materialSlice'
import dieReducer from '../apps/collectors/admin/die/dieSlice'
import dieTypeReducer from '../apps/collectors/admin/dieType/dieTypeSlice'

import adventureHexReducer from '../apps/adventure-hex/adventureHexSlice'
import maphexCellReducer from '../apps/adventure-hex/admin/maphexCell/maphexCellSlice'
import avatarReducer from '../apps/adventure-hex/admin/avatar/avatarSlice'
import playerRecucer from "../apps/adventure-hex/player/playerSlice";
import playerRoleReducer from 'global/playerRoleSlice'
import mobileMenuReducer from 'store/mobile-menu';
import isAdminReducer from './IsAdminSlice'
import subscriptionReducer from "../apps/adventure-hex/subscription/subscriptionSlice";
import bookBuilderReducer from 'apps/tool/book/bookBuilderSlice'
import siteRoleReducer from 'apps/tool/book/bookScrapRats/siteSlice'

export const store = configureStore({
  reducer: {
    [gamecodexApi.reducerPath]: gamecodexApi.reducer,
    posts: postsReducer,
    users: usersReducer,
    productLines: productLineReducer,
    productModels: productModelReducer,
    manufacturers: manufacturerReducer,
    sets: setReducer,
    setTypes: setTypeReducer,
    colors: colorReducer,
    materials: materialReducer,
    dies: dieReducer,
    dieTypes: dieTypeReducer,
    adventureHexs: adventureHexReducer,
    maphexCells: maphexCellReducer,
    avatars: avatarReducer,
    player: playerRecucer,
    playerRole: playerRoleReducer,
    mobileMenu: mobileMenuReducer,
    isAdminReducer: isAdminReducer,
    subscriptions: subscriptionReducer,
    bookBuilderContent: bookBuilderReducer,
    siteRole: siteRoleReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(gamecodexApi.middleware),
})

setupListeners(store.dispatch)

export default store