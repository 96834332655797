import { Form, Formik } from 'formik';
import * as Yup from "yup";
import React from 'react'
import { Button, Col, FormFeedback, Input, Row } from 'reactstrap';

const PromoCode = () => {

    const playerSchema = Yup.object().shape({
        DiscountCode: Yup.string().required("Code is required")
    });

    const initialValues = {
        DiscountCode: ""
    }

    const submitForm = (values) => {
        console.log(values,"value");
    }
    return (
        <div className="promo-code">
            <p className="subscription-cart-titles text-center">Have a promo code?</p>
            <div>
            <Formik
                initialValues={initialValues}
                validationSchema={playerSchema}
                onSubmit={submitForm}
            >
                {(formik) => {
                    const { errors, values, handleChange } = formik;
                    return (
                        <Form>
                            <Row>
                                <Col md="8">
                                <Input
                                            name="DiscountCode"
                                            id="DiscountCode"
                                            type="text"
                                            placeholder="Enter Code Here"
                                            className={`${errors.DiscountCode ? "is-invalid" : null} promo-code-input`}
                                            value={values.DiscountCode}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.DiscountCode && <FormFeedback className="promoerror">{errors.DiscountCode}</FormFeedback>}
                                </Col>
                                <Col md="4">
                                <Button
                                type="submit"
                                className="add-promo-code"   
                            >
                               Add Code
                            </Button>
                                </Col>
                            </Row>
                        </Form >
                    );
                }
                }
            </Formik >
            </div>
        </div>
    )
}

export default PromoCode