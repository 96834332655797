import { createSlice } from "@reduxjs/toolkit";

const setSlice = createSlice({
  name: "set",
  initialState: {
   set: [],
   status: 'idle',
  },
  reducers: {
    getAllSet: (state,action) => {
      state.status = 'succeeded'
      state.set = action.payload
    }
  },
});

export default setSlice.reducer;
export const { getAllSet } = setSlice.actions

