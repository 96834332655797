import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'universal-cookie';
import { Modal } from "react-bootstrap";

import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { PLAYER_DATA } from 'common/tagFile'
import PlayerForm from "apps/adventure-hex/player/playerForm";
import { getPlayer } from "apps/adventure-hex/player/playerSlice";

const SelectInitialPlayer = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const myAvatars = useSelector((state) => state.player.playerAvatar);
    const [sort, setSort] = useState({
        sortDirection: 'none',
        accessor: 'some_accessor',
    });
    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            var accessToken = '';
            const getToken = async () => {
                accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH_AUDIENCE
                });
                const cookies = new Cookies();
                cookies.set('accessToken', accessToken);
            }
            getToken();
        }
        // eslint-disable-next-line
    }, [isAuthenticated])

    const [
        getPlayerRecord,
        {
            data: playerData,
            error,
            isLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        isAuthenticated && userId && getPlayerRecord({
            columns: [],
            entity: `Player`,
            tag: PLAYER_DATA,
            page: 1,
            sort: sort,
            columnFilter: { userId: parseInt(userId) },
            globalFilter: [],
        })
        // eslint-disable-next-line
    }, [userId, isAuthenticated])

    useEffect(() => {
        if (!isLoading && isAuthenticated && !error && playerData && playerData?.value)
            dispatch(getPlayer(playerData.value))
        playerData?.value?.length === 0 && setIsModalOpen(true)
        // eslint-disable-next-line
    }, [isLoading, playerData, error, isAuthenticated])

    useEffect(() => {
        if (playerData && playerData?.value?.length === 1) {
            localStorage.setItem('playerRecord', JSON.stringify(playerData.value[0]))
            setIsModalOpen(false)
        }
    }, [isLoading, error, playerData, myAvatars]);
    return (
        <div>
            <div>
                <Modal centered show={isModalOpen} size="md">
                    <Modal.Header>
                        <h4>Please Create Player To Continue</h4>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <PlayerForm />
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};
export default SelectInitialPlayer;
