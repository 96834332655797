// react
import React, { useState, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react'

// application
import AppLink from '../common/AppLink';
// import languages from '../languages';
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedDown9x6Svg } from '../svg';

// data stubs
import navLinks from '../data/headerNavigation';
import { FormattedMessage } from 'react-intl';
import { getIsAdmin } from './IsAdminSlice';

function NavLinks({ isPublisher }) {
    const { user } = useAuth0();
    const [unique, setUnique] = useState(true);
    const [publisherUnique, setPublisherUnique] = useState(true)
    const dispatch = useDispatch();
    if (localStorage.getItem('isAdmin') === 'true' && unique) {
        navLinks.splice(1, 0, {
            title: <FormattedMessage id="headerNavigation.Administration" defaultMessage="Administration" />,
            url: '',
            submenu: {
                type: 'megamenu',
                menu: {
                    size: 'nl',
                    columns: [
                        {
                            size: 6,
                            links: [
                                {

                                    title: <FormattedMessage id="headerNavigation.Administration.Game-Components" defaultMessage="Game Components" />,
                                    url: '',
                                    links: [
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.Grid-Maps" defaultMessage="Grid Maps" />,
                                            url: ''
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.Hex-Maps" defaultMessage="Hex Maps" />,
                                            url: ''
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.Avatars" defaultMessage="Avatars" />,
                                            url: '/admin/avatar'
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.AvatarsCopy" defaultMessage="AvatarsCopy" />,
                                            url: '/admin/avatarCopy'
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.Epics" defaultMessage="Epics" />,
                                            url: ''
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.Manufacturers" defaultMessage="Manufacturers" />,
                                            url: '/admin/manufacturer'
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.MyCollection" defaultMessage="MyCollection" />,
                                            url: '/admin/myCollection'
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Game-Components.DiscountCode" defaultMessage="Discount Code" />,
                                            url: '/generateCode'
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            size: 6,
                            links: [
                                {
                                    title: <FormattedMessage id="headerNavigation.Administration.Master-Collection" defaultMessage="Master Collection" />,
                                    url: '',
                                    links: [
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Master-Collection.Product-Line" defaultMessage="Product Line" />,
                                            url: ''
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Master-Collection.Product-Model" defaultMessage="Product Model" />,
                                            url: ''
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Master-Collection.Set" defaultMessage="Set" />,
                                            url: '/setCollection'
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.Master-Collection.Die" defaultMessage="Die" />,
                                            url: '/dieCollection'
                                        },
                                    ],
                                },
                                {
                                    title: <FormattedMessage id="headerNavigation.Administration.My-Collection" defaultMessage="My Collection" />,
                                    url: '',
                                    links: [
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.My-Collection.Featured-Collection" defaultMessage="Featured Collection" />,
                                            url: '/collector'
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.My-Collection.My-Collection" defaultMessage="My Collection" />,
                                            url: ''
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.My-Collection.My-Set" defaultMessage="My Set" />,
                                            url: ''
                                        },
                                        {
                                            title: <FormattedMessage id="headerNavigation.Administration.My-Collection.My-Die" defaultMessage="My Die" />,
                                            url: ''
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        })
        setUnique(false)
        dispatch(getIsAdmin(true))
        localStorage.setItem('isAdmin', true)
    }
    useEffect(() => {
        if (isPublisher && publisherUnique) {
            navLinks.push(
                {
                    title: <FormattedMessage id="headerNavigation.Publisher" defaultMessage="Publisher" />,
                    url: '/',
                    submenu: {
                        type: 'menu',
                        menu: [
                            {
                                title: <FormattedMessage id="headerNavigation.Publisher.AnySystem" defaultMessage="AnySystem" />,
                                url: '/publication',
                            }
                        ]
                    }
                }
            )
            localStorage.setItem('isPublisher', true)
            setPublisherUnique(false)
        }
    }, [user, isPublisher])

    const handleMouseEnter = (event) => {
        //const { locale } = props;
        const { direction } = 'ltr';

        const item = event.currentTarget;
        const megamenu = item.querySelector('.nav-links__megamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === 'rtl') {
                const itemPosition = containerWidth - (
                    itemOffsetLeft + item.getBoundingClientRect().width
                );

                const megamenuPosition = Math.round(
                    Math.min(itemPosition, containerWidth - megamenuWidth),
                );

                megamenu.style.left = '';
                megamenu.style.right = `${megamenuPosition}px`;
            } else {
                const megamenuPosition = Math.round(
                    Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
                );

                megamenu.style.right = '';
                megamenu.style.left = `${megamenuPosition}px`;
            }
        }
    };

    const linksList = navLinks.map((item, index) => {
        let arrow;
        let submenu;

        if (item.submenu) {
            arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
        }

        if (item.submenu && item.submenu.type === 'menu') {
            submenu = (
                <div className="nav-links__menu">
                    <Menu items={item.submenu.menu} />
                </div>
            );
        }

        if (item.submenu && item.submenu.type === 'megamenu') {
            submenu = (
                <div className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}>
                    <Megamenu menu={item.submenu.menu} />
                </div>
            );
        }

        const classes = classNames('nav-links__item', {
            'nav-links__item--with-submenu': item.submenu,
        });

        return (
            <li key={index} className={classes} onMouseEnter={handleMouseEnter}>
                <AppLink to={item.url} {...item.props}>
                    <span>
                        {item.title}
                        {arrow}
                    </span>
                </AppLink>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="nav-links__list">
            {linksList}
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks);
