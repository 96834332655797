import React, { useEffect, useState } from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import upArrowButton from "./images/uparr.png";
import downArrowButton from "./images/downarr.png";

const ScaleNavigator = ({ coord, isCellAroundFetching, selected, toggleSidebar, toggleSelected, arrowClick, currentScaleId, isAvatarCellLoading }) => {
  const [upArrowImage] = useImage(upArrowButton);
  const [downArrowImage] = useImage(downArrowButton);
  const [xCenter, setXCenter] = useState(window.innerWidth / 2);
  const [yCenter, setYCenter] = useState(window.innerHeight / 2);
  useEffect(() => {
    const windowsize = () => {
      setXCenter(window.innerWidth / 2);
      setYCenter(window.innerHeight / 2);
    }
    window.addEventListener('resize', windowsize);
    return () => { window.removeEventListener('resize', windowsize); }
  }, [])
  return (
    <div>
      <Image
        image={upArrowImage}
        x={coord.X + xCenter - 55}
        y={coord.Y + yCenter + 10}
        shadowBlur={selected ? 10 : 10}
        shadowColor={!selected ? "#000000" : "#ffffff"}
        width={50}
        height={50}
        selected={selected}
        onClick={e => arrowClick("UpArrow")}
        onContextMenu={e => {
          e.evt.preventDefault();
        }}
        onTap={toggleSelected}
        onDblTap={toggleSidebar}
        onDblClick={toggleSidebar}
        fillEnabled={!isCellAroundFetching || !isAvatarCellLoading}
        visible={currentScaleId !== 5}
      />
      <Image
        image={downArrowImage}
        x={coord.X + xCenter - 1}
        y={coord.Y + yCenter + 12}
        shadowBlur={selected ? 10 : 10}
        shadowColor={!selected ? "#000000" : "#ffffff"}
        width={50}
        height={50}
        selected={selected}
        onClick={e => arrowClick("DownArrow")}
        onContextMenu={e => {
          e.evt.preventDefault();
        }}
        onTap={toggleSelected}
        onDblTap={toggleSidebar}
        onDblClick={toggleSidebar}
        fillEnabled={!isCellAroundFetching || !isAvatarCellLoading}
        visible={currentScaleId !== 1}
      />
    </div>
  );

};
export default ScaleNavigator;
