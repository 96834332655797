// react
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import BlockHeader from './BlockHeader';
import AppCard from './AppCard';
import StroykaSlick from './StroykaSlick';
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { useEffect } from 'react';
import { PLAYER_CAMPAIGN_TAG } from './tagFile';



const BlockProductsCarousel = (props) => {
    const {
        layout,
        title,
        withSidebar,
        onGroupClick,
        groups,
        loading,
        redirect,
        setCurrentAvatarRecord,
        currentOperationHandler,
        link,
        userRole,
        page,
        setPage,
        campaignLogoItem,
        callBack,
        actionHandler,
        addToFavoriteLogo,
        quickView,
        player,
        addBook
    } = props;

    const [slickRef, setSlickRef] = useState(null);
    const [currentSub, setCurrentSub] = useState(false);
    const { isAuthenticated } = useAuth0()
    const slickSettings = {
        'grid-4': {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            slidesToShow: title.props.defaultMessage === "Books" ? 2 : 4,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: title.props.defaultMessage === "Books" ? 2 : 4,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: title.props.defaultMessage === "Books" ? 2 : 3,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
        'grid-4-sm': {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 474,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
        'grid-5': {
            rtl: true,
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            beforeChange: (currentSlide, activeSlides) => { currentSlide < activeSlides && handleNextClick() },
            slidesToShow: title.props.defaultMessage === "Players" ? 9 : 7,
            slidesToScroll: 7,
            responsive: [
                {
                    breakpoint: 1460,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                    },
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
        horizontal: {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
    };
    const slickInvitationCardSettings = {
        'grid-4': {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            slidesToShow: title.props.defaultMessage === "Books" ? 2 : 4,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: title.props.defaultMessage === "Books" ? 2 : 4,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: title.props.defaultMessage === "Books" ? 2 : 3,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
        'grid-4-sm': {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 474,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
        'grid-5': {
            rtl: true,
            dots: false,
            arrows: false,
            speed: 400,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1460,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
        horizontal: {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        },
    }

    const sort = {
        sortDirection: 'desc',
        accessor: 'Id',
    };
    const [
        getFavoriteCampaign,
        {
            data: favoriteCampaign,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        isAuthenticated && getFavoriteCampaign({
            columns: [],
            entity: "PlayerCampaign",
            tag: PLAYER_CAMPAIGN_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            ...(player && { expandFilter: `PlayerId eq ${player && player?.Id}` })
        })
    }, [isAuthenticated]);

    const productsColumns = () => {
        const columns = [];
        const { rows } = props;
        let { products } = props;
        products?.sort((val) => {
            return favoriteCampaign?.value?.some((f) => f.CampaignId === val.campaignId) ? -1 : 1
        })
        if (rows > 0) {
            products = products.slice();
            while (products.length > 0) {
                columns.push(products.splice(0, rows));
            }
        }
        return columns;
    }
    const productsInvitationColumns = () => {
        const columns = [];
        const { rows } = props;
        let { ...invitationCard } = props;
        if (rows > 0) {
            if (invitationCard && invitationCard.invitation && invitationCard.invitation.length > 0) {
                invitationCard.invitation.forEach((invite) => {
                    columns.push(invite)
                })
            }
        }
        return columns;
    }

    const columns = productsColumns().map((column) => {
        const products = column.map((product) => (
            <div key={`productCarousel${product.id}`} className="block-products-carousel__cell">
                <AppCard quickView={quickView} addToFavoriteLogo={addToFavoriteLogo} title={title.props.defaultMessage} product={product} redirect={redirect} link={link} userRole={userRole} setCurrentAvatarRecord={setCurrentAvatarRecord} currentOperationHandler={currentOperationHandler} myFavoriteCampaign={favoriteCampaign?.value?.length && favoriteCampaign.value.find((val) => val.CampaignId === product.campaignId)} callBack={callBack} actionHandler={actionHandler} />
            </div>
        ));

        return (
            <div key={`productCarousel0`} className="block-products-carousel__column">
                {products}
            </div>
        );
    });
    const defaultInvitationCard = productsInvitationColumns().map(
        (column, index) => {
            const products =
                <div key={column.Id} className="block-products-carousel__cell">
                    <AppCard quickView={quickView} addToFavoriteLogo={addToFavoriteLogo} product={column} redirect={redirect} userRole={userRole} setCurrentAvatarRecord={setCurrentAvatarRecord} currentOperationHandler={currentOperationHandler} actionHandler={actionHandler} addBook={addBook} />
                </div>

            return (
                <div key={index} className="block-products-carousel__column">
                    {products}
                </div>
            );
        });

    const blockClasses = classNames('block block-products-carousel', {
        'block-products-carousel--loading': loading,
        'block-products-carousel--has-items': columns.length > 0,
    });

    const containerClasses = classNames({
        container: !withSidebar,
    });

    const handleNextClick = (items) => {
        if (slickRef) {
            slickRef.slickNext();
            campaignLogoItem &&
                campaignLogoItem['@odata.count'] &&
                props.products.length !== campaignLogoItem['@odata.count'] && setPage && setPage(page + 1)
        }
    };

    const handlePrevClick = () => {
        if (slickRef) {
            slickRef.slickPrev();
        }
    };

    return (
        <div className={blockClasses} data-layout={layout}>
            <div className={containerClasses}>
                <BlockHeader
                    title={title.props.defaultMessage}
                    groups={groups}
                    arrows
                    onNext={handleNextClick}
                    onPrev={handlePrevClick}
                    slickRef={slickRef}
                    onGroupClick={onGroupClick}
                />
                <div className={`${title.props.defaultMessage === "Players" || title.props.defaultMessage === "Books" ? "d-flex justify-content-between" : ""}`}>                  <div className={`${columns && columns.length > 0 ? "block-products-carousel__slider" : "block-products-carousel__no-records"} ${title.props.defaultMessage === "Players" ? "customPlayer" : title.props.defaultMessage === "Books" ? "customBook" : ""}`} onClick={() => {
                    setCurrentSub(true)
                }
                }>
                    <div className="block-products-carousel__preloader" />
                    <StroykaSlick
                        ref={setSlickRef}
                        {...slickSettings[layout]}
                    >
                        {columns && columns.length > 0 ? columns : !loading && "No Records"}
                    </StroykaSlick>
                </div>
                    {
                        title.props.defaultMessage === "Books" &&
                        <div className={`block-products-carousel__slider customBook`} onClick={() => {
                            setCurrentSub(true)
                        }
                        }>
                            <StroykaSlick
                                ref={setSlickRef}
                                {...slickInvitationCardSettings[layout]}
                            >
                                {defaultInvitationCard}
                            </StroykaSlick>
                        </div>
                    }
                    {
                        (title.props.defaultMessage === "Players" || title.props.defaultMessage === "invitePlayer") && <div className={`block-products-carousel__slider ${title.props.defaultMessage === "Players" ? "invitePlayer" : ""}`} onClick={() => {
                            setCurrentSub(true)
                        }
                        }>
                            <div className=''>
                                <StroykaSlick
                                    ref={setSlickRef}
                                    {...slickInvitationCardSettings[layout]}
                                >
                                    {defaultInvitationCard}
                                </StroykaSlick>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
}
export default BlockProductsCarousel
BlockProductsCarousel.propTypes = {
    title: PropTypes.object.isRequired,
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    products: PropTypes.array,
    groups: PropTypes.array,
    withSidebar: PropTypes.bool,
    loading: PropTypes.bool,
    onGroupClick: PropTypes.func,
};

BlockProductsCarousel.defaultProps = {
    layout: 'grid-4',
    rows: 1,
    products: [],
    groups: [],
    withSidebar: false,
    loading: false,
    onGroupClick: undefined,
};