import React, { useMemo, useEffect, useState } from "react";
import {
    useTable,
    useFlexLayout,
    useResizeColumns,
    useSortBy
} from "react-table";
import { PlusIcon } from "svg";
import Cell from "./Cell";
import Header from "./Header";

const defaultColumn = {
    minWidth: 50,
    width: 150,
    maxWidth: 400,
    Cell: Cell,
    Header: Header,
    sortType: "alphanumericFalsyLast"
};

const Table = ({ columns, data, dispatch: dataDispatch, skipReset, maximumValue }) => {
    const [nextValue, setNextValue] = useState()
    const sortTypes = useMemo(
        () => ({
            alphanumericFalsyLast(rowA, rowB, columnId, desc) {
                if (!rowA.values[columnId] && !rowB.values[columnId]) {
                    return 0;
                }

                if (!rowA.values[columnId]) {
                    return desc ? -1 : 1;
                }

                if (!rowB.values[columnId]) {
                    return desc ? 1 : -1;
                }

                return isNaN(rowA.values[columnId])
                    ? rowA.values[columnId].localeCompare(rowB.values[columnId])
                    : rowA.values[columnId] - rowB.values[columnId];
            }
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            dataDispatch,
            maximumValue,
            autoResetSortBy: !skipReset,
            autoResetFilters: !skipReset,
            autoResetRowState: !skipReset,
            sortTypes
        },
        useFlexLayout,
        useResizeColumns,
        useSortBy
    );

    useEffect(() => {
        rows.map((row) => {
            setNextValue(row.cells[1].value)
        })
    }, [rows]);

    return (
        <>
            <div {...getTableProps()} className={"table noselect"}>
                <div>
                    {headerGroups.map((headerGroup, index) => (
                        <div key={`header_${index}`} {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map((column, index) => <React.Fragment key={`column_${index}`}>{column.render("Header")}</React.Fragment>)}
                        </div>
                    ))}
                </div>
                <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div key={`row_${i}`}  {...row.getRowProps()} className="tr">
                                {row.cells.map((cell, index) => (
                                    <div key={`cell_${index}`} {...cell.getCellProps()} className={`td ${cell.column.id === "minimumValue" && `data-input-disabled`}`}>
                                        {cell.render("Cell")}
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                    <div
                        className={`tr add-row ${Number(nextValue) === maximumValue && `add-row-disabled`}`}
                        onClick={() => {
                            Number(nextValue) < maximumValue && dataDispatch({ type: "add_row", payload: Number(nextValue) + 1, ...(Number(nextValue) + 1 === maximumValue && { maxValue: true }) })
                        }}
                    >
                        <span className="svg-icon svg-gray" style={{ marginRight: 4 }}>
                            <PlusIcon />
                        </span>
                        New
                    </div>
                </div>
            </div>
        </>
    );
}

export default Table;