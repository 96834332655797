import { createSlice } from "@reduxjs/toolkit";

const adventureHexSlice = createSlice({
  name: "adventureHex",
  initialState: {
    adventureHex: [],
    tiles: [],
  },
  reducers: {
    getAllProductModel: (state, action) => {
      state.productModel = action.payload
    },
    addTiles: (state, action) => {
      state.tiles = action.payload
    },
    hexAdded(state, action) {
      state.adventureHex = action.payload;
    },
  },
});

export default adventureHexSlice.reducer;
export const { getAllProductModel, addTiles, hexAdded } = adventureHexSlice.actions

