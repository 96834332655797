import React, { useEffect, useState } from "react";
import { withAsyncPaginate } from "react-select-async-paginate";
import { Col, FormGroup, InputGroupAddon, Label, Row } from "reactstrap";
import Cookies from "universal-cookie";
import Creatable from "react-select/creatable";
import { AsyncPaginate } from "react-select-async-paginate";

const AsyncPaginates = ({
	values,
	label,
	name,
	card,
	entity,
	filter = "",
	bookImage,
	setBookImage,
	deckImage,
	setDeckImage,
	setFieldValue,
	allValues,
	screenIndex,
	zoneIndex,
	contentIndex,
	tableIndex,
	index,
	fieldErrors,
	fieldTouched,
	setSelectedPlayer,
	handleBlur,
	featuredProducts,
	isOpenContentTypeModal,
	setIsOpenContentTypeModal,
	readonly = false,
	required = false,
	isLabel
}) => {
	const [currentPlayer, setCurrentPlayer] = useState([]);
	const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
	let errorMessage = ""
	const regEmail = /^[a-zA-Z0-2]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
	useEffect(() => {
		setSelectedPlayer && setSelectedPlayer(currentPlayer)
	}, [currentPlayer])
	const loadOptions = async (e, _, { page }) => {
		if (regEmail.test(e) || label === 'Content Type' || label === 'Section Type' || label === 'Add Table' || name === 'File' || label === "Avatar" || name === 'Deck' || name === 'Card') {
			const baseUrl = 'https://api.wogd.com/api'
			const urlString = `${baseUrl}/${entity}${name === 'File' ? '&' : '?'}${label === 'Email' ? `playerEmail=${e}` : ''}${label === 'Email' ? '&' : ''}$skip=${(page - 1) * 5}&$top=5&$count=true&$orderby=Id desc&$filter=${name === 'Deck' ? `contains(URL,'/system_swordmaster/deck') and ` : name === 'Card' ? `contains(URL,'/system_swordmaster/card') and ` : ''}${label === 'Email' ? 'avatar/' : ''}isDeleted ne true${filter.length ? `and ${filter}` : ''}`
			let updatedUrlString = ''
			if (values && e) updatedUrlString = urlString
			if (e || !values)
				updatedUrlString = urlString + ` and (contains(Name,'${e}'))`;
			if (!e) updatedUrlString = urlString;
			const cookies = new Cookies()
			const accessToken = cookies.get('accessToken')
			const response = await fetch(updatedUrlString, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			const responseJSON = await response.json();
			var transformedResponse = [];
			if (label === "Email") {
				responseJSON.forEach((val) => {
					val.avatar.isDeleted !== true &&
						!featuredProducts.data.some(
							(avatar) => avatar?.avatarRecord?.Id === val.avatar.id
						) &&
						transformedResponse.push({
							label: `${val.avatar.id} - ${val.avatar.name}`,
							value: val.avatar.id,
							playerEmail: val.user.email,
						})
				})
			} else {
				if (label === 'Content Type' || label === 'Section Type' || label === 'Add Table' || label === "Avatar") {
					responseJSON?.value.forEach((val) => {
						transformedResponse.push({
							label: `${val.Id} - ${val.Name}`,
							value: (label === 'Add Table' && tableIndex?.toString()?.length) ? val?.RandomEncounterObject && JSON.parse(val.RandomEncounterObject)?.[0].tableImage : val,
						})
					})
				} else if (name === 'Deck' || name === 'Card') {
					responseJSON?.value.forEach((val) => {
						transformedResponse.push({
							label: (<><img src={val?.URL}
								style={{ width: '50px' }}
								alt=""
							/>{' '}
								{val.Name}
							</>
							),
							value: val,
						})
					})
				} else {
					responseJSON?.value.forEach((val) => {
						transformedResponse.push({
							label: (<><img src={val?.CloudinaryAddress || val.url || val.Url}
								style={{ width: '50px' }}
								alt=""
							/>{' '}
								{val.Name ?? val.name}
							</>
							),
							value: val,
						})
					})
				}
			}
			errorMessage = ""
			return {
				options: transformedResponse,
				hasMore: page * 5 < responseJSON["@odata.count"],
				additional: {
					page: page + 1,
				},
			};
		} else {
			errorMessage = "Please Enter valid email"
			return {
				options: [],
			}
		}
	}

	const changeContentType = (event) => {
		if (allValues) {
			const contentValue = allValues.screen
			if (label === 'Content Type') {
				if (isOpenContentTypeModal) setIsOpenContentTypeModal({
					...isOpenContentTypeModal,
					mainContent: { contentType: event },
				})
			} else if (label === 'Section Type') {
				if (isOpenContentTypeModal) setIsOpenContentTypeModal({
					...isOpenContentTypeModal,
					screenStep: { sectionType: event },
				})
			} else if (label === 'Add Table') {
				if (tableIndex?.toString()?.length) contentValue[screenIndex].contents[contentIndex].innerContent.lists[tableIndex].tableImage = event;
				else contentValue[screenIndex].contents[
					contentIndex
				].tableFormat = event
			} else if (card) {
				contentValue[contentIndex].CardArt.url = event.value.URL
				contentValue[contentIndex].CardArt.name = event.value.Name
				contentValue[contentIndex].CardArt.id = event.value.Id
			} else {
				contentValue[screenIndex].contents[contentIndex].innerContent.images[index].url = event.value.CloudinaryAddress || event.value.Url
				contentValue[screenIndex].contents[contentIndex].innerContent.images[index].name = event.value.Name
			}
			setFieldValue('screen', contentValue)
		} else if (bookImage) {
			setBookImage({
				...bookImage,
				url: event.value.CloudinaryAddress || event.value.Url,
				name: event.value.Name,
				mediaId: event.value.Id,
			})
		} else if (label === "Avatar") {
			setFieldValue("Avatar", event.value)
		} else {
			setDeckImage({
				...deckImage,
				url: event.value.CloudinaryAddress || event.value.URL,
				name: event.value.Name || event.value.Name,
				mediaId: event.value.Id || event.value.Id,
			})
			setFieldValue && setFieldValue("Photo", {
				...deckImage,
				url: event.value.URL,
				name: event.value.Name,
				mediaId: event.value.Id,
			})
		}
	}

	return (
		<FormGroup className="mb-0">
			<Row className={label === 'Content Type' || label === 'Section Type' || label === 'Add Table' ? 'mb-3' : ''}>
				{label === 'Email' && (
					<Col md="3">
						<InputGroupAddon addonType="prepend" className="campaignLabel">
							{label}
						</InputGroupAddon>
					</Col>
				)}
				<Col md={label === 'Email' ? '9' : '12'}>
					{label === 'Email' ? (
						<CreatableAsyncPaginate
							className={`lazySelect ${readonly && 'disabled'}`}
							value={currentPlayer}
							loadOptions={loadOptions}
							getOptionValue={(option) => option.value}
							placeholder="Search by email"
							isSearchable={label === 'Email'}
							onBlur={handleBlur}
							onChange={(e) => {
								setFieldValue(name, e)
								setCurrentPlayer(e)
							}}
							isDisabled={readonly}
							additional={{
								page: 1,
							}}
							isMulti
						/>
					) : (
						<FormGroup className="mb-0">
							{isLabel && <Label for={name}>
								{label}
								{required && <font color="red"> *</font>}
							</Label>}
							<AsyncPaginate
								className={`lazySelect ${readonly && 'disabled'} ${card && 'custom-card-art'}`}
								style={{ backgroundColor: 'transparent', color: 'white' }}
								value={values}
								maxMenuHeight={card && !deckImage ? 300 : 300}
								loadOptions={loadOptions}
								getOptionValue={(option) => option.value}
								placeholder={
									label === 'Choose from Existing'
										? label
										: `Select ${label}`
								}
								isSearchable={true}
								onBlur={handleBlur}
								isDisabled={readonly}
								onChange={changeContentType}
								additional={{
									page: 1,
								}}
							/>
						</FormGroup>
					)}
					{errorMessage ? (<font color="red">{errorMessage}</font>) :
						(fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>)}
				</Col>
			</Row>
		</FormGroup>
	)
}

export default AsyncPaginates
