import React, { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Formik } from 'formik';
import _, { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import * as Yup from "yup";
import { FormattedMessage } from 'react-intl';
import AsyncPaginates from 'global/SearchResult';
import { errorToast, successToast } from 'common/toast';
import BlockProductsCarousel from 'common/BlockProductsCarousel';
import CustomModal from 'common/CustomModal';
import DeactivateConfirmationModal from 'common/DeactivateConfirmationModal';
import { GAME_MASTER_ROLE } from 'common/roleFile';
import { useAddMutation, useLazyGetRecordsQuery, useUpdateMutation } from 'services/gamecodexApi';
import { AVATAR_CAMPAIGN_TAG, AVATAR_CELL, CAMPAIGN_TAG, PLAYER_AVATAR_DATA } from 'common/tagFile';
import plusIcon from './../../../assets/icons8-plus-50.png'
import AvatarForm from '../.././adventure-hex/player/avatarForm'


const PlayerList = (props) => {
    const { user, isAuthenticated } = useAuth0();
    const currentUser = user
    const [currentTabId, setCurrentTabId] = useState(0);
    const [featuredProducts, setFeaturedProduct] = useState({
        data: [],
        handleTabChange: function (tab) { setCurrentTabId(tab.id) },
        isLoading: true,
    });
    const [dataContainer, setDataContainer] = useState([]);
    let currentPlayer = JSON.parse(localStorage.getItem('playerRecord'));
    const [invitePlayer] = useAddMutation()
    const [columnFilter, setColumnFilter] = useState({});
    const params = useParams()
    const campaignId = Number(params?.campaignId);
    const [deleteAvatar, { isLoading: isDeactivating }] = useUpdateMutation()
    const sort = {
        sortDirection: 'desc',
        accessor: 'Id',
    };
    const [selectedPlayer, setSelectedPlayer] = useState([]);
    const [isInvitedAvatarListOpen, setIsInvitedAvatarListOpen] = useState(false);
    const [currentAvatarRecord, setCurrentAvatarRecord] = useState();
    const [currentOperationMode, setCurrentOperationMode] = useState({ status: null, currentOperationData: { title: "", primaryButton: { title: "", isLoading: false }, secondaryButton: { title: "", isLoading: false } } });
    const [isPlayerGameMaster, setIsPlayerGameMaster] = useState(false);

    useEffect(() => {
        if (props.userRoles) {
            props.userRoles.forEach((val) => {
                if (val.Roles.Name.toLowerCase().trim().replace(" ", "") === GAME_MASTER_ROLE.toLowerCase().trim().replace(" ", "") && val.CampaignId === campaignId) {
                    setIsPlayerGameMaster(true)
                }
            })
        }
    }, [props.userRoles, campaignId])

    const [
        getAvatarRecords,
        {
            data: avatarList,
            isFetching: isAvatarLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        !isEmpty(columnFilter) && getAvatarRecords({
            columns: [],
            entity: "AvatarCampaign",
            tag: AVATAR_CAMPAIGN_TAG,
            page: 1,
            sort: sort,
            columnFilter: columnFilter,
            globalFilter: [],
            expandFilter: 'Avatar/IsDeleted ne true',
            expand: "Avatar,Campaign"
        })
        // eslint-disable-next-line
    }, [columnFilter])

    useEffect(() => {
        campaignId && getCampaignRecord({
            columns: [],
            entity: "Campaign",
            tag: CAMPAIGN_TAG,
            page: 1,
            sort: sort,
            columnFilter: { Id: campaignId },
            globalFilter: [],
            expand: ""
        })
        // eslint-disable-next-line
    }, [campaignId])

    const [
        getAllAvatarData,
        {
            data: avatarRecords,
            isLoading: isAvatarRecordsLoading
        }
    ] = useLazyGetRecordsQuery();
    const [
        getCampaignRecord,
        {
            data: campaignList,
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        if (currentTabId && !isNaN(campaignId)) {
            setColumnFilter({ campaignId: campaignId, statusId: currentTabId })
        } else {
            !isNaN(campaignId) && setColumnFilter({ campaignId: campaignId })
        }
    }, [campaignId, currentTabId])

    const avatarInvitationSchema = Yup.object().shape({
        Component: Yup.mixed().required("Please select component."),
    });
    const avatarInvitationInitialValues = { Component: "" }
    useEffect(() => {
        setFeaturedProduct({ ...featuredProducts, isLoading: isAvatarLoading });
        if (avatarList && avatarList.value) {
            let avatarRecords = []
            let invitationCard = []
            let avatarTabs = [
                { id: 0, name: 'All', categorySlug: 'All', current: currentTabId === 0 },
                { id: 1, name: 'Active', categorySlug: 'Active', current: currentTabId === 1 },
                { id: 4, name: 'Inactive', categorySlug: 'Inactive', current: currentTabId === 4 }
            ];
            avatarList.value.forEach((avatar) => {
                if ((avatar.StatusId === 2 || avatar.StatusId === 4 || avatar.StatusId === 5) && (campaignList?.value?.length)) {
                    return avatarRecords.push({
                        name: avatar.Avatar.Name, data: avatar, images: "https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/avatars/20240610114043141_Baris_lhbnlb.png", statusId: avatar.StatusId, Id: avatar.Id, buttonValue: "View", ...(avatar?.Avatar?.UserId === currentPlayer?.UserId) ? { redirect: "/map" } : { redirect: "/adventurehex" }, avatarRecord: avatar.Avatar, isCampaignMaster: isPlayerGameMaster, ...(props?.userRoles && isPlayerGameMaster && {
                            secondButtonValue: avatar.StatusId === 4 ? "Activate" : "Remove"
                        }), isRequirePlayButton: avatar?.Avatar?.UserId === currentPlayer?.UserId && "Play"
                    })
                }
            })
            if (avatarRecords.length < 8 && props.userRoles && isPlayerGameMaster && campaignList?.value?.length) {
                avatarRecords.push({ secondButtonValue: "Invite", images: plusIcon, isCampaignMaster: isPlayerGameMaster }, { secondButtonValue: "JOIN", images: plusIcon, isCampaignMaster: isPlayerGameMaster })
            } else if (props.userRoles && isPlayerGameMaster && campaignList?.value?.length && currentPlayer.Id === campaignList?.value[0]?.PlayerId) {
                invitationCard.push({ default: true, secondButtonValue: "Invite", images: plusIcon, isCampaignMaster: isPlayerGameMaster }, { default: true, secondButtonValue: "JOIN", images: plusIcon, isCampaignMaster: isPlayerGameMaster })
            }
            setFeaturedProduct({ ...featuredProducts, isLoading: isAvatarLoading, data: avatarRecords, invitation: invitationCard, tabs: avatarTabs });
        }
        // eslint-disable-next-line
    }, [avatarList, campaignId, user, currentTabId, campaignList, isAvatarLoading])
    useEffect(() => {
        switch (currentOperationMode.status) {
            case "CampaignRequest":
                let requestedData = []
                avatarList && avatarList?.value && _.uniqBy(avatarList?.value, 'AvatarId')?.map((value, index) => {
                    if (value.StatusId === 6 && currentPlayer.Id === value.Campaign.PlayerId) {
                        requestedData.push(value)
                    }
                })
                setDataContainer(requestedData)
                break;
            case "Join":
                let joinData = []
                avatarList && avatarList?.value && avatarList?.value?.map((value, index) => {
                    if (value.StatusId === 7 && currentPlayer?.UserId === value?.Avatar?.UserId) {
                        joinData.push(value)
                    }
                })
                setDataContainer(joinData)
                break;
            case "RequestingToJoin":
                let avatarDataTop = []
                avatarRecords && avatarRecords?.value?.map((value, index) => {
                    if (currentPlayer?.UserId === value?.Avatar?.UserId && !value?.IsDeleted && !value?.Avatar?.IsDeleted && !featuredProducts.data.some((avatar) => avatar?.avatarRecord?.Id === value.AvatarId)) {
                        return avatarDataTop.push({ Avatar: { Name: value.Avatar.Name, Id: value.AvatarId } })
                    }
                    else return null
                })
                setDataContainer(avatarDataTop)
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [currentOperationMode, avatarRecords])
    const avatarRecordStatus = () => {
        deleteAvatar({ entity: 'AvatarCampaign', data: { ...currentAvatarRecord, StatusId: currentOperationMode.status === "Activate" ? 2 : 4 }, tag: AVATAR_CAMPAIGN_TAG }).then(() => {
            successToast(`Player ${currentOperationMode.status} successfully.`)
            setCurrentOperationMode({ status: null })
        }).catch((error) => {
            errorToast(
                error.data
                    ? error.data.title
                    : error.error
                        ? error.error
                        : "Error occurred while Deactivating"
            );
        });
    }

    const onSubmitInvitation = (values) => {
        const invitedAvatar = [];
        const selectedPlayerList = [];
        const selectedEmailList = [];

        values.Component.forEach((val) => {
            val.__isNew__ ? selectedPlayerList.push() : selectedPlayerList.push(val.value)
            val.__isNew__ ? selectedEmailList.push({ email: val.value, isNew: val.__isNew__ }) : selectedEmailList.push({ email: val.playerEmail, isNew: false })
        })
        invitedAvatar.push({ userEmail: currentUser?.email, domain: process.env.REACT_APP_LOGIN_HOST, campaignId: campaignId, avatarIdList: selectedPlayerList, emailLists: selectedEmailList })
        try {
            values.Component !== null && !isEmpty(values.Component) &&
                invitePlayer({
                    entity: 'CampaignInvitation', data:
                        invitedAvatar[0],
                }).then(() => {
                    setCurrentOperationMode({ status: null })
                    successToast(<FormattedMessage id="successToast.PlayerList.InvitationSent" defaultMessage="Invitation sent successfully." />);
                })
        } catch (error) {
            errorToast(<FormattedMessage id="errorToast.EditAvatar.AddingError" defaultMessage="Error while adding avatar-attribute." />);
        }
    }
    const currentOperationHandler = (mode) => {
        switch (mode) {
            case "Join":
                let joinData = { title: "Join With Invitation.", primaryButton: { title: "Reject", isLoading: false }, secondaryButton: { title: "Join", isLoading: false } }
                setCurrentOperationMode({ status: mode, currentOperationData: joinData })
                break;
            case "RequestingToJoin":
                getAllAvatarData({
                    columns: [],
                    entity: "PlayerAvatar",
                    tag: PLAYER_AVATAR_DATA,
                    columnFilter: [],
                    page: 1,
                    sort: sort,
                    expand: "Avatar"
                })
                let requestingData = { title: "Send request to join campaign", button: { title: "Cancel", isLoading: false }, secondaryButton: { title: "Send Request", isLoading: false }, createButton: { title: "Create", isLoading: false } }
                setCurrentOperationMode({ status: mode, currentOperationData: requestingData })
                break;
            case "CampaignRequest":
                let campaignRequest = { title: "All joining request's.", primaryButton: { title: "Reject", isLoading: false }, secondaryButton: { title: "Approve", isLoading: false } }
                setCurrentOperationMode({ status: mode, currentOperationData: campaignRequest })
                break;
            case "Deactivate" || "Activate":
                setCurrentOperationMode({ status: mode })
                break;
            case "Invite":
                let inviteData = { title: "Search using Email To Invite", button: { title: "cancel", isLoading: false }, secondaryButton: { title: "send invitation", isLoading: false } }
                setCurrentOperationMode({ status: mode, currentOperationData: inviteData })
                break;
            case "JOIN":
                setCurrentOperationMode({ status: mode })
                break;
            default:
                setCurrentOperationMode({ status: mode })
                break;
        }
        setIsInvitedAvatarListOpen(!isInvitedAvatarListOpen)
        setSelectedPlayer([])
    }
    const selectedAvatarDesign = (value) => {
        if (selectedPlayer.find((val) => val.avatarId === value.Avatar.Id)) {
            document.getElementById(value.Avatar.Id).style.opacity = "1"
            var data = selectedPlayer.filter((player) => player.avatarId !== value.Avatar.Id)
            setSelectedPlayer(data)
        } else {
            document.getElementById(value.Avatar.Id).style.opacity = "0.5";
            setSelectedPlayer([...selectedPlayer, { avatarId: value?.Avatar?.Id, avatarCampaignId: value?.Id, campaignId: value?.Campaign?.Id }])
        }
    }
    const submitInvitation = (status) => {
        switch (currentOperationMode.status) {
            case "RequestingToJoin":
                let requestedPlayer = [];
                selectedPlayer.forEach((player) => {
                    requestedPlayer.push(player.avatarId)
                })
                invitePlayer({ entity: 'RequestToJoinCampaign', query: { campaignId: campaignId, avatarCampaignTypeId: 1, userEmail: currentUser?.email }, data: requestedPlayer, tag: AVATAR_CAMPAIGN_TAG }).unwrap().then(() => {
                    currentOperationHandler();
                }).catch(rejected => {
                    successToast(<FormattedMessage id="successToast.PlayerList.InvitationSent" defaultMessage="Request to join campaign sent successfully." />)
                    setCurrentOperationMode({ status: null })
                })
                break
            default:
                var updatePlayerStatus = [];
                if (selectedPlayer.length) {
                    selectedPlayer.forEach((player) => {
                        updatePlayerStatus.push({ AvatarId: player.avatarId, StatusId: status, Id: player.avatarCampaignId, AvatarCampaignTypeId: 1, CampaignId: player.campaignId })
                    })
                    deleteAvatar({ entity: 'AvatarCampaign/UpdateAvatarCampaign', data: updatePlayerStatus, tag: AVATAR_CAMPAIGN_TAG }).unwrap().then(() => {
                        currentOperationHandler();
                        successToast(<FormattedMessage id="successToast.PlayerList.AvatatJoinCapaign" defaultMessage="Avatar joined campaign successfully." />)
                        setCurrentOperationMode({ status: null })
                    }).catch(rejected => errorToast(rejected));
                }
                break;
        }
    }

    const handleRemoveClick = (product) => {
        if (product.secondButtonValue === "Remove") {
            const { StatusId, ...data } = product.data
            setCurrentAvatarRecord(data)
            currentOperationHandler("Deactivate");
        } else if (product.secondButtonValue === "Invite") {
            setCurrentAvatarRecord({ Id: product.Id, Name: product.name })
            currentOperationHandler("Invite");
        } else if (product.secondButtonValue === "JOIN") {
            currentOperationHandler("JOIN");
        }
        else {
            const { StatusId, ...data } = product.data
            setCurrentAvatarRecord(data)
            currentOperationHandler("Activate");
        }
    }

    const onBlur = () => {
        setCurrentOperationMode({ status: null })
    }

    const blockClasses = classNames('block block-products-carousel', {
        'block-products-carousel--loading': true,
        'block-products-carousel--has-items': false,
    });

    return (
        <div> 
            {
                isAuthenticated && (avatarList?.value?.some((val) => val.StatusId === 7 && currentPlayer?.UserId === val?.Avatar?.UserId) ?
                    <Button className="button custom joinCampaignbutton" style={{ position: "fixed" }} onClick={() => currentOperationHandler("Join")}>
                        <FormattedMessage id="playerList.JoinCampaign" defaultMessage="Join Campaign" />
                    </Button>
                    :
                    <Button className="button custom joinCampaignbutton" style={{ position: "fixed" }} onLoad={() => currentOperationHandler("RequestingToJoin")} onClick={() => currentOperationHandler("RequestingToJoin")}>
                        <FormattedMessage id="playerList.RequestToJoin" defaultMessage="Request To Join" />
                    </Button>)
            }
            {
                isPlayerGameMaster && _.uniqBy(avatarList?.value, 'AvatarId').some((val) => val.StatusId === 6) &&
                <Button className="button custom requestButton" style={{ position: "fixed" }} onClick={() => currentOperationHandler("CampaignRequest")}>
                    <FormattedMessage id="playerList.CampaignRequest" defaultMessage="Campaign Request's" />
                </Button>
            }
            {
                currentOperationMode.status && (currentOperationMode.status === "Join" || currentOperationMode.status === "RequestingToJoin" || currentOperationMode.status === "CampaignRequest" || currentOperationMode.status === "CreateAvatar") &&
                <CustomModal
                    setCurrentOperationMode={setCurrentOperationMode}
                    submitForm={submitInvitation}
                    selectedPlayer={selectedPlayer}
                    dataContainer={dataContainer}
                    currentOperationMode={currentOperationMode}
                >
                    {isAvatarRecordsLoading ? <div className='pdf-block'>
                        <div style={{ margin: "20% 0 0 0" }}>
                            <div className={blockClasses}>
                                <div className="block-products-carousel__preloader">
                                </div>
                            </div>
                        </div>
                    </div> :
                        (<>
                            <small className='text-danger d-flex justify-content-center mb-3'>
                                {dataContainer?.length && !selectedPlayer.length ? "Please select at least one Avatar to join" : ""}
                            </small>
                            <Row id="images" className="d-flex flex-wrap justify-content-center">
                                {isAvatarRecordsLoading ?
                                    "" : (dataContainer && dataContainer?.length ? dataContainer?.map((value, index) => {
                                        return (
                                            <Col md="4" lg="2" className="d-flex flex-column" key={value.Avatar.Id}>
                                                <img onClick={() => { selectedAvatarDesign(value, index) }} id={value.Avatar.Id} className="hexDisplay" src={`https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/avatars/20240610114043141_Baris_lhbnlb.png`} alt={`avatar-img-${index}`} />
                                                <span className="text-center">{value.Avatar.Name}</span>
                                            </Col>
                                        )
                                    }) :
                                        <small className='d-flex justify-content-center mt-3'>
                                            <b><FormattedMessage id="playerList.NoAvatar" defaultMessage="No Avatars exist in your profile that can join this campaign" /> </b>
                                        </small>)
                                }
                            </Row>
                        </>)}
                </CustomModal>
            }
            {
                useMemo(() => (
                    <BlockProductsCarousel
                        title={<FormattedMessage id="players.Carousel.Title.Players" defaultMessage="Players" />}
                        layout="grid-5"
                        rows={1}
                        products={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.data : []}
                        loading={featuredProducts.isLoading}
                        groups={featuredProducts.tabs}
                        onGroupClick={featuredProducts.handleTabChange}
                        setCurrentAvatarRecord={setCurrentAvatarRecord}
                        currentOperationHandler={currentOperationHandler}
                        userRole={isPlayerGameMaster}
                        invitation={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.invitation : []}
                        campaignId={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.data : []}
                        actionHandler={handleRemoveClick}
                    />
                ), [featuredProducts])
            }
            {
                currentOperationMode?.status && (currentOperationMode.status === "Deactivate" || currentOperationMode.status === "Activate") && <DeactivateConfirmationModal
                    setCurrentOperationMode={setCurrentOperationMode}
                    avatarRecordStatus={avatarRecordStatus}
                    entityName={currentAvatarRecord.Avatar.Name}
                    isDeactivating={isDeactivating}
                    currentOperationMode={currentOperationMode?.status}
                    onBlur={onBlur}
                />
            }
            {
                currentOperationMode.status && currentOperationMode.status === "Invite" &&
                <Formik
                    initialValues={avatarInvitationInitialValues}
                    validationSchema={avatarInvitationSchema}
                    onSubmit={onSubmitInvitation}
                >
                    {(formik) => {
                        const {
                            errors,
                            touched,
                            values,
                            handleBlur,
                            setFieldValue,
                            submitForm
                        } = formik;

                        return (
                            <Form>
                                <CustomModal
                                    setCurrentOperationMode={setCurrentOperationMode}
                                    submitForm={submitForm}
                                    selectedPlayer={selectedPlayer}
                                    currentOperationMode={currentOperationMode}
                                >
                                    <AsyncPaginates
                                        values={values.Component}
                                        label="Email"
                                        entity="CampaignInvitationList"
                                        name="Component"
                                        setFieldValue={setFieldValue}
                                        fieldErrors={errors.Component}
                                        fieldTouched={touched.Component}
                                        handleBlur={handleBlur}
                                        required
                                        setSelectedPlayer={setSelectedPlayer}
                                        featuredProducts={featuredProducts}
                                    />
                                </CustomModal>
                            </Form>
                        );
                    }}
                </Formik>
            }
            {currentOperationMode.status && currentOperationMode.status === "CreateAvatar" &&
                <Modal className='justify-content-center' show={true} onHide={() => setCurrentOperationMode({ status: null })} centered={true} size="md" style={{ border: "1px solid black" }}>
                    <Modal.Header closeButton>
                        <h4><FormattedMessage id="playerList.NewAvatar" defaultMessage="New Avatar" /></h4>
                    </Modal.Header>
                    <AvatarForm currentOperationMode={currentOperationMode} playerInfo={currentPlayer} setModal={setCurrentOperationMode} tags={[AVATAR_CELL, PLAYER_AVATAR_DATA, AVATAR_CAMPAIGN_TAG]} />
                </Modal>
            }
            {
                currentOperationMode.status && currentOperationMode.status === "JOIN" && <Modal className='justify-content-center' show={true} onHide={() => setCurrentOperationMode({ status: null })} centered={true} size="md">
                    <Modal.Header closeButton>
                        <h4><FormattedMessage id="playerList.AddAvatar" defaultMessage="Add Avatar" /></h4>
                    </Modal.Header>
                    <AvatarForm playerInfo={currentPlayer} campaignId={campaignId} setModal={setCurrentOperationMode} tags={AVATAR_CAMPAIGN_TAG} />
                </Modal>
            }
        </div >
    )
}

export default PlayerList