import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Stage, Layer } from "react-konva";
import HexTerrain from "./HexTerrain";
import HexTile from "./HexTile";
import Piece from "./Piece";
import Sidebar from "react-sidebar";
import { getCoords } from "./calc";

import { useLazyGetRecordsQuery, useUpdateMutation, useAddMutation, useLazyGetRecordsByIdQuery } from 'services/gamecodexApi';
import { hexAdded } from "./adventureHexSlice";
import EditHexTabs from './editHexTabs';
import "./MapViewer.scss";
import { CELL_TAG, AVATAR_CELL, CELL_AROUND_TAG } from "common/tagFile";
import { errorToast } from "common/toast";
import { isEmpty, sortBy } from "lodash";
import _ from "lodash";
import EditAvatarData from "./editAvatarData";
import { Spinner } from "reactstrap";
import { getMyAvatarCell } from "./admin/avatar/avatarSlice";
import { getAllMaphexCell } from "./admin/maphexCell/maphexCellSlice";
import { useLocation } from "react-router-dom";

const MapViewer = (props) => {
  let minX, maxX, minY, maxY;
  let minXId, maxXId, minYId, maxYId;
  let newCenterCell;
  const BSON = require('bson');
  const [maxValuesId, setMaxValuesId] = useState({ minXId: 0, minYId: 0, maxXId: 0, maxYId: 0 });
  const [getNewScreens, setGetNewScreens] = useState(false);
  const [newScreenData, setNewScreenData] = useState({});
  const [hexDirectionX, setHexDirectionX] = useState();
  const [getFirstDataWithFunCall, setGetFirstDataWithFunCall] = useState(false);
  const [sortedHexData, setSortedHexData] = useState();
  const [hexDirectionY, setHexDirectionY] = useState();
  const [allData, setAllData] = useState([]);
  const [position, setPosition] = useState(0);
  const [uniqueCellCall, setUniqueCellCall] = useState(true);
  const dispatch = useDispatch();
  const mapData = useSelector((state) => state.adventureHexs.adventureHex);
  //------------ Configuration Settings --------------
  const viewableRange = 25;
  const [showCoordinates] = useState(false);
  const [recallFunQuery, setRecallFunQuery] = useState(0);
  const location = useLocation();
  //------------ toggles the selected player --------------
  const [selected, setSelectedState] = useState(false);
  const [newMapData, setNewMapData] = useState([]);
  const [mapCell, setMapCell] = useState();
  const toggleSelected = () => {
    setSelectedState(!selected);
  };
  //------------ monitors the player location and movement ---------------------
  const [playerCell, setPlayerCell] = useState({ X: 0, Y: -3, Z: 0, T: 0 });
  const [cellList, setCellList] = useState();
  const [currentOperationMode, setCurrentOperationMode] = useState(null)
  //------------- remembers the players original location for map refreshing --------------------
  const [originalPlayerCell, setOriginalPlayerCell] = useState(playerCell);
  //------------- toggles the avatar sidebar --------------------
  const [sidebarIsOpen, setSidebarIsOpenState] = useState(false);
  const toggleSidebar = () => {
    setSidebarIsOpenState(!sidebarIsOpen);
  };
  //------------- toggles the hex tile editor sidebar --------------------
  const [terrainSidebarIsOpen, setTerrainSidebarIsOpen] = useState(false);
  const [selectedHex, setSelectedHex] = useState({});
  const [selectedHexIndex, setSelectedHexIndex] = useState(null);

  const [xCenter, setXCenter] = useState(window.innerWidth / 2);
  const [yCenter, setYCenter] = useState(window.innerHeight / 2);
  const coord = getCoords(playerCell);
  const [avatarId, setAvatarId] = useState(location.state?.avatarRecord.Id);
  const [avatarRecord, setAvatarRecord] = useState(location?.state?.avatarRecord);
  const [avatarCellId, setAvatarCellId] = useState(0);
  const [sort, setSort] = useState({
    sortDirection: 'none',
    accessor: 'some_accessor',
  })
  //paint mode change
  const [newSelectedTile, setNewSelectedTile] = useState({});
  const [selectedCellIds, setSelectedCellIds] = useState({});
  const [updateTileData] = useUpdateMutation();
  const [updateAvatarData] = useUpdateMutation();
  //scale data
  const [playerCellData, setPlayerCellData] = useState();
  const [columnFilter, setColumnFilter] = useState({});
  const [parentFilter, setParentFilter] = useState({});
  const [addCells] = useAddMutation();
  const [newScaleId, setNewScaleId] = useState(0);
  const [spinner, setSpinner] = useState(true);
  const [uniqueTileRecords, setUniqueTileRecords] = useState([]);
  const [uniqueTiles, setUniqueTiles] = useState([]);
  const [paintedTiles, setPaintedTiles] = useState(null);
  const [stage, setStage] = useState()

  const [
    getCellAroundRecord,
    {
      data: hexList,
      error: isHexListError,
      isLoading: isHexListLoading,
      isFetching: isCellAroundFetching
    }
  ] = useLazyGetRecordsQuery();

  const [
    getCellBlackTilesAroundRecord,
    {
      data: blackTilesList,
      isFetching: isBlackCellAroundFetching
    }

  ] = useLazyGetRecordsQuery();

  const [
    getAvatarCellRecord,
    {
      data: avatarCell,
      error: isAvatarCellError,
      isLoading: isAvatarCellLoading,
      isFetching: isAvatarCellLoadingFetching
    }
  ] = useLazyGetRecordsQuery();

  const [
    getCellRecord,
    {
      data: cellListData,
      error: cellError,
      isLoading: getCellLoading,
    }
  ] = useLazyGetRecordsByIdQuery();

  const movePlayerCell = e => {
    const { X, Y, Z, T } = e.target.attrs.cell;
    setPlayerCell({ X: X, Y: Y, Z: Z, T: T });
    setPlayerCellData(e.target.attrs.cell);
    setMapCell(e.target.attrs.cell.Id)
    toggleSelected();
    setStage({ stageX: stage.stageX, stageY: stage.stageY })
  };

  useEffect(() => {
    setSelectedCellIds({});
  }, [newSelectedTile]);

  useEffect(() => {
    const windowsize = () => {
      setXCenter(window.innerWidth / 2);
      setYCenter(window.innerHeight / 2);
    }
    window.addEventListener('resize', windowsize);
    return () => { window.removeEventListener('resize', windowsize); }
  }, [])
  const toggleTerrainSidebar = (cell, index) => {
    if (!terrainSidebarIsOpen) {
      setSelectedHex(cell)
      setSelectedHexIndex(index);
    }
    else {
      setNewSelectedTile({});
      setSelectedHexIndex(null);
    }
    setTerrainSidebarIsOpen(!terrainSidebarIsOpen);
  };

  useEffect(() => {
    setStage({
      stageX: coord.X,
      stageY: coord.Y
    })
  }, [xCenter, yCenter, coord.X, coord.Y]);

  useEffect(() => {
    if (!isEmpty(selectedCellIds) && !isEmpty(newSelectedTile)) {
      let newIds = newSelectedTile.map((item) => item.Id);
      let item;
      let index = newIds.indexOf(selectedCellIds.TileId)
      if (index !== -1) {
        if (index + 1 === newIds.length) {
          item = newSelectedTile[0];
        }
        else {
          item = newSelectedTile[index + 1]
        }
      }
      else {
        item = newSelectedTile[Math.floor(Math.random() * newIds.length)];
      }
      let paintedData = visibleMapData.map((value) => {
        if (value.Id === selectedCellIds.Id) {
          return {
            ...selectedCellIds,
            TileId: item.Id,
            Tile: {
              ...item
            }
          }
        }
        else {
          return value;
        }
      });
      setPaintedTiles(sortBy(paintedData, 'Y'))
      setUniqueTileRecords(sortBy(paintedData, 'Y'))
      setSortedHexData(sortBy(paintedData, 'Y'))
      localStorage.setItem("hexList", JSON.stringify(paintedData))
      updateTileData({ entity: 'Cell', data: { ...selectedCellIds, Id: selectedCellIds.Id, TileId: item.Id }, tag: "" }).unwrap().then(() => {
        }).catch(rejected => errorToast(rejected));
    }
  }, [selectedCellIds]);

  const uniqueTileCollection = (sourceArray) => {
    let compareStr = []
    const result = sourceArray.filter((value) => {
      const str = `${value.X},${value.Y},${value.Z},${value.T}`
      if (!compareStr.includes(str)) {
        compareStr.push(str)
        return value
      }
    })
    return result
  }

  useEffect(() => {
    if (avatarId !== 0) {
      getAvatarCellRecord({
        columns: [],
        entity: "AvatarCell",
        tag: AVATAR_CELL,
        page: 1,
        sort: {
          sortDirection: 'desc',
          accessor: 'Id',
        },
        columnFilter: { AvatarId: avatarId, IsVisited: true },
        globalFilter: [],
        expand: "Cell($expand=Parent)"
      })
    }
  }, [avatarId])

  useEffect(() => {
    avatarCell && avatarCell?.value[0]?.Cell?.ParentId && setParentFilter({ ParentId: avatarCell?.value[0]?.Cell?.ParentId })
  }, [avatarCell]);

  useEffect(() => {
    if (!isAvatarCellLoading && !isAvatarCellError && avatarCell) {
      dispatch(getMyAvatarCell(avatarCell.value));
    }
  }, [isAvatarCellLoading, isAvatarCellError]);

  useEffect(() => {
    if (avatarCell && avatarCell.value.length > 0) {
      !currentOperationMode && setAvatarCellId(avatarCell.value[0].CellId);
      setPlayerCellData(avatarCell.value[0].Cell);
    }
  }, [avatarCell]);

  useEffect(() => {
    if (avatarId !== 0 && avatarCell && !isAvatarCellLoadingFetching && !isAvatarCellLoading) {
      addCells({ entity: 'AvatarCell', data: { CellId: mapCell, AvatarId: avatarId, IsVisited: true } }).unwrap();
    }
    if (avatarId !== 0 && avatarCell && !isAvatarCellLoadingFetching && !isAvatarCellLoading) {
      updateAvatarData({ entity: 'AvatarCell', data: { Id: avatarCell?.value[0]?.Id, CellId: avatarCell?.value[0].CellId, AvatarId: avatarId, IsVisited: currentOperationMode ? true : false }, tag: AVATAR_CELL }).unwrap().then((res) => {
        currentOperationMode && setCurrentOperationMode(null)
      }).catch(rejected => errorToast(rejected));
    }
  }, [mapCell]);

  useEffect(() => {
    setAllData([...allData, hexList && hexList?.value])
  }, [mapData, sortedHexData, hexList]);

  useEffect(() => {
    if (hexList && hexList?.value && hexList?.value?.length === 1) {
      getCellBlackTilesAroundRecord({
        columns: [],
        entity: `CellGenerateMap/${avatarCellId}`,
        tag: CELL_AROUND_TAG,
        page: 1,
        sort: sort,
        columnFilter: parentFilter,
        globalFilter: [],
        expand: "Tile"
      })
      setUniqueCellCall(true)
    }
  }, [hexList, currentOperationMode]);

  useEffect(() => {
    if ((avatarCellId !== 0 && uniqueCellCall) || (newScaleId !== 0 && avatarCellId) || recallFunQuery === 20) {
      getCellAroundRecord({
        columns: [],
        entity: `CellAround/${avatarCellId}`,
        tag: CELL_AROUND_TAG,
        page: 1,
        sort: sort,
        columnFilter: parentFilter,
        globalFilter: [],
        expand: "Tile,Scale"
      })
      setGetFirstDataWithFunCall(true)
      setUniqueCellCall(false);
      setHexDirectionX(avatarCell?.value[0]?.Cell.X);
      setHexDirectionY(avatarCell?.value[0]?.Cell.Y);
      setRecallFunQuery(0)
    }
    if (getFirstDataWithFunCall) {
      setGetFirstDataWithFunCall(false)
    }
  }, [avatarCellId, isBlackCellAroundFetching, uniqueCellCall, recallFunQuery, avatarCell])

  useEffect(() => {
    blackTilesList && hexList?.value?.length && avatarCellId && getCellAroundRecord({
      columns: [],
      entity: `CellAround/${avatarCellId}`,
      tag: CELL_AROUND_TAG,
      page: 1,
      sort: sort,
      columnFilter: parentFilter,
      globalFilter: [],
      expand: "Tile,Scale"
    })
  }, [blackTilesList])

  useEffect(() => {
    if (window.location.pathname === '/map' && document.getElementsByClassName('site_header_image').length)
      document.getElementsByClassName('site_header_image')[0].style.display = 'block';
  }, [])

  const getNewScreen = (Id) => {
    // getCellAroundRecord({
    //   columns: [],
    //   entity: `CellAround/${Id}`,
    //   tag: CELL_TAG,
    //   page: 1,
    //   sort: sort,
    //   columnFilter: parentFilter,
    //   globalFilter: [],
    //   expand: "Tile"
    // })
  }
  useEffect(() => {
    hexList && hexList.value.length && setUniqueTileRecords(uniqueTileCollection(sortBy(hexList.value, 'Y')))
  }, [hexList, getNewScreens, newScaleId])

  useEffect(() => {
    hexList && setSortedHexData(sortBy(uniqueTileRecords, 'Y'));

  }, [hexList, getNewScreens, uniqueTileRecords])

  useEffect(() => {
    setUniqueTiles([...uniqueTiles, ...uniqueTileRecords])
    const tilesUniqueByKey = [...new Map(uniqueTiles.map(item => [item.Id, item])).values()];
    const uniqueTilesArray = tilesUniqueByKey.filter((val) => val.ParentId === hexList?.value[0]?.ParentId && val.ScaleId === hexList?.value[0]?.ScaleId)
    sortedHexData && sortedHexData.length > 0 && dispatch(hexAdded(paintedTiles?.length ? paintedTiles : uniqueTilesArray));
  }, [newScaleId, sortedHexData]);

  const currentHexCell = avatarCell?.value[0]?.Cell;

  useEffect(() => {
    currentHexCell?.X === hexDirectionX - 7 ? setNewScaleId(2) : currentHexCell?.X === hexDirectionX + 7 ? setNewScaleId(3) : currentHexCell?.Y === (-7) + hexDirectionY ? setNewScaleId(4) : currentHexCell?.Y === hexDirectionY + 7 ? setNewScaleId(5) : setNewScaleId(0)
  }, [currentHexCell?.X, currentHexCell?.Y])

  useEffect(() => {
    if (!isEmpty(columnFilter)) {
      getCellRecord({
        columns: [],
        entity: `Cell`,
        tag: CELL_TAG,
        page: 1,
        pageSize: 500,
        sort: sort,
        columnFilter: columnFilter,
        globalFilter: [],
      })
    }

  }, [columnFilter])

  useEffect(() => {
    if (cellListData) {
      setCellList(bsonToJson(cellListData))
    }
  }, [cellListData]);

  const bsonToJson = (bin) => {
    let textAreaText = bin.value;
    let rawBytes = window.atob(textAreaText);
    let uintArray = new Uint8Array(rawBytes.length);
    for (let i = 0; i < rawBytes.length; i++) {
      uintArray[i] = rawBytes.charCodeAt(i);
    }
    let jsonDoc = BSON.deserialize(uintArray);
    let finalJSON = _.values(jsonDoc)
    return finalJSON;
  }

  useEffect(() => {
    if (!getCellLoading && !isHexListLoading && !isCellAroundFetching && !isBlackCellAroundFetching) {
      setSpinner(false);
    }
    else {
      setSpinner(true);
    }
  }, [getCellLoading, isHexListLoading, isCellAroundFetching, isBlackCellAroundFetching]);

  useEffect(() => {
    if (cellList && cellList.length > 0) {
      if (currentOperationMode === "DOWN") {
        newCenterCell = cellList.find((item) => item.X === playerCellData.X && item.Y === playerCellData.Y);
      } else {
        newCenterCell = cellList.find((item) => item);
      }
      !avatarCellId && setAvatarCellId(newCenterCell?.Id);
      setPlayerCellData({ ...playerCellData, ScaleId: newCenterCell?.ScaleId });
    }
  }, [cellList]);

  useEffect(() => {
    if (!getCellLoading && !cellError && cellList) {
      dispatch(getAllMaphexCell(cellList));
    }
  }, [getCellLoading, cellError]);

  useEffect(() => {
    if (!isHexListLoading && !isHexListError && hexList && sortedHexData?.length > 0 && !getNewScreens && (position === 0 || recallFunQuery === 20)) {
      localStorage.setItem("hexList", JSON.stringify(sortedHexData))
      if (hexList && sortedHexData && sortedHexData.length > 0) {
        if (allData[4]) {
          fetchBaseQuery(allData[4])
        } else {
          fetchBaseQuery(sortedHexData)
        }
      }
      setMaxValuesId({ maxXId: maxXId, minXId: allData[4] ? minXId : minXId + 6, minYId: minYId, maxYId: maxYId });
      setGetNewScreens(true);
      getNewScreen(maxYId);
    }

    if (!isHexListLoading && !isHexListError && hexList && sortedHexData?.length > 0 && getNewScreens) {
      // Position is use for calling the API where 
      // 0 indicate that we need to call API for right center cell
      // 1 indicate that we need to call API for bottom center cell
      // 2 indicate that we need to call API for left center cell
      switch (position) {
        case 0:
          getNewScreen(maxValuesId.maxXId);
          setPosition(position + 1);
          break;
        case 1:
          setNewScreenData({ ...newScreenData, top: hexList.value });
          getNewScreen(maxValuesId.minYId);
          setPosition(position + 1);
          break;
        case 2:
          setNewScreenData({ ...newScreenData, right: hexList.value });
          getNewScreen(maxValuesId.minXId);
          setPosition(position + 1);
          break;
        case 3:
          setNewScreenData({ ...newScreenData, bottom: hexList.value });
          setPosition(position + 1);
          break;
        case 4:
          setNewScreenData({ ...newScreenData, left: hexList.value });
          setGetNewScreens(false);
          break;
        default:
          break;
      }
    }
  }, [isHexListLoading, hexList, isHexListError, sortedHexData, recallFunQuery]);
  //------------- monitors the visible map data and boundary trigger --------------------
  const [visibleMapData, setVisibleMapData] = useState(newMapData);
  const [atMapBoundary, setAtMapBoundary] = useState(false);
  const arrowClick = (arrow) => {
    let changedScaleId = 0;
    if (arrow === "DownArrow") {
      setCurrentOperationMode("DOWN")
      var nextCellGenerator = avatarCell.value.filter((next) => next.Cell.ParentId === avatarCellId && next.Cell.ScaleId !== playerCellData.ScaleId && next.Cell.Id !== avatarCellId)
      changedScaleId = playerCellData.ScaleId - 1;
      parentFilterData({ ParentId: avatarCellId })
      if (nextCellGenerator && nextCellGenerator.length) {
        setAvatarCellId(nextCellGenerator[0].CellId)
      } else {
        setAvatarCellId(0)
      }
      setColumnFilter({ ScaleId: changedScaleId, X: playerCellData.X, Y: playerCellData.Y, ParentId: avatarCellId });
    }
    else if (arrow === "UpArrow") {
      setCurrentOperationMode("UP")
      changedScaleId = playerCellData.ScaleId + 1;
      parentFilterData({ ParentId: playerCellData?.Parent?.ParentId })
      setAvatarCellId(playerCellData?.ParentId);
      setColumnFilter({ ScaleId: changedScaleId, cellId: playerCellData?.ParentId, X: avatarCell?.value[0].Cell?.Parent.X, Y: avatarCell?.value[0].Cell?.Parent.Y });
    }
    if (changedScaleId !== 0) {
      setNewScaleId(changedScaleId);
    }
    setPaintedTiles(null)
  }
  const parentFilterData = (data) => {
    setParentFilter(data)
  }
  useEffect(() => {
    if (cellList && cellList && cellList.length === 0) {
      const { cellId, ...response } = columnFilter;
      if (newScaleId !== 5) {
        addCells({ entity: 'Cell', data: { ...(avatarCellId && { ParentId: avatarCellId }), TileId: avatarCell?.value[0]?.Cell?.TileId, ...response, Z: playerCellData.Z, Description: avatarCell?.value[0].Cell.Description, Exits: avatarCell?.value[0].Cell?.Exits, Terrain: avatarCell?.value[0].Cell.Terrain, Version: avatarCell?.value[0].Cell.Version, Name: avatarCell?.value[0].Cell.Name, IsDeleted: avatarCell?.value[0].Cell.IsDeleted, T: playerCellData.T }, tag: CELL_TAG }).unwrap();
      }
      else {
        addCells({ entity: 'Cell', data: { TileId: avatarCell?.value[0]?.Cell?.TileId, ...response, Z: playerCellData.Z, Description: avatarCell?.value[0].Cell.Description, Exits: avatarCell?.value[0].Cell?.Exits, Terrain: avatarCell?.value[0].Cell.Terrain, Version: avatarCell?.value[0].Cell.Version, Name: avatarCell?.value[0].Cell.Name, IsDeleted: avatarCell?.value[0].Cell.IsDeleted, T: playerCellData.T }, tag: CELL_TAG }).unwrap();
      }
    }
  }, [cellList]);
  useEffect(() => {
    localStorage.setItem("newScreenData", JSON.stringify(newScreenData));
  }, [newScreenData]);
  useEffect(() => {
    setNewMapData(mapData);
    setVisibleMapData(mapData);
  }, [mapData]);
  // useEffect(() => {
  //   if (maxValuesId.minXId === avatarCellId) {
  //     newScreenData.left && dispatch(hexAdded(newScreenData.left));
  //     if (position > 4) {
  //       setPosition(0)
  //       setRecallFunQuery(20);
  //     }
  //   }
  //   if (maxValuesId.maxXId === avatarCellId) {
  //     newScreenData.right && dispatch(hexAdded(newScreenData.right));
  //     if (position > 4) {
  //       setPosition(0)
  //       setRecallFunQuery(20);
  //     }
  //   }
  // }, [mapData, avatarCellId]);

  const fetchBaseQuery = (baseData) => {
    if (mapData.length > 0) {
      minX = maxX = mapData[0].X;
      minY = maxY = mapData[0].Y;
      minXId = maxXId = mapData[0].Id;
      minYId = maxYId = mapData[0].Id;
      mapData.forEach((item) => {
        if (item.X > maxX && item.Y === playerCell.Y) {
          maxXId = item.Id
          maxX = item.X;
        }
        else if (item.X < minX && item.Y === playerCell.Y) {
          minXId = item.Id
          minX = item.X;
        }
        if (item.Y > maxY && item.X === playerCell.X) {
          maxYId = item.Id
          maxY = item.Y;
        }
        else if (item.Y < minY && item.X === playerCell.X) {
          minYId = item.Id
          minY = item.Y;
        }
      });
    } else {
      minX = maxX = baseData[0].X;
      minY = maxY = baseData[0].Y;
      minXId = maxXId = baseData[0].Id;
      minYId = maxYId = baseData[0].Id;
      baseData.forEach((item) => {
        if (item.X > maxX && item.Y === playerCell.Y) {
          maxXId = item.Id
          maxX = item.X;
        }
        else if (item.X < minX && item.Y === playerCell.Y) {
          minXId = item.Id
          minX = item.X;
        }
        if (item.Y > maxY && item.X === playerCell.X) {
          maxYId = item.Id
          maxY = item.Y;
        }
        else if (item.Y < minY && item.X === playerCell.X) {
          minYId = item.Id
          minY = item.Y;
        }
      });
    }
  }
  useEffect(() => {
    if (avatarCellId !== 0 && mapData && mapData.length > 0) {
      let playerAt = mapData.find((value) => value.Id === avatarCellId);
      playerAt && setPlayerCell({ X: playerAt?.X, Y: playerAt?.Y, Z: playerAt?.Z, T: playerAt?.T });
    }
    if (newScaleId !== 0 && avatarCellId) {
      setMapCell(avatarCellId)
    }
  }, [mapData, newScaleId, avatarCellId, cellList]);

  useEffect(() => {
    //This detects when the player has reached a boundary that requires the map to be requeried from the larger mapData set
    setVisibleMapData(newMapData);
    //Now that the visible map has been expanded around the player, set the origin point
    setOriginalPlayerCell(playerCell);
    //Reset the map boundary trigger
    setAtMapBoundary(false);
  }, [atMapBoundary, newMapData]);

  useEffect(() => {
    let newHexData = [...newMapData];
    if (selectedHexIndex) {
      newHexData[selectedHexIndex] = selectedHex;
      setNewMapData(newHexData);
      setVisibleMapData(newHexData);
    }
  }, [selectedHex])

  useEffect(() => {
    //This monitors the current playerCell and if it reaches half the viewable range, trigger the map boundary refresh
    if (
      (Math.abs(originalPlayerCell.X - playerCell.X) > viewableRange / 2) ||
      (Math.abs(originalPlayerCell.Y - playerCell.Y) > viewableRange / 2)
    ) setAtMapBoundary(true);
  }, [playerCell]);

  //movement logic
  const isValidToMove = (cell, playerCell) => {
    const isValid =
      (Math.abs(playerCell.Y) % 2 === 0 &&
        (cell.X >= playerCell.X - 1 &&
          cell.Y >= playerCell.Y - 1 &&
          cell.Y <= playerCell.Y + 1 &&
          cell.X <= playerCell.X + 1 &&
          !(cell.X === playerCell.X + 1 && cell.Y === playerCell.Y - 1) &&
          !(cell.X === playerCell.X + 1 && cell.Y === playerCell.Y + 1))) ||
      (Math.abs(playerCell.Y) % 2 === 1 &&
        (cell.X >= playerCell.X - 1 &&
          cell.Y >= playerCell.Y - 1 &&
          cell.Y <= playerCell.Y + 1 &&
          cell.X <= playerCell.X + 1 &&
          !(cell.X === playerCell.X - 1 && cell.Y === playerCell.Y - 1) &&
          !(cell.X === playerCell.X - 1 && cell.Y === playerCell.Y + 1)));
    return isValid;
  };

  // Generates the visible field of map cells
  const tiles = visibleMapData.map((cell, index) => (
    <HexTile
      key={`tile-${index}`}
      coord={getCoords(cell)}
      player={1}
      cell={cell}

      url={cell.Tile && cell.Tile.UrlFileName}
      showCoordinates={showCoordinates}
      movePlayerCell={
        isValidToMove(cell, playerCell) && selected && movePlayerCell
      }
      toggleTerrainSidebar={() => toggleTerrainSidebar(cell, index)}
      setSelectedCellIds={setSelectedCellIds}
    />
  ));
  // prettier-ignore
  const hexes = visibleMapData.map((cell, index) => {
    return <HexTerrain
      key={`hex-${index}`}
      coord={getCoords(cell)}
      player={1}
      type={cell}
      cell={cell}
      highlighted={selected && isValidToMove(cell, playerCell)}
    />
  });
  return (
    <div className="adventurehex">
      <div className="avatarTop">
        <div className="avatarIconStyle">
          <div className="redgemStoneStyle">
            <div style={{ margin: '10px 70px' }}>25</div>
          </div>
          <div className="bluegemStoneStyle">
            <div style={{ margin: '10px 70px' }}>25</div>
          </div>
          <div className="yellowgemStoneStyle">
            <div style={{ margin: '10px 70px' }}>25</div>
          </div>
        </div>
      </div>
      <div className="footer">
        © 2022, WOGD LLC, All Rights Reserved
      </div>
      <React.Fragment>
        {spinner &&
          <span>
            <div className="loadingOverlay">
            </div>
            <span className="loader">
              <Spinner animation="border" variant="primary" className="mapLoader" />
            </span>
          </span>
        }
        <Sidebar
          sidebar={sidebarIsOpen ?
            <div className="avatarSidebar">
              <EditAvatarData toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} playerCell={playerCell} avatarRecord={avatarRecord} />
            </div> : <></>
          }
          open={sidebarIsOpen}
          defaultSidebarWidth={0}
          pullRight
          // docked
          touch
          touchHandleWidth={20}
          dragToggleDistance={30}
          shadow={false}
          styles={{
            sidebar: { top: "54px", marginBottom: "22px", width: "865px" },
            overlay: { backgroundColor: "none" }
          }}
        >
          <br />
        </Sidebar>
        <Sidebar
          sidebar={terrainSidebarIsOpen ?
            <div className="hexSidebar">
              <EditHexTabs userRoles={props.userRoles} toggleTerrainSidebar={toggleTerrainSidebar} setSelectedHex={setSelectedHex} selectedHex={selectedHex} setNewSelectedTile={setNewSelectedTile} />
            </div>
            : <></>
          }
          open={terrainSidebarIsOpen}
          defaultSidebarWidth={0}
          pullRight={false}
          touch
          touchHandleWidth={20}
          dragToggleDistance={30}
          shadow={false}
          styles={{
            sidebar: { width: "480px", top: "50px", marginBottom: '20px' },
            overlay: { backgroundColor: "none" }
          }}
        >
          <br />
        </Sidebar>
        <button
          className="btn btn-primary avatarDetails"
          onClick={toggleSidebar}

        >
          Avatar Details
        </button>
        <Stage
          key="mapStage"
          width={window.screen.width}
          height={window.screen.height}
          draggable={true}
          visible={true}
          offsetX={stage?.stageX}
          offsetY={stage?.stageY}
        >
          <Layer key="hexLayer"
            visible={true}
          >
            {hexes}
          </Layer>
          <Layer key="tileLayer">
            {tiles}
            <Piece
              cell={playerCell}
              player={1}
              id={54}
              selected={selected}
              toggleSelected={toggleSelected}
              toggleSidebar={toggleSidebar}
              arrowClick={arrowClick}
              isCellAroundFetching={isCellAroundFetching}
              isAvatarCellLoading={isAvatarCellLoading}
              currentScaleId={playerCellData && playerCellData?.ScaleId}
            />
          </Layer>
        </Stage>
      </React.Fragment>
    </div>
  );
};

export default MapViewer;