import React, { useEffect, useState } from 'react'
import Sidebar from 'react-sidebar'
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { GAME_SYSTEM_TAG } from 'common/tagFile';
import CreateCampaign from 'global/CreateCampaign';
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';

const Campaign = ({ setIsCampaignCreating, isCampaignCreating, setPage }) => {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const { isAuthenticated } = useAuth0();
    const toggleCampaignSideBar = () => {
        setIsSideBarOpen(!isSideBarOpen)
    }
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const sort = {
        sortDirection: 'asc',
        accessor: 'Id',
    };
    const [
        getSystemRecords,
        {
            data: systemRecords,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getSystemRecords({
            columns: [],
            entity: "GameSystem",
            tag: GAME_SYSTEM_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
        })
    }, [])

    const handleClick = () => {
        toggleCampaignSideBar();
    }

    useEffect(() => {
        setIsLoggedIn(isAuthenticated)
    }, [isAuthenticated])


    return (
        <>
            {
                isLoggedIn &&
                <Button className="button custom" style={{ zIndex: 10, position: 'fixed', right: "15%", top: "5px" }} onClick={handleClick}>
                    <FormattedMessage id="headerNavigation.CreateCampaign" defaultMessage="Create Campaign" />
                </Button>
            }
            <div style={isSideBarOpen ? { zIndex: 6 } : { zIndex: 3 }}>
                {isLoggedIn && <Sidebar
                    sidebar={isSideBarOpen ?
                        <div className="">
                            <CreateCampaign setPage={setPage} setIsCampaignCreating={setIsCampaignCreating} isCampaignCreating={isCampaignCreating} toggleCampaignSideBar={toggleCampaignSideBar} systemRecords={systemRecords} />
                        </div>
                        : <></>
                    }
                    open={isSideBarOpen}
                    defaultSidebarWidth={0}
                    pullRight
                    touch
                    touchHandleWidth={20}
                    dragToggleDistance={30}
                    shadow={false}
                    styles={{
                        sidebar: {
                            backgroundColor: "#f0f0f0", width: "50%", zIndex: 6, position: 'fixed', top: '55px', borderRadius: "10px", transition: "transform .3s ease-out",
                            WebkitTransition: "-webkit-transform .3s ease-out",
                            willChange: "transform",
                        },
                        overlay: { backgroundColor: "none" }
                    }}
                    rootId="mainSidebar"
                >
                    <br />
                </Sidebar>
                }
            </div>
        </>
    )
}

export default Campaign 