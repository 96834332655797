const CoverTitle = async (mainContent, getBase64ImageFromURL, documentMethods, stylesheet) => {
    let contentObject = JSON.parse(mainContent || "{}")
    const [dungeonEpicsLogo, WOGDLogo] = contentObject?.images || [];
    documentMethods.on('pageAdded', function () {
        stylesheet?.value?.backgroundImage?.commonBg && documentMethods.image(stylesheet?.value?.backgroundImage?.commonBg, 0, 0, { height: documentMethods.page.height, width: documentMethods.page.width })
    })
    stylesheet?.value?.backgroundImage?.commonBg && documentMethods.image(stylesheet.value.backgroundImage.commonBg, 0, 0, { height: documentMethods.page.height, width: documentMethods.page.width })
    dungeonEpicsLogo?.url && documentMethods.image(await getBase64ImageFromURL(dungeonEpicsLogo?.url), 120, documentMethods.page.margins.top, { width: 400 })
    documentMethods.moveDown(4);
    stylesheet?.title === 'montkelian' && stylesheet?.value.backgroundImage.bottomLeftImage && documentMethods.image(stylesheet?.value.backgroundImage.bottomLeftImage, -55, 560, { width: 400, opacity: 0 })
    // documentMethods.fontSize(stylesheet.value.screenTitle.fontSize).font(stylesheet.value.screenTitle.font).text(contentObject?.title, { align: "center" })
    // documentMethods.fontSize(stylesheet.value.screenTitleBanner.fontSize).font(stylesheet.value.screenTitleBanner.font).text(contentObject?.authorName, { align: "center" })
    // documentMethods.moveDown(6);
    // documentMethods.fontSize(stylesheet.value.keyFeatures.fontSize).font(stylesheet.value.keyFeatures.font).text(contentObject?.coverArt, { align: "center" });
    // documentMethods.fontSize(stylesheet.value.keyFeatures.fontSize).text(contentObject?.coverArtist, { align: "center" });
    // documentMethods.moveDown(8);
    // documentMethods.image(await getBase64ImageFromURL(dungeonEpicsLogo?.url), (documentMethods.page.width / 2) - 100, documentMethods.y, { fit: [200, 200] })
}

export default CoverTitle