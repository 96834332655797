import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { successToast } from 'common/toast';
import CustomInput from 'global/CustomInput';
import { useAddMutation } from 'services/gamecodexApi';

const AddMessage = ({ isOpen, setIsOpen, counterPartyId }) => {
    const [counterPartyActionLog] = useAddMutation();

    const rejectMessageSchema = Yup.object().shape({
      Message: Yup.string("Enter Message").required("Message is required.")
    })

    const submitForm = (values) => {
      const actionLog = {
        counterPartyId: counterPartyId,
        actionName: "rejected",
        rejectedReason: values.Message
      }
      counterPartyActionLog({
        entity: "Doc/CreateCounterPartyActionLog",
        isWogdV2Api: true,
        data: actionLog
      }).then(res => {
        if(res){
          successToast("Thanks for you response...")
          setIsOpen()
        }
      })
    }

  return (
    <>
      <Modal isOpen={isOpen} toggle={setIsOpen} size="md">
        <ModalHeader className='justify-content-between' toggle={setIsOpen} >
            Add Points
        </ModalHeader>
        <ModalBody>
            <Formik
              initialValues={{
                Message: ""
              }}
              validationSchema={rejectMessageSchema}
              onSubmit={submitForm}
            >
              {(formik) => {
                const {
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                } = formik;
                
                return (
                  <Form >
                    <CustomInput
                        label="Rejection Points"
                        name="Message"
                        type="textarea"
                        placeholder="Enter rejection message"
                        fieldValue={values.Message}
                        fieldErrors={errors.Message}
                        fieldTouched={touched.Message}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                    />
                    <Button onClick={handleSubmit}> Submit </Button>
                  </Form>
                )
              }}
            </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddMessage
