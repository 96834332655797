import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { Button, Row, Col, FormGroup, Label, Input, FormFeedback, ListGroup, ListGroupItem } from 'reactstrap';
import { useAddMutation, useLazyGetRecordByIdQuery, useLazyGetRecordsQuery, useUpdateMutation } from 'services/gamecodexApi';
import { ALIGNMENT, AVATAR_TAG, GAME_ITEM, GAME_SKILL, PLAYER_AVATAR_DATA, SYSTEM_ATTRIBUTE } from "common/tagFile";
import { errorToast, successToast } from "common/toast";
import './editAvatarData.scss';

const EditAvatarData = ({ toggleSidebar, playerCell, sidebarIsOpen, avatarRecord }) => {
    const sort = {
        sortDirection: 'none',
        accessor: 'some_accessor',
    };
    const [updateAvatar] = useUpdateMutation();
    const [addAvatarData] = useAddMutation();
    const history = useHistory();

    const [
        getGameItemRecord,
        {
            data: gameItem,
            error: isErrorGameItem,
            isLoading: isLoadingGameItem,
        }
    ] = useLazyGetRecordsQuery();

    const [
        getSystemAttributeRecord,
        {
            data: systemAttribute,
            error: isErrorSystemAttribute,
            isLoading: isLoadingSystemAttribute
        }
    ] = useLazyGetRecordsQuery();

    const [
        getAvatarRecord,
        {
            data: avatarData,
            error: isErrorAvatarData,
            isLoading: isLoadingAvatarData
        }
    ] = useLazyGetRecordByIdQuery();
    const [
        getAlignmentRecord,
        {
            data: alignmentRecord,
            error: isErrorAlignmentRecord,
            isLoading: isLoadingAlignmentRecord
        }
    ] = useLazyGetRecordsQuery();

    const [
        getGameSkillRecord,
        {
            data: gameSkill,
            error: isErrorGameSkill,
            isLoading: isLoadingGameSkill,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (sidebarIsOpen) {
            getGameItemRecord({
                columns: [],
                entity: "GameItem",
                tag: GAME_ITEM,
                page: 1,
                sort: sort,
                columnFilter: [],
                globalFilter: [],

            })
            getAlignmentRecord({
                columns: [],
                entity: "Alignment",
                tag: ALIGNMENT,
                page: 1,
                sort: sort,
                columnFilter: [],
                globalFilter: [],

            })
            getSystemAttributeRecord({
                columns: [],
                entity: "SystemDnd5eAttribute",
                tag: SYSTEM_ATTRIBUTE,
                page: 1,
                sort: sort,
                columnFilter: [],
                globalFilter: [],

            })
            getGameSkillRecord({
                columns: [],
                entity: "GameSkill",
                tag: GAME_SKILL,
                page: 1,
                sort: sort,
                columnFilter: [],
                globalFilter: [],

            })
            getAvatarRecord({
                entity: "Avatar",
                id: avatarRecord.Id,
                tag: AVATAR_TAG,
            })
        }
    }, [sidebarIsOpen])

    const hexSchema = Yup.object().shape({
        CharName: Yup.string("Enter valid string").required("Name is required"),
        CharRace: Yup.mixed(),
        CharClass: Yup.mixed(),
        CharAlignment: Yup.mixed().required(),
        CharLevel: Yup.mixed(),
        CharXp: Yup.mixed(),
    });

    const initialValues = {
        CharName: avatarData && avatarData ? avatarData.Name : "",
        CharRace: null,
        CharClass: null,
        CharAlignment: null,
        CharLevel: null,
        CharXp: null
    };
    const submitForm = (values) => {
        try {
            avatarData && avatarData.Name !== values.Name &&
                updateAvatar({ entity: 'Avatar', data: { Name: values.CharName, Id: avatarRecord.Id }, tag: PLAYER_AVATAR_DATA })
                    .then((res) => successToast(<FormattedMessage id="successToast.Avatar.EditAvatar" defaultMessage="Avatar edit successfully." />))
        } catch (error) {
            errorToast(<FormattedMessage id="errorToast.EditAvatar.UpdateError" defaultMessage="Error while updating Avatar" />);
        }
        try {
            values.Attribute !== null && !isEmpty(values.Attribute) &&
                addAvatarData({
                    entity: 'SystemDnd5eAvatarAttributes', data: {
                        AvatarId: avatarRecord.Id,
                        AttributeId: values.Attribute.Id
                    }
                }).then(() => successToast(<FormattedMessage id="successToast.Avatar.AttributeAdded" defaultMessage="Avatar-attribute added successfully." />))
        } catch (error) {
            errorToast(<FormattedMessage id="errorToast.EditAvatar.AddingError" defaultMessage="Error while adding avatar-attribute." />);
        }
        try {
            values.Item !== null && !isEmpty(values.Item) &&
                addAvatarData({
                    entity: 'AvatarItem', data: {
                        AvatarId: avatarRecord.Id,
                        ItemId: values.Item.Id,
                        Name: `${values.Name}_${values.Item.Name}`
                    }
                }).then(() => successToast(<FormattedMessage id="successToast.Avatar.ItemAdded" defaultMessage="Avatar-item added successfully." />))
        } catch (error) {
            errorToast(<FormattedMessage id="errorToast.EditAvatar.AddingItem" defaultMessage="Error while adding avatar-item." />);
        }
        try {
            values.Skill !== null && !isEmpty(values.Skill) &&
                addAvatarData({
                    entity: 'AvatarSkill', data: {
                        AvatarId: avatarRecord.Id,
                        SkillId: values.Skill.Id,
                    }
                }).then(() => successToast(<FormattedMessage id="successToast.Avatar.SkillAdded" defaultMessage="Avatar-skill added successfully." />))
        } catch (error) {
            errorToast(<FormattedMessage id="errorToast.EditAvatar.AddingSkills" defaultMessage="Error while adding avatar-skill." />);
        }
        toggleSidebar();
    }
    return (
        <>
            <Button onClick={toggleSidebar} color="primary" style={{ position: "absolute", top: '5px', left: '100px' }}>X</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={hexSchema}
                enableReinitialize
                onSubmit={submitForm}
            >
                {(formik) => {
                    const { errors, touched, values, handleBlur, handleChange, setFieldValue } = formik;
                    return (
                        <Form style={{ marginRight: '50px' }}>
                            <Row style={{ marginTop: '40px' }}>
                                <Col lg={2}>
                                    <Button className="saveAvatarStyle"><FormattedMessage id="application.Custom.Button.Save" defaultMessage="Save" /></Button>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col lg={7}>
                                            <FormGroup className="avatarFormGroup">
                                                <Label for="CharName">
                                                    <FormattedMessage id="editAvatar.CharacterName" defaultMessage="Character Name" />
                                                </Label>
                                                <Input
                                                    name="CharName"
                                                    id="CharName"
                                                    type="text"
                                                    placeholder="Name"
                                                    className={`${errors.CharName && touched.CharName ? "is-invalid" : null} editAvatarInput form-control`}
                                                    value={values.CharName}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                />
                                                {errors.CharName && <FormFeedback>{errors.CharName}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="avatarFormGroup">
                                                <Label for="CharRace">
                                                    <FormattedMessage id="editAvatar.Race" defaultMessage="Race" />
                                                </Label>
                                                <Input
                                                    name="CharRace"
                                                    id="CharRace"
                                                    type="text"
                                                    placeholder="Enter CharRace"
                                                    className={`${errors.CharRace && touched.CharRace ? "is-invalid" : null} editAvatarInput`}
                                                    value={values.CharRace}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                />
                                                {errors.CharRace && <FormFeedback className="errorMarginLeft">{errors.CharRace}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={7}>
                                            <FormGroup className="avatarFormGroup">
                                                <Label for="CharClass"><FormattedMessage id="editAvatar.Class" defaultMessage="class" /></Label>
                                                <Input
                                                    name="CharClass"
                                                    id="CharClass"
                                                    type="text"
                                                    placeholder="Enter Char class"
                                                    className={`${errors.CharClass && touched.CharClass ? "is-invalid" : null} editAvatarInput`}
                                                    value={values.CharClass}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                />
                                                {errors.CharClass && <FormFeedback className="errorMarginLeft">{errors.CharClass}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="avatarFormGroup">
                                                <Label for="CharAlignment"><FormattedMessage id="editAvatar.Alignment" defaultMessage="Alignment" /></Label>
                                                <Input
                                                    name="CharAlignment"
                                                    type="select"
                                                    id="CharAlignment" className={`${errors.CharAlignment && touched.CharAlignment ? "is-invalid" : null} editAvatarInput form-control`} value={values.CharAlignment}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} autoComplete="off"
                                                >
                                                    <option value={null}><FormattedMessage id="editAvatar.SelectAlignment" defaultMessage="select alignment" /> </option>
                                                    {
                                                        alignmentRecord && alignmentRecord?.value?.map((data, index) => {
                                                            return (
                                                                <option value={data?.Id} key={index}>{data.Name}</option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                                {errors.CharAlignment && <FormFeedback className="ml-0">{errors.CharAlignment}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={7}>
                                            <Row>
                                                <Col lg={4}>
                                                    <FormGroup className="avatarFormGroup">
                                                        <Label for="CharLevel">Level</Label>
                                                        <Input
                                                            name="CharLevel"
                                                            id="CharLevel"
                                                            type="text"
                                                            placeholder="Enter Char Level"
                                                            className={`${errors.CharLevel && touched.CharLevel ? "is-invalid" : null} editAvatarInput`}
                                                            value={values.CharLevel}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            autoComplete="off"
                                                        />
                                                        {errors.CharLevel && <FormFeedback className="errorMarginLeft">{errors.CharLevel}</FormFeedback>}
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup className="avatarFormGroup">
                                                        <Label for="CharXp"><FormattedMessage id="editAvatar.Xp" defaultMessage="xp" /> </Label>
                                                        <Input
                                                            name="CharXp"
                                                            id="CharXp"
                                                            type="text"
                                                            placeholder="Enter Char XP"
                                                            className={`${errors.CharXp && touched.CharXp ? "is-invalid" : null} editAvatarInput`}
                                                            value={values.CharXp}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            autoComplete="off"
                                                        />
                                                        {errors.CharXp && <FormFeedback className="errorMarginLeft">{errors.CharXp}</FormFeedback>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    )
                }
                }
            </Formik>
            <div style={{ marginRight: '62px' }}>
                <Row>
                    <Col lg={5}>
                        <div className="bonusImage">
                            <p className="bonusLabel"><FormattedMessage id="editAvatar.ProficiencyBonus" defaultMessage="proficiency bonus" /></p>
                        </div>
                        <div className="bonusImage">
                            <p className="bonusLabel"><FormattedMessage id="editAvatar.Inspiration" defaultMessage="inspiration" /></p>
                        </div>
                        <div className="qualityList" style={{ marginTop: '20px' }}>
                            <div className="qualityLabel"><FormattedMessage id="editAvatar.Strength" defaultMessage="strength" /></div>
                            <div className="strength" />
                            <div className="qualityInputNumber" />
                        </div>
                        <div className="qualityList">
                            <div className="qualityLabel"><FormattedMessage id="editAvatar.Dexerity" defaultMessage="dexerity" /></div>
                            <div className="dexerity" />
                            <div className="qualityInputNumber" />
                        </div>
                        <div className="qualityList">
                            <div className="qualityLabel"><FormattedMessage id="editAvatar.Constitution" defaultMessage="constitution" /> </div>
                            <div className="constitution" />
                            <div className="qualityInputNumber" />
                        </div>
                        <div className="qualityList">
                            <div className="qualityLabel"><FormattedMessage id="editAvatar.Intelligence" defaultMessage="intelligence" /> </div>
                            <div className="intelligence" />
                            <div className="qualityInputNumber" />
                        </div>
                        <div className="qualityList">
                            <div className="qualityLabel"><FormattedMessage id="editAvatar.Wisdom" defaultMessage="wisdom" /></div>
                            <div className="wisdom" />
                            <div className="qualityInputNumber" />
                        </div>
                        <div className="qualityList">
                            <div className="qualityLabel"><FormattedMessage id="editAvatar.Charisma" defaultMessage="charisma" /> </div>
                            <div className="charisma" />
                            <div className="qualityInputNumber" />
                        </div>
                    </Col>
                    <Col lg={7}>
                        <div className="avatarArmor" />
                        <div className="bonusImage" style={{ marginLeft: '10px' }}>
                            <p className="bonusLabel"><FormattedMessage id="editAvatar.PassiveWisdom" defaultMessage="passive wisdom" /></p>
                        </div>
                    </Col>
                </Row>
                <div className="avatarImage" />
            </div>
        </>
    );
}

export default EditAvatarData;