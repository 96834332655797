import React, { useEffect, useState } from "react";
import { RegularPolygon, Text } from "react-konva";
const HexTile = ({ coord, cell, movePlayerCell, toggleTerrainSidebar, showCoordinates, setSelectedCellIds }) => {
  const radius = 50;
  const [xCenter, setXCenter] = useState(window.innerWidth / 2);
  const [yCenter, setYCenter] = useState(window.innerHeight / 2);
  useEffect(() => {
    const windowsize = () => {
      setXCenter(window.innerWidth / 2);
      setYCenter(window.innerHeight / 2);
    }
    window.addEventListener('resize', windowsize)
  }, [])

  const getListOfHexes = (e) => {
    if(!movePlayerCell)
    {
      setSelectedCellIds(cell);
    }
    else{
      return movePlayerCell(e);
    }
  }
  return (
    <React.Fragment>
      {showCoordinates &&
        <Text
          opacity={1}
          // text={`(${cell.x},${cell.y})\n(${coord.x}, ${coord.y})`}
          text={`(${cell.X},${cell.Y})`}
          x={coord.X + xCenter - 11}
          y={coord.Y + yCenter - 5}
          fill="#FFFFFF"
        // stroke="#FFFFFF"
        />
      }
      <RegularPolygon
        cell={cell}
        x={coord.X + xCenter}
        y={coord.Y + yCenter}
        sides={6}
        radius={radius}
        opacity={1}
        onClick={(e)=>getListOfHexes(e)}
        onTap={movePlayerCell}
        onDblClick={toggleTerrainSidebar}
        onDblTap={toggleTerrainSidebar}
      />
    </React.Fragment>
  );
};
export default HexTile;
