async function HeaderImage(mainContent, getBase64ImageFromURL, documentMethods, stylesheet) {
    let contentObject = JSON.parse(mainContent || "{}")
    const [map] = contentObject?.images || []
    if ((documentMethods.page.height - 120 - documentMethods.y) < 250) {
        documentMethods.text("", documentMethods.x, documentMethods.y + (documentMethods.page.height - documentMethods.y), { continued: true });
    }
    let urlFormation1 = `https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms/longborder/20240531071043023_LongBorder[1].png`
    // documentMethods.on('pageAdded', function () {
    //     stylesheet?.value?.backgroundImage && documentMethods.image(stylesheet.value.backgroundImage.left, 0, 0, { height: documentMethods.page.height, width: documentMethods.page.width })
    // })
    documentMethods.image(await getBase64ImageFromURL(map.url, 'png'), 90, documentMethods.y, { fit: [450, 730] });
    stylesheet?.title !== 'montkelian' && documentMethods.moveDown();
    stylesheet?.title === 'montkelian' && documentMethods.image(await getBase64ImageFromURL(urlFormation1, 'png'), { height: 30, width: 500 })
}

export default HeaderImage