// react
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
//import Footer from './footer';

import Header from './Header';
import MobileHeader from './mobile/MobileHeader';

import MobileMenu from './mobile/MobileMenu';
// import Quickview from './shared/Quickview';

// pages
import Home from '../apps/home'
import BookBuilder from '../apps/tool/book/bookBuilder';

// import Forum from '../apps/forum'
// import StartScreen from '../apps/adventure-hex/StartScreen'
// import Collector from '../apps/collectors'
// import SetLists from '../apps/collectors/admin/set'
// import DieLists from '../apps/collectors/admin/die'
// import MapViewer from '../apps/adventure-hex/MapViewer'
// import AdminAvatar from '../apps/adventure-hex/admin/avatar'
// import Manufacturer from '../apps/collectors/admin/manufacturer';
// import AccountLayout from './account/AccountLayout';
// import AccountPageLogin from './account/AccountPageLogin';
// import BlogPageCategory from './blog/BlogPageCategory';
// import BlogPagePost from './blog/BlogPagePost';
// import PageCart from './shop/ShopPageCart';
// import PageCheckout from './shop/ShopPageCheckout';
// import PageCompare from './shop/ShopPageCompare';
// import PageWishlist from './shop/ShopPageWishlist';
// import ShopPageCategory from './shop/ShopPageCategory';
// import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
// import ShopPageProduct from './shop/ShopPageProduct';
// import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
// import SitePageAboutUs from './site/SitePageAboutUs';
// import SitePageComponents from './site/SitePageComponents';
// import SitePageContactUs from './site/SitePageContactUs';
// import SitePageContactUsAlt from './site/SitePageContactUsAlt';
// import SitePageFaq from './site/SitePageFaq';
import SitePageNotFound from './site/SitePageNotFound';
// import SitePageTerms from './site/SitePageTerms';
// import SitePageTypography from './site/SitePageTypography';

// data stubs
import theme from '../data/Theme';

// import SubscriptionList from 'apps/adventure-hex/subscription/subscriptionList';
// import SubscriptionCart from 'apps/adventure-hex/subscription/subscriptionCart';
// import MySubscriptions from 'apps/adventure-hex/subscription/mySubscriptions';
// import GenerateCode from 'apps/adventure-hex/subscription/generateCode';
// import MySubscriptionDetail from 'apps/adventure-hex/subscription/mySubscriptionDetail';
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import CustomLoader from 'common/CustomLoader';
import { GAME_ROLE_TAG, PLAYER_DATA, SITE_ROLE_TAG } from 'common/tagFile';
import { useDispatch, useSelector } from 'react-redux';
import { getRole } from './playerRoleSlice';
import { getPlayer } from 'apps/adventure-hex/player/playerSlice';
import SubscriptionList from 'apps/adventure-hex/subscription/subscriptionList';
import MySubscriptionDetail from 'apps/adventure-hex/subscription/mySubscriptionDetail';
import SubscriptionCart from 'apps/adventure-hex/subscription/subscriptionCart';
import GenerateCode from 'apps/adventure-hex/subscription/generateCode';
import { getSiteRole } from 'apps/tool/book/bookScrapRats/siteSlice';
import TableMaker from 'apps/tool/book/tableMakerTool/tableMaker';
import ProductCatalog from 'apps/tool/book/bookComponents/ProductCatalog';
import Contract from 'apps/contract/Contract'
// const categoryLayouts = [
//     ['/shop/category-grid-3-columns-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'start' }],
//     ['/shop/category-grid-4-columns-full', { columns: 4, viewMode: 'grid' }],
//     ['/shop/category-grid-5-columns-full', { columns: 5, viewMode: 'grid' }],
//     ['/shop/category-list', { columns: 3, viewMode: 'list', sidebarPosition: 'start' }],
//     ['/shop/category-right-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'end' }],
// ].map(([url, options]) => (
//     <Route
//         key={url}
//         exact
//         path={url}
//         render={(props) => <ShopPageCategory {...props} {...options} categorySlug="power-tools" />}
//     />
// ));

// const productLayouts = [
//     ['/shop/product-standard', { layout: 'standard' }],
//     ['/shop/product-columnar', { layout: 'columnar' }],
//     ['/shop/product-sidebar', { layout: 'sidebar' }],
// ].map(([url, options]) => (
//     <Route
//         key={url}
//         exact
//         path={url}
//         render={(props) => <ShopPageProduct {...props} {...options} productSlug="brandix-screwdriver-screw1500acc" />}
//     />
// ));

function Layout() {

    const { isAuthenticated } = useAuth0();
    const sort = {
        sortDirection: 'asc',
        accessor: 'Id',
    };
    const [path, setPath] = useState()
    const [userId, setUserId] = useState();
    const [playerRecord, setPlayerRecord] = useState()
    const history = useHistory()
    const param = useLocation();
    const [userRoles, setUserRoles] = useState([]);
    const currentDomain = 'app.wogd.com';
    let homeScreenId = {}
    const user = useSelector((state) => state.users.userInfo)

    const dispatch = useDispatch();
    const [
        getScreenRecords,
        {
            data: screenRecords,
            isLoading: isLoadingApp,
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        getScreenRecords({
            columns: [],
            entity: 'ApplicationScreen',
            page: 1,
            sort: sort,
            globalFilter: [],
            expandFilter: `Contains(Application/Name,'${currentDomain}')`,
            expand: `Screen`,
        })
        setPath(window.location.pathname)
    }, [param, history])

    const [
        getPlayerRole,
        {
            data: playerRole,
        }
    ] = useLazyGetRecordsQuery();
    const [
        getPlayerRecords,
        {
            data: playerRecords,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        isAuthenticated && playerRecord && getPlayerRole({
            columns: [],
            entity: "PlayerRole",
            tag: GAME_ROLE_TAG,
            page: 1,
            sort: sort,
            columnFilter: { PlayerId: parseInt(playerRecord?.Id) },
            globalFilter: [],
            expand: "roles"
        })
        // eslint-disable-next-line
    }, [isAuthenticated, playerRecord])

    useEffect(() => {
        isAuthenticated && userId && getPlayerRecords({
            columns: [],
            entity: "Player",
            tag: PLAYER_DATA,
            page: 1,
            sort: sort,
            ...(userId && { columnFilter: { userId: parseInt(userId) } }),
            globalFilter: [],
        })
        // eslint-disable-next-line
    }, [isAuthenticated, userId])

    const [
        getSiteRolesRecords,
        {
            data: siteRoles,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        isAuthenticated && userId && getSiteRolesRecords({
            columns: [],
            entity: "SiteRole",
            tag: SITE_ROLE_TAG,
            page: 1,
            sort: sort,
            columnFilter: { UserId: parseInt(userId) },
            globalFilter: [],
            expand: "RoleType"
        })
        // eslint-disable-next-line
    }, [isAuthenticated, userId])

    useEffect(() => {
        siteRoles?.value?.length && dispatch(getSiteRole(siteRoles.value))
    }, [siteRoles])

    useEffect(() => {
        setUserId(user?.id)
    }, [user])

    useEffect(() => {
        playerRole && playerRole?.value?.length && setUserRoles(playerRole.value)
        playerRole && dispatch(getRole(playerRole.value))
    }, [playerRole, isAuthenticated])

    useEffect(() => {
        playerRecords && dispatch(getPlayer(playerRecords.value[0] ?? {})) && setPlayerRecord(playerRecords.value[0]);
        playerRecords && playerRecords.value && playerRecords.value.length && localStorage.setItem('playerRecord', JSON.stringify(playerRecords.value[0]))
    }, [isAuthenticated, playerRecords])

    const newPath = localStorage.getItem("path")
    const contractPath = localStorage.getItem("contract-path")
    useEffect(() => {
        
        if (contractPath || newPath) {
            setTimeout(() => {
                contractPath && history.push(contractPath)
                newPath && history.push(newPath)
            }, 1000)
        }
        localStorage.removeItem('contract-path')
        localStorage.removeItem('path')
       return
    }, [newPath])

    if (screenRecords && screenRecords.value && screenRecords.value.length) {
        homeScreenId = screenRecords.value.find((val) => {
            return val.Screen.Name.toLowerCase().includes(param.pathname.slice(1, 6)) ?? val.Screen.Name.toLowerCase().includes(param.pathname.slice(1, 9))
        }
        )
    }
    let headerLayout = ""
    switch (window.location.pathname) {
        case '/map':
            headerLayout = "adventureHex"
            break;
        default:
            headerLayout = "default"
    }
    if (window.location.pathname === '/map' && document.getElementsByClassName('site_header_image').length) {
        document.getElementsByClassName('site_header_image')[0].style.display = 'block';
    }
    else if (document.getElementsByClassName('site_header_image').length) {
        document.getElementsByClassName('site_header_image')[0].style.display = 'none';
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={3000} hideProgressBar />
            <MobileMenu />
            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>
                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>
                <div className="site__body">
                    <Switch>
                        <Route exact path="/product/product-catalog" component={ProductCatalog} />
                        <Route exact path="/tools/writer/bookbuilder" component={BookBuilder} />
                        <Route exact path="/contract" component={Contract} />  
                        <Route exact path="/contract/:contractId" component={Contract} />
                        <Route exact path="/tools/writer/tablemaker" component={TableMaker} />
                        {
                            isLoadingApp ? <CustomLoader /> : homeScreenId ? <Route exact path={homeScreenId?.Screen?.Path}>
                                <Home homeScreenId={homeScreenId} screenRecords={screenRecords} userRoles={userRoles} />
                            </Route> : <Route component={SitePageNotFound} />
                        }
                        <Route exact path="/subscription-details" component={MySubscriptionDetail} />
                        <Route exact path="/subscription" component={SubscriptionList} />
                        <Route exact path="/cart" component={SubscriptionCart} />
                        <Route exact path="/generateCode" component={GenerateCode} />
                        {/* <Route exact path="/campaign/:campaignSlug/:campaignId" component={CampaignDetails} /> */}
                        {/* <Route exact path="/forum" component={Forum} />
                        <Route exact path="/adventurehex" component={StartScreen} />
                        <Route exact path="/collector" component={Collector} />
                        <Route exact path="/setCollection" component={SetLists} />
                        <Route exact path="/dieCollection" component={DieLists} />
                        <Route exact path="/map" component={MapViewer} />
                        {/* AdventureHex Routes */}
                        {/* <Route exact path="/admin/avatar" component={AdminAvatar} /> */}
                        {/* <Route exact path="/admin/manufacturer" component={() => <Manufacturer isForMyCollection={false} />} />
                        <Route exact path="/admin/myCollection" component={() => <Manufacturer isForMyCollection={true} />} /> */}
                        {/* campaign */}
                        {/* <Route exact path="/campaign/:campaignSlug/:campaignId" component={CampaignDetails} /> */}

                        {/*Shop*/}
                        {/* <Redirect exact from="/shop" to="/shop/catalog" />
                        <Route
                            exact
                            path="/shop/catalog"
                            render={(props) => (
                                <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/catalog/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                />
                            )}
                        /> */}
                        {/* Following category layouts only for demonstration. */}
                        {/* {categoryLayouts} */}

                        {/* <Route
                            exact
                            path="/shop/products/:productSlug"
                            render={(props) => (
                                <ShopPageProduct
                                    {...props}
                                    layout="standard"
                                    productSlug={props.match.params.productSlug}
                                />
                            )}
                        /> */}
                        {/* Following product layouts only for demonstration. */}
                        {/* {productLayouts} */}

                        {/* <Route exact path="/shop/cart" component={PageCart} />
                        <Route exact path="/shop/checkout" component={PageCheckout} />
                        <Route exact path="/shop/checkout/success" component={ShopPageOrderSuccess} />
                        <Route exact path="/shop/wishlist" component={PageWishlist} />
                        <Route exact path="/shop/compare" component={PageCompare} />
                        <Route exact path="/shop/track-order" component={ShopPageTrackOrder} /> */}

                        {/*
                        // Blog
                        */}
                        {/* <Redirect exact from="/blog" to="/blog/category-classic" />
                        <Route
                            exact
                            path="/blog/category-classic"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-grid"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-list"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="list" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-left-sidebar"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-classic"
                            render={(props) => (
                                <BlogPagePost {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-full"
                            render={(props) => (
                                <BlogPagePost {...props} layout="full" />
                            )}
                        /> */}

                        {/*
                        // Account
                        */}
                        {/* <Route exact path="/account/login" component={AccountPageLogin} />
                        <Route path="/account" component={AccountLayout} /> */}

                        {/*
                        // Site
                        */}
                        {/* <Redirect exact from="/site" to="/site/about-us" />
                        <Route exact path="/site/about-us" component={SitePageAboutUs} />
                        <Route exact path="/site/components" component={SitePageComponents} />
                        <Route exact path="/site/contact-us" component={SitePageContactUs} />
                        <Route exact path="/site/contact-us-alt" component={SitePageContactUsAlt} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/faq" component={SitePageFaq} />
                        <Route exact path="/site/terms" component={SitePageTerms} />
                        <Route exact path="/site/typography" component={SitePageTypography} /> */}

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
