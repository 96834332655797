import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Table } from 'reactstrap'
import { loadStripe } from '@stripe/stripe-js/pure';
import {
    Elements
} from '@stripe/react-stripe-js';
import PaymentForm from './paymentForm';
import { useHistory } from 'react-router';
import { useUpdateMutation, useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { ORDER_ITEM } from 'common/tagFile';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderItem } from "./subscriptionSlice";
import PromoCode from './promoCode';

const YourCart = () => {
    const sort = {
        sortDirection: 'none',
        accessor: 'some_accessor',
    };
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const history = useHistory()
    const stripePromise = loadStripe('pk_test_KklHd4sOEWLvvMNhiYd8UvfP');
    const orderItems = useSelector((state) => state.subscriptions.orderItem);
    const orderId = parseInt(localStorage.getItem("orderId"));
    const [updateOrderItem] = useUpdateMutation();
    const dispatch = useDispatch();
    const [discount, setDiscount] = useState(10)

    const [
        getOrderItems,
        {
            data: orderItemList,
            error: orderItemError,
            isLoading: orderItemLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (!orderItemError && !orderItemLoading && orderItemList && orderItemList.value) {
            dispatch(getOrderItem(orderItemList.value));
        }
    }, [orderItemLoading, orderItemError, orderItemList]);

    const subtotal = orderItems.map((items) => {
        let total = items.Sku;
        return total.Price;
    })
    const reducer = (accumulator, curr) => accumulator + curr;
    const finalTotal = subtotal.length > 0 && subtotal?.reduce(reducer)
    const finalPrice = (finalTotal - ((finalTotal / 100) * discount)) + 10

    const removeItem = (orderItem) => {
        const { Sku, ...otherOrderItem } = orderItem;
        updateOrderItem({ entity: 'OrderItem', data: { ...otherOrderItem, IsDeleted: true }, tag: ORDER_ITEM })
            .then(() => {
                getOrderItems({
                    columns: [],
                    entity: "OrderItem",
                    tag: ORDER_ITEM,
                    page: 1,
                    sort: sort,
                    columnFilter: { OrderId: orderId },
                    globalFilter: [],
                    expand: "sku"
                })
            });
    }

    return (
        <div style={{ overflow: 'hidden auto' }}>
            <p className="text-center text-primary subscription-cart-titles mb-0" ><span><i className="fas fa-shopping-cart"></i></span>Your Cart</p>
            <p className="text-center">Please review your items below</p>
            <div className="text-center">
                {orderItems && orderItems.length > 0 && <Table responsive>
                    <tbody>
                        {
                            orderItems && orderItems.map((items) => {
                                let itemDetails = items.Sku;
                                return (
                                    <tr key={items.Id}>
                                        <td>{itemDetails.Name}</td>
                                        <th>${itemDetails.Price}</th>
                                        <th className="subscription-quantity"><span onClick={() => removeItem(items)}><i className="fas fa-minus-square"></i></span>1</th>
                                        <th>${itemDetails.Price}</th>
                                    </tr>
                                );
                            })
                        }
                        <tr>
                            <th> Sub Total</th>
                            <th></th>
                            <th></th>
                            <th>
                                ${finalTotal}
                            </th>
                        </tr>
                        <tr className="text-success">
                            <th>  Discount</th>
                            <th>{discount}%</th>
                            <th></th>
                            <th>- ${((finalTotal / 100) * discount).toFixed(2)}</th>
                        </tr>

                        <tr>
                            <th>Shipment Price</th>
                            <th></th>
                            <th></th>
                            <th>+ $10</th>
                        </tr>
                        <tr>
                            <th>Final Price</th>
                            <th></th>
                            <th></th>
                            <th>${finalPrice}</th>
                        </tr>
                    </tbody>
                </Table>
                }
            </div>
           <PromoCode />
            <div className="your-cart-buttons">
                <Button color="primary" onClick={() => history.goBack()}>Back</Button>
                <Button color="primary" onClick={() => toggle()}>Checkout</Button>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} color="primary">CheckOut</ModalHeader>
                <ModalBody>
                    <Elements stripe={stripePromise}>
                        <PaymentForm toggle={toggle} />
                    </Elements>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default YourCart