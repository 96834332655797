import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { AVATAR_CELL, PLAYER_AVATAR_DATA } from "common/tagFile";
import AvatarForm from "../../player/avatarForm";

const SelectAvatarScreen = (props) => {
    const { setModal, playerInfo, modal, campaignId } = props;
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(0);
    const [avatarRecord, setAvatarRecord] = useState({});
    const [openAvatarForm, setOpenAvatarForm] = useState(false);
    const myAvatarData = useSelector((state) => state.player.playerAvatar);
    const handleAvatarData = () => {
        setOpenAvatarForm(!openAvatarForm);
        setModal(false)
    }
    const selectedAvatarDesign = (value, index) => {
        if (selectedOption !== 0) {
            document.getElementById(selectedOption).style.opacity = "0.5";
        }
        setSelectedOption(value?.Id);
        setAvatarRecord(value?.Avatar);
        document.getElementById(value?.Id).style.opacity = "1";
        modal && setModal(!modal)
    }
    useEffect(()=>{
        if (!modal && avatarRecord.Id) {
            history.push("/map", { avatarRecord: avatarRecord });
        }
    }, [modal]);

    useEffect(() => {
        if (myAvatarData && myAvatarData.length === 0) {
            selectedAvatarDesign(myAvatarData[0], 0)
        }
    }, [myAvatarData]);
    return (
        <div>
            <div id="images" className="d-flex flex-wrap">
                {
                    myAvatarData && myAvatarData.map((value, index) => {
                        return (
                            <div className="d-flex flex-column" key={value.Id}>
                                <img onClick={() => { selectedAvatarDesign(value, index) }} id={value.Id} className="hexDisplay" src={`https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/avatars/20240610114043141_Baris_lhbnlb.png`} alt={`avatar-img-${index}`} />
                                <span className="text-center">{value.Avatar.Name}</span>
                            </div>
                        )
                    })
                }
            </div>
            <Modal isOpen={openAvatarForm} toggle={() => handleAvatarData()} size="lg">
                <ModalHeader toggle={() => handleAvatarData()}>Add Avatar</ModalHeader>
                <ModalBody>
                    <AvatarForm playerInfo={playerInfo} campaignId={campaignId} setModal={setOpenAvatarForm} tags={[PLAYER_AVATAR_DATA,AVATAR_CELL]} />
                </ModalBody>
            </Modal>
            <hr />
            <div className="d-flex justify-content-end">
                <Button color="primary" className="m-3 mr-auto" onClick={() => setOpenAvatarForm(true)}>Add Avatar</Button>
            </div>
        </div>
    );
}

export default SelectAvatarScreen;