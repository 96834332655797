import React, { useEffect, useState } from 'react';

// third-party
// application

// blocks
import Cookies from 'universal-cookie';
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { useAuth0 } from '@auth0/auth0-react';
import GlobalRenderer from 'common/GlobalRenderer';
import { useLocation } from 'react-router-dom';
import SelectInitialPlayer from './components/SelectInitialPlayer';
import CustomLoader from 'common/CustomLoader';

function Home({ screenRecords, homeScreenId, userRoles }) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const sort = {
    sortDirection: 'asc',
    accessor: 'Id',
  };
  const queryParams = useLocation()
  useEffect(() => {
    if (isAuthenticated) {
      var accessToken = '';
      const getToken = async () => {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH_AUDIENCE
        });
        const cookies = new Cookies();
        cookies.set('accessToken', accessToken);
      }
      getToken();
    }
  }, [isAuthenticated])

  const [
    getScreenZoneRecords,
    {
      data: screenZone,
    }
  ] = useLazyGetRecordsQuery();

  useEffect(() => {
    homeScreenId?.Screen && getScreenZoneRecords({
      columns: [],
      entity: "ScreenZone",
      page: 1,
      sort: sort,
      columnFilter: { ScreenId: homeScreenId.Screen.Id },
      expandFilter: "Zone/IsDeleted ne true",
      globalFilter: [],
      expand: "Zone,Screen"
    })
  }, [screenRecords, queryParams, homeScreenId])

  return (
    <React.Fragment>
      {
        screenZone?.value?.length && screenZone?.value?.map((block, key) => {
          if (block?.ScreenId === homeScreenId?.Screen?.Id) {
            return (
              <div key={`homeZone${key}`}>
                <GlobalRenderer block={block} userRoles={userRoles}/>
                <SelectInitialPlayer />
              </div>
            )
          } else {
            return (
              <div key={`homeZone${key}`}>
                <CustomLoader />
              </div>
            )
          }
        })
      }
    </React.Fragment>
  );
}

export default Home;
