import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAddMutation } from 'services/gamecodexApi';
import { errorToast } from "common/toast";

const AvatarForm = (props) => {
    const { currentOperationMode, playerInfo, setModal, campaignId, tags } = props;
    const [addAvatar, { isLoading: isAvatarAdding }] = useAddMutation();
    const avatarSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required.")
    });
    const playerData = JSON.parse(localStorage.getItem('playerRecord'));
    const initialValues = {
        Name: ""
    }

    const submitForm = (values) => {
        try {
            addAvatar({ entity: 'Avatar', data: { Name: values.Name, UserId: playerData.UserId } }).unwrap().then((response) => {
                addAvatar({ entity: 'AvatarCell', data: { AvatarId: response.Id, CellId: 3, IsVisited: true } }).unwrap().then(() => {
                });
                campaignId && addAvatar({ entity: 'AvatarCampaign', data: { AvatarId: response.Id, CampaignId: campaignId, AvatarCampaignTypeId: 1, StatusId: 2 } }).unwrap().then(() => { });
                addAvatar({
                    entity: 'PlayerAvatar', data: {
                        AvatarId: response.Id, Name: `${playerInfo.Name}_${response.Name}`, PlayerId: playerInfo.Id ? playerInfo.Id :
                            playerData.Id
                    }
                }).unwrap().then((res) => {
                    try {
                        addAvatar({ entity: 'AvatarRole', data: { AvatarId: response.Id, RoleId: 1 }, tag: tags }).unwrap().then(() => {
                            if (campaignId) {
                                if (currentOperationMode) {
                                    const { currentOperationData } = currentOperationMode
                                    setModal({ status: "RequestingToJoin", currentOperationData })
                                }
                                else setModal(false);
                            } else {
                                if (currentOperationMode) {
                                    const { currentOperationData } = currentOperationMode
                                    setModal({ status: "RequestingToJoin", currentOperationData })
                                }
                                else {
                                    setModal("SELECT_AVATAR")
                                }
                            }
                        }).catch(rejected => errorToast(rejected));

                    } catch {
                        errorToast(<FormattedMessage id="errorToast.Common.Something-Went-Wrong" defaultMessage="Something went wrong." />)
                    }
                });


            });
        } catch (e) {
            errorToast(<FormattedMessage id="errorToast.AvatarForm.NoAvatar" defaultMessage='No Avatar added.' />)
        }
    }

    return (
        <div className="wrapper">
            <Formik
                initialValues={initialValues}
                validationSchema={avatarSchema}
                onSubmit={submitForm}
            >
                {(formik) => {
                    const { errors, touched, values, handleBlur, handleChange } = formik;

                    return (
                        <Form>
                            <FormGroup className="outerDiv my-3 mx-2">
                                <Row className="w-100 align-items-center">
                                    <Col md="3">
                                        <Label for="name" className="txtDiv"><FormattedMessage id="createCampaign.Campaign.InputName" defaultMessage='Name' /></Label>
                                    </Col>
                                    <Col md="8">
                                        <Input
                                            name="Name"
                                            id="Name"
                                            type="text"
                                            placeholder="Name"
                                            className={`${errors.Name && touched.Name ? "is-invalid" : "is-valid"} selection`}
                                            value={values.Name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.Name && <FormFeedback className="selection">{errors.Name}</FormFeedback>}
                                    </Col>
                                </Row>
                            </FormGroup>
                            <Modal.Footer>
                                <Row>
                                    <Col>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isAvatarAdding}
                                        >
                                            {
                                                isAvatarAdding &&
                                                <Spinner animation="border" variant="primary" size="sm" />
                                            }{" "}
                                            <FormattedMessage id="application.Custom.Button.Save" defaultMessage="Save" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </Form >
                    );
                }
                }
            </Formik >

        </div >
    );
}
export default AvatarForm;