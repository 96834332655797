import React, { useEffect, useMemo, useRef, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from './bookScrapRats/pdfMakeFonts';
import 'pdfmake/build/vfs_fonts';
import VerticalLinearStepper from './DynamicStepper';
import BookCarousel from './bookScrapRats/bookCarousel';
import { STYLESHEET_TAG, PUBLICATION_SECTION_TAG } from 'common/tagFile';
import GlobalPDFComponent from './GlobalPDFComponent';
import BookList from './bookComponents/BookList';
import './bookBuilder.scss';

const BookBuilder = () => {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;
  const [docDefinition, setDocDefinition] = useState("");
  const [styleSheet, setStyleSheet] = useState("");
  const [bookImage, setBookImage] = useState("")
  const [viewBook, setViewBook] = useState({ id: null, btn: null });
  const [showModal, setShowModal] = useState(false)
  const [createBook, setCreateBook] = useState(false)
  const [appNameField, setAppNameField] = useState("")
  const sort = {
    sortDirection: 'asc',
    accessor: 'Id',
  };
  const [previewPanel, setPreviewPanel] = useState(
    <div className='block block-products-carousel block-products-carousel--loading'>
      <div className="block-products-carousel__preloader">
      </div>
    </div>
  )
  const [contentDefinition, setContentDefinition] = useState({ img: {}, content: [], footer: () => { }, background: [] });
  const contentRef = useRef({ content: [], img: {}, footer: () => { }, background: [] })
  const [appData, setAppData] = useState()
  const [selectedBook, setSelectedBook] = useState("");
  const [cloneBtn, setCloneBtn] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState();
  const [stepOptions, setStepOptions] = useState([
    [
      { label: 'title', value: "" },
      { label: 'subTitle', value: "" },
      { label: 'screenHeading', value: "" },
      { label: 'content', value: "" },
    ]
  ]);

  return (
    <div>
      <div className="pageWrapper">
        <div className="dataEntryPanel">
          {/* <BookCarousel viewBook={viewBook} setViewBook={setViewBook} stepOptions={stepOptions} setStepOptions={setStepOptions} setAppData={setAppData} setShowModal={setShowModal} setBookImage={setBookImage} setCreateBook={setCreateBook} setAppNameField={setAppNameField} setStyleSheet={setStyleSheet} /> */}
          <BookList viewBook={viewBook} setViewBook={setViewBook} stepOptions={stepOptions} setStepOptions={setStepOptions} setAppData={setAppData} setShowModal={setShowModal} setBookImage={setBookImage} setCreateBook={setCreateBook} setAppNameField={setAppNameField} setStyleSheet={setStyleSheet} selectedBook={selectedBook} setSelectedBook={setSelectedBook} setCloneBtn={setCloneBtn} />
          <VerticalLinearStepper document={docDefinition} downloadPdf={downloadPdf} viewBook={viewBook} setViewBook={setViewBook} styleSheet={styleSheet} setStyleSheet={setStyleSheet} bookImage={bookImage} setBookImage={setBookImage} stepOptions={stepOptions} setStepOptions={setStepOptions} appData={appData} setAppData={setAppData} previewPanel={previewPanel} setPreviewPanel={setPreviewPanel} showModal={showModal} setShowModal={setShowModal} createBook={createBook} setCreateBook={setCreateBook} appNameField={appNameField} setAppNameField={setAppNameField} selectedBook={selectedBook} cloneBtn={cloneBtn} PUBLICATION_SECTION_TAG={PUBLICATION_SECTION_TAG} />
          {
            useMemo(() => viewBook && <GlobalPDFComponent viewResponse={viewBook} contentRef={contentRef} setContentDefinition={setContentDefinition} contentDefinition={contentDefinition} setDownloadPdf={setDownloadPdf} setPreviewPanel={setPreviewPanel} PUBLICATION_SECTION_TAG={PUBLICATION_SECTION_TAG} />, [viewBook])
          }
        </div>
        <div className='pdf-block'>
          <div className='previewPanel' id='iframeContainer'>
            {appData && previewPanel}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookBuilder;	