import { createSlice } from '@reduxjs/toolkit'

const isAdminSlice = createSlice({
    name: "isAdmin",
    initialState: {
        isAdmin: false
    },
    reducers: {
        getIsAdmin: (state, action) => {
            state.isAdmin = action.payload
        }
    }
});

export default isAdminSlice.reducer;
export const { getIsAdmin } = isAdminSlice.actions