import { useSelector } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import CoverPage from './CoverPage';
import AuthorBooks from './AuthorBooks';
import CoverTitle from './CoverTitle';
import Copyright from './Copyright';
import HeaderImage from './HeaderImage';
// import ListCollection from './ListCollection';
// import Chapter from './Chapter';
import Footer from './Footer';
import BackgroundImage from './BackGroundImage';
import vfsFonts from './../bookScrapRats/pdfMakeFonts';
// import ChapterHeader from './ChapterHeader';
import styleSheetData from '../styleSheetData';
import PDFTable from './PDFTable';
// import Card from 'apps/tool/swordmaster/Card';
import TableOfContents from './TableOfContents';
// import Bestiary from './Bestiary';
import SimpleHeader from './SimpleHeader';
import Notes from './Notes';
import SectionHeading from './SectionHeading';
import NotesWithHeader from './NotesWithHeader';
import SmallTable from './SmallTable';
import SingleImage from './SingleImage';
import StatBlock from './StatBlock';


const pdfRenderer = async (screenZone, contentDefinition, setContentDefinition, outerRspHandler, setLoading, contentRef, documentMethods, stylesheet, count, screenCount, deckLength, deckScreenLength = 0, screenType = "", cards = 0) => {
    stylesheet?.value?.screenContentDescription && documentMethods.text("").fontSize(13).font(stylesheet.value.screenContentDescription.font)
    let screenZoneWithHeight
    if (!cards) {
        screenZoneWithHeight = screenZone?.map((data, index) => {
            return {
                ...data,
                heightOfString: documentMethods.heightOfString(JSON.parse(data?.Content?.ContentObject || "{}")?.description, { columns: 2 }),
                ...(data?.Content?.ContentType?.Name.toLowerCase() === 'statblock' && { blockHeight: calculateBlockHeight(JSON.parse(data?.Content?.ContentObject || "{}")?.description) })
            }
        })
    }
    const Components = {
        CoverPage: CoverPage,
        // AuthorBooks: AuthorBooks,
        CoverTitle: CoverTitle,
        // Copyright: Copyright,
        TableOfContents: TableOfContents,
        // Chapter: Chapter,
        SectionHeading: SectionHeading,
        SimpleHeader: SimpleHeader,
        Notes: Notes,
        NotesWithHeader: NotesWithHeader,
        HeaderImage: HeaderImage,
        // ListCollection: ListCollection,
        // Bestiary: Bestiary,
        SmallTable: SmallTable,
        SingleImage: SingleImage,
        StatBlock: StatBlock,
        // ChapterHeader: ChapterHeader,
        Table: PDFTable,
        // Card: Card
    }
    async function showPdf(innerContent) {
        if (cards) {
            await Components["Card"](innerContent, getBase64ImageFromURL, documentMethods, stylesheet, count, screenCount, deckLength, deckScreenLength, screenType)
        } else {
            let index = 0
            for (let data of innerContent) {
                if (!Components[data?.Content?.ContentType?.Name]) {
                    return { img: {}, content: [] }
                } else {
                    await Components[data?.Content?.ContentType?.Name || data?.ContentType?.Name]((data?.Content?.ContentType?.Name === "Table" || data?.Content?.ContentType?.Name === "SmallTable") ? data.Content
                        : data.Content.ContentObject, getBase64ImageFromURL, documentMethods, stylesheet, count, convertHTMLToPDF, calculateBlockHeight, screenCount, deckLength, deckScreenLength, screenType, data.heightOfString + 25, data.blockHeight, index)
                }
                index++
            }
        }
    }
    if (cards) {
        return showPdf(screenZone).then((resp) => {
            setLoading(true)
            return resp
        })
    } else {
        return showPdf(screenZoneWithHeight).then((resp) => {
            setLoading(true)
            return resp
        })
    }
    async function getBase64ImageFromURL(url) {
        const resp = await fetch(url, { cache: 'no-cache' })
        const blob = await resp.blob()

        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
    }
    function convertHTMLToPDF(htmlString, listType, isLast = false) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(htmlString, 'text/html');
        const body = htmlDoc.body.children;
        function processNode(node) {
            switch (node.nodeName) {
                case 'B':
                case 'STRONG':
                    documentMethods.font(`Helvetica-Bold`).fillColor("black").text(node.textContent, { continued: true });
                    break;
                case 'I':
                case 'em':
                    documentMethods.font('Helvetica-Oblique').text(node.textContent, { continued: true });
                    break;
                case 'u':
                    documentMethods.text(node.textContent);
                    break;
                case 'BR':
                    documentMethods.text("\n", { continued: true });
                    break;
                case 'p':
                    documentMethods.text(`${node.textContent}\n`, { continued: true });
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    break;
                case '#text':
                    if (listType === 'index') documentMethods.font(stylesheet.value.screenHeading.font)
                    else documentMethods.font(stylesheet.value.screenContentDescription.font)
                    documentMethods.text(node.textContent, { continued: true });
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    documentMethods.text("", { continued: true })
                    break;
                case 'div':
                    Array.from(node.childNodes).forEach(childNode => processNode(childNode));
                    break;
                default:
                    Array.from(node.childNodes).forEach(processNode);
                    break;
            }
        }

        Array.from(body).forEach(processNode);
        if (listType === 'block' && stylesheet.title !== 'dungeon') {
            documentMethods.text("", documentMethods.x, documentMethods.y + 10, {
                continued: true
            })
            !isLast && documentMethods
                .moveTo(documentMethods.x - 10, documentMethods.y + 10)
                .lineTo(documentMethods.x > 300 ? 560 : 300, documentMethods.y + 10)
                .lineWidth(1)
                .stroke("#095559")
            documentMethods.text("", documentMethods.x, documentMethods.y + 10, {
                continued: true
            })
        }
        return;
    }

    function calculateBlockHeight(data) {
        const dataHeight = data.split('<br><br><br>').reduce((acc, crr) => {
            return acc + crr
        }, "")
        stylesheet?.value?.screenContentDescription && documentMethods.text("").fontSize(13).font(stylesheet.value.screenContentDescription.font)
        const calculatedHeight = documentMethods.heightOfString(dataHeight) + 150
        return calculatedHeight;
    }

}

export default pdfRenderer