import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'universal-cookie';
import { Button } from "reactstrap";

const LoginLink = () => {
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
    const logoutUser = () => {
        logout();
        localStorage.removeItem("userId");
        localStorage.removeItem("hexList");
        localStorage.removeItem("playerRecord");
        const cookies = new Cookies();
        cookies.remove('accessToken');
        window.location.href = process.env.REACT_APP_LOGIN_HOST;
    }
    return (<div>{isAuthenticated ? <Button onClick={logoutUser}><FormattedMessage id="headerNavigation.logout" defaultMessage="Logout" /></Button> : <Button onClick={loginWithRedirect}><FormattedMessage id="headerNavigation.Login" defaultMessage="Login" /></Button>}</div>);
}

export default [
    {
        type: 'link',
        label: <FormattedMessage id="headerNavigation.Home" defaultMessage="Home" />,
        url: '/'
    },
    {
        type: 'link',
        label: <FormattedMessage id="headerNavigation.GetStarted" defaultMessage='Get Started' />,
        url: '/',
        children: [
            {
                type: 'link',
                label: <FormattedMessage id="headerNavigation.Tools.CreateCharacter" defaultMessage="Create Characters" />,
                url: '',
                children: [
                    { type: 'link', label: <FormattedMessage id="headerNavigation.Tools.Character.Evolutionary" defaultMessage="Evolutionary" />, url: '' },
                    { type: 'link', label: <FormattedMessage id="headerNavigation.Tools.Character.Mork-Borg" defaultMessage="Mork Borg" />, url: '' },
                    { type: 'link', label: <FormattedMessage id="headerNavigation.Tools.Character.Fifth-Edition" defaultMessage="5th Edition" />, url: '' },
                    { type: 'link', label: <FormattedMessage id="headerNavigation.Tools.Character.Mothership" defaultMessage="Mothership" />, url: '' },
                    { type: 'link', label: <FormattedMessage id="headerNavigation.Tools.Character.SwordMaster" defaultMessage="SwordMaster" />, url: '' },
                    { type: 'link', label: <FormattedMessage id="headerNavigation.Tools.Character.Hannibal" defaultMessage="Hannibal" />, url: '' },
                ],
            },
            {
                type: 'link',
                label: <FormattedMessage id="headerNavigation.Tools.Create-Campaigns" defaultMessage="Create Campaigns" />,
                url: '',
                children: [
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Tools.AdventureHex-Campaigns" defaultMessage="AdventureHex Campaigns" />,
                        url: ''
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Tools.Sword-Master-Pro" defaultMessage="Sword Master Pro" />,
                        url: '/swordmasterpro/home'
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Tools.Sword-Master-Pro-Events" defaultMessage="Sword Master Pro Events" />,
                        url: '/myevents'
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Tools.Writers.BookBuilder" defaultMessage="Book Builder" />,
                        url: '/tools/writer/bookbuilder'
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Tools.Table-Maker-Tool" defaultMessage="Table Maker Tool" />,
                        url: '/tools/writer/tablemaker'
                    },
                ],
            },
            {
                type: 'link',
                label: <FormattedMessage id="headerNavigation.Administration.Manage-Collections" defaultMessage="Manage Collections" />,
                url: '',
                children: [
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Administration.Dice-Collection" defaultMessage="Dice Collection" />,
                        url: '/dice-collection'
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Administration.RPG-Collection" defaultMessage="RPG Collection" />, //आरपीजी कलेक्शन
                        url: ''
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Administration.Portfolio-Tool" defaultMessage="Portfolio Tool" />,
                        url: ''
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Administration.Subscription.Subscription-List" defaultMessage="Your Subscriptions" />,
                        url: '/subscription',
                    }
                ],
            },
            {
                type: 'link',
                label: <FormattedMessage id="headerNavigation.Kickstarters" defaultMessage="View Kickstarters" />,
                url: '',
                children: [
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Kickstarters.Hannibal-RPG" defaultMessage="Hannibal RPG" />,
                        url: ''
                    },
                    {
                        type: 'link',
                        label: <FormattedMessage id="headerNavigation.Kickstarters.Highlander-TCG" defaultMessage="Highlander TCG" />,
                        url: ''
                    },
                ],
            },
        ],
    },
    {
        type: 'link',
        label: <FormattedMessage id="headerNavigation.Product" defaultMessage="Product" />,
        url: '/',
        children: [
            { type: 'link', label: <FormattedMessage id="headerNavigation.Product.ProductCatalog" defaultMessage="Product Catalog" />, url: '/product/product-catalog' },
        ],
    },
    {
        type: 'link',
        label: <FormattedMessage id="headerNavigation.Create-Contracts" defaultMessage="Contract" />,
        url: '/contract'
    },
    {
        type: 'link',
        label: <LoginLink />,
        url: '/'
    }
];
