// application
import enMessages from './messages/en.json';
import ruMessages from './messages/ru.json';
import rtlMessages from './messages/rtl.json';
import hiMessages from './messages/hi.json';

export const messages = {
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
    ru: {
        messages: ruMessages,
        direction: 'ltr',
    },
    ar: {
        messages: rtlMessages,
        direction: 'rtl',
    },
    hi: {
        messages: hiMessages,
        direction: 'rtl',
    },
};
export default messages;