import { makeIdGenerator } from '../utils';

const getId = makeIdGenerator();

const categoriesDef = [
    {
        name: 'Dice',
        slug: 'dice',
        items: 272,
        children: [
            {
                name: 'Acrylic Dice',
                slug: 'acrylic-dice',
                image: 'assets/images/categories/category-1.jpg',
                items: 370,
                children: [
                    {
                        name: 'Drills & Mixers',
                        slug: 'drills-mixers',
                        items: 57,
                    },
                ],
            },
            {
                name: 'Metal Dice',
                slug: 'metal-dice',
                image: 'assets/images/categories/category-2.jpg',
                items: 134,
                children: [
                    {
                        name: 'Tool Kits',
                        slug: 'tool-kits',
                        items: 57,
                    },
                ],
            },
            {
                name: 'Handmade Dice',
                slug: 'handmade=dice',
                image: 'assets/images/categories/category-3.jpg',
                items: 302,
                children: [
                    {
                        name: 'Lathes',
                        slug: 'lathes',
                        items: 104,
                    },
                ],
            },
            {
                name: 'Stone Dice',
                slug: 'stone-dice',
                image: 'assets/images/categories/category-4.jpg',
                items: 79,
                children: [
                    {
                        name: 'Generators',
                        slug: 'generators',
                        items: 23,
                    },
                ],
            },
            {
                name: 'Resin Dice',
                slug: 'resin-dice',
                image: 'assets/images/categories/category-5.jpg',
                items: 366,
                children: [
                    {
                        name: 'Tape Measure',
                        slug: 'tape-measure',
                        items: 57,
                    },
                ],
            },
            {
                name: 'Wooden Dice',
                slug: 'wooden-dice',
                image: 'assets/images/categories/category-6.jpg',
                items: 82,
                children: [
                    {
                        name: 'Winter Workwear',
                        slug: 'winter-workwear',
                        items: 24,
                    },
                ],
            },
        ],
    },
    {
        name: 'Apps',
        slug: 'apps',
        items: 54,
    },
    {
        name: 'Epics',
        slug: 'epics',
        items: 421,
    },
    {
        name: 'Terrain',
        slug: 'terrain',
        items: 182,
    },
];

function walkTree(defs, parent = null) {
    let list = [];
    const tree = defs.map((def) => {
        const category = {
            id: getId(),
            name: def.name,
            slug: def.slug,
            image: def.image || null,
            items: def.items || 0,
            customFields: {},
            parent,
            children: [],
        };

        const [childrenTree, childrenList] = walkTree(def.children || [], category);

        category.children = childrenTree;
        list = [...list, category, ...childrenList];

        return category;
    });

    return [tree, list];
}

export function prepareCategory(category, depth) {
    let children;

    if (depth && depth > 0) {
        children = category.children.map((x) => prepareCategory(x, depth - 1));
    }

    return JSON.parse(JSON.stringify({
        ...category,
        parent: category.parent ? prepareCategory(category.parent) : null,
        children,
    }));
}

export const [categoriesTreeData, categoriesListData] = walkTree(categoriesDef);
