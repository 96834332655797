import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader,Button } from "reactstrap";
import PlayerForm from "../../player/playerForm";

const SelectPlayerScreen = (props) => {
    const { setModal,setSelectedPlayer, modal } = props;
    const [selectedOption, setSelectedOption] = useState(0);
    const [openPlayerForm, setOpenPlayerForm] = useState(false);
    const myPlayerData = useSelector((state) => state.player.players);
    const handleSelectedPlayer = () => {
        setOpenPlayerForm(false);
        setModal(false);
    }
    const selectedPlayerDesign = (value, index) => {
        if (selectedOption !== 0) {
            document.getElementById(selectedOption).style.opacity = "0.5";
        }
        setSelectedOption(value.Id);
        document.getElementById(value.Id).style.opacity = "1";
        modal && setModal(!modal)
    }
    if (!modal && selectedOption) {
        setModal(false);
        setSelectedPlayer({ Id: selectedOption });
        localStorage.setItem('playerRecord', JSON.stringify({ Id: selectedOption }))
    }
    useEffect(() => {
        if (myPlayerData && myPlayerData.length === 0) {
            selectedPlayerDesign(myPlayerData[0], 0);
        }
    }, [myPlayerData]);
    return (
        <div>
            <div id="images" className="d-flex flex-wrap">
                {
                    myPlayerData && myPlayerData.map((value, index) => {
                        return (
                            <div className="d-flex flex-column">
                                <img key={value.id} onClick={() => { selectedPlayerDesign(value, index) }} id={value.Id} className="hexDisplay" src={`https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/avatars/20240610114043141_Baris_lhbnlb.png`} />
                                <span className="text-center">{value.Name}</span>
                            </div>
                        )
                    })
                }
            </div>
            <Modal isOpen={openPlayerForm} toggle={() => handleSelectedPlayer()} size="lg">
                <ModalHeader toggle={() => handleSelectedPlayer()}>Add Player</ModalHeader>
                <ModalBody>
                    <PlayerForm setModal={setOpenPlayerForm} />
                </ModalBody>
            </Modal>
            <hr/>
            <div className="d-flex justify-content-end">
                <Button color="primary" className="m-3 mr-auto" onClick={() => setOpenPlayerForm(true)}>Add Player</Button>
            </div>
        </div>
    );
}

export default SelectPlayerScreen;