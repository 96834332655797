import { createSlice } from "@reduxjs/toolkit";

const bookBuilderSlice = createSlice({
    name: "avatar",
    initialState: {
        bookContent: []
    },
    reducers: {
        getAllBookContent: (state, action) => {
            state.bookContent = action.payload
        },
    },
});

export default bookBuilderSlice.reducer;
export const { getAllBookContent } = bookBuilderSlice.actions