import { useAuth0 } from "@auth0/auth0-react";
import { GAME_MASTER_ROLE } from "common/roleFile";
import { CAMPAIGN_TAG, DEFAULT_CAMPAIGN_LOGO_TAG, GAME_SYSTEM_TAG, GET_ALL_CAMPAIGN_LOGO_TAG, GET_ALL_PRODUCTLINE_MEDIA_TAG, GET_ALL_SYSTEM_MEDIA_TAG } from "common/tagFile";
import CreateCampaign from "global/CreateCampaign";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "react-sidebar";
import { Button } from "reactstrap";
import { useLazyGetRecordsQuery } from "services/gamecodexApi";


const EditCampaign = (props) => {
    const [columnFilter, setColumnFilter] = useState([]);
    const params = useParams();
    const campaignId = Number(params?.campaignId);
    const sort = {
        sortDirection: 'desc',
        accessor: 'Id',
    };
    const { isAuthenticated } = useAuth0();
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [currentOperationMode, setCurrentOperationMode] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isPlayerGameMaster, setIsPlayerGameMaster] = useState(false);

    useEffect(() => {
        if (props.userRoles) {
            props.userRoles.forEach((val) => {
                if (val.Roles.Name.toLowerCase().trim().replace(" ", "") === GAME_MASTER_ROLE.toLowerCase().trim().replace(" ", "") && val.CampaignId === campaignId) {
                    setIsPlayerGameMaster(true)
                }
            })
        }
    }, [props.userRoles, campaignId])

    const [
        getSystemRecords,
        {
            data: systemRecords,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getSystemRecords({
            columns: [],
            entity: "GameSystem",
            tag: GAME_SYSTEM_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
        })
    }, [])

    useEffect(() => {
        setIsLoggedIn(isAuthenticated)
    }, [isAuthenticated])

    const [
        getCampaignRecord,
        {
            data: campaignItem,
        }
    ] = useLazyGetRecordsQuery();

    const [
        getProductLineMedia,
        {
            data: productLineMediaItem,
        }
    ] = useLazyGetRecordsQuery();

    const [
        getCampaignLogo,
        {
            data: campaignLogoItem,
        }
    ] = useLazyGetRecordsQuery();
    const [
        getDefaultLogo,
        {
            data: currentCampaignLogo,
        }
    ] = useLazyGetRecordsQuery();

    const [
        getSystemMedia,
        {
            data: systemMediaItem,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        campaignId && getCampaignLogo({
            columns: [],
            entity: "GetAllCampaignLogo",
            tag: GET_ALL_CAMPAIGN_LOGO_TAG,
            page: 1,
            sort: sort,
            columnFilter: { campaignId: campaignId },
            globalFilter: [],
        })
        getSystemMedia({
            columns: [],
            entity: "GetAllSystemMedia",
            tag: GET_ALL_SYSTEM_MEDIA_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            expand: "GameMedia"
        })
        !isNaN(campaignId) && getDefaultLogo({
            columns: [],
            entity: "CampaignLogo",
            page: 1,
            tag: DEFAULT_CAMPAIGN_LOGO_TAG,
            sort: {
                sortDirection: 'asc',
                accessor: 'Id',
            },
            columnFilter: { campaignId: campaignId, StatusId: 2 },
            globalFilter: [],
            expand: ""
        })
        getProductLineMedia({
            columns: [],
            entity: "GetAllProductLineMedia",
            tag: GET_ALL_PRODUCTLINE_MEDIA_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            expand: "GameMedia"
        })
        !isNaN(campaignId) && getCampaignRecord({
            columns: [],
            entity: "Campaign",
            tag: CAMPAIGN_TAG,
            page: 1,
            sort: sort,
            columnFilter: { Id: campaignId },
            globalFilter: [],
        })
    }, [columnFilter, campaignId])

    const toggleCampaignSideBar = () => {
        setIsSideBarOpen(!isSideBarOpen)
        setCurrentOperationMode("Edit")
    }
    return (
        <>
            {
                isLoggedIn && isPlayerGameMaster && <Button className="button editCampaignButton" onClick={toggleCampaignSideBar} style={{ position: "fixed" }}>Edit Campaign</Button>
            }
            <div style={isSideBarOpen ? { zIndex: 6 } : { position: "absolute", zIndex: 6 }}>
                {
                    isLoggedIn && <Sidebar
                        sidebar={isSideBarOpen ?
                            <div className="">
                                <CreateCampaign campaignId={campaignId} currentOperationMode={currentOperationMode} setCurrentOperationMode={setCurrentOperationMode} toggleCampaignSideBar={toggleCampaignSideBar} campaignItem={campaignItem?.value[0]} systemRecords={systemRecords} productLineMediaItem={productLineMediaItem} systemMediaItem={systemMediaItem} campaignLogoItem={campaignLogoItem?.value[0]} currentCampaignLogo={currentCampaignLogo?.value[0]} />
                            </div>
                            : <></>
                        }
                        open={isSideBarOpen}
                        defaultSidebarWidth={0}
                        pullRight
                        touch
                        touchHandleWidth={20}
                        dragToggleDistance={30}
                        shadow={false}
                        styles={{
                            sidebar: {
                                backgroundColor: "#f0f0f0", width: "50%", zIndex: 6, position: 'fixed', top: '55px', borderRadius: "10px", transition: "transform .3s ease-out",
                                WebkitTransition: "-webkit-transform .3s ease-out",
                                willChange: "transform",
                            },
                            overlay: { backgroundColor: "none" }
                        }}
                        rootId="mainSidebar"
                    >
                        <br />
                    </Sidebar>
                }
            </div>
        </>
    )
}

export default EditCampaign