import React from 'react'
import { CardBody, Card, CardHeader, CardText, CardTitle, Button, CardFooter } from 'reactstrap'
import './subscription.scss'

const MySubscriptions = () => {
    return (
        <>
            <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
                <h2>My Subscriptions</h2>
                <div className="mt-4 w-50 text-center">
                    <Card className="subscription-card mt-4">
                        <CardHeader className="bg-secondary text-white text-center p-1">
                            <h4>
                                Monthly
                            </h4>
                        </CardHeader>
                        <CardBody className="text-secondary p-0 text-center">
                            {/* {/ <p>{subscriptionInfo.Product.ProductType.Description}</p> /} */}
                            <CardTitle><p>Dice Fanatics Annual Subscription</p></CardTitle>
                            <h4>$360/mo</h4>
                            <CardText className="subscription-card-description">Subscription</CardText>
                        </CardBody>
                        <CardFooter className="subscription-card-footer">
                            <Button className=" bg-white text-dark buy-now"> Cancel Subscripton</Button>
                            <Button className=" bg-white text-dark buy-now">Renew Subscripton</Button>
                        </CardFooter>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default MySubscriptions