export const url = {
    home: () => '/',

    catalog: () => '/shop/catalog',

    category: (category) => `/shop/catalog/${category.slug}`,

    product: (product) => `/shop/products/${product.slug}`,

    app: (app) => `/app/${app.slug}`,

    campaign: (campaign) => `/campaign/${campaign.slug}/${campaign.campaignId}`.replaceAll(" ", "-")
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}
