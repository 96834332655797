import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// application
//import * as serviceWorker from './serviceWorker';
import App from './App'
import store from './global/store'

// data
import { fetchUsers } from './apps/forum/users/usersSlice'

// authentication
import { Auth0Provider } from "@auth0/auth0-react";

// styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-input-range/lib/css/index.css';
import './scss/style.scss';

// import './api/server'

store.dispatch(fetchUsers())

ReactDOM.render(

  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      redirectUri={process.env.REACT_APP_LOGIN_HOST}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <App locale={window.navigator.language.slice(0, 2)} />
    </Auth0Provider>
  </Provider>,

  document.getElementById('root')
)

