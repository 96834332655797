import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";
import { tileTypeLabels } from "./data/TileTypeLabels";
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { addTiles } from "./adventureHexSlice";
import { TILE_TAG } from "common/tagFile";
import { isEmpty } from "lodash";

const HexPalette = ({ setNewSelectedTile }) => {
    const imageUrl = "https://res.cloudinary.com/wogd/image/upload/v1562770924/hex/t/";
    const dispatch = useDispatch();
    const tileTypesFromDb = useSelector((state) => state.adventureHexs.tiles);
    const [selectedOption, setSelectedOption] = useState(0);
    const [columnFilter, setColumnFilter] = useState({});
    const [selectedFilterName, setSelectedFilterName] = useState({ type: 'all', code: 'a', subCode: [] });
    const [selectedSubFilterName, setSelectedSubFilterName] = useState({ type: 'all', code: [] });
    const [tileTypes, setTileTypes] = useState(tileTypesFromDb);
    const sort = {
        sortDirection: 'none',
        accessor: 'some_accessor',
    };
    const selectedImageDesign = (value, index) => {
        document.getElementById(value).style.opacity = "1";
        setSelectedOption(value);
        if (selectedOption !== 0) {
            document.getElementById(selectedOption).style.opacity = "0.7";
        }
    }

    const [
        getAvatarCellRecord,
        {
            data: tileList,
            error,
            isLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (!isEmpty(columnFilter)) {
            let tileData = Object.entries(columnFilter);
            let filteredTiles = tileTypesFromDb.filter((value) => {
                if (value[tileData[0][0].toUpperCase()] !== "" && tileData[0][1].indexOf(value[tileData[0][0].toUpperCase()]) !== -1) {
                    return value;
                }
            });
            setTileTypes(filteredTiles);
        }
    }, [columnFilter])

    useEffect(() => {
        if (!isLoading && !error && tileList) {
            dispatch(addTiles(tileList.value))
        }
    }, [isLoading, error, tileList]);

    useEffect(() => {
        setTileTypes(tileTypesFromDb);
    }, [tileTypesFromDb]);

    var varientsId = [];
    return (
        <div className="text-white text-center ">
            <div >
                <h2>select hex</h2>

                <Input
                    name="Filter"
                    id="Filter"
                    type="select"
                    placeholder="Filter"
                    className='selection editHexFilter'
                    style={{ width: '60%', background: 'url("https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png")', backgroundPosition: '-827px 2579px', height: '35px', marginLeft: '70px', border: 'none', color: 'white' }}
                    value={selectedFilterName.type}
                    onChange={(e) => {
                        setColumnFilter([]);
                        setSelectedOption(0);
                        setSelectedFilterName({
                            ...selectedFilterName, type: e.target.value, code: e.target.value !== "all" ?
                                tileTypeLabels.filter((value) => value.name === e.target.value)?.[0].code
                                : 'all', subCode: e.target.value !== "all" ?
                                    tileTypeLabels.filter((value) => value.name === e.target.value)?.[0].values.map((value) => value.code)
                                    : []
                        });
                        setSelectedSubFilterName({ ...selectedSubFilterName, type: 'all', code: [] })
                        tileTypeLabels.filter((value) => value.name === e.target.value)?.[0]?.code &&
                            setColumnFilter({ [tileTypeLabels.filter((value) => value.name === e.target.value)?.[0].code]: tileTypeLabels.filter((value) => value.name === e.target.value)?.[0].values.map((value) => value.code) })
                    }}
                >
                    <option value="all" className="editHexFilterOption">Show all</option>
                    {tileTypeLabels && tileTypeLabels.map((item, index) =>
                        <option value={item.name} key={index} className="editHexFilterOption">{item.name}</option>
                    )}
                </Input>
                {selectedFilterName.type !== "all" &&
                    <div className="row">
                        <button className="col-3 m-1 editHexButton" color="primary" onClick={() => { setSelectedOption(0); setSelectedSubFilterName({ ...selectedSubFilterName, type: "all" }); setColumnFilter([]); }}>Show All</button>
                        {
                            tileTypeLabels.filter((name) => name.name === selectedFilterName.type)?.[0].values?.map((item, index) => {
                                return (
                                    <button key={index} className="col-3 m-1 editHexButton" color="primary" onClick={() => { setColumnFilter({ [selectedFilterName.code]: item.code }); setSelectedOption(0); setSelectedSubFilterName({ type: item.name, code: item.code }) }}>{item.name}</button>
                                )
                            })}
                    </div>}
            </div>
            <div id="images" className="d-flex flex-wrap hexImages">
                {
                    tileTypes.map((value, index) => {
                        if (varientsId.includes(value.Id)) {
                            return;
                        }
                        else {
                            var tileUrl = value.UrlFileName.substring(0, value.UrlFileName.length - 2);
                            var isHavingVarients = tileTypes.filter((tile) => tile.UrlFileName.includes(tileUrl));
                            var VarientsIds = isHavingVarients.map((item) => {
                                return (item.Id);
                            });
                            varientsId.push(...VarientsIds);
                        }
                        return (
                            <div className="d-flex flex-column" key={value.Id}>
                                <img
                                    onClick={() => { selectedImageDesign(value.Id, index); setNewSelectedTile(isHavingVarients) }}
                                    id={value.Id}
                                    alt={value.UrlFileName}
                                    className="hexTileDisplay" src={`${imageUrl}${value.UrlFileName}.png`}
                                    onError={(e) => { e.target.onerror = null; e.target.src = `${imageUrl}ve-di-gr-gr--ju----2.png` }}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default HexPalette;