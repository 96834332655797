import React, { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { AVATAR_CAMPAIGN_TAG, GAME_SYSTEM_TAG, GET_ALL_CAMPAIGN_LOGO_TAG, GET_ALL_PRODUCTLINE_MEDIA_TAG, PLAYER_AVATAR_DATA } from 'common/tagFile';
import BlockProductsCarousel from 'common/BlockProductsCarousel';
import Campaign from './Campaign';

const CampaignList = () => {
    const { isAuthenticated } = useAuth0()
    const history = useHistory()
    const [currentTabId, setCurrentTabId] = useState(0);
    const [featuredProducts, setFeaturedProduct] = useState({
        data: [],
        handleTabChange: function (tab) { setCurrentTabId(tab.id) },
        isLoading: true,
        tabs: []
    });
    const sort = {
        sortDirection: 'desc',
        accessor: 'Id',
    };
    const [isCampaignCreating, setIsCampaignCreating] = useState(false)
    const [pageSize, setPageSize] = useState(14);
    const [page, setPage] = useState(1);
    let player = useSelector((state) => state?.player?.players[0]);
    const [
        getCampaignLogo,
        {
            data: campaignLogoItem,
            isFetching: isGetAllCampaignLogoLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getCampaignLogo({
            columns: [],
            entity: "GetAllCampaignLogo",
            tag: GET_ALL_CAMPAIGN_LOGO_TAG,
            page: page,
            sort: sort,
            columnFilter: [],
            expandFilter: "Campaign ne null and Campaign/Player ne null and Campaign/Player/IsDeleted ne true",
            pageSize: pageSize,
            globalFilter: [],
            ...(currentTabId && currentTabId !== 0 && { queryParams: currentTabId }),
            expand: "CampaignLogoStatus,Campaign($filter=IsDeleted ne true;$expand=Player($filter=IsDeleted ne true))"
        })
    }, [currentTabId, page])

    const [
        getProductLineMedia,
        {
            data: productLineMediaItem,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getProductLineMedia({
            columns: [],
            entity: "GetAllProductLineMedia",
            tag: GET_ALL_PRODUCTLINE_MEDIA_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            expand: "GameMedia"
        })
    }, []);

    const [
        getSystemRecords,
        {
            data: systemRecords,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getSystemRecords({
            columns: [],
            entity: "GameSystem",
            tag: GAME_SYSTEM_TAG,
            page: 1,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
        })
    }, [])

    const [
        getAvatarCampaignRecords,
        {
            data: avatarCampaignRecords,
            isFetching: isAvatarCampaignRecordsFetching,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getAvatarCampaignRecords({
            columns: [],
            entity: "AvatarCampaign",
            tag: AVATAR_CAMPAIGN_TAG,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            expand: "Campaign,Avatar"
        })
    }, []);
    const [
        getPlayerAvatarRecords,
        {
            data: playerAvatarRecords,
            isFetching: isPlayerAvatarRecordsFetching
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        isAuthenticated && player && getPlayerAvatarRecords({
            columns: [],
            entity: "PlayerAvatar",
            sort: sort,
            tag: PLAYER_AVATAR_DATA,
            columnFilter: { PlayerId: player?.Id },
            globalFilter: [],
            expand: "Avatar"
        })
    }, [isAuthenticated, player]);

    useEffect(() => {
        let campTabs = [{
            id: 0,
            name: <FormattedMessage id="applications.BookBuilder.CreateBook.Heading.Types.All" defaultMessage="All" />,
            categorySlug: 'All',
            current: currentTabId === 0
        }];
        if (systemRecords?.value?.length) {
            systemRecords.value.forEach((tabs) => {
                campTabs.push({ id: tabs.Id, name: tabs.Name, categorySlug: tabs.Name, current: currentTabId === tabs.Id })
            })
            setPage(1)
            setIsCampaignCreating(true)
            setFeaturedProduct({ ...featuredProducts, isLoading: true, data: [...featuredProducts.data], tabs: campTabs })
        }
    }, [systemRecords, currentTabId])

    useEffect(() => {
        setFeaturedProduct((prev) => {
            return { ...prev, isLoading: isGetAllCampaignLogoLoading || isAvatarCampaignRecordsFetching || isPlayerAvatarRecordsFetching, data: [], tabs: [...prev.tabs] }
        })
        let container = [];
        if (campaignLogoItem?.value && avatarCampaignRecords?.value?.length && !isGetAllCampaignLogoLoading && !isAvatarCampaignRecordsFetching && !isPlayerAvatarRecordsFetching) {
            if (isCampaignCreating) {
                setFeaturedProduct({ isLoading: true, data: [], tabs: [...featuredProducts.tabs] })
            }
            campaignLogoItem.value.forEach((item) => {
                container.push({
                    name: item?.Campaign?.Name,
                    campaignId: item?.Campaign?.Id,
                    slug: item?.Campaign?.Name,
                    rating: 5,
                    data: { CampaignId: item?.Campaign?.Id },
                    images: item?.CampaignLogoStatus?.Status === "Pending" ? productLineMediaItem?.value?.length && productLineMediaItem.value.find((media) => media?.ProductLineId === item?.Campaign?.ProductLineId)?.GameMedia?.Url : item?.CampaignLogoUrl,
                    redirect: "/adventurehex",
                    ...(avatarCampaignRecords?.value.some((a) => playerAvatarRecords?.value.some((val) => val.AvatarId === a.AvatarId && a.CampaignId === item?.Campaign?.Id)) ? { isRequirePlayButton: "Play" } : { isRequirePlayButton: "Join" }),
                    Id: item?.Campaign?.Id,
                })
            })
            setFeaturedProduct({ ...featuredProducts, isLoading: false, data: isCampaignCreating ? [...container] : [...featuredProducts.data, ...container], tabs: [...featuredProducts.tabs] })
            setIsCampaignCreating(false)
        }
        // eslint-disable-next-line
    }, [campaignLogoItem, playerAvatarRecords, avatarCampaignRecords, isAvatarCampaignRecordsFetching, isPlayerAvatarRecordsFetching, isGetAllCampaignLogoLoading])

    const redirectHandler = (params) => {
        history.push(params)
    }

    return (
        <>
            <Campaign setIsCampaignCreating={setIsCampaignCreating} isCampaignCreating={isCampaignCreating} setPage={setPage} />
            <div>
                {useMemo(() => (
                    <BlockProductsCarousel
                        title={<FormattedMessage id="home.Carousel.Title.Campaign" defaultMessage="Campaign" />}
                        layout="grid-5"
                        rows={1}
                        products={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.data : []}
                        loading={featuredProducts.isLoading}
                        groups={featuredProducts.tabs}
                        onGroupClick={featuredProducts.handleTabChange}
                        redirect={"/adventurehex"}
                        campaignId={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.data : []}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        page={page}
                        setPage={setPage}
                        campaignLogoItem={campaignLogoItem}
                        callBack={redirectHandler}
                        addToFavoriteLogo
                        quickView
                        player={player}
                    />
                ), [featuredProducts.isLoading])}
            </div>
        </>
    )
}

export default CampaignList 