import React, { useEffect, useState } from "react";
import { Image } from "react-konva";
import useImage from "use-image";
const HexTerrain = ({ coord, hexId, highlighted, type }) => {
  const hexWidth = 87;
  const hexHeight = (hexWidth * 384) / 256 + 18;
  let url = "";
  if (type && type.Tile && type.Tile.UrlFileName) {
    url = `https://res.cloudinary.com/wogd/image/upload/v1562770924/hex/t/${type && type.Tile && type.Tile.UrlFileName}.png`;
  }
  const [image] = useImage(url);
  const [xCenter, setXCenter] = useState(window.innerWidth / 2);
  const [yCenter, setYCenter] = useState(window.innerHeight / 2);
  useEffect(() => {
    const windowsize = () => {
      setXCenter(window.innerWidth / 2);
      setYCenter(window.innerHeight / 2);
    }
    window.addEventListener('resize', windowsize)
  }, [])
  return (

    <Image
      image={image ? image : ''}
      x={coord.X + xCenter - 44}
      y={coord.Y + yCenter - 99}
      draggable={false}
      hexId={hexId}
      width={hexWidth}
      height={hexHeight}
      shadowColor="#FFFFFF"
      shadowBlur={highlighted ? 10 : 0}
    />
  );
};

export default HexTerrain;
