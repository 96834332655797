import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Spinner } from "reactstrap";

function DeactivateConfirmationModal(props) {
    const { avatarRecordStatus, entityName, isDeactivating, currentOperationMode, onBlur } = props;
    return (
        <Modal show={true} onHide={onBlur} centered={true} >
            <Modal.Header closeButton>
                <h4>{currentOperationMode} {entityName}</h4>
            </Modal.Header>
            <Modal.Body>Are you sure you want to {currentOperationMode} {entityName} ?</Modal.Body>
            <Modal.Footer>
                <Button onClick={onBlur}>Cancel</Button>
                <Button variant={currentOperationMode === "Deactivate" ? "danger" : "success"} disabled={isDeactivating} onClick={() => avatarRecordStatus()}>
                    <div className="d-flex">
                        {
                            isDeactivating &&
                            <Spinner animation="border" size="sm" variant="primary" className="campaignLoader" />
                        }{""}
                        {currentOperationMode}
                    </div>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeactivateConfirmationModal;