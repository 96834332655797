// react
import React, { useEffect, useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

// application
import StroykaSlick from './StroykaSlick';
import { useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { CAMPAIGN_TAG, GET_ALL_CAMPAIGN_LOGO_TAG, ZONE_CONTENT_TAG } from 'common/tagFile';
import defaultImage from "../../../assets/bgTalocMayan.jpg"

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: true,
    fade: true,
};

const BlockSlideshow = (props) => {
    const { withDepartments, zoneId } = props;
    const direction = 'ltr';
    const [blockCarousal, setBlockCarousal] = useState([]);
    const sort = {
        sortDirection: 'desc',
        accessor: 'Id',
    };
    const queryParams = useLocation()
    const params = useParams()
    const campaignId = Number(params?.campaignId);
    const blockClasses = classNames(
        'block-slideshow block',
        {
            'block-slideshow--layout--full': !withDepartments,
            'block-slideshow--layout--with-departments': withDepartments,
        },
    );

    const layoutClasses = classNames(
        'col-12',
        {
            'col-lg-12 g-0': !withDepartments,
            'col-lg-9 g-0': withDepartments,
        },
    );
    const [
        getZoneContentRecords,
        {
            data: zoneContent,
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        zoneId && getZoneContentRecords({
            columns: [],
            entity: "ZoneContent",
            page: 1,
            tag: ZONE_CONTENT_TAG,
            sort: sort,
            columnFilter: { ZoneId: zoneId },
            globalFilter: [],
            expand: "Content($expand=ContentMedias($expand=Media)),ZoneContentMedias($expand=Media),Component"
        })
    }, [zoneId])

    const [
        getCampaignRecord,
        {
            data: campaignItem,
        }
    ] = useLazyGetRecordsQuery();
    const [
        getCampaignLogo,
        {
            data: campaignLogo,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (campaignId) {
            getCampaignRecord({
                columns: [],
                entity: "Campaign",
                tag: CAMPAIGN_TAG,
                page: 1,
                sort: sort,
                columnFilter: { Id: campaignId },
                globalFilter: [],
                expand: "gameSystem($expand=gameMediaSystems($expand=gameMedia)),gameProductLine($expand=gameMediaProductLines($expand=gameMedia))"
            })
            getCampaignLogo({
                columns: [],
                entity: "GetAllCampaignLogo",
                tag: GET_ALL_CAMPAIGN_LOGO_TAG,
                page: 1,
                sort: sort,
                columnFilter: { campaignId: campaignId },
                globalFilter: [],
            })
        }
    }, [campaignId]);

    useEffect(() => {
        if (zoneContent?.value?.length) {
            let dataContainer = []
            if ((`${queryParams.pathname.slice(1, 9)}`).includes("campaign")) {
                if (campaignItem && campaignItem.value.length && campaignLogo?.value) {
                    dataContainer.push({
                        title: campaignItem.value[0].Name,
                        isCampaign: true,
                        productLineImage: campaignItem?.value[0]?.GameProductLine?.GameMediaProductLines[0]?.GameMedia.Url,
                        systemImage: campaignItem?.value[0].GameSystem?.GameMediaSystems[0]?.GameMedia.Url,
                        image_classic: {
                            ltr: campaignLogo?.value[0]?.CampaignLogoUrl !== "" ? campaignLogo?.value[0]?.CampaignLogoUrl : defaultImage,
                            rtl: campaignLogo?.value[0]?.CampaignLogoUrl !== "" ? campaignLogo?.value[0]?.CampaignLogoUrl : defaultImage,
                        },
                        image_full: {
                            ltr: campaignLogo?.value[0]?.CampaignLogoUrl !== "" ? campaignLogo?.value[0]?.CampaignLogoUrl : defaultImage,
                            rtl: campaignLogo?.value[0]?.CampaignLogoUrl !== "" ? campaignLogo?.value[0]?.CampaignLogoUrl : defaultImage,
                        },
                        image_mobile: {
                            ltr: campaignLogo?.value[0]?.CampaignLogoUrl !== "" ? campaignLogo?.value[0]?.CampaignLogoUrl : defaultImage,
                            rtl: campaignLogo?.value[0]?.CampaignLogoUrl !== "" ? campaignLogo?.value[0]?.CampaignLogoUrl : defaultImage,
                        },
                    })
                }
            } else {
                zoneContent.value.map((slide) => {
                    let contentRecords = JSON.parse(slide.Content.ContentObject)
                    return dataContainer.push({
                        title: contentRecords.title,
                        text: contentRecords.description,
                        image_classic: {
                            ltr: contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url,
                            rtl: contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url,
                        },
                        image_full: {
                            ltr: contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url,
                            rtl: contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url,
                        },
                        image_mobile: {
                            ltr: contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url,
                            rtl: contentRecords?.images.length > 1 ? contentRecords?.images[contentRecords?.images.length - 1]?.url : contentRecords?.images[0]?.url,
                        },
                    })
                })
            }
            setBlockCarousal(dataContainer);
        }
    }, [zoneContent, campaignLogo, campaignItem])

    const slides = blockCarousal.length ? blockCarousal.map((slide, index) => {
        const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];
        return (
            <div key={index} className="block-slideshow__slide">
                <div
                    className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                    style={{
                        backgroundImage: `url(${image})`,
                    }}
                />
                <div
                    className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                    style={{
                        backgroundImage: `url(${slide.image_mobile[direction]})`,
                    }}
                />
                <div className={`block-slideshow__slide-content ${slide.isCampaign ? 'd-flex justify-content-between align-items-end' : ""}`} style={{ width: "95%" }}>
                    {
                        slide?.productLineImage &&
                        <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: `<image src=${slide?.productLineImage} />` }}
                        />
                    }
                    <div
                        className="block-slideshow__slide-title"
                        dangerouslySetInnerHTML={{ __html: slide.title }}
                    />
                    {
                        slide.text && <div
                            className="block-slideshow__slide-text"
                            dangerouslySetInnerHTML={{ __html: slide.text }}
                        />
                    }
                    {
                        slide?.systemImage && <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: `<image src=${slide?.systemImage} />` }}
                        />
                    }
                    {
                        slide.buttonText && <div className="block-slideshow__slide-button">
                            <Link to="/" className="btn btn-primary btn-lg">{slide.buttonText}</Link>
                        </div>
                    }
                </div>
            </div>
        );
    }) : null
    return (
        <div className={blockClasses} style={{ zIndex: 5 }}>
            <div className="container">
                <div className="row">
                    {withDepartments && (
                        <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                    )}
                    <div className={layoutClasses}>
                        <div className="block-slideshow__body">
                            <StroykaSlick {...slickSettings}>
                                {slides}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
BlockSlideshow.propTypes = {
    withDepartments: PropTypes.bool,

    locale: PropTypes.string,
};

BlockSlideshow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});
export default connect(mapStateToProps)(BlockSlideshow);