import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { InputLabel, FormControl, MenuItem, Select } from '@mui/material';
import DeactivateConfirmationModal from 'common/DeactivateConfirmationModal';
import { PUBLICATION_TAG, PUBLICATION_SECTION_TAG, USER_PUBLICATION_TAG } from 'common/tagFile';
import { errorToast } from 'common/toast';
import { useDeleteMutation, useLazyGetRecordsQuery } from 'services/gamecodexApi';

const BookList = ({ selectedBook, setSelectedBook, setViewBook, setAppData, setShowModal, setBookImage, setCreateBook, setAppNameField, setStyleSheet, setCloneBtn }) => {

    const { isAuthenticated } = useAuth0()
    const [deactivateApplication, { isLoading: isDeactivating }] = useDeleteMutation()
    let player = JSON.parse(localStorage.getItem('playerRecord'));
    const [currentOperationMode, setCurrentOperationMode] = useState(false);
    const [currentApplication, setCurrentApplication] = useState(null);
    const sort = {
        sortDirection: 'desc',
        accessor: 'Id',
    };
    const currentUser = player?.UserId;

    const onApplicationEditHandler = (product, btn) => {
        setViewBook((prev) => prev?.btn === 'Edit' ? {
            id: product?.Id,
            btn: prev?.btn,
            name: product.Name,
            product,
        } : {
            id: product?.Id,
            btn,
            name: product.Name,
            product,
        })
    }

    const [
        getAllPublications,
        {
            data: publications,
            isLoading: isPublicationLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        if (currentUser) {
            getAllPublications({
                columns: [],
                entity: "UserPublication",
                tag: USER_PUBLICATION_TAG,
                sort: sort,
                columnFilter: { UserId: currentUser },
                globalFilter: [],
                expand: "Publication($expand=PublicationType,PublicationMedias($expand=Media),MasterClone)"
            })
        }
    }, [currentUser])

    const actionHandler = (params) => {
        setCurrentOperationMode("Deactivate");
        setCurrentApplication(params)
    }

    const addBook = (btn = false) => {
        !btn && setSelectedBook("")
        setShowModal(true)
        setCreateBook(false)
        setAppData("")
        setViewBook({ id: null, btn: null })
        setStyleSheet("dungeon")
        setAppNameField("")
        setBookImage("")
    }

    const onBlur = () => {
        setCurrentOperationMode(false)
    }

    const bookDeactivateHandler = () => {
        deactivateApplication({ entity: "Publication", id: currentApplication?.Id, tag: [PUBLICATION_TAG, PUBLICATION_SECTION_TAG, USER_PUBLICATION_TAG] }).then((resp) => {
            errorToast('Book removed successfully.')
            setSelectedBook("")
            setCreateBook(false)
            setAppData("")
            setViewBook({ id: null, btn: null })
            setStyleSheet("dungeon")
            setAppNameField("")
            setBookImage("")
            onBlur();
        })
    }

    return (
        <div className='mt-3 p-3'>
            {!isPublicationLoading ?
                <div>
                    <h3>Library</h3>
                    <div className='my-4'>
                        <FormControl className='book-list'>
                            <InputLabel id="demo-simple-select-helper-label">
                                Books
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                value={selectedBook || ""}
                                onChange={(e) => {
                                    onApplicationEditHandler(e.target.value, 'View')
                                    setSelectedBook(e.target.value)
                                }}
                                disabled={!isAuthenticated}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    PaperProps: {
                                        style: {
                                            maxHeight: '400px',
                                        },
                                    },
                                }}
                                label={'Books'}
                            >
                                {publications?.value?.length ? publications?.value.map((book, index) => {
                                    const pubName = book?.Publication?.Name.replace(`- ${book?.Publication?.MasterClone?.Name}`, "")
                                    return book?.Publication?.Name && <MenuItem key={`product_${index}`} className='all-epics' value={book?.Publication}>{pubName}</MenuItem>
                                }) : <MenuItem className='all-epics' disabled value={''}>No Records</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className='btn-collection'>
                        {isAuthenticated ? <>
                            <Button disabled={!selectedBook} onClick={() => onApplicationEditHandler(selectedBook, 'Edit')}>Edit Book</Button>
                            <Button disabled={!selectedBook} onClick={() => actionHandler(selectedBook)}>Delete Book</Button>
                            <Button onClick={() => {
                                addBook()
                                setCloneBtn(false)
                            }}>New Book</Button>
                            {selectedBook && <Button onClick={() => {
                                addBook("clone")
                                setCloneBtn(true)
                            }}>Clone Book</Button>}
                        </> : ''
                        }
                    </div>
                </div> :
                <div className='block block-products-carousel block-products-carousel--loading'>
                    <div className="block-products-carousel__preloader">
                    </div>
                </div>
            }
            {
                currentOperationMode && (currentOperationMode === "Deactivate") && <DeactivateConfirmationModal
                    setCurrentOperationMode={setCurrentOperationMode}
                    entityName={currentApplication.name}
                    isDeactivating={isDeactivating}
                    onBlur={onBlur}
                    currentOperationMode={currentOperationMode}
                    avatarRecordStatus={bookDeactivateHandler}
                />
            }
        </div>
    )
}

export default BookList