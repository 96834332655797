// react
import { useEffect, useState } from 'react';
// third-party
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { errorToast, successToast } from 'common/toast';
import { PLAYER_CAMPAIGN_TAG } from 'common/tagFile';
import { useAddMutation, useDeleteMutation } from 'services/gamecodexApi';

const AsyncAction = (props) => {
    let canceled = false;
    const [loading, setLoading] = useState(false);
    const { action, render, campaignId, myFavoriteCampaign } = props;
    const [addToFavoriteCampaign, { isLoading: isAdding }] = useAddMutation()
    const [removeFavorite, { isLoading: isRemoving }] = useDeleteMutation()
    const playerId = useSelector((state) => state.player.players[0])
    useEffect(() => {
        setLoading(isAdding || isRemoving)
    }, [isAdding, isRemoving])

    const addToFavorite = () => {
        addToFavoriteCampaign({
            entity: 'PlayerCampaign', data: {
                PlayerId: playerId.Id,
                CampaignId: campaignId,
                PlayerCampaignTypeId: 1
            },
            tag: PLAYER_CAMPAIGN_TAG

        }).then((resp) => {
            successToast(<FormattedMessage id="successToast.AsyncAction.CampaignAdded" defaultMessage="Campaign added to favorite successfully" />);
        }).catch((err) => {
            errorToast("Error while adding camping to favorite")
        })
    }
    const removeFromFavorite = () => {
        removeFavorite({
            entity: 'PlayerCampaign',
            id: myFavoriteCampaign.Id,
            tag: PLAYER_CAMPAIGN_TAG
        }).then((resp) => errorToast(<FormattedMessage id="errorToast.AsyncAction.Campaign.RemoveFavorite" defaultMessage='Campaign removed from favorite successfully' />))
    }

    const run = () => {
        if (loading || !action) {
            return;
        }
        setLoading(true)
        action().then(() => {
            if (canceled) {
                return;
            }
            setLoading(false)
        });
    };

    if (render) {
        return render({ run: run, addToFavorite: addToFavorite, removeFromFavorite: removeFromFavorite, loading });
    }
    return null
}

export default AsyncAction

AsyncAction.propTypes = {
    action: PropTypes.func,
    render: PropTypes.func,
};