import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper"
import { randomColor } from "./utils";
import ContentEditable from "react-contenteditable";
import { PlusIcon } from "svg";

const Cell = ({ value: initialValue, row: { index }, column: { id, dataType, options }, dataDispatch, maximumValue }) => {
    const [value, setValue] = useState({ value: initialValue, update: false });
    const [selectRef, setSelectRef] = useState(null);
    const [selectPop, setSelectPop] = useState(null);
    const [showSelect, setShowSelect] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [addSelectRef, setAddSelectRef] = useState(null);
    useEffect(() => {
        setValue({ value: initialValue, update: false });
    }, [initialValue]);

    useEffect(() => {
        if (value.update) {
            dataDispatch({ type: "update_cell", columnId: id, rowIndex: index, value: value.value });
        }
    }, [value, dataDispatch, id, index]);

    const onChange = (e) => {
        setValue({ value: id === "maximumValue" && Number(e.target.value) > maximumValue ? maximumValue.toString() : e.target.value, update: false });
    };

    function handleOptionKeyDown(e) {
        if (e.key === "Enter") {
            if (e.target.value !== "") {
                dataDispatch({
                    type: "add_option_to_column",
                    option: e.target.value,
                    backgroundColor: randomColor(),
                    columnId: id
                });
            }
            setShowAdd(false);
        }
    }

    function handleAddOption(e) {
        setShowAdd(true);
    }

    function handleOptionBlur(e) {
        if (e.target.value !== "") {
            dataDispatch({
                type: "add_option_to_column",
                option: e.target.value,
                backgroundColor: randomColor(),
                columnId: id
            });
        }
        setShowAdd(false);
    }

    const { styles, attributes } = usePopper(selectRef, selectPop, {
        placement: "bottom-start",
        strategy: "fixed"
    });

    useEffect(() => {
        if (addSelectRef && showAdd) {
            addSelectRef.focus();
        }
    }, [addSelectRef, showAdd]);

    let element;
    switch (dataType) {
        case "text":
            element = (
                <ContentEditable
                    html={(value.value && value.value.toString()) || ""}
                    onChange={onChange}
                    onBlur={() => setValue((old) => ({ value: old.value, update: true }))}
                    className='data-input'
                />
            );
            break;
        case "number":
            element = (
                <ContentEditable
                    disabled={id === "minimumValue"}
                    html={(value.value && value.value.toString()) || ""}
                    onChange={onChange}
                    onBlur={() => setValue((old) => ({ value: old.value, update: true }))}
                    className={`data-input text-center ${id === "minimumValue" && `data-input-disabled`}`}
                />
            );
            break;
        case "select":
            element = (
                <>
                    <div
                        ref={setSelectRef}
                        className='cell-padding d-flex cursor-default align-items-center flex-1'
                        onClick={() => setShowSelect(true)}>
                        {value.value}
                    </div>
                    {showSelect && <div className='overlay' onClick={() => setShowSelect(false)} />}
                    {showSelect && (
                        <div
                            className='shadow-5 bg-white border-radius-md'
                            ref={setSelectPop}
                            {...attributes.popper}
                            style={{
                                ...styles.popper,
                                zIndex: 4,
                                minWidth: 200,
                                maxWidth: 320,
                                padding: "0.75rem"
                            }}>
                            <div className='d-flex flex-wrap-wrap' style={{ marginTop: "-0.5rem" }}>
                                {options.map((option, index) => (
                                    <div
                                        key={`options_${index}`}
                                        className='cursor-pointer'
                                        style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
                                        onClick={() => {
                                            setValue({ value: option.label, update: true });
                                            setShowSelect(false);
                                        }}>
                                        {option.label}
                                    </div>
                                ))}
                                {showAdd && (
                                    <div
                                        style={{
                                            marginRight: "0.5rem",
                                            marginTop: "0.5rem",
                                            width: 120,
                                            padding: "2px 4px",
                                            backgroundColor: "#eeeeee",
                                            borderRadius: 4
                                        }}>
                                        <input
                                            type='text'
                                            className='option-input'
                                            onBlur={handleOptionBlur}
                                            ref={setAddSelectRef}
                                            onKeyDown={handleOptionKeyDown}
                                        />
                                    </div>
                                )}
                                <div
                                    className='cursor-pointer'
                                    style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
                                    onClick={handleAddOption}>
                                    <span className='svg-icon-sm svg-text'>
                                        <PlusIcon />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
            break;
        default:
            element = <span></span>;
            break;
    }

    return element;
}

export default Cell;    