import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
    name: "subscription",
    initialState: {
        sku: [],
        order: [],
        orderItem: [],
        countries: [],
        states: [],
        cities: []
    },
    reducers: {
        getSku: (state, action) => {
            state.sku = action.payload
        },
        getOrder: (state, action) => {
            state.order = action.payload
        },
        getOrderItem: (state, action)=> {
            state.orderItem = action.payload;
        },
        getCountries: (state, action)=> {
            state.countries = action.payload;
        },
        getStates:  (state, action)=> {
            state.states = action.payload;
        },
        getCities:  (state, action)=> {
            state.cities = action.payload;
        },
    },
});

export default subscriptionSlice.reducer;
export const { getSku, getOrder, getOrderItem, getCountries, getStates, getCities } = subscriptionSlice.actions

