import React, { useEffect, useState, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import BlockProductsCarousel from 'common/BlockProductsCarousel'
import DeactivateConfirmationModal from 'common/DeactivateConfirmationModal';
import { APPLICATION_TAG, APPLICATION_SCREEN_TAG } from 'common/tagFile';
import { errorToast } from 'common/toast';
import { useDeleteMutation, useLazyGetRecordsQuery } from 'services/gamecodexApi';
import { ADMIN_ROLE, GAME_MASTER_ROLE, SUPER_ADMIN_ROLE } from 'common/roleFile';
import plusIcon from './../../../../assets/icons8-plus-50.png'

const BookCarousel = ({ viewBook, setBookImage, setViewBook, setShowModal, setCreateBook, setAppNameField, setStyleSheet, setAppData }) => {

    const { isAuthenticated } = useAuth0()
    const [deactivateApplication, { isLoading: isDeactivating }] = useDeleteMutation()
    const [playerRole, setPlayerRole] = useState({});
    const [currentTabId, setCurrentTabId] = useState(0);
    let player = JSON.parse(localStorage.getItem('playerRecord'));
    const roles = useSelector((state) => state.siteRole.role);
    const [featuredProducts, setFeaturedProduct] = useState({
        data: [],
        handleTabChange: function (tab) { setCurrentTabId(tab.id) },
        isLoading: true,
        tabs: []
    });
    const [currentOperationMode, setCurrentOperationMode] = useState(false);
    const [currentApplication, setCurrentApplication] = useState(null);
    const sort = {
        sortDirection: 'desc',
        accessor: 'Id',
    };

    const onApplicationEditHandler = (product, btn) => {
        setViewBook({
            id: product?.Id,
            btn,
            name: product.name,
            product,
        })
    }

    const [
        getAllApplications,
        {
            data: applications,
            isLoading: isApplicationLoading,
        }
    ] = useLazyGetRecordsQuery();

    useEffect(() => {
        getAllApplications({
            columns: [],
            entity: "Publication",
            tag: APPLICATION_TAG,
            sort: sort,
            columnFilter: [],
            globalFilter: [],
            expand: "PublicationType,PublicationMedias($expand=Media)"
        })
    }, [])

    useEffect(() => {
        setPlayerRole(roles)
    }, [roles])

    useEffect(() => {
        setFeaturedProduct((prev) => {
            return { ...prev, isLoading: isApplicationLoading }
        })
        if (applications?.value?.length && !isApplicationLoading) {
            let container = []
            let invitationCard = []
            let applicationTabs = [
                {
                    id: 0,
                    name: <FormattedMessage id="applications.Carousel.Heading.Types.All" defaultMessage="All" />,
                    categorySlug: 'All',
                    current: currentTabId === 0
                },
                {
                    id: 1,
                    name: <FormattedMessage id="applications.BookBuilder.CreateBook.Heading.Types.Favourite" defaultMessage={"Favourite"} />,
                    categorySlug: 'favourite',
                    current: currentTabId === 1
                },
            ];
            applications.value.forEach((item) => {
                if (item?.PublicationType?.Name.includes('Publication')) {
                    if (!item.IsPrivate) {
                        container.push({
                            name: item.Name,
                            rating: 5,
                            data: { applicationMediaId: item?.PublicationMedias?.[0]?.Id, mediaId: item?.PublicationMedias?.[0]?.MediaId, mediaName: item?.PublicationMedias?.[0]?.Media.Name },
                            images: item?.PublicationMedias?.[0]?.Media?.Url || "https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/content/381/20240604103702720_pv2hb2gdo9e8tvmu5zmj.png",
                            Id: item.Id,
                            ...(Object.entries(playerRole).length && playerRole.some((role) =>
                                role.RoleType.Name.replace(" ", "").toLowerCase() === GAME_MASTER_ROLE.replace(" ", "").toLowerCase() || role.RoleType.Name.replace(" ", "").toLowerCase() === ADMIN_ROLE.replace(" ", "").toLowerCase() || role.RoleType.Name.replace(" ", "").toLowerCase() === SUPER_ADMIN_ROLE.replace(" ", "").toLowerCase()
                            ) && {
                                buttonValue: "Edit",
                                secondButtonValue: "Remove"
                            }),
                            isRequirePlayButton: "View",
                            isCampaignMaster: true
                        })
                    } else if (item?.UserId === player?.UserId) {
                        container.push({
                            name: item.Name,
                            rating: 5,
                            data: { applicationMediaId: item?.PublicationMedias?.[0]?.Id, mediaId: item?.PublicationMedias?.[0]?.MediaId, mediaName: item?.PublicationMedias?.[0]?.Media?.Name },
                            images: item?.PublicationMedias?.[0]?.Media?.Url || "https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/site/content/381/20240604103702720_pv2hb2gdo9e8tvmu5zmj.png",
                            Id: item.Id,
                            buttonValue: "Edit",
                            secondButtonValue: "Remove",
                            isRequirePlayButton: "View",
                            isCampaignMaster: true
                        })
                    }
                }
            })
            isAuthenticated && invitationCard.push({ default: true, secondButtonValue: "Add Book", images: plusIcon, setShowModal: setShowModal })
            setFeaturedProduct({ ...featuredProducts, isLoading: false, data: container, invitation: invitationCard, tabs: [...applicationTabs] })
        }
    }, [isApplicationLoading, applications, playerRole])

    const actionHandler = (params) => {
        setCurrentOperationMode(params?.secondButtonValue === "Remove" ? "Deactivate" : "")
        setCurrentApplication(params)
    }

    const addBook = () => {
        setShowModal(true)
        setCreateBook(false)
        setAppData("")
        setViewBook({ id: null, btn: null })
        setStyleSheet("dungeon")
        setAppNameField("")
        setBookImage("")
    }

    const onBlur = () => {
        setCurrentOperationMode(false)
    }
    const bookDeactivateHandler = () => {
        deactivateApplication({ entity: "Application", id: currentApplication?.Id, tag: [APPLICATION_TAG, APPLICATION_SCREEN_TAG] }).then((resp) => {
            errorToast(<FormattedMessage id="errorToast.BookCarousel.RemoveApplication" defaultMessage='Application removed successfully.' />)
            onBlur();
        })
        if (currentApplication?.Id === viewBook?.id) {
            setCreateBook(false)
            setAppData("")
            setViewBook({ id: null, btn: null })
            setStyleSheet("dungeon")
            setAppNameField("")
            setBookImage("")
        }
    }
    return (
        <>
            <div>
                {useMemo(() => (
                    <BlockProductsCarousel
                        title={<FormattedMessage id="applications.BookBuilder.CreateBook.Heading.Books" defaultMessage="Books" />}
                        layout="grid-4"
                        rows={1}
                        products={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.data : []}
                        loading={featuredProducts.isLoading}
                        groups={featuredProducts.tabs}
                        onGroupClick={featuredProducts.handleTabChange}
                        redirect={"/adventurehex"}
                        campaignId={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.data : []}
                        invitation={featuredProducts && featuredProducts.data && featuredProducts.data.length > 0 ? featuredProducts.invitation : []}
                        callBack={onApplicationEditHandler}
                        actionHandler={actionHandler}
                        addBook={addBook}
                    />
                ), [featuredProducts])}
            </div>
            {
                currentOperationMode && (currentOperationMode === "Deactivate" || currentOperationMode === "Activate") && <DeactivateConfirmationModal
                    setCurrentOperationMode={setCurrentOperationMode}
                    entityName={currentApplication.name}
                    isDeactivating={isDeactivating}
                    onBlur={onBlur}
                    currentOperationMode={currentOperationMode}
                    avatarRecordStatus={bookDeactivateHandler}
                />
            }
        </>
    )
}

export default BookCarousel